import { Absence, AbsenceWithCategory } from '../absences/absence';
import { Shift, ShiftWithActivitiesWithLocations } from '../shifts/shift';
import { StayDTO, Stay } from '../stays/stays';

export type Task<
  T = AbsenceWithCategory | ShiftWithActivitiesWithLocations | Stay,
> = T extends Shift
  ? T & { taskType: TaskType.Shift }
  : T extends Absence
  ? T & { taskType: TaskType.Absence }
  : T extends StayDTO
  ? T & { taskType: TaskType.Stay }
  : null;

export interface DayTasks {
  date: Date;
  isFirstOfMonth: boolean;
  tasks: Task[];
}

export enum TaskType {
  Shift = 'shift',
  Absence = 'absence',
  Stay = 'stay',
}
