<div>
  <div class="d-flex ps-1">
    <div class="me-3">
      <span class="fas fa-play-circle text-success"></span>
    </div>
    <div>
      <p class="fw-bold text-dark m-0">{{ startLocationName }}</p>
      <p class="form-text m-0">
        {{ 'page.services.from' | translate }}
      </p>
    </div>
  </div>

  <div
    cdkDropList
    cdkDropListLockAxis="y"
    cdkDropListOrientation="vertical"
    (cdkDropListDropped)="drop($event)"
  >
    <div
      class="row align-items-center ps-1 pe-3 py-2"
      *ngFor="let location of viaLocations(); let i = index"
      cdkDrag
    >
      <fa-icon class="col-1" [icon]="faBars" cdkDragHandle></fa-icon>
      <div class="col">
        <div
          *ngIf="
            'custom-locations' | featureFlagPure | async;
            else displayExistingViaLocations
          "
        >
          <wilson-custom-locations-autocomplete
            [selectedLocation]="location"
            [placeholder]="'page.service.via_points.placeholder' | translate"
            (locationChange)="changeLocation($event, i)"
          ></wilson-custom-locations-autocomplete>
        </div>
        <ng-template #displayExistingViaLocations>
          <wilson-location-autocomplete
            *ngIf="sectorName === 'rail'; else startAddressAutoComplete"
            [selectedLocation]="location"
            [placeholder]="'page.service.via_points.placeholder' | translate"
            (locationChange)="changeLocation($event, i)"
          >
          </wilson-location-autocomplete>
          <ng-template #startAddressAutoComplete>
            <wilson-address-autocomplete
              [selectedLocation]="location"
              [placeholder]="'page.service.via_points.placeholder' | translate"
              (selectLocation)="changeLocation($event, i)"
            ></wilson-address-autocomplete>
          </ng-template>
        </ng-template>
      </div>
      <button
        type="button"
        class="col-1 action-btn border-0 rounded bg-white shadow-sm d-flex align-items-center justify-content-center"
        (click)="removeLocation(i)"
      >
        <span class="fas fa-trash-alt text-danger"></span>
      </button>
    </div>
  </div>

  <div class="row align-items-center ps-1 pe-3 py-2">
    <div class="col-1"></div>
    <div class="col" *ngIf="!selectedLocation">
      <div
        *ngIf="
          'custom-locations' | featureFlagPure | async;
          else existingViaLocations
        "
      >
        <wilson-custom-locations-autocomplete
          [placeholder]="'page.service.via_points.placeholder' | translate"
          (locationChange)="selectedLocation = $event; setLocation($event)"
        ></wilson-custom-locations-autocomplete>
      </div>
      <ng-template #existingViaLocations>
        <wilson-location-autocomplete
          *ngIf="sectorName === 'rail'; else startAddressAutoComplete"
          [placeholder]="'page.service.via_points.placeholder' | translate"
          (locationChange)="selectedLocation = $event; setLocation($event)"
        >
        </wilson-location-autocomplete>
        <ng-template #startAddressAutoComplete>
          <wilson-address-autocomplete
            [placeholder]="'page.service.via_points.placeholder' | translate"
            (selectLocation)="selectedLocation = $event; setLocation($event)"
          ></wilson-address-autocomplete>
        </ng-template>
      </ng-template>
    </div>
    <div class="col-1"></div>
  </div>

  <div class="d-flex ps-1">
    <div class="me-3">
      <span
        class="icon-circle fas fa-flag text-white d-flex align-items-center justify-content-center"
      ></span>
    </div>
    <div>
      <p class="fw-bold text-dark m-0">{{ endLocationName }}</p>
      <p class="form-text m-0">
        {{ 'page.services.to' | translate }}
      </p>
    </div>
  </div>
</div>
