import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BackendService } from '@wilson/base';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { Profession } from '@wilson/interfaces';

@Injectable({
  providedIn: 'root',
})
export class ProfessionGateway extends BackendService<Profession> {
  protected readonly path = 'professions';
  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
  ) {
    super();
  }
}
