import { CommonModule } from '@angular/common';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ConfigModule } from '@wilson/config';
import { NotificationTimerService } from './notification-timer.service';
import { NotificationsService } from './notifications.service';

@NgModule({
  imports: [CommonModule, ConfigModule],
  providers: [
    NotificationsService,
    NotificationTimerService,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class NotificationsModule {}
