export enum ActivitiesListMode {
  View = 'view',
  Edit = 'edit',
  PlannedEdit = 'planned-edit',
  Live = 'live',
  SelfDispatchedEdit = 'self-dispatched-edit',
}

export enum ActivitiesListEditMode {
  Single = 'single',
  Multi = 'multi',
}
