import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-name-renderer2',
  template: `
    <div>
      <div
        nz-tooltip
        [nzTooltipTitle]="cellValue"
        [nzTooltipColor]="'#fff'"
        class="text-dark text-truncate"
      >
        {{ cellValue ? cellValue : '---' }}
      </div>
    </div>
  `,
})
export class NameRendererComponent implements AgRendererComponent {
  public cellValue: string;

  agInit(params: ICellRendererParams): void {
    this.cellValue = this.getValueToDisplay(params);
  }

  refresh(params: ICellRendererParams) {
    this.cellValue = this.getValueToDisplay(params);
    return true;
  }

  getValueToDisplay(params: ICellRendererParams) {
    return params.value ? params.value : null;
  }
}
