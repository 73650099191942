import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';

enum Sort {
  ASC = 'asc',
  DESC = 'desc',
}

@Component({
  selector: 'wilson-title-renderer',
  template: `
    <div
      class="d-flex justify-content-between"
      (click)="params.enableSorting && onSortRequested($event)"
    >
      <div class="list-header text-secondary fw-normal">
        {{ params.displayName | translate }}
      </div>
      <div
        class="sort-icon text-secondary d-flex flex-column"
        *ngIf="params.enableSorting"
      >
        <i class="fas fa-chevron-up" [class.text-dark]="order === Sort.ASC"></i>
        <i
          class="fas fa-chevron-down"
          [class.text-dark]="order === Sort.DESC"
        ></i>
      </div>
    </div>
  `,
  styles: [
    `
      .sort-icon {
        font-size: 8px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleRendererComponent implements IHeaderAngularComp {
  public params!: IHeaderParams;
  public order: 'asc' | 'desc' | null | undefined = null;
  public Sort = Sort;

  constructor(private readonly cdRef: ChangeDetectorRef) {}

  agInit(params: IHeaderParams): void {
    this.params = params;
    params.column.addEventListener(
      'sortChanged',
      this.onSortChanged.bind(this),
    );
    this.onSortChanged();
    this.checkDefaultSort(params.column['colDef'].sort);
  }

  refresh(params: IHeaderParams): boolean {
    this.params = params;
    this.checkDefaultSort(params.column['colDef'].sort);
    return true;
  }

  checkDefaultSort(sort: 'asc' | 'desc' | null | undefined) {
    this.order = sort ? sort : null;
  }

  onSortChanged() {
    this.order = null;
    if (this.params.column.isSortAscending()) {
      this.order = Sort.ASC;
    } else if (this.params.column.isSortDescending()) {
      this.order = Sort.DESC;
    } else {
      this.order = null;
    }
    this.cdRef.detectChanges();
  }

  onSortRequested(event: MouseEvent) {
    if (this.order === Sort.ASC) {
      this.order = Sort.DESC;
    } else if (this.order === Sort.DESC) {
      this.order = null;
    } else {
      this.order = Sort.ASC;
    }
    this.params.setSort(this.order, event.shiftKey);
  }
}
