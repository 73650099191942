import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { PublicationStatus, Shift, ShiftState } from '@wilson/interfaces';
import { map } from 'rxjs';

export const AUDIT_TRAIL_SHIFT_TRANSLATION_KEY_BASE = 'audit_trail.shift.';

export type AuditTrailShiftType =
  | 'created'
  | 'assigned'
  | 'released'
  | 'unreleased'
  | 'unassigned'
  | 'acceptedAZM'
  | 'reopened'
  | 'acceptedPayroll'
  | 'changedDate'
  | 'changedName'
  | 'unknown';

export interface AuditTrailShiftHistoryDelta extends Partial<Shift> {
  updatedById?: string;
}

export enum AuditTrailOperation {
  INSERT = 'INSERT',
  UPDATE = 'UPDATE',
}

export interface RawAuditTrailItem {
  historyDelta: AuditTrailShiftHistoryDelta;
  operation: AuditTrailOperation;
  timeStamp: string;
}

export interface AuditTrailItem extends RawAuditTrailItem {
  types: AuditTrailShiftType[];
}

@Injectable({
  providedIn: 'root',
})
export class AuditTrailShiftGateway {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
  ) {}

  getAuditTrailShift(shiftId: string) {
    return this.httpClient
      .get<RawAuditTrailItem[]>(`${this.config.host}/shifts/${shiftId}/history`)
      .pipe(
        map((rawAuditTrailShift) =>
          this.transformAuditTrailShift(rawAuditTrailShift),
        ),
      );
  }

  private transformAuditTrailShift(
    rawAuditTrailShift: RawAuditTrailItem[],
  ): AuditTrailItem[] {
    let currentUpdatedById = '';
    const auditTrailShift: AuditTrailItem[] = rawAuditTrailShift
      .reverse()
      .map((rawAuditTrailShiftItem: RawAuditTrailItem) => {
        const auditTrailTypes: AuditTrailShiftType[] = [];

        if (this.isCreatedAuditTrailItem(rawAuditTrailShiftItem)) {
          auditTrailTypes.push('created');
        } else {
          const conditions: {
            method: (auditTrailItem: RawAuditTrailItem) => boolean;
            type: AuditTrailShiftType;
          }[] = [
            { method: this.isAssignedAuditTrailItem, type: 'assigned' },
            { method: this.isReleasedAuditTrailItem, type: 'released' },
            { method: this.isUnreleasedAuditTrailItem, type: 'unreleased' },
            { method: this.isUnassignedAuditTrailItem, type: 'unassigned' },
            { method: this.isAcceptedAZMAuditTrailItem, type: 'acceptedAZM' },
            { method: this.isReopenedAuditTrailItem, type: 'reopened' },
            {
              method: this.isAcceptedPayrollAuditTrailItem,
              type: 'acceptedPayroll',
            },
            {
              method: this.isChangedDatePayrollAuditTrailItem,
              type: 'changedDate',
            },
            { method: this.isChangedNameAuditTrailItem, type: 'changedName' },
          ];

          conditions.forEach((condition) => {
            if (condition.method(rawAuditTrailShiftItem)) {
              auditTrailTypes.push(condition.type);
            }
          });
        }

        if (!auditTrailTypes.length) {
          auditTrailTypes.push('unknown');
        }

        if (rawAuditTrailShiftItem.historyDelta.updatedById) {
          currentUpdatedById = rawAuditTrailShiftItem.historyDelta.updatedById;
        }

        return {
          ...rawAuditTrailShiftItem,
          historyDelta: {
            ...rawAuditTrailShiftItem.historyDelta,
            updatedById: currentUpdatedById,
          },
          types: auditTrailTypes,
        };
      });
    return auditTrailShift.reverse();
  }

  private isCreatedAuditTrailItem(auditTrailItem: RawAuditTrailItem): boolean {
    return auditTrailItem.operation === 'INSERT';
  }

  private isAssignedAuditTrailItem(auditTrailItem: RawAuditTrailItem): boolean {
    return !!(
      auditTrailItem.historyDelta.userId &&
      auditTrailItem.historyDelta.userId !== null
    );
  }

  private isReleasedAuditTrailItem(auditTrailItem: RawAuditTrailItem): boolean {
    return (
      auditTrailItem.historyDelta.publicationStatus ===
      PublicationStatus.Published
    );
  }

  private isUnreleasedAuditTrailItem(
    auditTrailItem: RawAuditTrailItem,
  ): boolean {
    return (
      auditTrailItem.historyDelta.publicationStatus ===
        PublicationStatus.NotPublished ||
      auditTrailItem.historyDelta.publicationStatus ===
        PublicationStatus.NotPublishedAgain
    );
  }

  private isUnassignedAuditTrailItem(
    auditTrailItem: RawAuditTrailItem,
  ): boolean {
    return auditTrailItem.historyDelta.userId === null;
  }

  private isAcceptedAZMAuditTrailItem(
    auditTrailItem: RawAuditTrailItem,
  ): boolean {
    return auditTrailItem.historyDelta.state === ShiftState.AcceptedTimes;
  }

  private isReopenedAuditTrailItem(auditTrailItem: RawAuditTrailItem): boolean {
    return auditTrailItem.historyDelta.state === ShiftState.Reopened;
  }

  private isAcceptedPayrollAuditTrailItem(
    auditTrailItem: RawAuditTrailItem,
  ): boolean {
    return (
      auditTrailItem.historyDelta.state ===
      ShiftState.SubmittedToPayrollProvider
    );
  }

  private isChangedDatePayrollAuditTrailItem(
    auditTrailItem: RawAuditTrailItem,
  ): boolean {
    return !!auditTrailItem.historyDelta.startDate;
  }

  private isChangedNameAuditTrailItem(
    auditTrailItem: RawAuditTrailItem,
  ): boolean {
    return !!auditTrailItem.historyDelta.name;
  }
}
