import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { User } from '@wilson/interfaces';
import { Observable, filter, of, switchMap } from 'rxjs';
import { FetchAndAddUserIfNotInUsersState } from '../users-state/users-state.action';
import { UsersState } from '../users-state/users-state.state';

@Pipe({
  name: 'getUserById',
  standalone: true,
})
export class GetUserByIdPipe implements PipeTransform {
  constructor(private readonly store: Store) {}

  transform(id: string): Observable<User | undefined> {
    if (!id) {
      return of(undefined);
    }

    return this.store.dispatch(new FetchAndAddUserIfNotInUsersState(id)).pipe(
      switchMap(() => this.store.select(UsersState.userById(id))),
      filter((user) => !!user),
    );
  }
}
