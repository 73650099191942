import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
} from '@angular/core';
import { FilesService } from '@wilson/files';
import { Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'wilson-user-avatar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
})
export class UserAvatarComponent implements OnDestroy {
  @HostBinding('class') cssClassName = 'avatar';

  @Input() set s3Urn(s3Urn: string) {
    this.setUserAvatar(s3Urn);
  }

  @Input() set fallbackImage(url) {
    if (typeof url === 'string') {
      this.fallback.type = 'image';
      this.fallback.image = url;
    }
  }
  get fallbackImage() {
    return this.fallback.image;
  }

  @Input() set fallbackIcon(iconClass: boolean | string) {
    this.fallback.type = iconClass ? 'icon' : 'image';
    if (typeof iconClass === 'string') {
      this.fallback.icon = iconClass;
    }
  }
  get fallbackIcon(): string {
    return this.fallback.icon;
  }

  public fallback: { type: 'image' | 'icon'; image: string; icon: string } = {
    type: 'image',
    image: 'assets/img/profile/profile-img-placeholder-dark.svg',
    icon: 'far fa-user',
  };
  public isLoading = true;
  public signedImageUrl: string | undefined;

  private loadImageSubscription!: Subscription;

  constructor(
    private cd: ChangeDetectorRef,
    private filesService: FilesService,
  ) {}

  public setUserAvatar(s3ImageUrn: string | undefined) {
    if (!s3ImageUrn) {
      this.signedImageUrl = undefined;
      this.isLoading = false;
      this.cd.markForCheck();
      return;
    }

    this.isLoading = true;
    this.cd.markForCheck();

    // Unsubscribe in case of previous subscription is still alive
    this.loadImageSubscription?.unsubscribe();

    const signedImageUrl$ = this.filesService
      .getFileAccessFromCache(s3ImageUrn)
      .pipe(switchMap((file) => this.loadImage(file.accessUrl)));

    this.loadImageSubscription = signedImageUrl$.subscribe({
      next: (url) => {
        this.signedImageUrl = url;
      },
      complete: () => {
        this.isLoading = false;
        this.cd.markForCheck();
      },
      error: () => {
        if (s3ImageUrn) {
          this.filesService.uncacheFile(s3ImageUrn);
        }
        this.signedImageUrl = undefined;
        this.isLoading = false;
        this.cd.markForCheck();
      },
    });
  }

  public loadImage(url: string): Observable<string> {
    if (!url?.length) {
      throw Error('empty image url');
    }
    return new Observable<string>((subscriber) => {
      const img: HTMLImageElement = new Image();

      img.onprogress = () => {
        subscriber.next(url);
      };
      img.onload = () => {
        subscriber.next(url);
        subscriber.complete();
      };
      img.onerror = () => subscriber.error();

      img.src = url;
    });
  }

  public ngOnDestroy(): void {
    this.loadImageSubscription?.unsubscribe();
  }
}
