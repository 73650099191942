import {
  differenceInCalendarDays,
  differenceInDays,
  differenceInMinutes,
  startOfDay,
} from 'date-fns';

export interface GanttActivityPixelCalculationInput {
  startTime: string;
  endTime: string;
  frameLength: number;
  days: Date[];
}

export function calculateStartingPixel({
  startTime,
  frameLength,
  days,
}: Omit<GanttActivityPixelCalculationInput, 'endTime'>): number {
  const startDate = new Date(startTime);
  const timelineStart = startOfDay(days[0]);

  if (startDate < timelineStart) {
    const timeDifference = differenceInMinutes(timelineStart, startDate);
    const timeMultiplier = frameLength / days.length / 1440;
    return -1 * timeDifference * timeMultiplier; // Multiply by -1 to get the correct negative value
  } else {
    const dayOffset = differenceInDays(startDate, timelineStart);
    const timeOffset = differenceInMinutes(startDate, startOfDay(startDate));
    const dayMultiplier = frameLength / days.length;
    const timeMultiplier = frameLength / days.length / 1440;
    return dayOffset * dayMultiplier + timeOffset * timeMultiplier;
  }
}

export function calculateEndingPixel({
  startTime,
  endTime,
  frameLength,
  days,
}: GanttActivityPixelCalculationInput): number {
  const startDate = new Date(startTime);
  const endDate = new Date(endTime);

  const dayOffset = differenceInCalendarDays(startDate, startOfDay(days[0]));
  const timeOffset = differenceInMinutes(startDate, startOfDay(startDate));

  const dayMultiplier = frameLength / days.length;
  const timeMultiplier = frameLength / days.length / 1440;
  const duration = differenceInMinutes(endDate, startDate);
  return (
    frameLength -
    (dayOffset * dayMultiplier + timeOffset * timeMultiplier) -
    duration * timeMultiplier
  );
}
