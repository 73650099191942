import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthState } from '@wilson/auth/core';
import {
  LDClient,
  LDFlagChangeset,
  LDFlagSet,
  LDOptions,
  basicLogger,
  initialize,
} from 'launchdarkly-js-client-sdk';
import { ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';

export const LaunchDarklyClientId = new InjectionToken<string>(
  'LaunchDarklyClientId',
);

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsService {
  private ldClient!: LDClient;
  private flagSubject = new ReplaySubject<LDFlagSet>(1);
  private _userChanged = new ReplaySubject<void>(1);

  public readonly flag$ = this.flagSubject.asObservable();

  constructor(
    @Inject(LaunchDarklyClientId)
    private readonly launchDarklyClientId: string,
    private readonly store: Store,
  ) {}

  userChanged$() {
    return this._userChanged.asObservable();
  }

  async isLaunchDarklyReady() {
    return this.ldClient.waitUntilReady();
  }

  initialize(
    key: string,
    attributes?: Record<string, string>,
    options?: LDOptions,
  ): void {
    this.ldClient = initialize(
      this.launchDarklyClientId,
      {
        kind: 'user',
        key,
        anonymous: true,
        ...attributes,
      },
      {
        logger: basicLogger({
          level: 'warn',
        }),
        ...options,
      },
    );

    this.ldClient.on('change', (flags: LDFlagChangeset) => {
      const currentChangedFlag = Object.keys(flags).reduce(
        (allFlags: LDFlagChangeset, flag) => {
          allFlags[flag] = flags[flag].current;
          return allFlags;
        },
        {},
      );
      this.flagSubject.pipe(first()).subscribe((flags: LDFlagSet) => {
        this.flagSubject.next({
          ...flags,
          ...currentChangedFlag,
        });
      });
    });

    this.ldClient.on('ready', () => {
      this.flagSubject.next(this.ldClient.allFlags());
    });
  }

  changeUser(
    userKey: string,
    customAttributes?: Record<string, string>,
  ): Promise<LDFlagSet> {
    const user = this.store.selectSnapshot(AuthState.user);
    const rootOrgId = this.store.selectSnapshot(AuthState.rootOrgUnitId);

    if (!user || !rootOrgId) {
      throw new Error('User or rootOrgUnitId is missing');
    }

    return this.ldClient.identify(
      {
        kind: 'user',
        key: userKey,
        ...customAttributes,
        organizationalUnit: rootOrgId,
        userEmail: user.email,
        userId: user.id,
        anonymous: true,
      },
      undefined,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (err: Error | null, flags: LDFlagSet | null) => {
        if (err) {
          console.error('LD user change failed!', err);
        }
        this._userChanged.next();
      },
    );
  }
}
