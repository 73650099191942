import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { LabelsHelperService } from '../../services/labels-helper.service';
import { LabelsState } from '../../state/labels.state';
import { Label } from '@wilson/interfaces';

@Component({
  selector: 'wilson-display-labels',
  templateUrl: './display-labels.component.html',
  styleUrls: ['./display-labels.component.scss'],
})
export class DisplayLabelComponent implements OnChanges {
  @Input() maxAmount = 0;
  @Input() tooltipDirection = 'right';
  @Input() selectedLabels: Label[] = [];

  selectedLabels$!: Observable<Label[]>;

  constructor(
    private store: Store,
    public labelsHelperService: LabelsHelperService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedLabels']) {
      this.selectedLabels$ = this.store.select(
        LabelsState.getLabelsByIds(changes['selectedLabels'].currentValue),
      );
    }
  }
}
