import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-learn-renderer',
  template: `
    <span *ngIf="cellValue">
      <img src="assets/img/svg-icons/star.svg" />
      <span class="fw-bold text-success ms-2">{{
        'shift.assignment.wilson_learn_suggestion' | translate
      }}</span
      ><br />
    </span>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WilsonLearnRendererComponent implements AgRendererComponent {
  public cellValue: string;

  agInit(params: ICellRendererParams) {
    this.cellValue = this.getValueToDisplay(params);
  }

  refresh(params: ICellRendererParams) {
    this.cellValue = this.getValueToDisplay(params);
    return true;
  }

  getValueToDisplay(params: ICellRendererParams) {
    return params?.value;
  }
}
