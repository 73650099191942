import { Activity, OperationStatus } from '@wilson/interfaces';

export function isOneActivityStarted(
  activities: Pick<Activity, 'operationalStatus'>[],
): boolean {
  return !!activities?.find(
    (activity) =>
      activity.operationalStatus === OperationStatus.Ongoing ||
      activity.operationalStatus === OperationStatus.Paused,
  );
}
