import { GeoLocation } from '@wilson/interfaces';

export class AddLocationSelectionHistory {
  static readonly type =
    '[LocationSelectionHistoryState] AddLocationSelectionHistory';
  constructor(public readonly location: GeoLocation) {}
}

export class ClearLocationSelectionHistory {
  static readonly type =
    '[LocationSelectionHistoryState] ClearLocationSelectionHistory';
}

export class DeleteLocationSelectionHistory {
  static readonly type =
    '[LocationSelectionHistoryState] DeleteLocationSelectionHistory';
  constructor(public readonly locationId: string) {}
}
