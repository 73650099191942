import { Injectable } from '@angular/core';
import { FeatureFlagNumberPipe } from '@wilson/feature-flags';
import { GeoLocation } from '@wilson/interfaces';
import Fuse, { FuseResult } from 'fuse.js';
import { map, Observable, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GeoLocationFilterService {
  constructor(private readonly featureFlagNumberPipe: FeatureFlagNumberPipe) {}

  public filterGeoLocations(
    searchKey: string,
    searchTargets: Observable<Pick<GeoLocation, 'name' | 'locationCode'>[]>,
    rawFuseResults?: false,
    maxResults?: number,
  ): Observable<Pick<GeoLocation, 'name' | 'locationCode' | 'id'>[]>;

  public filterGeoLocations(
    searchKey: string,
    searchTargets: Observable<Pick<GeoLocation, 'name' | 'locationCode'>[]>,
    rawFuseResults?: true,
    maxResults?: number,
  ): Observable<FuseResult<Pick<GeoLocation, 'name' | 'locationCode'>>[]>;

  public filterGeoLocations(
    searchKey: string,
    searchTargets: Observable<Pick<GeoLocation, 'name' | 'locationCode'>[]>,
    rawFuseResults = false,
    maxResults = 25,
  ) {
    return this.featureFlagNumberPipe
      .transform('portal-mobile-fuzzy-location-search-threshold')
      .pipe(
        switchMap((fuseSearchThreshold) => {
          return this.fuseSearch(
            searchKey,
            searchTargets,
            fuseSearchThreshold,
          ).pipe(
            map((results) => {
              if (rawFuseResults) {
                return results.slice(0, maxResults);
              } else {
                return results
                  .slice(0, maxResults)
                  .map((result) => result.item);
              }
            }),
          );
        }),
      );
  }

  private fuseSearch(
    searchKey: string,
    searchTargets: Observable<Pick<GeoLocation, 'name' | 'locationCode'>[]>,
    fuseSearchThreshold: number,
  ): Observable<FuseResult<Pick<GeoLocation, 'name' | 'locationCode'>>[]> {
    return searchTargets.pipe(
      map((targets) => {
        const fuse = new Fuse(targets, {
          keys: ['name', 'locationCode'],
          threshold: fuseSearchThreshold,
          includeScore: true,
          includeMatches: true,
          ignoreLocation: true,
          isCaseSensitive: false,
          shouldSort: true,
        });

        return fuse.search(searchKey);
      }),
    );
  }
}
