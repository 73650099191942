import { Base } from '@wilson/base';

export function joinById<T extends Base>(left: T[], right: T[]): T[] {
  return right.reduce((acc: T[], curr) => {
    const existingEntryIndex = acc.findIndex((data) => data.id === curr.id);

    if (existingEntryIndex > -1) {
      acc.splice(existingEntryIndex, 1, curr);
    } else {
      acc.push(curr);
    }

    return acc;
  }, left);
}
