import { Base } from '@wilson/base';

export interface ShiftScore extends Base {
  name: ShiftScoreName;
  score: number;
  shiftId: string;
}

export enum ShiftScoreName {
  ShiftPreferenceScore = 'shift-preference-score',
  ShiftWorkTimePreferenceScore = 'shift-work-time-preference-score',
  ShiftLocationPreferenceScore = 'shift-location-preference-score',
  ShiftVehiclePreferenceScore = 'shift-vehicle-preference-score',
  ShiftEndAtHomePreferenceScore = 'shift-end-at-home-preference-score',
  CompanyShiftTimePreferenceScore = 'company-shift-time-preference-score',
  LongShiftPreferenceScore = 'long-shift-preference-score',
  ShiftProfitabilityScore = 'shift-profitability-score',
}

export enum ShiftSmartScoreName {
  ShiftSmartScore = 'shift-smart-score',
  ShiftTransferScore = 'shift-transfer-score',
  ShiftWorktimeScore = 'shift-worktime-score',
  ShiftProductivityScore = 'shift-productivity-score',
  ShiftRequirementsScore = 'shift-requirements-score',
  RenewQualificationsScore = 'renew-qualifications-score',
  QualificationFlexibilityScore = 'qualification-flexibility-score',
  RestTimeBetweenShiftsScore = 'rest-time-between-shifts-score',
  RobustnessScore = 'robustness-score',
  OvertimeAccountScore = 'overtime-account-score',
}
