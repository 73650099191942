import { OperativeReport } from '@wilson/interfaces';

export class CreateOperativeReport {
  static readonly type = '[OperativeReports] CreateOperativeReport';
  constructor(public newOperativeReport: OperativeReport) {}
}

export class ClearOperativeReportsState {
  static readonly type = '[OperativeReports] ClearState';
}

export class SetupSyncForAllUnrecievedOperativeReports {
  static readonly type = '[OperativeReports] Syncing non recieved reports';
}
