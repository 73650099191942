import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToQualificationCategoryPipe } from './to-qualification-category.pipe';
import { QualificationCategoriesService } from './qualification-categories.service';

@NgModule({
  imports: [CommonModule],
  providers: [QualificationCategoriesService],
  declarations: [ToQualificationCategoryPipe],
  exports: [ToQualificationCategoryPipe],
})
export class QualificationCategoriesModule {}
