import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Profession } from '@wilson/interfaces';
import { finalize, tap } from 'rxjs/operators';
import { InitializeProfessionState } from './profession.actions';
import { ProfessionHelperService } from '../services/profession-helper.service';

export interface ProfessionStateModel {
  professions: Profession[];
  isLoading: boolean;
}

@State<ProfessionStateModel>({
  name: 'profession',
  defaults: { professions: [], isLoading: false },
})
@Injectable()
export class ProfessionState {
  constructor(private professionHelperService: ProfessionHelperService) {}

  @Selector()
  static professions(state: ProfessionStateModel): Profession[] {
    return state.professions;
  }

  @Action(InitializeProfessionState)
  async initializeProfessionState(context: StateContext<ProfessionStateModel>) {
    context.patchState({ isLoading: true });

    return this.professionHelperService.getProfessions().pipe(
      tap((professions) => {
        context.patchState({ professions });
      }),
      finalize(() => {
        context.patchState({ isLoading: false });
      }),
    );
  }
}
