import { Pipe, PipeTransform } from '@angular/core';
import * as LinkifyIt from 'linkify-it';

@Pipe({
  name: 'linkify',
})
export class LinkifyPipe implements PipeTransform {
  transform(value: string): string {
    const matches = LinkifyIt().match(value);

    matches?.forEach(({ text, url }) => {
      value = value.replace(text, `<a href="${url}">${text}</a>`);
    });

    return value;
  }
}
