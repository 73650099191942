import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import {
  Action,
  NgxsAfterBootstrap,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { AuthState } from '@wilson/auth/core';
import { GeoLocation } from '@wilson/interfaces';
import { LocationsService } from '@wilson/locations';
import { map, take, tap } from 'rxjs';
import { InitializeCustomLocationsStateState } from './custom-locations.action';

interface CustomLocationsStateModal {
  customLocations: GeoLocation[];
}
export const LOCATIONS_STATE_NAME = 'customLocations';
const defaultOperationsState: CustomLocationsStateModal = {
  customLocations: [],
};

@State<CustomLocationsStateModal>({
  name: LOCATIONS_STATE_NAME,
  defaults: defaultOperationsState,
})
@Injectable()
export class CustomLocationsState implements NgxsAfterBootstrap {
  constructor(
    private readonly locationsService: LocationsService,
    private readonly store: Store,
    private readonly storage: Storage,
  ) {}

  @Selector()
  static customLocations(state: CustomLocationsStateModal): GeoLocation[] {
    return state.customLocations;
  }

  @Selector()
  static highlightedCustomLocations(
    state: CustomLocationsStateModal,
  ): GeoLocation[] {
    return state.customLocations.filter((locations) => locations.isHighlighted);
  }

  async ngxsAfterBootstrap(
    ctx: StateContext<CustomLocationsStateModal>,
  ): Promise<void> {
    const locationsState = await this.storage.get(LOCATIONS_STATE_NAME);
    if (locationsState) ctx.patchState(locationsState);
  }

  @Action(InitializeCustomLocationsStateState)
  initializeCustomLocationsStateState(
    ctx: StateContext<CustomLocationsStateModal>,
  ) {
    const rootOrgUnit = this.store.selectSnapshot(
      AuthState.rootOrgUnitId,
    ) as string;
    return this.locationsService
      .getAll({
        where: {
          organizationalUnitId: rootOrgUnit,
        },
        relations: ['address', 'locationCategory'],
      })
      .pipe(
        take(1),
        map((locations) => {
          return {
            customLocations: locations,
          };
        }),
        tap((locationsState) => {
          ctx.patchState(locationsState);
          this.storage.set(LOCATIONS_STATE_NAME, locationsState);
        }),
      );
  }
}
