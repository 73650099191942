<div class="copy-button-container" (click)="copyValueToClipboard()">
  <fa-icon
    [ngClass]="isBeingCopied ? 'fade-out detail-icon' : 'fade-in detail-icon'"
    [icon]="faCopyIcon"
  >
  </fa-icon>
  <fa-icon
    [ngClass]="isBeingCopied ? 'fade-in success-icon' : 'fade-out success-icon'"
    [icon]="faCheckIcon"
  >
  </fa-icon>
</div>
