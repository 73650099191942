import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '@wilson/interfaces';

@Pipe({
  name: 'addressFormat',
})
export class AddressPipe implements PipeTransform {
  transform(
    address: Pick<Address, 'street' | 'postCode' | 'city' | 'country'>,
  ): string {
    let formattedAddress = '';
    if (address?.street?.trim()) {
      formattedAddress += `${address.street}, `;
    }
    if (address?.postCode?.trim()) {
      formattedAddress += `${address.postCode} `;
    }
    if (address?.city?.trim()) {
      formattedAddress += `${address.city}, `;
    }
    if (address?.country?.trim()) {
      formattedAddress += `${address.country}`;
    }
    formattedAddress = formattedAddress.replace(/,\s*$/, '');
    return formattedAddress;
  }
}
