import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, filter, map, of, switchMap } from 'rxjs';
import { FetchAndAddUserIfNotInUsersState } from '../users-state/users-state.action';
import { UsersState } from '../users-state/users-state.state';

@Pipe({
  name: 'userName',
})
export class UserNamePipe implements PipeTransform {
  constructor(private readonly store: Store) {}

  transform(
    userId: string | undefined | null,
    emptyStringOnError = false,
  ): Observable<string> {
    if (!userId) {
      return of(this.handleError(emptyStringOnError));
    }
    return this.store
      .dispatch(new FetchAndAddUserIfNotInUsersState(userId))
      .pipe(
        switchMap(() => this.store.select(UsersState.userById(userId))),
        filter((user) => !!user),
        map((user) =>
          user
            ? `${user.firstName} ${user.lastName}`
            : this.handleError(emptyStringOnError),
        ),
      );
  }

  private handleError(emptyStringOnError: boolean) {
    if (emptyStringOnError) {
      return '';
    } else {
      return 'n/a';
    }
  }
}

@NgModule({
  imports: [CommonModule],
  declarations: [UserNamePipe],
  exports: [UserNamePipe],
})
export class UserNamePipeModule {}
