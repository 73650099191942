import { BehaviorSubject } from 'rxjs';

/**
 *
 * Automatically create a behavior subject with getter and setter for your property
 * Example:
 *
 * class MyStateService {
 *  @WilsonState<string[]>([])
 *  userIds!: string[];
 *  userIds$!: Observable<string[]>;
 * }
 *
 * Usage:
 *
 * const myService = new MyStateService();
 *
 * // Setting value
 * myService.userIds = ['123'];
 *
 * // Reading value
 * console.log(myService.userIds)
 *
 * // Subscribing to value stream
 * myService.userIds$.subscribe((ids) => {console.log(ids)})
 */
export function WilsonState<T>(defaultValue: T) {
  //eslint-disable-next-line
  return function (target: any, propertyKey: string) {
    const subject = new BehaviorSubject<T>(defaultValue);

    Object.defineProperty(target, propertyKey, {
      get: () => subject.value,
      set: (value: T) => subject.next(value),
    });

    Object.defineProperty(target, `${propertyKey}$`, {
      get: () => subject.asObservable(),
    });
  };
}
