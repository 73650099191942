import { GeoLocation } from '@wilson/interfaces';

export class FetchSelectedLocation {
  static readonly type = '[LocationPreferences] Fetch Selected Location';
}

export class SelectLocation {
  static readonly type = '[LocationPreferences] Select Location';
  constructor(
    public payload: {
      location: GeoLocation;
    },
  ) {}
}

export class UnselectLocation {
  static readonly type = '[LocationPreferences] Unselect Location';
  constructor(
    public payload: {
      selectedLocationPreferenceId: string;
    },
  ) {}
}

export class UnselectAllLocations {
  static readonly type = '[LocationPreferences] Unselect All Locations';
}
