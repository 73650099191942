import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BackendService, Base } from '@wilson/base';
import { ConfigOptions, ConfigService } from '@wilson/config';

export interface UserSharedByMe extends Base {
  firstName: string;
  lastName: string;
  sharedUsers: SharedUser[];
}

export interface SharedUser extends Base {
  sharedAt: string;
  approvedAt: string;
  approvalStatus: SharedUserApprovalStatus;
  professionIds: string[];
}

export enum SharedUserApprovalStatus {
  APPROVED = 'approved',
  IN_REVIEW = 'in-review',
  OPEN = 'open',
  TEMPORARY_DECLINED = 'temporary-declined',
  DECLINED = 'declined',
}

@Injectable({
  providedIn: 'root',
})
export class ClientPartnerShipGateway extends BackendService<UserSharedByMe> {
  protected readonly path = 'partnerships';
  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
  ) {
    super();
  }
}
