import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { QualificationName, ShiftValidationDetails } from '@wilson/interfaces';

@Component({
  selector: 'wilson-qualification-details',
  templateUrl: './qualification-details.component.html',
  styleUrls: ['./qualification-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QualificationDetailsComponent implements OnChanges {
  public faValidIcon = faCheckCircle;
  public faInValidIcon = faTimesCircle;
  public groupedQualifications: Record<string, QualificationName[]> = {};

  @Input() qualificationNames: QualificationName[] = [];
  @Input() professionValidations: ShiftValidationDetails[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if (changes['qualificationNames']) {
      this.groupedQualifications = this.groupByQualificationType(
        this.qualificationNames,
      );
    }
  }

  groupByQualificationType(qualifications: QualificationName[]) {
    return qualifications.reduce(
      (grouped: Record<string, QualificationName[]>, qualification) => {
        const key = qualification.qualificationType;
        if (!grouped[key]) {
          grouped[key] = [];
        }
        grouped[key].push(qualification);
        return grouped;
      },
      {},
    );
  }
}
