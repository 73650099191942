/* eslint-disable @nx/enforce-module-boundaries */
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { SetBulkEditDrawerSaveStatus } from '@wilson/activities/states';
import { TypeOfGood } from '@wilson/activities/symbols';
import { ServiceSeriesGateway, ServicesGateway } from '@wilson/api/gateway';
import {
  ActivityTemplate,
  convertWeekDayStringsInWeekdayObject,
  OrganizationalUnit,
  ServiceActivityStatus,
  ServiceOperationalStatus,
  ServiceSeriesEditLogs,
} from '@wilson/interfaces';
import { OrganizationalUnitService } from '@wilson/organizational-unit';
import { QualificationsState } from '@wilson/qualifications/state';
import {
  ServiceDetailSegment,
  ServiceDetailStateModel,
} from '@wilson/services/interfaces';
import { chain, cloneDeep } from 'lodash';
import { NzMessageService } from 'ng-zorro-antd/message';
import { map, Observable, take, tap } from 'rxjs';
import {
  BulkUpdateServiceSeries,
  ClearServiceDetailState,
  GetUnitTransportGoods,
  InitializeServiceDetailState,
  InitializeServiceSeriesDetailState,
  UpdateSeries,
} from './service-detail.action';
import { constructUpdatedActivityTemplates } from './constructUpdatedActivityTemplates.fn';

export const SERVICE_DETAIL_STATE_NAME = 'serviceDetail';
const defaultServiceDetailState: ServiceDetailStateModel = {
  id: null,
  name: null,
  organizationalUnit: null,
  recurring: false,
  weekdays: null,
  startDate: null,
  endDate: null,
  comment: null,
  segments: [],
  serviceSeries: null,
  activityStatus: null,
  operationalStatus: null,
  activityTemplates: null,
  labels: [],
  trainNumber: null,
};

@State<ServiceDetailStateModel>({
  name: SERVICE_DETAIL_STATE_NAME,
  defaults: defaultServiceDetailState,
})
@Injectable()
export class ServiceDetailState {
  constructor(
    private readonly servicesGateway: ServicesGateway,
    private readonly serviceSeriesGateway: ServiceSeriesGateway,
    private readonly store: Store,
    private readonly organizationalUnitService: OrganizationalUnitService,
    private readonly toastMessage: NzMessageService,
    private readonly translate: TranslateService,
  ) {}

  @Selector()
  static service(state: ServiceDetailStateModel): ServiceDetailStateModel {
    return state;
  }

  @Selector()
  static serviceId(state: ServiceDetailStateModel): string | null {
    return state.id;
  }

  @Selector()
  static serviceName(state: ServiceDetailStateModel): string | null {
    return state.name;
  }

  @Selector()
  static organizationalUnitId(
    state: ServiceDetailStateModel,
  ): string | undefined {
    return state.organizationalUnit.id;
  }

  @Selector()
  static isRecurring(state: ServiceDetailStateModel): boolean {
    return state.recurring;
  }

  @Selector()
  static serviceSegments(
    state: ServiceDetailStateModel,
  ): ServiceDetailSegment[] {
    return state.segments;
  }

  @Selector()
  static serviceActivityStatus(
    state: ServiceDetailStateModel,
  ): ServiceActivityStatus {
    return state.activityStatus;
  }

  @Selector()
  static serviceOperationalStatus(
    state: ServiceDetailStateModel,
  ): ServiceOperationalStatus {
    return state.operationalStatus;
  }

  @Selector()
  static selectOrganizationalUnitTransportGood(
    state: ServiceDetailStateModel,
  ): TypeOfGood[] {
    return state.organizationalUnit.organizationalUnitTransportGoods;
  }

  @Selector()
  static serviceSeriesEditLogs(
    state: ServiceDetailStateModel,
  ): ServiceSeriesEditLogs[] | undefined {
    return state.serviceSeriesEditLogs;
  }

  @Action(InitializeServiceDetailState)
  initializeState(
    ctx: StateContext<ServiceDetailStateModel>,
    action: InitializeServiceDetailState,
  ): Observable<ServiceDetailStateModel | null> {
    return this.servicesGateway
      .getServices(
        {
          where: {
            id: action.serviceId,
          },
          relations: [
            'organizationalUnit',
            'activities',
            'activities.activityCategory',
            'activities.activityReports',
            'activities.activityReports.location',
            'activities.startLocation',
            'activities.endLocation',
            'activities.agreement',
            'activities.agreement.client',
            'activities.job',
            'activities.profession',
            'serviceSeries',
            'serviceSeries.serviceSeries',
            'activities.serviceDeviations',
            'labels',
          ],
          order: {},
          limit: 0,
          offset: 0,
        },
        {
          withActivityStatus: true,
          withOperationalStatus: true,
        },
      )
      .pipe(
        take(1),
        map((services) => {
          const service = services[0];
          return {
            id: service.id,
            name: service.name,
            organizationalUnit: service.organizationalUnit,
            comment: service.comment,
            trainNumber: service.trainNumber,
            startDate: service.startDate,
            endDate: service.startDate, // this could be null
            recurring: false,
            weekdays: null,
            segments: this.generateSegments(service.activities),
            serviceSeries: service.serviceSeries,
            activityStatus: service.activityStatus,
            operationalStatus: service.operationalStatus,
            activities: service.activities,
            labels: service.labels,
          };
        }),
        tap((serviceDetailState) => {
          ctx.setState(serviceDetailState);
          this.store.dispatch(new GetUnitTransportGoods(serviceDetailState));
        }),
      );
  }

  @Action(GetUnitTransportGoods)
  getUnitTransportGoods(
    { patchState }: StateContext<ServiceDetailStateModel>,
    { serviceDetail }: GetUnitTransportGoods,
  ) {
    return this.organizationalUnitService
      .getOrganizationalUnit(serviceDetail.organizationalUnit?.id, [
        'organizationalUnitTransportGoods',
      ])
      .pipe(
        map((orgUnit: OrganizationalUnit) => {
          patchState({
            organizationalUnit: orgUnit,
          });
        }),
      );
  }

  @Action(InitializeServiceSeriesDetailState)
  initializeServiceSeriesDetailState(
    ctx: StateContext<ServiceDetailStateModel>,
    action: InitializeServiceSeriesDetailState,
  ): Observable<ServiceDetailStateModel | null> {
    return this.serviceSeriesGateway
      .getServiceSeriesForDetailsPage(action.serviceSeriesId)
      .pipe(
        take(1),
        map((series) => {
          const serviceSeries = series[0];
          return {
            id: serviceSeries.id,
            name: serviceSeries.name,
            organizationalUnit: serviceSeries.organizationalUnit,
            comment: serviceSeries.comment,
            trainNumber: serviceSeries.trainNumber,
            startDate: serviceSeries.validFrom,
            endDate: serviceSeries.validTill,
            recurring: true,
            weekdays: convertWeekDayStringsInWeekdayObject(
              serviceSeries.recurringOn,
            ),
            segments: this.generateSegments(serviceSeries.activityTemplates),
            serviceSeries: null,
            activityStatus: null,
            operationalStatus: null,
            activityTemplates: serviceSeries.activityTemplates,
            seriesRules: serviceSeries.seriesRules,
            serviceSeriesEditLogs: serviceSeries.serviceSeriesEditLogs,
          };
        }),
        tap((serviceDetailState) => {
          ctx.setState(serviceDetailState);
        }),
      );
  }

  @Action(ClearServiceDetailState)
  clearServiceDetailState(ctx: StateContext<ServiceDetailStateModel>) {
    ctx.setState(defaultServiceDetailState);
  }

  private generateSegments(activities): ServiceDetailSegment[] {
    return chain(activities)
      .groupBy(
        (activity) =>
          `${activity.startLocationId}${activity.endLocationId}${activity.startDatetime}${activity.endDatetime}`,
      )
      .map((activities) => ({
        name: activities[0].name,
        startDatetime: activities[0].startDatetime,
        endDatetime: activities[0].endDatetime,
        startLocationId: activities[0].startLocationId,
        startLocation: activities[0].startLocation,
        endLocationId: activities[0].endLocationId,
        endLocation: activities[0].endLocation,
        isReported:
          activities.findIndex(
            (activity) => activity?.activityReports?.length,
          ) !== -1,
        activities: activities,
      }))
      .sortBy((segment) => segment.startDatetime)
      .value();
  }

  @Action(BulkUpdateServiceSeries)
  bulkUpdateServiceSeries(
    { getState }: StateContext<ServiceDetailStateModel>,
    { activitiyTemplateIds, updateDto }: BulkUpdateServiceSeries,
  ) {
    const { activityTemplates, id } = getState();
    if (activityTemplates) {
      const activityTemplateUpdatePartials: Partial<ActivityTemplate>[] =
        constructUpdatedActivityTemplates({
          clonedActivityTemplates: cloneDeep(activityTemplates),
          selectedActivitiyTemplateIds: activitiyTemplateIds,
          updateDto,
          qualificationCatoregoryIdResolver: (id: string) => {
            return this.store.selectSnapshot(
              QualificationsState.getCategoryOfQualificationById(id),
            )?.qualificationCategory?.id;
          },
        });

      this.store.dispatch(new SetBulkEditDrawerSaveStatus(true));
      return this.serviceSeriesGateway
        .updateServiceSeries({
          id: id as string,
          activityTemplates: activityTemplateUpdatePartials,
        })
        .finally(() => {
          this.store.dispatch(new SetBulkEditDrawerSaveStatus(false));
        });
    }
  }

  @Action(UpdateSeries)
  async updateServiceSeries(
    { setState }: StateContext<ServiceDetailStateModel>,
    { updateDto }: UpdateSeries,
  ) {
    setState(patch<ServiceDetailStateModel>(updateDto));
    try {
      await this.servicesGateway.updateService(updateDto);
      this.toastMessage.success(this.translate.instant('popup.save.text'), {
        nzDuration: 3000,
      });
    } catch (error) {
      this.store.dispatch(new InitializeServiceDetailState(updateDto.id));
      if (error instanceof HttpErrorResponse) {
        this.toastMessage.error(this.translate.instant('general.error'), {
          nzDuration: 3000,
        });
      }
    }
  }
}
