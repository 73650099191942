import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  faMapMarkerAlt,
  faMapPin,
  faTrain,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faMapMarkerAlt as faMapMarkerAltSolid,
  faMapPin as faMapPinSolid,
} from '@fortawesome/pro-solid-svg-icons';
import { GeoLocation, LocationType } from '@wilson/interfaces';

@Component({
  selector: 'wilson-location-list',
  templateUrl: './location-list.component.html',
  styleUrls: ['./location-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationListComponent {
  @Input() location!: GeoLocation;
  public faTrain = faTrain;
  public faMapPin = faMapPin;
  public faMapMarkerAlt = faMapMarkerAlt;
  public faMapPinSolid = faMapPinSolid;
  public faMapMarkerAltSolid = faMapMarkerAltSolid;
  public LocationType = LocationType;
}
