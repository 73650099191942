import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EndLiveShiftStateService } from './end-live-shift-state.service';
import { NextLiveShiftActivityStateService } from './next-live-shift-activity-state.service';
import { ShiftSyncService } from './shift-sync.service';
import { StartLiveShiftStateService } from './start-live-shift-state.service';
import { StartPauseActivityStateService } from './start-pause-activity-state.service';

@NgModule({
  imports: [CommonModule],
  providers: [
    EndLiveShiftStateService,
    NextLiveShiftActivityStateService,
    StartLiveShiftStateService,
    StartPauseActivityStateService,
    ShiftSyncService,
  ],
})
export class ShiftsMobileModule {}
