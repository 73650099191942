import { Injectable } from '@angular/core';
import { ValidatorFn } from '@angular/forms';
import { addDays, isBefore, isMatch, parse } from 'date-fns';

@Injectable({
  providedIn: 'root',
})
export class ActivityValidationService {
  plannedTimesValidatorFn(): ValidatorFn {
    return (activityForm) => {
      const startDatetime = activityForm.get('startDatetime');
      const endDatetime = activityForm.get('endDatetime');

      const valid = this.validateTimes(
        this.parseTime(startDatetime.value),
        this.parseTime(
          endDatetime.value,
          startDatetime.value !== endDatetime.value, // ensures, validation only fails when start and end are the same when in "HH:mm" format
        ),
      );

      const errors = valid ? null : { invalidTimes: true };

      if (startDatetime.touched || startDatetime.value) {
        startDatetime.setErrors(errors);
      }
      if (endDatetime.touched || endDatetime.value) {
        endDatetime.setErrors(errors);
      }

      return valid ? null : { invalidTimes: true };
    };
  }

  validateTimes(startDatetime: string, endDatetime: string) {
    return !!(
      startDatetime &&
      endDatetime &&
      isBefore(new Date(startDatetime), new Date(endDatetime))
    );
  }

  private parseTime(time: string, addDayToReferenceDate = false) {
    return isMatch(time, 'HH:mm')
      ? parse(
          time,
          'HH:mm',
          addDayToReferenceDate ? addDays(new Date(), 1) : new Date(),
        ).toISOString()
      : time;
  }
}
