<div class="version-update-wrapper">
  <img src="../../../../assets/img/app-logo.svg" />

  <ng-container *ngIf="updateRequired">
    <h1>{{ 'versionUpdate.updateRequired.header' | translate }}</h1>
    <p>{{ 'versionUpdate.updateRequired.message' | translate }}</p>
  </ng-container>

  <ng-container *ngIf="!updateRequired">
    <h1>{{ 'versionUpdate.updateOptional.header' | translate }}</h1>
    <p>{{ 'versionUpdate.updateOptional.message' | translate }}</p>
  </ng-container>

  <div class="buttons">
    <a *ngIf="storeUrl" [href]="storeUrl" id="store-link">
      <ion-button expand="block">
        {{ 'version_update.navigate_to_app_store' | translate }}
      </ion-button>
    </a>

    <ion-button
      (click)="dismiss()"
      *ngIf="!updateRequired"
      fill="outline"
      expand="block"
      id="dismiss-button"
    >
      {{ 'versionUpdate.updateOptional.button' | translate }}
    </ion-button>
  </div>
</div>
