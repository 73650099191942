/// <reference types="@types/googlemaps" />

import { Injectable } from '@angular/core';
import {
  Address,
  GeoLocation,
  LocationCategoryIdsMap,
} from '@wilson/interfaces';
import { LocationNamePipe } from '@wilson/pipes';

@Injectable({
  providedIn: 'root',
})
export class GooglePlacesService {
  constructor(private readonly locationNamePipe: LocationNamePipe) {}
  public addressEvent(
    term: string,
  ): Promise<google.maps.places.QueryAutocompletePrediction[]> {
    return new Promise((resolve) => {
      const displaySuggestions = (
        predictions: google.maps.places.QueryAutocompletePrediction[] | null,
        status: google.maps.places.PlacesServiceStatus,
      ) => {
        if (
          status !== google.maps.places.PlacesServiceStatus.OK ||
          !predictions
        ) {
          return;
        }

        resolve(predictions);
      };
      const service = new google.maps.places.AutocompleteService();
      service.getQueryPredictions({ input: term }, displaySuggestions);
    });
  }

  public getGeoLocationFromGooglePlace(
    // eslint-disable-next-line
    location: any,
    callBack: (result: GeoLocation) => void,
  ) {
    if (location.place_id) {
      const request = {
        placeId: location.place_id,
        fields: [
          'name',
          'address_component',
          'type',
          'formatted_address',
          'geometry',
          'place_id',
          'reference',
          'url',
          'vicinity',
          'website',
        ],
      };
      const service = new google.maps.places.PlacesService(
        document.createElement('div'),
      );
      service.getDetails(request, (place) => {
        callBack(
          this.transformLocationObjectFromGoogleMaps(
            place,
            this.locationNamePipe.transform(location),
          ),
        );
      });
    } else {
      callBack(this.transformLocationObjectFromGoogleMaps(location));
    }
  }

  private transformLocationObjectFromGoogleMaps(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    event: any,
    name?: string,
  ): GeoLocation {
    const location = event.geometry.location;
    const lat =
      typeof location.lat() === 'number' ? location.lat() : location.lat;
    const lng =
      typeof location.lng() === 'number' ? location.lng() : location.lng;

    const address: Address = {
      street: ' ',
      postCode: ' ',
      city: ' ',
      state: ' ',
      country: ' ',
    };

    for (const data of event.address_components) {
      switch (data?.types[0]) {
        case 'route':
          address.street = data.long_name;
          break;
        case 'street_number':
          address.street += ` ${data.long_name}`;
          break;
        case 'postal_code':
          address.postCode = data.long_name;
          break;
        case 'locality':
          address.city = data.long_name;
          break;
        case 'administrative_area_level_1':
          address.state = data.long_name;
          break;
        case 'country':
          address.country = data.long_name;
          break;
        default:
          break;
      }
    }

    const geoLocation: GeoLocation = {
      name: name || event.formatted_address,
      locationCode: null,
      latitude: lat,
      longitude: lng,
      locationCategoryId: LocationCategoryIdsMap.Address,
      addressId: null,
      address,
    };

    return geoLocation;
  }
}
