import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { GeoLocation } from '@wilson/interfaces';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { Store } from '@ngxs/store';
import { CustomLocationsState } from '@wilson/locations-v2/state';
import { LocationSuggestionAutocompleteService } from '@wilson/locations-v2/services';

@Component({
  selector: 'wilson-custom-locations-autocomplete',
  templateUrl: './custom-locations-autocomplete.component.html',
  styleUrls: ['./custom-locations-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomLocationsAutocompleteComponent {
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() invalid = false;
  @Input() warning = false;
  @Input() isAddress = false;
  @Input() placeholder = 'customLocations.input.placeholder';
  @Input() formatedLocation!: Observable<GeoLocation[]>;
  @Input() tabindexValue = -1;
  @Input() ignoreFeatureFlag = false;

  @Output() touched = new EventEmitter<boolean>();
  @Output() locationChange = new EventEmitter<GeoLocation | null>();
  locationOptions$!: Observable<
    (
      | google.maps.places.QueryAutocompletePrediction
      | Pick<GeoLocation, 'name' | 'locationCode'>
    )[]
  >;

  @Input() set selectedLocation(location: GeoLocation | null | undefined) {
    if (location) {
      this.selectedLocation$.next(location);
    }
  }

  get selectedValue(): Observable<GeoLocation> {
    return this.selectedLocation$;
  }

  private selectedLocation$ = new BehaviorSubject<GeoLocation>(
    {} as GeoLocation,
  );

  constructor(
    private readonly store: Store,
    public locationSuggestionAutocompleteService: LocationSuggestionAutocompleteService,
  ) {}

  async setSelectedLocation(location: GeoLocation) {
    if (typeof location === 'object') {
      const processedUserGeoLocation =
        await this.locationSuggestionAutocompleteService.selectedUserLocation(
          location,
        );
      this.locationChange.emit(processedUserGeoLocation);
    }
  }

  async onTouch() {
    this.touched.emit(true);
    const preSelectedLocation = await firstValueFrom(this.selectedValue);
    if (!preSelectedLocation.id) {
      this.locationOptions$ = this.store.select(
        CustomLocationsState.highlightedCustomLocations,
      );
    }
  }

  search(term: string) {
    if (!term) {
      this.locationChange.emit(null);
    }
    this.locationOptions$ = this.locationSuggestionAutocompleteService.search(
      term,
      this.ignoreFeatureFlag,
    );
  }
}
