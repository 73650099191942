import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { GeoLocation } from '@wilson/interfaces';
import {
  LocationPreferencesGatewayService,
  OrganizationalUnitLocationPreference,
} from '@wilson/preferences/core';
import { catchError, map, of } from 'rxjs';
import {
  CreatePreselectedLocations,
  FetchAndResolveLocationSettingsRemotely,
  RemovePreselectedLocations,
} from './location-preferences.actions';
import { v4 as uuidv4 } from 'uuid';
import { AuthState } from '@wilson/auth/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { TranslateService } from '@ngx-translate/core';

export const LocationPreferencesStateName = 'locationPreferences';

export interface LocationPreferenceSettingStateModel {
  loadingPreference: boolean;
  loadingPreferenceSuccess: boolean;
  loadingPreferenceError: boolean;
  selectableLocationSettings: (OrganizationalUnitLocationPreference & {
    geoLocation: GeoLocation;
  })[];
}

const defaults = {
  loadingPreference: false,
  loadingPreferenceSuccess: false,
  loadingPreferenceError: false,
  selectableLocationSettings: [],
};

@State<LocationPreferenceSettingStateModel>({
  name: LocationPreferencesStateName,
  defaults,
})
@Injectable()
export class LocationPreferencesState {
  constructor(
    private locationPreferencesGatewayService: LocationPreferencesGatewayService,
    private store: Store,
    private messageService: NzMessageService,
    private translateService: TranslateService,
  ) {}

  @Selector()
  static loadingPreference(state: LocationPreferenceSettingStateModel) {
    return state.loadingPreference;
  }

  @Selector()
  static loadingPreferenceError(state: LocationPreferenceSettingStateModel) {
    return state.loadingPreferenceError;
  }

  @Selector()
  static loadingPreferenceSuccess(state: LocationPreferenceSettingStateModel) {
    return state.loadingPreferenceSuccess;
  }

  @Selector()
  static mustSelectFromShortList(state: LocationPreferenceSettingStateModel) {
    return (
      state.loadingPreferenceSuccess &&
      state.selectableLocationSettings.length > 0
    );
  }

  @Selector()
  static selectableLocationSettings(
    state: LocationPreferenceSettingStateModel,
  ): (OrganizationalUnitLocationPreference & { geoLocation: GeoLocation })[] {
    return state.selectableLocationSettings;
  }

  @Selector()
  static selectableLocations(
    state: LocationPreferenceSettingStateModel,
  ): GeoLocation[] {
    return state.selectableLocationSettings.map(
      (setting) => setting.geoLocation,
    );
  }

  @Action(CreatePreselectedLocations)
  async createPreselectedLocations(
    { patchState, getState }: StateContext<LocationPreferenceSettingStateModel>,
    { payload }: CreatePreselectedLocations,
  ) {
    const userOrgUnitId = this.store.selectSnapshot(
      AuthState.organizationalUnitId,
    );
    const preselectedLocation: OrganizationalUnitLocationPreference & {
      geoLocation: GeoLocation;
    } = {
      id: uuidv4(),
      geoLocationId: payload.geoLocation.id,
      organizationalUnitId: userOrgUnitId,
      geoLocation: payload.geoLocation,
    };

    const currentList = getState().selectableLocationSettings;

    try {
      patchState({
        selectableLocationSettings: [...currentList, preselectedLocation],
      });

      await this.locationPreferencesGatewayService.add({
        id: preselectedLocation.id,
        geoLocationId: preselectedLocation.geoLocationId,
      });
    } catch {
      patchState({
        selectableLocationSettings: currentList,
      });
      this.messageService.error(this.translateService.instant('general.error'));
    }
  }

  @Action(RemovePreselectedLocations)
  async removePreselectedLocations(
    { patchState, getState }: StateContext<LocationPreferenceSettingStateModel>,
    { payload }: RemovePreselectedLocations,
  ) {
    const currentList = getState().selectableLocationSettings;

    try {
      patchState({
        selectableLocationSettings: currentList.filter(
          (item) => item.id !== payload.settingId,
        ),
      });

      await this.locationPreferencesGatewayService.remove(payload.settingId);
    } catch {
      patchState({
        selectableLocationSettings: currentList,
      });
      this.messageService.error(this.translateService.instant('general.error'));
    }
  }

  @Action(FetchAndResolveLocationSettingsRemotely)
  fetchAndResolveLocationSettingsRemotely({
    patchState,
  }: StateContext<LocationPreferenceSettingStateModel>) {
    patchState({
      loadingPreference: true,
      loadingPreferenceSuccess: false,
      loadingPreferenceError: false,
    });
    return this.locationPreferencesGatewayService
      .getAll({
        embed: ['geoLocation'],
      })
      .pipe(
        map(
          (
            data: (OrganizationalUnitLocationPreference & {
              geoLocation: GeoLocation;
            })[],
          ) => {
            patchState({
              loadingPreference: false,
              loadingPreferenceSuccess: true,
              loadingPreferenceError: false,
              selectableLocationSettings: data,
            });
          },
        ),
        catchError(() => {
          patchState({
            loadingPreference: false,
            loadingPreferenceSuccess: false,
            loadingPreferenceError: true,
          });

          return of(null);
        }),
      );
  }
}
