import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@wilson/pipes';
import { LocationListComponent } from './location-list.component';

@NgModule({
  declarations: [LocationListComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    FontAwesomeModule,
    PipesModule,
  ],
  exports: [LocationListComponent],
})
export class LocationListModule {}
