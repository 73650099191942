import { Injectable } from '@angular/core';
import { patch, updateItem } from '@ngxs/store/operators';
import {
  ResolvedShiftWithSyncStatus,
  ResolvedShiftsStateModel,
} from './state/resolved-shifts.state';

@Injectable({
  providedIn: 'root',
})
export class ShiftNotSyncedOperatorFactory {
  public createShiftNotSyncedOperation(shift: ResolvedShiftWithSyncStatus) {
    return patch<ResolvedShiftsStateModel>({
      shifts: updateItem<ResolvedShiftWithSyncStatus>(
        (s) => s?.id === shift.id,
        patch<ResolvedShiftWithSyncStatus>({
          syncedAt: null,
        }),
      ),
    });
  }
}
