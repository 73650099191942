export const presetColors = [
  '#5297ff',
  '#006acb',
  '#a9455d',
  '#761334',
  '#fcac64',
  '#c57d37',
  '#59ca8f',
  '#1c9861',
  '#60cc9f',
  '#f6d982',
  '#c1a853',
  '#00ccd9',
];
