import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AuthorizationModule } from '@wilson/authorization';
import { AgGridModule } from 'ag-grid-angular';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { EmptyStateModule } from '../empty-state/empty-state.module';
import { AssignStaffListComponent } from './assign-staff-list/assign-staff-list.component';
import { AssignStaffPopupComponent } from './assign-staff-popup.component';

@NgModule({
  declarations: [AssignStaffPopupComponent, AssignStaffListComponent],
  imports: [
    CommonModule,
    AgGridModule,
    TranslateModule,
    FormsModule,
    NzToolTipModule,
    EmptyStateModule,
    NzSkeletonModule,
    AuthorizationModule,
  ],
  exports: [AssignStaffPopupComponent, AssignStaffListComponent],
})
export class AssignStaffPopupModule {}
