import { Injectable } from '@angular/core';
import { User } from '@wilson/interfaces';
import { ValueGetterParams } from 'ag-grid-community';

@Injectable()
export class AccountValueGetterService {
  userNameValueGetter(params: ValueGetterParams<User>): string {
    if (!params.data?.firstName || !params.data?.lastName) {
      return '---';
    }

    return `${params.data.lastName}, ${params.data.firstName}`;
  }

  userNameNestedValueGetter(params: ValueGetterParams<{ user: User }>): string {
    if (!params.data?.user?.firstName || !params.data?.user?.lastName) {
      return '---';
    }

    return `${params.data.user.lastName}, ${params.data.user.firstName}`;
  }
}
