export interface Bucket {
  id: string;
  itemIds: string[];
}

export enum BucketType {
  Service = 'single-service',
  ServiceSeries = 'service-series',
}

export interface BucketStateModel {
  bucket: Bucket[];
  bucketType: BucketType;
}
