import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormatMinutesPipe } from './format-minutes.pipe';

@NgModule({
  declarations: [FormatMinutesPipe],
  imports: [CommonModule],
  exports: [FormatMinutesPipe],
})
export class FormatMinutesModule {}
