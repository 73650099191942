import { Pipe, PipeTransform } from '@angular/core';
import { NzCascaderOption } from 'ng-zorro-antd/cascader';

@Pipe({
  name: 'selectedAgreement',
})
export class SelectedAgreementPipe implements PipeTransform {
  transform(
    agreementId: string,
    agreementOptions: NzCascaderOption[],
  ): string[] {
    let result: string[] = [];
    for (const option of agreementOptions) {
      if (option.children?.findIndex((op) => op.value === agreementId) !== -1) {
        result = [option.value, agreementId];
        break;
      }
    }
    return result;
  }
}
