import { HttpClient } from '@angular/common/http';
import { ConfigOptions } from '@wilson/config';
import { firstValueFrom, Observable } from 'rxjs';
import { Base, ManyEntity, TypeOrmFindManyOptions } from '../interfaces/base';
import { stringify } from 'qs';

export class UpdateResult {
  raw: unknown;
  affected?: number | null;
}

export class DeleteResult {
  raw: unknown;
  affected?: number | null;
}

export abstract class BackendService<T extends Base> {
  protected abstract readonly http: HttpClient;
  protected abstract readonly config: ConfigOptions;
  protected abstract readonly path: string;

  public get<G extends T>(
    entityId: T['id'] | null,
    options?: TypeOrmFindManyOptions,
  ): Observable<G> {
    const params = stringify(options);
    return this.http.get<G>(
      `${this.config.host}/${this.path}/${entityId}?${params}`,
    );
  }

  /** @deprecated the return type from the backend is no longer consistent with frontend type. Use getMany() instead */
  public getAll<G extends T>(
    options?: TypeOrmFindManyOptions,
  ): Observable<G[]> {
    const params = stringify(options, {
      arrayFormat: 'brackets',
    });

    return this.http.get<G[]>(`${this.config.host}/${this.path}?${params}`);
  }

  public getMany<G extends T>(
    options?: TypeOrmFindManyOptions,
  ): Observable<ManyEntity<G>> {
    const params = stringify(options);
    return this.http.get<ManyEntity<G>>(
      `${this.config.host}/${this.path}?${params}`,
    );
  }

  public add<T>(entity: T): Promise<T> {
    return firstValueFrom(
      this.http.post<T>(`${this.config.host}/${this.path}`, entity),
    );
  }

  public addMany(entity: T): Promise<T[]> {
    return firstValueFrom(
      this.http.post<T[]>(`${this.config.host}/${this.path}`, {
        items: [entity],
      }),
    );
  }

  public update(entity: Partial<T>): Promise<UpdateResult> {
    return firstValueFrom(
      this.http.patch<UpdateResult>(
        `${this.config.host}/${this.path}/${entity.id}`,
        entity,
      ),
    );
  }

  public remove(entityId: string): Promise<DeleteResult> {
    return firstValueFrom(
      this.http.delete<DeleteResult>(
        `${this.config.host}/${this.path}/${entityId}`,
      ),
    );
  }
}
