import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { MobileVersions } from '@wilson/api';

@Injectable({
  providedIn: 'root',
})
export class VersioningService {
  constructor(
    private httpClient: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
  ) {}

  getMobileVersions() {
    return this.httpClient.get<MobileVersions>(
      `${this.config.host}/mobile/versions`,
    );
  }
}
