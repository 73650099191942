import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { TranslateActivityCategoryPipe } from './translate-activity-category.pipe';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [TranslateActivityCategoryPipe],
  providers: [TranslatePipe],
  exports: [TranslateActivityCategoryPipe],
})
export class TranslateActivityCategoryPipeModule {}
