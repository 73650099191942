import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QualificationsService } from './qualifications.service';
import { QualificationsUtilityService } from './qualifications-utility.service';

@NgModule({
  imports: [CommonModule],
  providers: [QualificationsService, QualificationsUtilityService],
})
export class QualificationsModule {}
