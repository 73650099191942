import { GeoLocation } from '../locations/geoLocation';

export enum ActivityDeviationStatus {
  Deviated = 'deviated',
  Planned = 'planned',
  Reported = 'reported',
}

export interface AccurateActivityTimeDetails {
  date: string;
  location: GeoLocation | null | undefined;
  type: ActivityDeviationStatus;
  timeDifference: number;
}
