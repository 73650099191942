import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { GeoLocation } from '@wilson/interfaces';
import {
  CreateOrganizationalUnitLocationPreferenceDto,
  UserLocationPreference,
  UserLocationPreferencesService,
} from '@wilson/preferences/core';
import {
  FetchSelectedLocation,
  SelectLocation,
  UnselectAllLocations,
  UnselectLocation,
} from './user-location-preferences.actions';
import { UserPreferenceHelperService } from '@wilson/preferences/services';
import { UserLocationPreferencesStateHelperService } from './user-location-preferences-state-helper.service';
import { firstValueFrom } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { AuthState } from '@wilson/auth/core';

export interface UserLocationPreferencesStateModel {
  isLoading: boolean;
  isLoadingSuccess: boolean;
  isLoadingError: boolean;
  selectedLocations: (UserLocationPreference & { geoLocation: GeoLocation })[];
}

const defaults = {
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingError: false,
  selectedLocations: [],
};

@State<UserLocationPreferencesStateModel>({
  name: 'userLocationPreferences',
  defaults,
})
@Injectable()
export class UserLocationPreferencesState {
  constructor(
    private userPreferenceHelperService: UserPreferenceHelperService,
    private userLocationPreferencesService: UserLocationPreferencesService,
    private userLocationPreferencesStateHelperService: UserLocationPreferencesStateHelperService,
    private store: Store,
  ) {}

  @Selector()
  static isLoadingError(state: UserLocationPreferencesStateModel) {
    return state.isLoadingError;
  }

  @Selector()
  static selectedLocations(
    state: UserLocationPreferencesStateModel,
  ): UserLocationPreferencesStateModel['selectedLocations'] {
    return state.selectedLocations;
  }

  @Action(FetchSelectedLocation)
  async loadSelectedLocation({
    patchState,
  }: StateContext<UserLocationPreferencesStateModel>) {
    patchState({
      isLoading: true,
      isLoadingSuccess: false,
      isLoadingError: false,
    });

    try {
      const userId = this.store.selectSnapshot(AuthState.userId) as string;

      const result = (await firstValueFrom(
        this.userLocationPreferencesService.getAll(userId, {
          embed: ['geoLocation'],
        }),
      )) as (UserLocationPreference & { geoLocation: GeoLocation })[];

      patchState({
        isLoading: false,
        isLoadingSuccess: true,
        isLoadingError: false,
        selectedLocations: result,
      });
    } catch {
      patchState({
        isLoading: false,
        isLoadingSuccess: false,
        isLoadingError: true,
      });
    }
  }

  @Action(SelectLocation)
  async selectLocation(
    stateContext: StateContext<UserLocationPreferencesStateModel>,
    { payload }: SelectLocation,
  ) {
    const newSelectedLocationPayload: CreateOrganizationalUnitLocationPreferenceDto =
      {
        id: uuidv4(),
        geoLocationId: payload.location.id,
      };

    stateContext.patchState({
      selectedLocations: [
        ...stateContext.getState().selectedLocations,
        {
          ...newSelectedLocationPayload,
          geoLocation: payload.location,
        },
      ],
    });
    try {
      await this.userLocationPreferencesService.add(newSelectedLocationPayload);
    } catch {
      this.userPreferenceHelperService.presentErrorToast();
      const filteredData =
        this.userLocationPreferencesStateHelperService.filterUnselected(
          stateContext.getState().selectedLocations,
          newSelectedLocationPayload.id,
        );

      stateContext.patchState({
        selectedLocations: filteredData.filteredResult,
      });
    }
  }

  @Action(UnselectLocation)
  async unselectLocation(
    { patchState, getState }: StateContext<UserLocationPreferencesStateModel>,
    { payload }: UnselectLocation,
  ) {
    const filteredData =
      this.userLocationPreferencesStateHelperService.filterUnselected(
        getState().selectedLocations,
        payload.selectedLocationPreferenceId,
      );
    patchState({
      selectedLocations: filteredData.filteredResult,
    });

    try {
      await this.userLocationPreferencesService.remove(
        filteredData.removedItem.id,
      );
    } catch {
      patchState({
        selectedLocations: [
          ...getState().selectedLocations,
          filteredData.removedItem,
        ],
      });

      this.userPreferenceHelperService.presentErrorToast();
    }
  }

  @Action(UnselectAllLocations)
  async unselectAllLocations(
    context: StateContext<UserLocationPreferencesStateModel>,
  ) {
    context
      .getState()
      .selectedLocations.forEach(async (preference: UserLocationPreference) => {
        await this.unselectLocation(context, {
          payload: {
            selectedLocationPreferenceId: preference.id,
          },
        });
      });
  }
}
