import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { Device, DeviceInfo } from '@capacitor/device';
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from '@capacitor/push-notifications';
import { Store } from '@ngxs/store';
import { DeviceToken } from '@wilson/interfaces';
import { NotificationsService } from '@wilson/notifications';
import { ReplaySubject, firstValueFrom } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { DeviceRegistration } from '../store/device/device.action';
import { PushNotificationHelperService } from './push-notification-helper.service';
import { FeatureFlagPurePipe } from '@wilson/feature-flags';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService implements OnDestroy {
  private destroyed$ = new ReplaySubject<void>();
  readonly pushDisabled = this.featureFlagPurePipe.transform(
    'mobile-prevent-push-notification-initialization',
  );

  constructor(
    private readonly featureFlagPurePipe: FeatureFlagPurePipe,
    private readonly notificationHelperService: PushNotificationHelperService,
    private readonly router: Router,
    private readonly store: Store,
    private readonly notificationsService: NotificationsService,
  ) {
    this.pushDisabled.subscribe((pushDisabled) => {
      if (pushDisabled) {
        PushNotifications.unregister();
      } else {
        this.initPush();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public async initPush() {
    const pushDisabled = await firstValueFrom(this.pushDisabled);

    if (Capacitor.isNativePlatform() && !pushDisabled) {
      this.store
        .select((state) => state.auth.userId)
        .pipe(
          filter((userId) => !!userId),
          takeUntil(this.destroyed$),
        )
        .subscribe(async (userId) => {
          const info: DeviceInfo = await Device.getInfo();
          const platform = info.platform;
          this.registerPush(userId, platform);
        });
    }
  }

  private async registerPush(userId: string, platform: string) {
    let permissionStatus = await PushNotifications.checkPermissions();

    if (permissionStatus.receive === 'prompt') {
      permissionStatus = await PushNotifications.requestPermissions();
    }

    if (permissionStatus.receive === 'granted') {
      await PushNotifications.register();
    }

    PushNotifications.addListener('registration', async (token: Token) => {
      if (token) {
        const device: DeviceToken = {
          token: token.value,
          operatingSystem: platform,
          userId,
        };

        this.notificationsService
          .setDeviceToken(device)
          .subscribe((deviceToken) => {
            this.store.dispatch(new DeviceRegistration(deviceToken.id));
          });
      }
    });

    PushNotifications.addListener('registrationError', (error) => {
      console.log('notification received: ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
        if (!this.notificationHelperService.presentingNotification) {
          this.notificationHelperService.presentNotification(notification);
        }
      },
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        const notificationData = notification.notification.data;
        if ((notificationData?.mobileUrl as string)?.length) {
          // workaround to stay backward compatible on backend side
          // the BE is currently constructing mobileUrl with /activity/:activityId for activity commment notifications
          if (notificationData.mobileUrl.includes('activity/')) {
            this.router.navigate(['/tabs/notifications']);
          } else {
            this.router.navigate([notificationData.mobileUrl]);
          }
        } else if (!this.notificationHelperService.presentingNotification) {
          this.notificationHelperService.presentNotification(
            notification.notification,
          );
        }
      },
    );
  }
}
