import { Injectable } from '@angular/core';
import {
  Activity,
  ActivityReport,
  ActivityReportCategory,
  DefaultActivityCategory,
  FullActivityReport,
  OperationStatus,
  ResolvedActivity,
  ResolvedActivityReport,
  ResolvedActivityWithReports,
  ResolvedShiftWithReports,
} from '@wilson/interfaces';
import { WilsonApp } from '@wilson/interfaces';
import {
  differenceInMilliseconds,
  isBefore,
  isValid,
  millisecondsToMinutes,
} from 'date-fns';
import { cloneDeep, findLast, sortBy } from 'lodash';

type PartialResolvedReport = ActivityReport & Partial<ResolvedActivityReport>;
type ActivityWithReports = Activity & {
  activityReports: PartialResolvedReport[];
};
interface ActivitiesOfShift {
  activities: ActivityWithReports[];
}

@Injectable({
  providedIn: 'root',
})
export class ReportUtilityService {
  /**
   * @param reports reports of an activity.
   * @returns the a full report of the given reports.
   */
  toFullReport(reports: PartialResolvedReport[]): FullActivityReport | null {
    if (!reports?.length) return null;
    const latestStartReport = this.latestStartReport(reports);
    const latestEndReport = this.latestEndReport(reports);
    const activityId = latestStartReport
      ? latestStartReport.activityId
      : latestEndReport
      ? latestEndReport.activityId
      : null;
    const fullReport: FullActivityReport = {
      activityId: activityId,
      startDatetime: latestStartReport ? latestStartReport.dateTime : null,
      endDatetime: latestEndReport ? latestEndReport.dateTime : null,
      startLocation: latestStartReport ? latestStartReport.location : null,
      endLocation: latestEndReport ? latestEndReport.location : null,
    };
    return fullReport.startDatetime && fullReport.endDatetime
      ? fullReport
      : fullReport.activityId
      ? fullReport
      : null;
  }

  /**
   * @returns the a full report of the shift.
   */
  getShiftReport(shift: ActivitiesOfShift): FullActivityReport {
    const activities = cloneDeep(shift)
      ?.activities.filter(
        (activity) =>
          activity.operationalStatus !== OperationStatus.SkippedByUser &&
          activity.operationalStatus !== OperationStatus.Cancelled,
      )
      .filter((activity) => this.hasCompleteReport(activity))
      .sort(this.compareActivitiesBasedOnPlannedOrReportedTimesFn);

    if (!activities.length) return;

    const startReport = this.latestStartReport(activities[0].activityReports);
    const endReport = this.latestEndReport(
      activities[activities.length - 1].activityReports,
    );

    return {
      activityId: null,
      startDatetime: startReport?.dateTime,
      endDatetime: endReport?.dateTime,
      startLocation: startReport?.location,
      endLocation: endReport?.location,
    };
  }

  /**
   * @returns true if the shift has at least one report
   */
  hasReports(shift: ResolvedShiftWithReports | ActivitiesOfShift): boolean;
  /**
   * @returns true if the activity has at least one report
   */
  hasReports(activity: ActivityWithReports): boolean;
  hasReports(shiftivity: ActivitiesOfShift | ActivityWithReports): boolean {
    const activities = (
      this.isActivity(shiftivity) ? [shiftivity] : shiftivity.activities
    ).filter(
      (activity) =>
        activity.operationalStatus !== OperationStatus.SkippedByUser &&
        activity.operationalStatus !== OperationStatus.Cancelled,
    );

    for (const activity of activities) {
      const report = this.toFullReport(activity.activityReports);
      if (report != null && (report.startDatetime || report.endDatetime))
        return true;
    }

    return false;
  }

  /**
   * @returns true if the activity has at least one start and one end report
   */
  hasCompleteReport(activity: Activity): boolean {
    const report = this.toFullReport(activity?.activityReports);
    return (
      report != null &&
      report.startDatetime != null &&
      report.endDatetime != null
    );
  }

  /**
   * @param reports reports of an activity
   * @returns the latest valid start report of the given reports. If no start report is present null will be returned.
   */
  latestStartReport<T extends ActivityReport>(reports: T[]): T | null {
    if (!reports?.length) return null;
    reports = sortBy(reports, (a) => a.createdAt);
    const lastStartReport = findLast(
      reports,
      (a) => a.reportCategory === ActivityReportCategory.Start,
    );
    return lastStartReport ? lastStartReport : null;
  }

  /**
   * @param reports reports of an activity
   * @returns the latest valid end report of the given reports. If no start report is present null will be returned.
   */
  latestEndReport<T extends ActivityReport>(reports: T[]): T | null {
    if (!reports?.length) return null;
    reports = sortBy(reports, (a) => a.createdAt);
    const lastEndReport = findLast(
      reports,
      (a) => a.reportCategory === ActivityReportCategory.End,
    );
    return lastEndReport ? lastEndReport : null;
  }

  /**
   * @returns the reported duration in milliseconds
   */
  getReportedDuration(
    reportsObject: ResolvedActivityWithReports[] | ActivityReport[],
    ignorePartialReports = true,
  ): number {
    if (!reportsObject?.length) return 0;

    if (this.isReportsArray(reportsObject)) {
      const report = this.toFullReport(reportsObject);
      if (
        !report.startDatetime ||
        (!report.endDatetime && ignorePartialReports)
      ) {
        return 0;
      }

      const now = new Date();
      return differenceInMilliseconds(
        new Date(report.endDatetime || now),
        new Date(report.startDatetime),
      );
    }

    return reportsObject.reduce((prev, curr) => {
      return (
        prev +
        this.getReportedDuration(curr.activityReports, ignorePartialReports)
      );
    }, 0);
  }

  getReportedDurationInHoursAndMinutes(
    activities: ResolvedActivityWithReports[],
    ignorePartialReports = true,
  ) {
    const durationInMinutes = millisecondsToMinutes(
      this.getReportedDuration(activities, ignorePartialReports),
    );

    return {
      hours: Math.floor(durationInMinutes / 60),
      minutes: durationInMinutes % 60,
    };
  }

  /**
   * The activity after is the first activity which is not a pause within te given activity.
   * Optional third argument changes this to also include pauses
   * @param activity The given activity after which the returned activity will be
   * @param activities The activities of the shift
   * @param ignorePauses Boolean to ignorePauses, default true
   * @returns the activity directly after the given activity
   */
  getActivityAfter(
    activity: ResolvedActivityWithReports,
    activities: ResolvedActivityWithReports[],
    ignorePauses = true,
  ) {
    const index = activities?.findIndex((act) => act.id === activity.id);

    for (let i = index + 1; i < activities.length; i++) {
      const activityAfter = activities[i];
      if (!ignorePauses) return activityAfter;
      if (!this.isPauseWithinActivity(activityAfter, activity))
        return activityAfter;
    }

    return null;
  }

  /**
   * Reported times of pauses within are within the reported times of the given activity.
   * @param activity The given activity in which the returned pauses are within
   * @param activities The activities of the shift
   * @returns pause activities within the the given activity
   */
  getPausesWithinActivity(
    activity: ResolvedActivityWithReports,
    activities: ResolvedActivityWithReports[],
  ) {
    if (
      !activity ||
      activities?.length < 1 ||
      this.isPause(activity) ||
      activity.operationalStatus === OperationStatus.NotStarted ||
      !activity.activityReports?.length
    )
      return [];

    const index = activities.findIndex((act) => act.id === activity.id);
    const pauses: ResolvedActivityWithReports[] = [];

    for (const possiblePause of activities.slice(index + 1)) {
      if (!this.isPauseWithinActivity(possiblePause, activity)) return pauses;
      pauses.push(possiblePause);
    }
    return pauses;
  }

  /**
   * Checks if an activity is a pause within another activity from the shift
   * @param activity the possible pause within
   * @param activities the activities of the shift
   * @returns boolean
   */
  isPauseWithinShift(
    activity: ResolvedActivityWithReports,
    activities: ResolvedActivityWithReports[],
  ) {
    if (
      !activity ||
      !this.isPause(activity) ||
      activity.operationalStatus !== OperationStatus.Completed ||
      !this.hasReports({ activities: [activity] })
    )
      return false;

    const index = activities.findIndex((act) => act.id === activity.id);
    for (let i = index - 1; i >= 0; i--) {
      if (this.isPause(activities[i])) continue;

      return this.isPauseWithinActivity(activity, activities[i]);
    }

    return false;
  }

  /**
   * Checks if an activity is a pause within the given activity
   * @param pauseActivity the possible pause within
   * @param activity the activity in which the pause could be
   * @returns boolean
   */
  isPauseWithinActivity(
    pauseActivity: ResolvedActivityWithReports,
    activity: ResolvedActivityWithReports,
  ) {
    if (
      !activity ||
      !pauseActivity ||
      !this.isPause(pauseActivity) ||
      pauseActivity.operationalStatus !== OperationStatus.Completed ||
      !this.hasReports({ activities: [pauseActivity] })
    ) {
      return false;
    }

    if (
      activity.operationalStatus === OperationStatus.Ongoing &&
      pauseActivity.operationalStatus === OperationStatus.Completed
    ) {
      return true;
    }

    const pauseReport = this.toFullReport(pauseActivity.activityReports);
    const activityReport = this.toFullReport(activity.activityReports);

    return (
      pauseReport &&
      activityReport &&
      differenceInMilliseconds(
        new Date(activityReport.startDatetime),
        new Date(pauseReport.startDatetime),
      ) < 0 &&
      differenceInMilliseconds(
        new Date(activityReport.endDatetime),
        new Date(pauseReport.endDatetime),
      ) > 0
    );
  }

  isValidPause(activity: ResolvedActivity) {
    return !!(
      activity?.activityCategory?.name === DefaultActivityCategory.Break ||
      activity?.activityCategory?.name ===
        DefaultActivityCategory.BreakPartiallyPaid ||
      activity?.activityCategory?.name === DefaultActivityCategory.BreakUnpaid
    );
  }

  isPause(activity: ResolvedActivity) {
    return !!(
      activity?.activityCategory?.name === DefaultActivityCategory.Break ||
      activity?.activityCategory?.name ===
        DefaultActivityCategory.BreakPartiallyPaid ||
      activity?.activityCategory?.name ===
        DefaultActivityCategory.BreakUnpaid ||
      activity?.activityCategory?.name ===
        DefaultActivityCategory.ActivityInterruption
    );
  }

  isValidUnplannedPause(activity: ResolvedActivity) {
    return !!(
      this.isValidPause(activity) && activity?.createdFrom === WilsonApp.Mobile
    );
  }

  isUnplannedPause(activity: ResolvedActivity) {
    return !!(
      this.isPause(activity) && activity?.createdFrom === WilsonApp.Mobile
    );
  }

  wasSkippedOnShiftStart(
    activity: ResolvedActivityWithReports,
    activities: ResolvedActivityWithReports[],
  ) {
    const activityIndex = activities.findIndex(({ id }) => id === activity.id);
    return activities.some((_activity, index) => {
      return (
        activity.operationalStatus === OperationStatus.NotStarted &&
        activityIndex < index &&
        this.hasReports(_activity)
      );
    });
  }

  private isReportsArray(
    object: ResolvedActivityWithReports[] | ActivityReport[],
  ): object is ActivityReport[] {
    return 'reportType' in object[0];
  }

  private isActivity(
    object: ActivitiesOfShift | ActivityWithReports,
  ): object is ActivityWithReports {
    return 'activityCategoryId' in object;
  }

  getReportedStartLocationName(activityReports: ResolvedActivityReport[]) {
    const reportedStartLocation =
      this.latestStartReport(activityReports)?.location;
    const reportedStartLocationCode = reportedStartLocation?.locationCode;
    return reportedStartLocation
      ? `${reportedStartLocationCode}${reportedStartLocationCode ? ' - ' : ''}${
          reportedStartLocation.name
        }`
      : null;
  }

  getReportedEndLocationName(activityReports: ResolvedActivityReport[]) {
    const reportedEndLocation = this.latestEndReport(activityReports)?.location;
    const reportedEndLocationCode = reportedEndLocation?.locationCode;
    return reportedEndLocation
      ? `${reportedEndLocationCode}${reportedEndLocationCode ? ' - ' : ''}${
          reportedEndLocation.name
        }`
      : null;
  }

  sortActivitiesBasedOnPlannedOrReportedTimes(
    activities: ResolvedActivityWithReports[],
  ) {
    return activities?.sort(
      this.compareActivitiesBasedOnPlannedOrReportedTimesFn,
    );
  }

  compareActivitiesBasedOnPlannedOrReportedTimesFn = (
    activityA: ActivityWithReports,
    activityB: ActivityWithReports,
  ) => {
    const timeToCompareA = this.hasReports(activityA)
      ? this.latestStartReport(activityA.activityReports)?.dateTime
      : activityA.startDatetime;

    const timeToCompareB = this.hasReports(activityB)
      ? this.latestStartReport(activityB.activityReports)?.dateTime
      : activityB.startDatetime;

    if (
      isValid(new Date(timeToCompareA)) &&
      isValid(new Date(timeToCompareB)) &&
      isBefore(new Date(timeToCompareB), new Date(timeToCompareA))
    ) {
      return 1;
    } else {
      return -1;
    }
  };
}
