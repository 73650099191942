import { Injectable } from '@angular/core';
import {
  calculateStartingPixel,
  GanttActivityPixelCalculationInput,
} from './calculate-left-right-pixel.fn';

@Injectable({
  providedIn: 'root',
})
export class GanttShiftPixelLengthService {
  calculate({
    startTime,
    endTime,
    frameLength,
    days,
  }: GanttActivityPixelCalculationInput): number {
    const rightPixel = calculateStartingPixel({
      startTime: endTime,
      frameLength,
      days,
    });

    const leftPixel = calculateStartingPixel({
      startTime,
      frameLength,
      days,
    });
    return rightPixel - leftPixel;
  }
}
