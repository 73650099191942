import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { QualificationSetting } from '@wilson/interfaces';
import { Observable } from 'rxjs';

@Injectable()
export class QualificationSettingsService {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
  ) {}

  public getQualificationSettings(): Observable<QualificationSetting[]> {
    return this.httpClient.get<QualificationSetting[]>(
      `${this.config.host}/qualification-settings`,
    );
  }

  public getQualificationSetting(id: string): Observable<QualificationSetting> {
    return this.httpClient.get<QualificationSetting>(
      `${this.config.host}/qualification-settings/${id}`,
    );
  }

  public searchQualificationSettings(
    searchConditions: Partial<QualificationSetting>,
  ): Observable<QualificationSetting[]> {
    return this.httpClient.post<QualificationSetting[]>(
      `${this.config.host}/qualification-settings/search`,
      searchConditions,
    );
  }
}
