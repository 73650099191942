import {
  ActivityTemplateQualifications,
  QualificationCategoryIdsMap,
} from '@wilson/interfaces';

export function getUpdatedActivityTemplateQualifications({
  updateQualificationIds,
  existingQualificationTemplates,
  activityTemplateId,
  qualificationCatoregoryIdResolver,
}: {
  updateQualificationIds: { added: string[]; removed: string[] };
  existingQualificationTemplates: Partial<ActivityTemplateQualifications>[];
  activityTemplateId: string;
  qualificationCatoregoryIdResolver: (id: string) => string | undefined;
}) {
  let newQualifcationTemplates: Partial<ActivityTemplateQualifications>[] =
    existingQualificationTemplates;

  if (updateQualificationIds.removed.length > 0) {
    updateQualificationIds.removed.map((qualificationId) => {
      newQualifcationTemplates = newQualifcationTemplates.filter((qual) => {
        return (
          qualificationId !== qual.qualificationRefId ||
          qual.qualificationCategoryId === QualificationCategoryIdsMap.Route ||
          qual.qualificationCategoryId === QualificationCategoryIdsMap.Location
        );
      });
    });
  }

  if (updateQualificationIds.added.length > 0) {
    updateQualificationIds.added.map((qualificationRefIdToAdd) => {
      const duplicateQualificationTemplate =
        existingQualificationTemplates.find(
          ({ qualificationRefId }) =>
            qualificationRefId === qualificationRefIdToAdd,
        );
      if (!duplicateQualificationTemplate) {
        newQualifcationTemplates.push({
          activityTemplateId: activityTemplateId,
          qualificationRefId: qualificationRefIdToAdd,
          qualificationCategoryId: qualificationCatoregoryIdResolver(
            qualificationRefIdToAdd,
          ),
        });
      }
    });
  }

  return newQualifcationTemplates;
}
