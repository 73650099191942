import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'minutesToAverageHours',
  standalone: true,
})
export class MinutesToAverageHoursPipe implements PipeTransform {
  constructor(private readonly translate: TranslateService) {}

  transform(minutes: number | null | undefined): string {
    const hoursTranslation = this.translate.instant('general.time.hours');
    if (!minutes) return `${0}${hoursTranslation}`;

    let hours = Math.abs(
      minutes < 0 ? Math.ceil(minutes / 60) : Math.floor(minutes / 60),
    );
    const remainingMinutes = Math.floor(Math.abs(minutes % 60));
    if (remainingMinutes > 45) {
      hours += 1;
    }

    const formattedHours = String(hours).padStart(2, '0');

    return `${minutes < 0 ? '-' : ''}${formattedHours}${hoursTranslation}`;
  }
}
