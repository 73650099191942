import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipComponent } from './tooltip/tooltip.component';
import { TranslateModule } from '@ngx-translate/core';
import { QualificationEmployeeTooltipComponent } from './qualification-employee-tooltip/qualification-employee-tooltip.component';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [TooltipComponent, QualificationEmployeeTooltipComponent],
  exports: [TooltipComponent, QualificationEmployeeTooltipComponent],
})
export class TooltipModule {
  static forRoot(): ModuleWithProviders<TooltipModule> {
    return {
      ngModule: TooltipModule,
    };
  }
}
