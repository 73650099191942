import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviationTimePipe } from './deviation-time/deviation-time.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [DeviationTimePipe],
  providers: [DeviationTimePipe],
  exports: [DeviationTimePipe],
})
export class ServiceDeviationPipesModule {}
