import { Pipe, PipeTransform } from '@angular/core';
import {
  Activity,
  ActivityReport,
  FullActivityReport,
  Shift,
} from '@wilson/interfaces';
import { ReportUtilityService } from '../services/report-utility.service';

@Pipe({
  name: 'shiftReport',
})
export class ShiftReportPipe implements PipeTransform {
  constructor(private reportUtility: ReportUtilityService) {}

  transform(
    shift: Shift & {
      activities: (Activity & { activityReports: ActivityReport[] })[];
    },
  ): FullActivityReport {
    return this.reportUtility.getShiftReport(shift);
  }
}
