<div class="d-flex" *ngIf="location">
  <div class="icon" [class.highlighted]="location.isHighlighted">
    <fa-icon
      [icon]="faTrain"
      *ngIf="
        location.locationCategory?.name === LocationType.Rail ||
        location.locationCodeType === 'RINF'
      "
    ></fa-icon>
    <fa-icon
      [icon]="location.isHighlighted ? faMapPinSolid : faMapPin"
      *ngIf="location.locationCategory?.name === LocationType.Custom"
    ></fa-icon>
    <fa-icon
      [icon]="location.isHighlighted ? faMapMarkerAltSolid : faMapMarkerAlt"
      *ngIf="
        location.locationCategory?.name === LocationType.Address ||
        location.place_id
      "
    ></fa-icon>
  </div>
  <div class="my-auto">
    <p class="m-0 fw-bold maintext">{{ location | locationName }}</p>
    <p class="m-0 subtext" *ngIf="location | locationNameSub as subHeader">
      {{ subHeader }}
    </p>
  </div>
</div>
