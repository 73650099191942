import { ProfitabilityScoreModelWithOptionalId } from '@wilson/api/gateway';
import { ProfitabilityScoreStateModel } from './profitability-score.state';

export class FetchProfitabilityScoreSetting {
  static readonly type =
    '[Profitability Score] fetch profitability score settings';
}

export class UpdateProfitabilityScoreSetting {
  static readonly type =
    '[Profitability Score] update profitability score settings';
  constructor(
    public profitabilityScore: ProfitabilityScoreModelWithOptionalId,
  ) {}
}
export class UpdateEnableSettings {
  static readonly type = '[Profitability Score] update enable settings';
  constructor(
    public enableSettings: ProfitabilityScoreStateModel['settings']['enableSettings'],
  ) {}
}
export class UpdateCurrency {
  static readonly type = '[Profitability Score] update currency';
  constructor(
    public currency: ProfitabilityScoreStateModel['settings']['currency'],
  ) {}
}
export class UpdateHotelRatePerDay {
  static readonly type = '[Profitability Score] update hotel rate per day';
  constructor(
    public hotelRatePerDay: ProfitabilityScoreStateModel['settings']['hotelRatePerDay'],
  ) {}
}
export class UpdateNonBillableActivities {
  static readonly type = '[Profitability Score] update non billable activities';
  constructor(
    public nonBillableActivities: ProfitabilityScoreStateModel['settings']['nonBillableActivities'],
  ) {}
}
export class UpdateProfitabilityClassification {
  static readonly type =
    '[Profitability Score] update profitability classification';
  constructor(
    public profitabilityClassification: ProfitabilityScoreStateModel['settings']['profitabilityClassification'],
  ) {}
}
export class UpdateRevenuePerHour {
  static readonly type = '[Profitability Score] update revenue per hour';
  constructor(
    public revenuePerHour: ProfitabilityScoreStateModel['settings']['revenuePerHour'],
  ) {}
}
export class UpdateTransferActivities {
  static readonly type = '[Profitability Score] update transfer activities';
  constructor(
    public transferActivities: ProfitabilityScoreStateModel['settings']['transferActivities'],
  ) {}
}
export class UpdateTransferCostPerKilometer {
  static readonly type =
    '[Profitability Score] update transfer cost per kilometer';
  constructor(
    public transferCostPerKilometer: ProfitabilityScoreStateModel['settings']['transferCostPerKilometer'],
  ) {}
}
export class UpdateWagePerHour {
  static readonly type = '[Profitability Score] update wage per hour';
  constructor(
    public wagePerHour: ProfitabilityScoreStateModel['settings']['wagePerHour'],
  ) {}
}
