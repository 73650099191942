import { ActivityReport } from '@wilson/interfaces';
import { isBefore } from 'date-fns';

export const activityReportsCompareFn = (
  reportA: ActivityReport,
  reportB: ActivityReport,
) => {
  if (isBefore(new Date(reportB.createdAt), new Date(reportA.createdAt)))
    return 1;
  else return -1;
};
