import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthState, AuthStateModel } from '@wilson/auth/core';
import { ActivityComment } from '@wilson/interfaces';

@Component({
  selector: 'wilson-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentComponent implements OnInit, OnChanges {
  @Input() comment: ActivityComment;
  @Input() userId: AuthStateModel['userId'];
  isMe = false;
  isAbleToLoadImage = false;

  constructor(public readonly store: Store) {}

  ngOnInit() {
    this.checkIfUserIsAbleToLoadImage();
  }

  ngOnChanges() {
    this.checkIfUserIsAbleToLoadImage();
    this.isMe = this.comment.senderId === this.userId;
  }

  private checkIfUserIsAbleToLoadImage() {
    const accessibleOrgUnits =
      this.store.selectSnapshot(AuthState.accessibleOrgUnits) || [];
    this.isAbleToLoadImage =
      accessibleOrgUnits.findIndex(
        (orgUnit) => orgUnit.id === this.comment.sender?.organizationalUnitId,
      ) !== -1;
  }
}
