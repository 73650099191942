import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import {
  faBadgeCheck,
  faCheckCircle,
  faTimesCircle,
} from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'wilson-validation-category-row',
  templateUrl: './validation-category-row.component.html',
  styleUrls: ['./validation-category-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationCategoryRowComponent {
  public faValidationTitleIcon = faBadgeCheck;
  public faValidIcon = faCheckCircle;
  public faInValidIcon = faTimesCircle;
  public faChevronRightIcon = faChevronRight;

  @Input() title = '';
  @Input() isValid = true;
  @Input() isSelected = false;
  @Output() selected = new EventEmitter<() => void>();

  onClick() {
    this.selected.emit();
  }
}
