import { InjectionToken } from '@angular/core';
import { GroupedUnassignedUserDataV2 } from '../timeline/shift-timeline-custom-types';
import { Activity } from '../activities/activity';

export const DetermineUnassignedActivitiesOverlapWorker =
  new InjectionToken<DetermineUnassignedActivitiesOverlapWorkerWrapper>(
    'DetermineUnassignedActivitiesOverlapWorker',
  );

export interface DetermineUnassignedActivitiesOverlapWorkerWrapper {
  execute: (data: Activity[]) => Promise<GroupedUnassignedUserDataV2[]>;
}
