import { ActivityTemplate } from '@wilson/interfaces';
import { getUpdatedActivityTemplateQualifications } from './getUpdatedActivityTemplateQualifications.fn';
import { ServiceSeriesBulkUpdateDto } from '../types';

export function constructUpdatedActivityTemplates({
  clonedActivityTemplates,
  selectedActivitiyTemplateIds,
  updateDto,
  qualificationCatoregoryIdResolver,
}: {
  clonedActivityTemplates: ActivityTemplate[];
  selectedActivitiyTemplateIds: string[];
  updateDto: Partial<ServiceSeriesBulkUpdateDto>;
  qualificationCatoregoryIdResolver: (id: string) => string | undefined;
}) {
  const activityTemplateUpdatePartials: Partial<ActivityTemplate>[] = [];
  clonedActivityTemplates.forEach((clonedTemplate) => {
    const activityTemplateUpdatePartial: Partial<ActivityTemplate> = {
      id: clonedTemplate.id,
    };
    const isActivitySelect = selectedActivitiyTemplateIds.includes(
      clonedTemplate.id as string,
    );

    activityTemplateUpdatePartial.cargo =
      updateDto.cargo !== undefined && isActivitySelect
        ? updateDto.cargo
        : clonedTemplate.cargo;
    activityTemplateUpdatePartial.track =
      updateDto.track !== undefined && isActivitySelect
        ? updateDto.track
        : clonedTemplate.track;
    activityTemplateUpdatePartial.externalNumber =
      updateDto.externalNumber !== undefined && isActivitySelect
        ? updateDto.externalNumber
        : clonedTemplate.externalNumber;
    activityTemplateUpdatePartial.note =
      updateDto.note !== undefined && isActivitySelect
        ? updateDto.note
        : clonedTemplate.note;
    activityTemplateUpdatePartial.agreementId =
      updateDto.agreementId !== undefined && isActivitySelect
        ? updateDto.agreementId
        : clonedTemplate.agreementId;
    activityTemplateUpdatePartial.professionId =
      updateDto.professionId !== undefined && isActivitySelect
        ? updateDto.professionId
        : clonedTemplate.professionId;

    if (
      updateDto.qualificationIds &&
      (updateDto.qualificationIds?.added.length > 0 ||
        updateDto.qualificationIds?.removed.length > 0) &&
      isActivitySelect
    ) {
      const updatedActivityTemplateQualifications =
        getUpdatedActivityTemplateQualifications({
          updateQualificationIds: updateDto.qualificationIds,
          existingQualificationTemplates:
            clonedTemplate.activityTemplateQualifications || [],
          activityTemplateId: clonedTemplate.id as string,
          qualificationCatoregoryIdResolver,
        });

      activityTemplateUpdatePartial.activityTemplateQualifications =
        updatedActivityTemplateQualifications;
    } else {
      activityTemplateUpdatePartial.activityTemplateQualifications =
        clonedTemplate.activityTemplateQualifications || [];
    }

    activityTemplateUpdatePartials.push(activityTemplateUpdatePartial);
  });

  return activityTemplateUpdatePartials;
}
