import { WilsonApp } from '@wilson/interfaces';

export interface AzureAuthConfig {
  baseUrl: string;
  clientId: string;
  redirectUri: string;
  responseMode: string;
  responseType: string;
  scope: string;
  state: string;
  prompt: string;
}

/**
 * redirectUri: Is used so that azure knows where to redirect after auth
 * state: Is used as redirect url for the backend to forward the request correctly back to the frontend
 * prompt: https://learn.microsoft.com/en-us/dotnet/api/microsoft.identity.client.prompt.selectaccount?view=msal-dotnet-latest
 */
export const azureAuthConfig: AzureAuthConfig = {
  baseUrl:
    'https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?',
  clientId: 'dbf771e4-7807-4423-be02-685779f5bfcb',
  redirectUri: '',
  responseMode: 'query',
  responseType: 'code',
  scope: 'openid email profile dbf771e4-7807-4423-be02-685779f5bfcb/.default',
  state: '',
  prompt: 'select_account',
};

const azurePath = '/auth/azure';

export const azureAuthConfigClient = (
  backendHost: string,
  redirectBaseUrl: string,
  target: WilsonApp,
): AzureAuthConfig => ({
  ...azureAuthConfig,
  redirectUri: `${backendHost}${azurePath}`,
  state: JSON.stringify({
    redirectBaseUrl: redirectBaseUrl,
    target: target,
  }),
});

export const azureAuthConfigBuildUrl = (
  authConfig: AzureAuthConfig,
): string => {
  const params = new URLSearchParams({
    client_id: authConfig.clientId,
    redirect_uri: authConfig.redirectUri,
    response_mode: authConfig.responseMode,
    response_type: authConfig.responseType,
    scope: authConfig.scope,
    state: authConfig.state,
    prompt: authConfig.prompt,
  });
  return `${authConfig.baseUrl}?${params.toString()}`;
};
