import { Injectable } from '@angular/core';
import { StateOperator } from '@ngxs/store';
import { append, patch, updateItem } from '@ngxs/store/operators';
import { activityReportFactory } from '@wilson/activities/util';
import {
  ActivityReportCategory,
  ActivityReportType,
  OperationStatus,
  ResolvedActivityReport,
  ResolvedActivityWithReports,
} from '@wilson/interfaces';
import { ResolvedShiftActions } from './state/resolved-shifts.action';
import {
  ResolvedShiftsStateModel,
  ResolvedShiftWithSyncStatus,
} from './state/resolved-shifts.state';

@Injectable()
export class StartLiveShiftStateService {
  patchStartLiveShift(
    action: ResolvedShiftActions.StartLiveShift,
    shift: ResolvedShiftWithSyncStatus,
  ) {
    const activityToStartWith = action.activityToStartWith;

    let stateOperators: StateOperator<ResolvedShiftsStateModel>[] = [];

    stateOperators = [
      ...stateOperators,
      patch<ResolvedShiftsStateModel>({
        shifts: updateItem<ResolvedShiftWithSyncStatus>(
          (s) => s?.id === shift.id,
          patch<ResolvedShiftWithSyncStatus>({
            syncedAt: null,
          }),
        ),
      }),
    ];

    stateOperators = [
      ...stateOperators,
      patch<ResolvedShiftsStateModel>({
        shifts: updateItem<ResolvedShiftWithSyncStatus>(
          (s) => s?.id === shift.id,
          patch<ResolvedShiftWithSyncStatus>({
            activities: updateItem<ResolvedActivityWithReports>(
              (a) => a?.id === activityToStartWith.id,
              patch<ResolvedActivityWithReports>({
                operationalStatus: OperationStatus.Ongoing,
                activityReports: append<ResolvedActivityReport>([
                  activityReportFactory(
                    activityToStartWith.id as string,
                    action.startWithPlannedTime
                      ? activityToStartWith.startDatetime
                      : new Date().toISOString(),
                    activityToStartWith.startLocation,
                    activityToStartWith.startLocationId,
                    ActivityReportCategory.Start,
                    ActivityReportType.IsTimes,
                  ),
                ]),
              }),
            ),
          }),
        ),
      }),
    ];

    return stateOperators;
  }
}
