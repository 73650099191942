<nz-select
  [(ngModel)]="activityCategory"
  (ngModelChange)="onActivityCategoryChange($event)"
  [compareWith]="compareActivityCategoryFn"
  [nzDisabled]="disabled"
  [nzMaxTagCount]="1"
  [nzShowArrow]="true"
>
  <nz-option
    *ngFor="let category of activityCategories"
    [nzValue]="category"
    [nzLabel]="'activity_category.' + category.name | translate"
  >
  </nz-option>
</nz-select>
