import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GeoLocation, LocationType } from '@wilson/interfaces';
import { map, Observable, shareReplay } from 'rxjs';

interface GeoLocationJSON {
  id: string;
  lat: number;
  lc: string | null;
  lcat: string;
  lng: number;
  n: string;
}

@Injectable({
  providedIn: 'root',
})
export class LocalLocationStoreService {
  public readonly localLocationData$: Observable<Map<string, GeoLocation>> =
    this.httpClient
      .get<GeoLocationJSON[]>('/assets/data/geolocations_minified.json')
      .pipe(
        map((data) => {
          const locationMap = new Map<string, GeoLocation>();

          data.forEach((item) => {
            locationMap.set(item.id, {
              addressId: undefined,
              description: undefined,
              id: item.id,
              latitude: item.lat,
              locationCategory: {
                name: LocationType.Rail,
              },
              locationCategoryId: item.lcat,
              locationCode: item.lc,
              longitude: item.lng,
              name: item.n,
            } as GeoLocation);
          });
          return locationMap;
        }),
        shareReplay(1),
      );

  constructor(private httpClient: HttpClient) {}
}
