import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { Country } from '@wilson/interfaces';

@Injectable()
export class CountriesService {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
  ) {}

  public getAllCountries() {
    return this.httpClient.get<Country[]>(`${this.config.host}/countries`);
  }

  public getCountry(id: string) {
    return this.httpClient.get<Country>(`${this.config.host}/countries/${id}`);
  }
}
