import { Injectable } from '@angular/core';
import {
  activityReportFactory,
  activityReportsCompareFn,
} from '@wilson/activities/util';
import {
  ActivityReportCategory,
  ActivityReportType,
  OperationStatus,
  ResolvedActivityWithReports,
} from '@wilson/interfaces';
import { addSeconds } from 'date-fns';
import { ReportUtilityService } from './report-utility.service';

@Injectable({
  providedIn: 'root',
})
export class ReportTimeAdjustmentService {
  constructor(private readonly reportUtility: ReportUtilityService) {}

  createActivityWithAdjustedLocationAndTime(
    activity: ResolvedActivityWithReports,
  ): ResolvedActivityWithReports | null {
    let activityToUpdate: ResolvedActivityWithReports = {
      ...activity,
    };

    const fullReport = this.reportUtility.toFullReport(
      activity.activityReports,
    );

    activityToUpdate = {
      ...activity,
      operationalStatus: OperationStatus.Completed,
      activityReports: [
        ...activity.activityReports,
        activityReportFactory(
          activity.id,
          activity.startDatetime,
          fullReport?.startLocation || activity.startLocation,
          fullReport?.startLocation?.id || activity.startLocation.id,
          ActivityReportCategory.Start,
          ActivityReportType.IsTimes,
        ),
        {
          ...activityReportFactory(
            activity.id,
            activity.endDatetime,
            fullReport?.endLocation || activity.endLocation,
            fullReport?.endLocation?.id || activity.endLocation.id,
            ActivityReportCategory.End,
            ActivityReportType.IsTimes,
          ),
          createdAt: addSeconds(new Date(), 1).toISOString(),
        },
      ].sort(activityReportsCompareFn),
    };

    return activityToUpdate;
  }
}
