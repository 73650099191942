import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, of, take, tap } from 'rxjs';
import {
  CreateBankDetails,
  DeleteBankDetail,
  FetchBankDetails,
  UpdateBankDetails,
} from './bank-details.actions';
import { BankDetailsGatewayService } from '../bank-details-gateway.service';
import { BankDetails } from '@wilson/interfaces';

export type BankDetailsStateModel = BankDetails[];

const defaults = [
  {
    id: '',
    bankName: '',
    bankCode: '',
    accountHolderName: '',
    accountNumber: '',
    iban: '',
    bic: '',
    isDefault: false,
  },
];

export const BANK_DETAILS_STATE_KEY = 'bankDetails';

@State<BankDetailsStateModel>({
  name: BANK_DETAILS_STATE_KEY,
  defaults,
})
@Injectable()
export class BankDetailsState {
  constructor(
    private readonly bankDetailsGatewayService: BankDetailsGatewayService,
  ) {}

  @Selector()
  static bankDetails(state: BankDetailsStateModel): BankDetails[] {
    return state;
  }

  @Action(UpdateBankDetails)
  async updateBankDetails(
    ctx: StateContext<BankDetailsStateModel>,
    action: UpdateBankDetails,
  ) {
    const currentState = ctx.getState();

    const updatedBankDetails = currentState.map((detail) =>
      detail.id === action.bankDetails.id ? action.bankDetails : detail,
    );

    ctx.setState(updatedBankDetails);

    try {
      return await this.bankDetailsGatewayService.updateBankDetails(
        action.bankDetails,
      );
    } catch (e) {
      ctx.patchState(currentState);
      return Promise.reject(e);
    }
  }

  @Action(DeleteBankDetail)
  async deleteBankDetail(
    ctx: StateContext<BankDetailsStateModel>,
    action: DeleteBankDetail,
  ) {
    const currentView = ctx.getState();

    const updatedBankDetails = currentView.filter(
      (detail) => detail.id !== action.bankDetailId,
    );

    ctx.setState(updatedBankDetails);

    try {
      return await this.bankDetailsGatewayService.deleteBankDetails(
        action.bankDetailId,
      );
    } catch (e) {
      ctx.patchState(currentView);
      return Promise.reject(e);
    }
  }

  @Action(CreateBankDetails)
  async createBankDetails(
    ctx: StateContext<BankDetailsStateModel>,
    action: CreateBankDetails,
  ) {
    const currentState = ctx.getState();
    ctx.setState([...currentState, action.bankDetails]);
  }

  @Action(FetchBankDetails)
  fetchBankDetails(ctx: StateContext<BankDetailsStateModel>) {
    return this.bankDetailsGatewayService.getBankDetails().pipe(
      take(1),
      tap((bankDetails) => {
        ctx.setState([...bankDetails]);
      }),
      catchError(() => {
        return of(null);
      }),
    );
  }
}
