import { activitiesCompareFn } from '@wilson/activities/util';
import { Activity } from '@wilson/interfaces';
import { isPast } from 'date-fns';
import { areAllActivitiesFinished } from './are-all-activities-finished.fn';
import { isOneActivityStarted } from './is-one-activity-started.fn';

export function shouldAllActivitiesHaveEnded(
  activities: Pick<
    Activity,
    'startDatetime' | 'endDatetime' | 'operationalStatus'
  >[],
) {
  if (!activities?.length) {
    return false;
  } else {
    const clonedActivities: Pick<
      Activity,
      'startDatetime' | 'endDatetime' | 'operationalStatus'
    >[] = [...activities];
    const sortedActivities = clonedActivities.sort(activitiesCompareFn);
    const oneActivityIsStarted = isOneActivityStarted(sortedActivities);
    const lastActivityIsInPast = isPast(
      new Date(sortedActivities[sortedActivities.length - 1].endDatetime),
    );
    const allActivitiesHaveEnded = areAllActivitiesFinished(sortedActivities);
    return (
      oneActivityIsStarted && lastActivityIsInPast && !allActivitiesHaveEnded
    );
  }
}
