import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigOptions, ConfigService } from '@wilson/config';

@Injectable({
  providedIn: 'root',
})
export class TalkjsGateway {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
  ) {}

  getLoggedInUserSignature() {
    return this.httpClient.get(`${this.config.host}/talkjs/signature`, {
      responseType: 'text',
    });
  }
}
