import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserDetailsComponent } from './user-details.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedComponentsModule } from '../shared-components.module';
import { CopyButtonModule } from '../copy-button/copy-button.module';
import { TranslateModule } from '@ngx-translate/core';
import { FeatureFlagsModule } from '@wilson/feature-flags';
import { LabelsModule } from '@wilson/labels';

@NgModule({
  declarations: [UserDetailsComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    SharedComponentsModule,
    CopyButtonModule,
    TranslateModule,
    FeatureFlagsModule,
    LabelsModule,
  ],
  exports: [UserDetailsComponent],
})
export class UserDetailsModule {}
