import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  InputData,
  ViaPointsComponent,
} from '../via-points/via-points.component';
import { GeoLocation } from '@wilson/interfaces';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'wilson-via-point-button',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './via-point-button.component.html',
  styleUrls: ['./via-point-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViaPointButtonComponent {
  @Input()
  sectorName!: string | null | undefined;

  @Input()
  disabled!: boolean;

  @Input()
  viaPointIds!: string[] | undefined | null;

  @Input()
  startLocation!: GeoLocation | null | undefined;

  @Input()
  endLocation!: GeoLocation | null | undefined;

  @Output()
  newViaLocationIds = new EventEmitter();

  constructor(
    private readonly modalService: NzModalService,
    private readonly translate: TranslateService,
  ) {}

  public async openViaPointsPopup() {
    if (this.sectorName && this.startLocation && this.endLocation) {
      const modalRef = this.modalService.create<ViaPointsComponent, InputData>({
        nzTitle: this.translate.instant('page.service.via_points.edit_route'),
        nzContent: ViaPointsComponent,
        nzData: {
          viaLocationIds: this.viaPointIds,
          sectorName: this.sectorName,
          startLocation: this.startLocation,
          endLocation: this.endLocation,
        },
        nzOkText: this.translate.instant('general.select'),
        nzOnOk: () => {
          this.newViaLocationIds.emit(
            modalRef.componentInstance?.viaLocationIds(),
          );
          modalRef.close();
        },
      });
    }
  }
}
