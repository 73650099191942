import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BackendService, Base } from '@wilson/base';
import { ConfigOptions, ConfigService } from '@wilson/config';

@Injectable({
  providedIn: 'root',
})
export class SharedUserGateway extends BackendService<
  {
    userId: string;
    partnershipId: string;
  } & Base
> {
  protected readonly path = 'shared-users';
  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
  ) {
    super();
  }
}
