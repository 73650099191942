import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfigModule } from '@wilson/config';
import { OrganizationalUnitService } from './organizational-unit.service';

@NgModule({
  imports: [CommonModule, ConfigModule],
  providers: [OrganizationalUnitService],
})
export class OrganizationalUnitModule {}
