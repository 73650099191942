import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { Role, RoleName, UserRole } from '@wilson/interfaces';
import {
  BehaviorSubject,
  firstValueFrom,
  Observable,
  ReplaySubject,
} from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';

@Injectable()
export class RolesService {
  private rolesSubject = new ReplaySubject<Role[]>(1);
  private isFetchingRoleSubject = new BehaviorSubject(false);
  private isRoleFetchSuccess = false;

  get fetchingRole$() {
    return this.isFetchingRoleSubject.asObservable();
  }

  constructor(
    private readonly httpClient: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
  ) {}

  getRoles(): Observable<Role[]> {
    if (!this.isRoleFetchSuccess && !this.isFetchingRoleSubject.value) {
      this.isFetchingRoleSubject.next(true);
      return this.httpClient.get<Role[]>(`${this.config.host}/roles`).pipe(
        map((result) =>
          result.filter((role) => role.name !== RoleName.SuperAdmin),
        ),
        tap((filteredRoles) => {
          this.rolesSubject.next(filteredRoles);
          this.isRoleFetchSuccess = true;
        }),
        finalize(() => {
          this.isFetchingRoleSubject.next(false);
        }),
      );
    } else {
      return this.rolesSubject.asObservable();
    }
  }

  getUserRoles(userId: string): Observable<UserRole[]> {
    return this.httpClient.post<UserRole[]>(
      `${this.config.host}/user-roles/resolved`,
      { userId },
    );
  }

  createUserRoles(userId: string, roles: Role[]): Promise<UserRole[]> {
    const userRoles: UserRole[] = [];
    roles.map((role) => {
      userRoles.push({
        userId,
        roleId: role.id,
      });
    });
    return firstValueFrom(
      this.httpClient.post<UserRole[]>(`${this.config.host}/user-roles`, {
        items: userRoles,
      }),
    );
  }

  deleteUserRoles(userRoleId: string): Promise<UserRole> {
    return firstValueFrom(
      this.httpClient.delete<UserRole>(
        `${this.config.host}/user-roles/${userRoleId}`,
      ),
    );
  }
}
