import {
  ActivityComment,
  NewStartPauseActivityLiveShiftStateAction,
  ResolvedActivityReport,
  ResolvedActivityWithReports,
  ResolvedShiftWithReports,
  Shift,
  ShiftReport,
} from '@wilson/interfaces';

const ACTION_SCOPE = `[ResolvedShifts]`;

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ResolvedShiftActions {
  export class InitializeLiveShift {
    static readonly type = `${ACTION_SCOPE} InitializeLiveShift`;
  }

  export class SmartHydrate {
    static readonly type = `${ACTION_SCOPE} SmartHydrate`;
    constructor(
      public range?: {
        startDate: Date;
        endDate: Date;
      },
    ) {}
  }

  export class HydrateState {
    static readonly type = `${ACTION_SCOPE} HydrateState`;
    constructor(
      public range?: {
        startDate: Date;
        endDate: Date;
      },
    ) {}
  }

  export class StartLiveShift {
    static readonly type = `${ACTION_SCOPE} StartLiveShift`;
    constructor(
      public activityToStartWith: ResolvedActivityWithReports,
      public startWithPlannedTime: boolean,
    ) {}
  }

  export class StartNextActivityLiveShift {
    static readonly type = `${ACTION_SCOPE} StartNextActivityLiveShift`;
    constructor(
      public pausedActivity: ResolvedActivityWithReports,
      public currentReportedStart: ResolvedActivityReport,
      public currentActivity: ResolvedActivityWithReports,
      public nextActivity: ResolvedActivityWithReports,
      public dateTime: string,
    ) {}
  }

  export class NewStartPauseActivityLiveShift {
    static readonly type = `${ACTION_SCOPE} NewStartPauseActivityLiveShift`;
    constructor(
      public currentActivityId: NewStartPauseActivityLiveShiftStateAction['currentActivityId'],
      public pauseActivity: NewStartPauseActivityLiveShiftStateAction['pauseActivity'],
      public duplicatedActivity?: NewStartPauseActivityLiveShiftStateAction['duplicatedActivity'],
    ) {}
  }

  export class StartPauseActivityLiveShift {
    static readonly type = `${ACTION_SCOPE} StartPauseActivityLiveShift`;
    constructor(
      public currentActivity: ResolvedActivityWithReports,
      public minPauseTime: number,
      public startDateTime: Date,
    ) {}
  }

  export class EndLiveShift {
    static readonly type = `${ACTION_SCOPE} EndLiveShift`;
    constructor(
      public skipUndoneActivities: boolean,
      public undoneActivities: ResolvedActivityWithReports[],
      public pausedActivity: ResolvedActivityWithReports,
      public currentActivity: ResolvedActivityWithReports,
      public dateTime: string,
    ) {}
  }

  export class SetSubmitShift {
    static readonly type = `${ACTION_SCOPE} SetSubmitShift`;
    constructor(public shiftReport: ShiftReport) {}
  }

  export class WithdrawSubmittedShift {
    static readonly type = `${ACTION_SCOPE} WithdrawSubmittedShift`;
    constructor(public shiftId: string) {}
  }

  export class EditShift {
    static readonly type = `${ACTION_SCOPE} EditShift`;
    constructor(
      public shiftId: string,
      public shift: Pick<Shift, 'name' | 'shiftCategoryId' | 'comment'>,
    ) {}
  }

  export class UpdateShiftFromServer {
    static readonly type = `${ACTION_SCOPE} UpdateShiftFromServer`;
    constructor(public shiftFromServer: ResolvedShiftWithReports) {}
  }

  export class CreateShift {
    static readonly type = `${ACTION_SCOPE} CreateShift`;
    constructor(public newShift: ResolvedShiftWithReports) {}
  }

  export class UpdateLastSeenAtShift {
    static readonly type = `${ACTION_SCOPE} Update LastSeenAt of a shift`;
    constructor(public shiftId: string) {}
  }
  export class UpdateConfirmedAtShift {
    static readonly type = `${ACTION_SCOPE} Update confirmedAt of a shift`;
    constructor(public shiftId: string) {}
  }
  export class UpdateDeclinedAtShift {
    static readonly type = `${ACTION_SCOPE} Update declinedAt of a shift`;
    constructor(public shiftId: string) {}
  }

  export class UpdateDeclineReasonShift {
    static readonly type = `${ACTION_SCOPE} Update declined reason of a shift`;
    constructor(public reasonAndShiftId: { shiftId: string; reason: string }) {}
  }

  export class DeleteShift {
    static readonly type = `${ACTION_SCOPE} DeleteShift`;
    constructor(public deleteShiftId: string) {}
  }

  export class UpdateActivity {
    static readonly type = `${ACTION_SCOPE} UpdateActivity`;
    constructor(public activity: ResolvedActivityWithReports) {}
  }

  export class InsertActivity {
    static readonly type = `${ACTION_SCOPE} InsertActivity`;
    constructor(public activity: ResolvedActivityWithReports) {}
  }

  export class InsertActivityWithoutSync {
    static readonly type = `${ACTION_SCOPE} InsertActivity without sync`;
    constructor(public activity: ResolvedActivityWithReports) {}
  }

  export class DeleteMobileActivity {
    static readonly type = `${ACTION_SCOPE} DeleteMobileActivity`;
    constructor(public activity: ResolvedActivityWithReports) {}
  }

  export class RemoveActivity {
    static readonly type = `${ACTION_SCOPE} Remove Activity`;
    constructor(
      public payload: {
        activityId: string;
        shiftId: string;
      },
    ) {}
  }

  export class RemoveActivityWithoutSync {
    static readonly type = `${ACTION_SCOPE} Remove Activity without sync`;
    constructor(
      public payload: {
        activityId: string;
        shiftId: string;
      },
    ) {}
  }

  export class CreateActivityComment {
    static readonly type = `${ACTION_SCOPE} CreateActivityComment`;
    constructor(
      public shiftId: string,
      public activityId: string,
      public comment: ActivityComment,
    ) {}
  }

  export class UpdateActivityCommentsSeenBy {
    static readonly type = `${ACTION_SCOPE} UpdateActivityCommentsSeenBy`;
    constructor(public shiftId: string, public activityId: string) {}
  }

  export class SetupSyncForAllUnsychronizedShifts {
    static readonly type = `${ACTION_SCOPE} SetupSyncForAllUnsychronizedShifts`;
  }

  export class ClearShiftsState {
    static readonly type = `${ACTION_SCOPE} ClearShiftsState`;
  }
}
