import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { Activity, Shift } from '@wilson/interfaces';

export enum ShiftActionsErrorMessages {
  ContainsSharedActivities = 'CONTAINS_SHARED_ACTIVITIES',
}

export interface UpdatedActivityPart {
  id: string;
  startDatetime: Activity['startDatetime'];
  endDatetime: Activity['endDatetime'];
}

export interface UpdatedShiftPart {
  id: string;
  startDate: Shift['startDate'];
  publicationStatus: Shift['publicationStatus'];
  startDatetime: string;
  endDatetime: string;
  activities: UpdatedActivityPart[];
}

export type MoveShiftsResponse = UpdatedShiftPart[];

@Injectable({
  providedIn: 'root',
})
export class ShiftActionsGateway {
  protected readonly path = 'shift-actions';

  constructor(
    private readonly httpClient: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
  ) {}

  moveShifts(shiftIds: string[], durationInMinutes: number) {
    return this.httpClient.patch<MoveShiftsResponse>(
      `${this.config.host}/${this.path}/move`,
      {
        shiftIds,
        durationInMinutes,
      },
    );
  }
}
