import { NgModule } from '@angular/core';
import { AnyActivityCanBeAddedToSharePipe } from './pipes/activity-can-be-added-to-share.pipe';
import { CountActivitiesCantBeAddedToSharePipe } from './pipes/count-activities-cant-add-to-share.pipe';

@NgModule({
  declarations: [
    AnyActivityCanBeAddedToSharePipe,
    CountActivitiesCantBeAddedToSharePipe,
  ],
  exports: [
    AnyActivityCanBeAddedToSharePipe,
    CountActivitiesCantBeAddedToSharePipe,
  ],
})
export class ActivitiesCanBeAddedToShareModule {}
