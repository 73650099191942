import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { stringify } from 'qs';
import { firstValueFrom } from 'rxjs';
import {
  CreateOrganizationalUnitVehiclePreferenceDto,
  OrganizationalUnitVehiclePreference,
} from './vehicle-preferences-gateway.interface';

@Injectable({
  providedIn: 'root',
})
export class VehiclePreferencesGatewayService {
  protected readonly path = 'organizational-units/vehicles/models';

  constructor(
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
    protected readonly http: HttpClient,
  ) {}

  public getAll(options?: {
    embed?: ('vehicleModel' | 'organizationalUnit')[];
  }) {
    const params = stringify(options || {});

    return this.http.get<OrganizationalUnitVehiclePreference[]>(
      `${this.config.host}/${this.path}?${params}`,
    );
  }

  public add(
    entity: CreateOrganizationalUnitVehiclePreferenceDto,
  ): Promise<OrganizationalUnitVehiclePreference> {
    return firstValueFrom(
      this.http.post<OrganizationalUnitVehiclePreference>(
        `${this.config.host}/${this.path}`,
        entity,
      ),
    );
  }

  public remove(entityId: string) {
    return firstValueFrom(
      this.http.delete(`${this.config.host}/${this.path}/${entityId}`),
    );
  }
}
