import { Pipe, PipeTransform } from '@angular/core';
import { formatDateISO } from '@wilson/utils';
import { format, parseISO } from 'date-fns';

@Pipe({
  name: 'time',
})
export class TimePipe implements PipeTransform {
  transform(value: string): string {
    return format(parseISO(formatDateISO(value)), 'HH:mm');
  }
}
