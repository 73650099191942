import { Pipe, PipeTransform } from '@angular/core';
import { Activity, ActivityReport, ExpectedTimes } from '@wilson/interfaces';
import { ExpectedTimesService } from '../services/expected-times.service';

type ActivityWithReports = Activity & { activityReports: ActivityReport[] };

@Pipe({
  name: 'expectedTimes',
  pure: true,
})
export class ExpectedTimesPipe implements PipeTransform {
  constructor(private expectedTimesService: ExpectedTimesService) {}

  transform(
    activity: ActivityWithReports,
    shiftActivities: ActivityWithReports[],
  ): ExpectedTimes | null {
    return this.expectedTimesService.forActivity(activity, shiftActivities);
  }
}
