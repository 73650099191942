<nz-tag
  class="rounded-0"
  *ngIf="activity?.profession; else noProfession"
  [class.cursor-pointer]="isEditable"
  (click)="isEditable && openChangeProfessionModal()"
>
  <span class="text-dark">
    {{ 'profession.' + activity.profession.id | translate }}</span
  >
</nz-tag>

<ng-template #noProfession>
  <nz-tag
    class="rounded-0"
    [ngClass]="{ 'cursor-pointer border-dashed': isEditable }"
    [class.cursor-pointer]="isEditable"
    (click)="isEditable && openChangeProfessionModal()"
  >
    <span *ngIf="isEditable" class="plus-size">+</span>
    <span class="text-dark">
      {{
        isEditable
          ? ('activity.profession.add' | translate)
          : ('activity.profession.no_selection' | translate)
      }}</span
    >
  </nz-tag>
</ng-template>
