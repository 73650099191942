import { ValidationErrors, ValidatorFn } from '@angular/forms';
import { DateTimeFormat } from '@wilson/utils';
import { format, isBefore } from 'date-fns';

export function StartDateTimeValidator(targetControlName: string): ValidatorFn {
  return (control): ValidationErrors | null => {
    const currentStartDatetime: string = control.value;
    const currentEndDatetime: string =
      control.parent?.controls[targetControlName]?.value;
    if (currentStartDatetime && !currentEndDatetime) {
      return null;
    }

    if (
      currentStartDatetime &&
      currentEndDatetime &&
      isBefore(
        new Date(
          format(
            new Date(currentStartDatetime),
            DateTimeFormat.DatabaseDateTimeFormat,
          ),
        ),
        new Date(
          format(
            new Date(currentEndDatetime),
            DateTimeFormat.DatabaseDateTimeFormat,
          ),
        ),
      )
    ) {
      return null;
    }

    return {
      isInvalid: true,
    };
  };
}
