import { Base } from '@wilson/base';
import { Role } from './role';

export enum FeatureName {
  Absence = 'absence',
  Administration = 'administration',
  Billing = 'billing',
  Client = 'client',
  Contract = 'contract',
  Integration = 'integration',
  Location = 'location',
  MobileShiftReporting = 'mobile-shift-reporting',
  OperationBoard = 'operation-board',
  OperativeReports = 'operative-reports',
  OrganizationalUnit = 'organizational-unit',
  Payroll = 'payroll',
  Preference = 'preference',
  Project = 'project',
  Qualification = 'qualification',
  Role = 'role',
  Service = 'service',
  Shift = 'shift',
  ShiftDisposition = 'shift-disposition',
  ShiftPlan = 'shift-plan',
  StayManagement = 'stayManagement',
  User = 'user',
  Vehicle = 'vehicle',
  WilsonAI = 'wilson-ai',
  WilsonShare = 'wilson-share',
  WorkTimeManagement = 'work-time-management',
}

export enum RolePermissionSubject {
  Absence = 'absence',
  ActivityTemplate = 'ActivityTemplate',
  Administration = 'administration',
  Billing = 'billing',
  BillingSetting = 'BillingSetting',
  Client = 'Client',
  Contract = 'contract',
  GeoLocation = 'GeoLocation',
  Integration = 'integration',
  Invoice = 'Invoice',
  Label = 'Label',
  MobileShiftReporting = 'mobile-shift-reporting',
  OperationBoard = 'operation-board',
  OperativeReport = 'OperativeReport',
  OperativeReportCategorySetting = 'OperativeReportCategorySetting',
  OrganizationalUnit = 'organizational-unit',
  Payroll = 'payroll',
  PayrollTransaction = 'PayrollTransaction',
  Project = 'Project',
  Qualification = 'qualification',
  Role = 'role',
  Route = 'Route',
  Service = 'service',
  ServiceDeviation = 'ServiceDeviation',
  ServiceTemplate = 'ServiceTemplate',
  SharedUser = 'SharedUser',
  Shift = 'shift',
  ShiftDisposition = 'shift-disposition',
  ShiftNote = 'ShiftNote',
  ShiftPlan = 'shift-plan',
  StaffSearchProfitabilitySetting = 'StaffSearchProfitabilitySetting',
  Stay = 'Stay',
  StayAttachment = 'StayAttachment',
  User = 'user',
  UserGeneralPreference = 'UserGeneralPreference',
  UserLocationPreference = 'UserLocationPreference',
  UserPayrollMonthlyClosure = 'UserPayrollMonthlyClosure',
  UserQualification = 'UserQualification',
  UserShiftTimePreference = 'UserShiftTimePreference',
  UserVehiclePreference = 'UserVehiclePreference',
  Vehicle = 'vehicle',
  WilsonAI = 'wilson-ai',
  WilsonShare = 'wilson-share',
  WorkTimeManagement = 'work-time-management',
  GeneralSetting = 'GeneralSetting',
  BankAccount = 'BankAccount',
}

export enum RoleAction {
  Create = 'create',
  Read = 'read',
  Update = 'update',
  Delete = 'delete',
}

export interface Permission extends Base {
  action: RoleAction;
  subject: FeatureName;
  fields: string | string[] | null;
  // eslint-disable-next-line
  conditions: any;

  rolePermissions: RolePermission[];
}

export interface RolePermission extends Base {
  role?: Role;
  roleId: Role['id'];
  permission?: Permission;
  permissionId: Permission['id'];
}

export interface Permissions {
  featurePermissions: string[];
  rolePermissions: Permission[];
}

// should only be consumed by route guards
export interface RolePermissionGuard {
  action: RoleAction;
  subject: RolePermissionSubject;
}

// should only be consumed by route guards
export interface AtLeastOneRolePermissionGuard {
  action: RoleAction;
  subjects: RolePermissionSubject[];
}
