/* eslint-disable @nx/enforce-module-boundaries */
import { EmptyStateComponent } from '@wilson/shared-components';
import { GridOptions } from 'ag-grid-community';

export const defaultGridOptionsFactory = (): GridOptions => ({
  pagination: true,
  paginationAutoPageSize: true,
  accentedSort: true,
  animateRows: true,
  enableCellTextSelection: true,
  suppressPropertyNamesCheck: true,
  domLayout: 'normal',
  noRowsOverlayComponent: EmptyStateComponent,
});
