<nz-date-picker
  (ngModelChange)="onDateChange($event)"
  [ngModel]="date"
  [nzDisabled]="disabled"
  [nzStatus]="invalid && 'error'"
  nzFormat="dd.MM.yyyy"
>
</nz-date-picker>

<nz-time-picker
  (ngModelChange)="onTimeChange($event)"
  [ngModel]="date"
  [nzDisabled]="disabled"
  [nzStatus]="invalid && 'error'"
  nzFormat="HH:mm"
>
</nz-time-picker>
