import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { ModalController, Platform } from '@ionic/angular';
import { MobilePlatformVersions } from '@wilson/api';
import { VersioningService } from '@wilson/api/gateway';
import { combineLatest, from, Observable, of } from 'rxjs';
import { catchError, filter, first, map } from 'rxjs/operators';
import { VersionUpdateComponent } from './components/version-update/version-update.component';
import { lt, valid } from 'semver';

@Injectable({
  providedIn: 'root',
})
export class VersionUpdateService {
  validatedCurrentVersion: boolean;

  constructor(
    private readonly versioningService: VersioningService,
    private readonly platform: Platform,
    private readonly router: Router,
    private readonly modalCtrl: ModalController,
  ) {}

  initAppVersionCheck() {
    if (Capacitor.isNativePlatform()) {
      combineLatest([this.getLocalVersion(), this.getRemoteVersionConfig()])
        .pipe(
          first(),
          filter(
            ([localVersion, remoteVersionConfig]: [
              string,
              MobilePlatformVersions,
            ]) =>
              !!localVersion &&
              !!valid(localVersion) &&
              !!remoteVersionConfig &&
              !!valid(remoteVersionConfig?.maxVersion) &&
              !!valid(remoteVersionConfig?.minVersion) &&
              !!valid(remoteVersionConfig?.minAPIVersion),
          ),
        )
        .subscribe(([localVersion, remoteVersionConfig]) =>
          this.onDidGetVersionData(localVersion, remoteVersionConfig),
        );
    }
    this.router.navigate(['/tabs/home']);
  }

  onDidGetVersionData(
    localVersion: string,
    remoteVersionConfig: MobilePlatformVersions,
  ) {
    // TODO: replace local storage with storage service
    this.validatedCurrentVersion =
      JSON.parse(localStorage.getItem('versionConfig'))?.maxVersion ===
      remoteVersionConfig?.maxVersion;

    if (remoteVersionConfig) {
      localStorage.setItem(
        'versionConfig',
        JSON.stringify(remoteVersionConfig),
      );
    }

    this.validateVersion(localVersion, remoteVersionConfig);
  }

  getLocalVersion() {
    return Capacitor.isNativePlatform()
      ? from(App.getInfo()).pipe(map(({ version }) => version))
      : of(null);
  }

  getRemoteVersionConfig() {
    const remoteVersionConfig$: Observable<MobilePlatformVersions> =
      this.versioningService.getMobileVersions().pipe(
        map((remoteVersionConfig) => {
          let versionConfig: MobilePlatformVersions;

          if (this.platform.is('ios')) {
            versionConfig = remoteVersionConfig.ios;
          } else if (this.platform.is('android')) {
            versionConfig = remoteVersionConfig.android;
          }
          return versionConfig;
        }),
        catchError(() => {
          return of(JSON.parse(localStorage.getItem('versionConfig')));
        }),
      );

    return remoteVersionConfig$;
  }

  validateVersion(
    localVersion: string,
    { maxVersion, minVersion, storeUrl }: MobilePlatformVersions,
  ) {
    if (lt(localVersion, minVersion)) {
      this.presentRequiredUpdateModal(storeUrl);
    } else if (lt(localVersion, maxVersion) && !this.validatedCurrentVersion) {
      this.presentUpdateModal(storeUrl);
    }
  }

  async presentUpdateModal(storeUrl: string) {
    const modal = await this.modalCtrl.create({
      component: VersionUpdateComponent,
      componentProps: { storeUrl },
    });

    modal?.present();
  }

  async presentRequiredUpdateModal(storeUrl: string) {
    const modal = await this.modalCtrl.create({
      component: VersionUpdateComponent,
      backdropDismiss: false,
      componentProps: { updateRequired: true, storeUrl },
    });

    modal?.present();
  }
}
