import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileInfoPanelComponent } from './mobile-info-panel.component';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [MobileInfoPanelComponent],
  imports: [CommonModule, IonicModule],
  exports: [MobileInfoPanelComponent],
})
export class MobileInfoPanelModule {}
