import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  UserVehiclePreferencesGatewayService,
  VehiclePreferences,
} from '@wilson/preferences/core';
import {
  FetchSelectedVehicles,
  SelectVehicle,
  UnselectAllVehicle,
  UnselectVehicle,
} from './user-vehicle-preferences.actions';
import { firstValueFrom, map } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { UserPreferenceHelperService } from '@wilson/preferences/services';
import { UserVehiclePreferencesStateModel } from './user-vehicle-preferences.interface';
import { UserVehiclePreferencesStateHelperService } from './user-vehicle-preferences-state-helper.service';
import { AuthState } from '@wilson/auth/core';

const defaults = {
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingError: false,
  selectedVehicles: [],
};

@State<UserVehiclePreferencesStateModel>({
  name: 'userVehiclePreferences',
  defaults,
})
@Injectable()
export class UserVehiclePreferencesState {
  constructor(
    private userVehiclePreferencesService: UserVehiclePreferencesGatewayService,
    private userPreferenceHelperService: UserPreferenceHelperService,
    private userVehiclePreferencesStateHelperService: UserVehiclePreferencesStateHelperService,
    private store: Store,
  ) {}

  @Selector()
  static selectedVehicles(
    state: UserVehiclePreferencesStateModel,
  ): UserVehiclePreferencesStateModel['selectedVehicles'] {
    return state.selectedVehicles;
  }

  @Action(FetchSelectedVehicles)
  async loadSelectedLocation({
    patchState,
  }: StateContext<UserVehiclePreferencesStateModel>) {
    patchState({
      isLoading: true,
      isLoadingSuccess: false,
      isLoadingError: false,
    });
    try {
      const userId = this.store.selectSnapshot(AuthState.userId) as string;

      const result = await firstValueFrom(
        this.userVehiclePreferencesService.getAll(userId).pipe(
          map((userSelectedVehicles: VehiclePreferences[]) =>
            userSelectedVehicles.map(
              ({ id, organizationalUnitVehicleModel }) => ({
                id,
                name: organizationalUnitVehicleModel.vehicleModel.name,
                vehicleId: organizationalUnitVehicleModel.vehicleModel.id,
              }),
            ),
          ),
        ),
      );

      patchState({
        isLoading: false,
        isLoadingSuccess: true,
        isLoadingError: false,
        selectedVehicles: result,
      });
    } catch {
      patchState({
        isLoading: false,
        isLoadingSuccess: false,
        isLoadingError: true,
      });
    }
  }

  @Action(SelectVehicle)
  async selectLocation(
    stateContext: StateContext<UserVehiclePreferencesStateModel>,
    { payload: { vehicle } }: SelectVehicle,
  ) {
    const newSelectedVehicle = {
      ...vehicle,
      organizationalUnitVehicleModelId: vehicle.id,
      id: uuidv4(),
    };

    stateContext.patchState({
      selectedVehicles: [
        ...stateContext.getState().selectedVehicles,
        newSelectedVehicle,
      ],
    });

    try {
      await this.userVehiclePreferencesService.add(newSelectedVehicle);
    } catch {
      const filteredResult = stateContext
        .getState()
        .selectedVehicles.filter((vehicle) => vehicle.id !== vehicle.id);
      this.userPreferenceHelperService.presentErrorToast();
      stateContext.patchState({
        selectedVehicles: filteredResult,
      });
    }
  }

  @Action(UnselectVehicle)
  async unselectLocation(
    { patchState, getState }: StateContext<UserVehiclePreferencesStateModel>,
    { payload }: UnselectVehicle,
  ) {
    const filteredData =
      this.userVehiclePreferencesStateHelperService.filterUnselected(
        getState().selectedVehicles,
        payload.selectedVehicleId,
      );

    patchState({
      selectedVehicles: filteredData.filteredResult,
    });

    try {
      await this.userVehiclePreferencesService.remove(
        payload.selectedVehicleId,
      );
    } catch {
      patchState({
        selectedVehicles: [
          ...getState().selectedVehicles,
          filteredData.removedItem,
        ],
      });
      this.userPreferenceHelperService.presentErrorToast();
    }
  }

  @Action(UnselectAllVehicle)
  async unselectAllLocation({
    patchState,
    getState,
  }: StateContext<UserVehiclePreferencesStateModel>) {
    const selectedVehicles = getState().selectedVehicles;
    patchState({
      selectedVehicles: [],
    });

    try {
      await this.userVehiclePreferencesService.removeAll();
    } catch {
      patchState({
        selectedVehicles: selectedVehicles,
      });
      this.userPreferenceHelperService.presentErrorToast();
    }
  }
}
