import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WithTranslations } from '@wilson/interfaces';

@Injectable({
  providedIn: 'root',
})
export class TransformMasterDataTranslationService {
  constructor(private translateService: TranslateService) {}

  transform<T extends Partial<WithTranslations>>(
    data: T[],
  ): (T & { translatedName: string })[] {
    const lang = this.translateService.currentLang.split('-')[0];
    const nameDescriptor = `name${lang[0].toUpperCase()}${
      lang[1]
    }` as keyof WithTranslations;

    return data.map((rawData) => ({
      ...rawData,
      translatedName: rawData[nameDescriptor] || rawData.nameEn || '',
    }));
  }
}
