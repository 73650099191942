export enum ServiceActivityStatus {
  StaffAssigned = 'staff-assigned',
  AssignedToShift = 'assigned-to-shift',
  NotStaffed = 'not-staffed',
}

export enum ServiceOperationalStatus {
  NotStarted = 'not-started',
  DelayWarning = 'delay-warning',
  Delayed = 'delayed',
  Ongoing = 'ongoing',
  OngoingWarning = 'ongoing-warning',
  Completed = 'completed',
  Incomplete = 'incomplete',
}
