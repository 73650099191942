import { AbstractControl } from '@angular/forms';

export function WhiteSpaceValidator(control: AbstractControl) {
  const hasOnlyWhiteSpace = !control.value?.trim();

  if (hasOnlyWhiteSpace && control.value) {
    return {
      whiteSpace: control.value,
    };
  }

  return null;
}
