import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Profession } from '@wilson/interfaces';
import { Observable, of } from 'rxjs';
import { ProfessionHelperService } from '../services/profession-helper.service';

@Component({
  selector: 'wilson-change-profession-modal',
  templateUrl: './change-profession-modal.component.html',
  styleUrls: ['./change-profession-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeProfessionModalComponent implements OnInit {
  @Input() profession: Profession;
  public submitting = false;
  public professions$: Observable<
    (Profession & { nameTranslationKey: string })[]
  > = of([]);

  constructor(
    private readonly activeModal: NgbActiveModal,
    public professionHelperService: ProfessionHelperService,
  ) {}

  ngOnInit(): void {
    this.professions$ = this.professionHelperService.getProfessions();
  }

  saveProfession() {
    this.submitting = true;
    this.activeModal.close(this.profession);
  }

  cancel() {
    this.activeModal.dismiss();
  }
}
