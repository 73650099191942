import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import {
  Action,
  NgxsAfterBootstrap,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import { firstValueFrom, map, take, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastController } from '@ionic/angular';
import { ActivityCategory } from '@wilson/interfaces';
import {
  AddFavoriteActivityCategory,
  InitializeFavoriteActivityCategoriesState,
  RemoveFavoriteActivityCategory,
} from './favorite-activity-categories.action';
import { UserFavoriteActivityCategoriesService } from '@wilson/api/gateway';
import { append, iif, patch, removeItem } from '@ngxs/store/operators';

interface FavoriteActivityCategoriesStateModal {
  favoriteActivityCategories: ActivityCategory[];
}
const STATE_NAME = 'favoriteActivityCategories';
const defaultOperationsState: FavoriteActivityCategoriesStateModal = {
  favoriteActivityCategories: [],
};

@State<FavoriteActivityCategoriesStateModal>({
  name: STATE_NAME,
  defaults: defaultOperationsState,
})
@Injectable()
export class FavoriteActivityCategoriesState implements NgxsAfterBootstrap {
  constructor(
    private readonly userFavoriteActivityCategoriesService: UserFavoriteActivityCategoriesService,
    private readonly storage: Storage,
    private readonly translate: TranslateService,
    private readonly toastController: ToastController,
  ) {}

  @Selector()
  static favoriteActivityCategories(
    state: FavoriteActivityCategoriesStateModal,
  ) {
    return state.favoriteActivityCategories;
  }

  async ngxsAfterBootstrap(
    ctx: StateContext<FavoriteActivityCategoriesStateModal>,
  ): Promise<void> {
    const favoriteActivityCategoriesState = await this.storage.get(STATE_NAME);
    if (favoriteActivityCategoriesState)
      ctx.patchState(favoriteActivityCategoriesState);
  }

  @Action(InitializeFavoriteActivityCategoriesState)
  initializeFavoriteActivityCategoriesState(
    ctx: StateContext<FavoriteActivityCategoriesStateModal>,
  ) {
    return this.userFavoriteActivityCategoriesService
      .getFavoriteActivityCategories()
      .pipe(
        take(1),
        map((userFavoriteActivityCategories) => {
          const favoriteActivityCategory: ActivityCategory[] =
            userFavoriteActivityCategories.map(
              (userFavoriteActivityCategory) => {
                return userFavoriteActivityCategory.activityCategory;
              },
            );
          return {
            favoriteActivityCategories: favoriteActivityCategory,
          };
        }),
        tap((activityCategoriesState) => {
          ctx.patchState(activityCategoriesState);
          this.updateStorage(ctx);
        }),
      );
  }

  @Action(AddFavoriteActivityCategory)
  async addFavoriteActivityCategory(
    ctx: StateContext<FavoriteActivityCategoriesStateModal>,
    action: AddFavoriteActivityCategory,
  ) {
    try {
      await firstValueFrom(
        this.userFavoriteActivityCategoriesService.setFavoriteActivityCategoryForUser(
          action.activityCategory.id as string,
        ),
      );

      ctx.setState(
        patch<FavoriteActivityCategoriesStateModal>({
          favoriteActivityCategories: iif<ActivityCategory[]>(
            (favoriteActivityCategories) =>
              !favoriteActivityCategories.some(
                (activityCategory) =>
                  activityCategory.id === action.activityCategory.id,
              ),
            append<ActivityCategory>([action.activityCategory]),
          ),
        }),
      );
      this.updateStorage(ctx);
    } catch (error) {
      console.error(error);
      const toast = await this.toastController.create({
        message: this.translate.instant('general.errorMessage'),
        duration: 3000,
        position: 'bottom',
      });
      await toast.present();
    }
  }

  @Action(RemoveFavoriteActivityCategory)
  async removeFavoriteActivityCategory(
    ctx: StateContext<FavoriteActivityCategoriesStateModal>,
    action: RemoveFavoriteActivityCategory,
  ) {
    try {
      await firstValueFrom(
        this.userFavoriteActivityCategoriesService.removeFavoriteActivityCategoryFromUser(
          action.activityCategoryId,
        ),
      );

      ctx.setState(
        patch<FavoriteActivityCategoriesStateModal>({
          favoriteActivityCategories: removeItem<ActivityCategory>(
            (activityCategory) =>
              activityCategory.id === action.activityCategoryId,
          ),
        }),
      );
      this.updateStorage(ctx);
    } catch (error) {
      console.error(error);
      const toast = await this.toastController.create({
        message: this.translate.instant('general.errorMessage'),
        duration: 3000,
        position: 'bottom',
      });
      await toast.present();
    }
  }

  private updateStorage(
    ctx: StateContext<FavoriteActivityCategoriesStateModal>,
  ) {
    this.storage.set(STATE_NAME, ctx.getState());
  }
}
