import { Pipe, PipeTransform } from '@angular/core';
import { Activity } from '@wilson/interfaces';
import { areAllActivitiesFinished } from './are-all-activities-finished.fn';

@Pipe({
  name: 'areAllActivitiesFinished',
})
export class AreAllActivitiesFinishedPipe implements PipeTransform {
  transform(activities: Activity[] | null | undefined): boolean {
    return areAllActivitiesFinished(activities);
  }
}
