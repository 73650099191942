import { Label } from '@wilson/interfaces';

export class InitLabels {
  static readonly type = '[Labels] - InitLabels';
}

export class ClearLabels {
  static readonly type = '[Labels] - ClearLabels';
}

export class ClearTempLabels {
  static readonly type = '[Labels] - ClearTempLabels';
}

export class AddTempLabel {
  static readonly type = '[Labels] - AddTempLabel';
}

export class UpdateTempLabel {
  static readonly type = '[Labels] - UpdateTempLabel';
  constructor(public partialLabel: Partial<Label> & { id: string }) {}
}

export class CreateLabel {
  static readonly type = '[Labels] - CreateLabel';
  constructor(
    public partialTempLabel: { id: string; agGridRowId: string },
    public createdLabel: Label,
  ) {}
}

export class UpdateLabel {
  static readonly type = '[Labels] - UpdateLabel';
  constructor(public partialLabel: Partial<Label> & { id: string }) {}
}

export class RemoveLabels {
  static readonly type = '[Labels] - RemoveLabels';
  constructor(public labels: Label[]) {}
}
