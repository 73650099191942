import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LabelsHelperService {
  public getContrast(hexColor: string): string {
    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5, 7), 16);
    const brightness = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    return brightness >= 0.5 ? 'black' : 'white';
  }
}
