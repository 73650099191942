import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShiftCategoriesColorCode } from '@wilson/interfaces';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'wilson-shift-category-tag',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './shift-category-tag.component.html',
  styleUrl: './shift-category-tag.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShiftCategoryTagComponent {
  @Input({ required: true }) shiftCategoryId!: string;
  @Input() size: 'small' | 'normal' = 'normal';

  readonly shiftCategoriesColorCode = ShiftCategoriesColorCode;
}
