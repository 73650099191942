import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfigModule } from '@wilson/config';
import { AccountService } from './account.service';

@NgModule({
  imports: [CommonModule, ConfigModule],
  providers: [AccountService],
})
export class AccountModule {}
