import { Activity } from '@wilson/interfaces';
import { BucketType } from '@wilson/share/interfaces';

export class ClearBucket {
  static readonly type = '[ActivityBucket] ClearBucket';
}

export class InsertToBucket {
  static readonly type = '[ActivityBucket] InsertItemToBucket';
  constructor(
    public id: string,
    public itemIds: string[],
    public bucketType: BucketType,
  ) {}
}

export class InsertActivitiesToBucket {
  static readonly type = '[ActivityBucket] InsertActivitiesToBucket';
  constructor(public activities: Activity[]) {}
}

export class DeleteItemFromBucket {
  static readonly type = '[ActivityBucket] DeleteItemFromBucket';
  constructor(public id: string, public itemId: string) {}
}

export class DeleteAllItemsFromId {
  static readonly type = '[ActivityBucket] DeleteAllItemsFromId';
  constructor(public id: string) {}
}
