import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'invoiceNumberFormat',
})
export class InvoiceNumberPipe implements PipeTransform {
  transform(
    nextInvoiceNumber: number,
    invoiceNumberNumberOfDigits: number,
  ): string {
    return String(nextInvoiceNumber).padStart(invoiceNumberNumberOfDigits, '0');
  }
}
