import { UserTimePreference } from '@wilson/interfaces';

export class RemoveUserTimePreferences {
  static readonly type = '[UserTimePreferences] Remove User time preferences';
}

export class CreateUserTimePreference {
  static readonly type = '[UserTimePreferences] Create User Time Preference';
  constructor(public userTimePreference: UserTimePreference) {}
}

export class UpdateUserTimePreference {
  static readonly type = '[UserTimePreferences] Update User Time Preference';
  constructor(public userTimePreference: UserTimePreference) {}
}

export class DeleteUserTimePreference {
  static readonly type = '[UserTimePreferences] Delete User Time Preference';
  constructor(public id: string) {}
}

export class FetchUserTimePreferences {
  static readonly type =
    '[UserTimePreferences] Fetch User Time UserPreferencesState';
}
