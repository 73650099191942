import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DeleteResult } from '@wilson/base';
import { ConfigOptions, ConfigService } from '@wilson/config';
import {
  DeviceToken,
  Notification,
  UserNotification,
  UserNotificationSearchParams,
} from '@wilson/interfaces';
import { firstValueFrom, Observable, Subscriber } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable()
export class NotificationsService {
  unReadData!: UserNotification[];
  readData!: UserNotification[];

  constructor(
    private readonly httpClient: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
  ) {}

  createNotification(notification: Notification): Promise<Notification[]> {
    return firstValueFrom(
      this.httpClient.post<Notification[]>(
        `${this.config.host}/notifications`,
        {
          items: [notification],
        },
      ),
    );
  }

  updateNotification(
    notification: Partial<Notification>,
    notificationId: string,
  ): Promise<Notification> {
    return firstValueFrom(
      this.httpClient.patch<Notification>(
        `${this.config.host}/notifications/${notificationId}`,
        notification,
      ),
    );
  }

  getUserNotificationByUserIdAndNotificationId(
    userId: string,
    notificationId: string,
  ): Observable<UserNotification[]> {
    return this.httpClient.post<UserNotification[]>(
      `${this.config.host}/user-notifications/search`,
      { userId, notificationId },
    );
  }

  createNotificationWithUserNotification(
    notification: Notification,
    userIds: string[],
  ): Promise<Notification[]> {
    return firstValueFrom(
      this.httpClient.post<Notification[]>(
        `${this.config.host}/notifications`,
        {
          items: [{ ...notification, userIds }],
        },
      ),
    );
  }

  createNotificationWithUserNotificationAndNativePush(
    notification: Notification,
    userIds: string[],
  ): Promise<Notification[]> {
    const params = new HttpParams({ fromString: `withPush=1` });

    return firstValueFrom(
      this.httpClient.post<Notification[]>(
        `${this.config.host}/notifications`,
        { items: [{ ...notification, userIds }] },
        { params },
      ),
    );
  }

  createNotificationUserNotifications(
    notificationId: string,
    userIds: string[],
  ): Promise<UserNotification[]> {
    const userNotifications: UserNotification[] = [];
    userIds.map((userId) =>
      userNotifications.push({
        isRead: false,
        notificationId,
        userId,
      }),
    );
    return firstValueFrom(
      this.httpClient.post<UserNotification[]>(
        `${this.config.host}/user-notifications`,
        {
          items: userNotifications,
        },
      ),
    );
  }

  updateUserNotification(
    userNotification: Partial<UserNotification>,
    userNotificationId: string,
  ): Promise<UserNotification[]> {
    return firstValueFrom(
      this.httpClient.patch<UserNotification[]>(
        `${this.config.host}/user-notifications/${userNotificationId}`,
        userNotification,
      ),
    );
  }

  getUserNotifications(
    notificationDetails: UserNotificationSearchParams,
    limit?: number,
  ): Observable<UserNotification[]> {
    const params = new HttpParams();
    if (limit && limit > 0) params.append('limit', `${limit}`);

    return this.httpClient.post<UserNotification[]>(
      `${this.config.host}/notifications/users`,
      notificationDetails,
      { params },
    );
  }

  findDevice(device: DeviceToken): Observable<DeviceToken[]> {
    return this.httpClient.post<DeviceToken[]>(
      `${this.config.host}/devices/search`,
      {
        userId: device.userId,
        token: device.token,
      },
    );
  }

  setDeviceToken(device: DeviceToken): Observable<DeviceToken> {
    return new Observable((deviceToken$: Subscriber<DeviceToken>) => {
      this.findDevice(device)
        .pipe(take(1))
        .subscribe((existingDevice) => {
          if (existingDevice.length) {
            deviceToken$.next(existingDevice[0]);
            deviceToken$.complete();
          } else {
            this.httpClient
              .post<DeviceToken>(`${this.config.host}/devices`, {
                items: [device],
              })
              .subscribe((newDeviceToken) => {
                deviceToken$.next(newDeviceToken[0]);
                deviceToken$.complete();
              });
          }
        });
    });
  }

  removeDeviceToken(pushNotificationsToken: string): Observable<DeleteResult> {
    return this.httpClient.delete<DeleteResult>(
      `${this.config.host}/devices/${pushNotificationsToken}`,
    );
  }

  getSortedNotifications(userId: string): Observable<UserNotification[]> {
    return this.getUserNotifications({
      userId,
    }).pipe(
      map((userNotifications) =>
        userNotifications.sort(
          (a, b) => a.createdAt.localeCompare(b.createdAt) * -1,
        ),
      ),
    );
  }

  markAllNotificationsAsRead() {
    return firstValueFrom(
      this.httpClient.patch(
        `${this.config.host}/user-notifications/mark-all-as-read`,
        {},
      ),
    );
  }
}
