import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { firstValueFrom } from 'rxjs';
import {
  CreateShiftTimePreferenceSettingDto,
  ShiftTimePreferenceSetting,
} from './shift-time-gateway.interface';

@Injectable({
  providedIn: 'root',
})
export class ShiftTimeGatewayService {
  protected readonly path = 'organizational-units/shift-times/preferences';

  constructor(
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
    protected readonly http: HttpClient,
  ) {}

  create(payload: CreateShiftTimePreferenceSettingDto) {
    return firstValueFrom(
      this.http.post<CreateShiftTimePreferenceSettingDto>(
        `${this.config.host}/${this.path}`,
        payload,
      ),
    );
  }

  getAll() {
    return this.http.get<ShiftTimePreferenceSetting[]>(
      `${this.config.host}/${this.path}`,
    );
  }

  remove(id: string) {
    return firstValueFrom(
      this.http.delete<CreateShiftTimePreferenceSettingDto>(
        `${this.config.host}/${this.path}/${id}`,
      ),
    );
  }
}
