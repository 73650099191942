export enum OperativeReportName {
  LocomotiveDamage = 'damage',
  Delay = 'delay',
}

export enum OperativeReportPriority {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export enum OperativeReportStatus {
  Open = 'open',
  Done = 'done',
}
