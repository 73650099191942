import { Injectable } from '@angular/core';
import {
  Action,
  Selector,
  State,
  StateContext,
  Store,
  createSelector,
} from '@ngxs/store';
import { ProjectsGateway } from '@wilson/api/gateway';
import { filter, finalize, switchMap, take, tap } from 'rxjs/operators';
import {
  FetchAndAddProjectIfNotInState,
  InitializeProjectsState,
  ResetProjectsState,
} from './projects.actions';
import { Observable, of } from 'rxjs';
import { append, patch } from '@ngxs/store/operators';
import { Project, ProjectMany, ProjectStatus } from '@wilson/interfaces';
export interface ProjectStateModel {
  projects: ProjectMany;
  isLoading: boolean;
}

const defaultProjectStateModel: ProjectStateModel = {
  projects: { count: 0, data: [] },
  isLoading: false,
};

@State<ProjectStateModel>({
  name: 'project',
  defaults: defaultProjectStateModel,
})
@Injectable()
export class ProjectsState {
  constructor(
    private projectsGateway: ProjectsGateway,
    private readonly store: Store,
  ) {}

  @Selector()
  static isLoading(state: ProjectStateModel) {
    return state.isLoading;
  }

  static projectById(
    id: string,
  ): (state: ProjectStateModel) => Project | undefined {
    return createSelector([ProjectsState], (state: ProjectStateModel) => {
      return state.projects.data.find((project) => project.id === id);
    });
  }

  static projectByIdExists(id: string): (state: ProjectStateModel) => boolean {
    return createSelector([ProjectsState], (state: ProjectStateModel) => {
      return state.projects.data.some((project) => project.id === id);
    });
  }

  @Selector()
  static projects(state: ProjectStateModel): Project[] {
    return state.projects.data;
  }
  @Selector()
  static getAllOngoingAndOpenProjects(state: ProjectStateModel): Project[] {
    return state.projects.data.filter(
      (project) => project.status !== ProjectStatus.FINISHED,
    );
  }

  @Action(InitializeProjectsState)
  async InitializeProjectsState(context: StateContext<ProjectStateModel>) {
    context.patchState({ isLoading: true });

    return this.projectsGateway.getProjects({ limit: 0 }).pipe(
      tap((projects) => {
        context.patchState({ projects });
      }),
      finalize(() => {
        context.patchState({ isLoading: false });
      }),
    );
  }

  @Action(ResetProjectsState)
  resetProjectsState(context: StateContext<ProjectStateModel>) {
    context.setState(defaultProjectStateModel);
  }

  @Action(FetchAndAddProjectIfNotInState)
  fetchAndAddProjectIfNotInState(
    { setState, getState }: StateContext<ProjectStateModel>,
    action: FetchAndAddProjectIfNotInState,
  ): Observable<Project | undefined> {
    return this.store.select(ProjectsState.isLoading).pipe(
      filter((isLoading) => !isLoading),
      take(1),
      switchMap(() => {
        if (ProjectsState.projectByIdExists(action.id)(getState())) {
          const existingProject = ProjectsState.projectById(action.id)(
            getState(),
          );
          return of(existingProject);
        }

        setState(patch<ProjectStateModel>({ isLoading: true }));

        return this.projectsGateway.getProjectById(action.id).pipe(
          tap((project) => {
            setState(
              patch<ProjectStateModel>({
                projects: patch<ProjectMany>({
                  data: append<Project>([project]),
                }),
                isLoading: false,
              }),
            );
          }),
        );
      }),
    );
  }
}
