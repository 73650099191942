<div class="modal-header p-0 mb-4">
  <div>
    <h5 class="modal-title">
      {{ title }}

      <ng-container *ngIf="withDownloadButton">
        <small
          *ngIf="shouldAccessSrc ? (srcWithAccess$ | async) : src as href"
          class="ms-2"
        >
          <a [href]="href" class="text-secondary">
            <i class="fa fa-download"></i>
          </a>
        </small>
      </ng-container>
    </h5>
    <p>{{ subtitle }}</p>
  </div>
</div>

<img [src]="shouldAccessSrc ? (srcWithAccess$ | async) : src" alt="" />

<wilson-loading-state
  *ngIf="shouldAccessSrc && (srcWithAccess$ | async) === null"
></wilson-loading-state>
