/* eslint-disable @nx/enforce-module-boundaries */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AbilityModule, AblePurePipe } from '@casl/angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { ActivitiesCanBeAddedToShareModule } from '@wilson/activities/can-be-shared';
import {
  ActivitiesPipesModule,
  ActivityIsSharedByClientPipe,
  ActivityTemplateIsSharedByClientPipe,
  TranslateActivityCategoryPipeModule,
} from '@wilson/activities/pipes';
import { ActivityQualificationsModule } from '@wilson/activity-qualifications';
import { AuthorizationModule } from '@wilson/authorization';
import { ConfigModule } from '@wilson/config';
import { FeatureFlagsModule } from '@wilson/feature-flags';
import { FormsModule as WilsonFormsModule } from '@wilson/forms';
import {
  ActivityTimeTagComponent,
  LocationSelectsComponent,
} from '@wilson/non-domain-specific/activities-helpers/components';
import { PipesModule } from '@wilson/pipes';
import { ProfessionCoreModule } from '@wilson/profession/core';
import { ServiceDeviationPipesModule } from '@wilson/service-deviation/pipes';
import {
  LocationAbbreviationNameModule,
  SharedComponentsModule,
  UserDetailsModule,
  ViaPointsToolTipModule,
} from '@wilson/shared-components';
import { ShiftValidationsCoreModule } from '@wilson/shift-validations/core';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCascaderModule } from 'ng-zorro-antd/cascader';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ActivityCommentsService } from './activity-comments.service';
import { ActivityReportsService } from './activity-reports.service';
import { CommentComponent } from './comment/comment.component';
import { ActivityCommentButtonComponent } from './components/activity-comment-button/activity-comment-button.component';
import { ActivityCommentsPromptComponent } from './components/activity-comments-prompt/activity-comments-prompt.component';
import { ActivityCommentsComponent } from './components/activity-comments/activity-comments.component';
import { ActivityDeviationTimeComponent } from './components/activity-deviation-time/activity-deviation-time.component';
import { ActivityEditButtonComponent } from './components/activity-edit-button/activity-edit-button.component';
import { ActivityEditCategoryComponent } from './components/activity-edit-category/activity-edit-category.component';
import { ActivityEditDatetimeComponent } from './components/activity-edit-datetime/activity-edit-datetime.component';
import { ActivityEditorComponent } from './components/activity-edit-modal/activity-editor.component';
import { ActivityIconComponent } from './components/activity-icon/activity-icon.component';
import { ActivityWithAssignmentComponent } from './components/activity-with-assignment/activity-with-assignment.component';
import { ActivityComponent } from './components/activity/activity.component';
import { AgreementIconComponent } from './components/agreement-icon/agreement-icon.component';
import { ChangeAgreementModalComponent } from './components/change-agreement-modal/change-agreement-modal.component';
import { ShareActivityTemplateComponent } from './components/share-activity-template/share-activity-template.component';
import { ShareActivityComponent } from './components/share-activity/share-activity.component';
import { UserAssignmentComponent } from './components/user-assignment/user-assignment.component';
import { CreatedFromMobilePipe } from './pipes/created-from-mobile.pipe';
import { ActivityEditService } from './services/activity-edit.service';
import { NzInputModule } from 'ng-zorro-antd/input';

@NgModule({
  declarations: [
    CommentComponent,
    CreatedFromMobilePipe,
    ActivityComponent,
    UserAssignmentComponent,
    ActivityCommentsComponent,
    ActivityCommentsPromptComponent,
    ActivityCommentButtonComponent,
    ActivityWithAssignmentComponent,
    ActivityIconComponent,
    ShareActivityComponent,
    ActivityEditButtonComponent,
    ActivityEditCategoryComponent,
    ActivityEditDatetimeComponent,
    ActivityEditorComponent,
    ShareActivityTemplateComponent,
    AgreementIconComponent,
    ChangeAgreementModalComponent,
    ActivityDeviationTimeComponent,
  ],
  imports: [
    LocationSelectsComponent,
    ActivityTimeTagComponent,
    AbilityModule,
    CommonModule,
    ConfigModule,
    RouterModule,
    FormsModule,
    TranslateModule,
    ActivityQualificationsModule,
    SharedComponentsModule,
    NzButtonModule,
    NzDatePickerModule,
    NzFormModule,
    NzModalModule,
    NzSelectModule,
    NzTimePickerModule,
    NzToolTipModule,
    NgxSkeletonLoaderModule,
    ViaPointsToolTipModule,
    LocationAbbreviationNameModule,
    AuthorizationModule,
    FeatureFlagsModule,
    FontAwesomeModule,
    ActivitiesPipesModule,
    WilsonFormsModule,
    ProfessionCoreModule,
    NzCascaderModule,
    PipesModule,
    TranslateActivityCategoryPipeModule,
    NzTagModule,
    ServiceDeviationPipesModule,
    ShiftValidationsCoreModule,
    UserDetailsModule,
    ActivitiesCanBeAddedToShareModule,
    ActivityIsSharedByClientPipe,
    ActivityTemplateIsSharedByClientPipe,
    NzInputModule,
  ],
  providers: [
    AblePurePipe,
    ActivityEditService,
    ActivityCommentsService,
    ActivityReportsService,
  ],
  exports: [
    CommentComponent,
    CreatedFromMobilePipe,
    ActivityComponent,
    UserAssignmentComponent,
    ActivityCommentsComponent,
    ActivityCommentsPromptComponent,
    ActivityCommentButtonComponent,
    ActivityIconComponent,
    ActivityWithAssignmentComponent,
    ShareActivityComponent,
    ShareActivityTemplateComponent,
    AgreementIconComponent,
    ChangeAgreementModalComponent,
    ActivityDeviationTimeComponent,
    ActivityEditButtonComponent,
  ],
})
export class ActivitiesModule {}
