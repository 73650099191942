export enum DefaultActivityCategory {
  ActivityInterruption = 'activity-interruption',
  BrakeTestWithControlWalk = 'brake-test-with-control-walk',
  Break = 'break',
  BreakTest = 'break-test',
  BreakUnpaid = 'break-unpaid',
  BreakPartiallyPaid = 'break-partially-paid',
  BusDrive = 'bus-drive',
  CancelledShift = 'cancelled-shift',
  CarTransfer = 'car-transfer',
  CompanyDoctor = 'company-doctor',
  Couple = 'couple',
  CustomsControl = 'customs-control',
  Dispo = 'dispo',
  Education = 'education',
  EmergencyResponse = 'emergency-response',
  EmergencyResponseByPhone = 'emergency-response-by-phone',
  EmptyDrive = 'empty-drive',
  Exam = 'exam',
  Finish1 = 'finish-1',
  Finish2 = 'finish-2',
  Finish3 = 'finish-3',
  Finish4 = 'finish-4',
  FullBrakeTest = 'full-brake-test',
  GuestRide = 'guest-ride',
  HazmatInspection = 'hazmat-inspection',
  HomeTransfer = 'home-transfer',
  Hotel = 'hotel',
  InternalTransfer = 'internal-transfer',
  Layoff = 'layoff',
  Loading = 'loading',
  LoadingInspection = 'loading-inspection',
  LocationKnowledge = 'location-knowledge',
  More = 'more',
  Office = 'office',
  OnCallService = 'on-call-service',
  OperationalWaitingTime = 'operational-waiting-time',
  PersonalPreparation = 'personal-preparation',
  PersonalWrapUp = 'personal-wrap-up',
  PlanningAndDispatching = 'planning-and-dispatching',
  Postprocessing = 'postprocessing',
  Preparation = 'preparation',
  Preparation4 = 'preparation-4',
  Refueling = 'refueling',
  RegularTraining = 'regular-training',
  ReserveTime = 'reserve-time',
  RouteKnowledge = 'route-knowledge',
  RunabilityTest = 'runability-test',
  SecureTrain = 'secure-train',
  Shunting = 'shunting',
  SimplifiedBrakeTest = 'simplified-brake-test',
  Simulation = 'simulation',
  SpecialWagonInspection = 'special-wagon-inspection',
  TaxiTransfer = 'taxi-transfer',
  TrainDrive = 'train-drive',
  TrainGuarding = 'train-guarding',
  Training = 'training',
  TrainInspection = 'train-inspection',
  TramDrive = 'tram-drive',
  TransportPlanning = 'transport-planning',
  TruckDrive = 'truck-drive',
  TruckInspection = 'truck-inspection',
  Unloading = 'unloading',
  UnloadingInspection = 'unloading-inspection',
  WagonInspection = 'wagon-inspection',
  WagonInspection1 = 'wagon-inspection-1',
  WagonInspection2 = 'wagon-inspection-2',
  WagonInspection4 = 'wagon-inspection-4',
  Walk = 'walk',
  Warmup1 = 'warmup-1',
  Warmup2 = 'warmup-2',
  Warmup3 = 'warmup-3',
  WashVehicle = 'wash-vehicle',
}
