import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { Observable } from 'rxjs';

export type ProfitabilityScoreModelWithOptionalId = Omit<
  ProfitabilityScoreModel,
  'id'
> &
  Partial<Pick<ProfitabilityScoreModel, 'id'>>;

export interface ProfitabilityScoreValues {
  min: number;
  max: number;
}

export interface ProfitabilityScoreSteps {
  veryHigh: ProfitabilityScoreValues;
  high: ProfitabilityScoreValues;
  moderate: ProfitabilityScoreValues;
  low: ProfitabilityScoreValues;
  negative: ProfitabilityScoreValues;
  [key: string]: ProfitabilityScoreValues;
}

export interface ProfitabilityScoreModel {
  currency: string;
  enableSettings: boolean;
  hotelRatePerDay: number;
  id: string;
  nonBillableActivities: string[];
  organizationalUnitId: string;
  profitabilityClassification: ProfitabilityScoreSteps;
  revenuePerHour: number;
  transferActivities: string[];
  transferCostPerKilometer: number;
  wagePerHour: number;
}

export interface ProfitabilityScore {
  isEnabled: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ProfitabilitySettingsGateway {
  protected readonly path = 'profitability-settings';
  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
  ) {}

  getProfitabilitySettings(): Observable<ProfitabilityScoreModel> {
    return this.http.get<ProfitabilityScoreModel>(
      `${this.config.host}/${this.path}`,
    );
  }

  updateProfitabilitySettings(
    profitabilityScore: ProfitabilityScoreModelWithOptionalId,
  ): Observable<ProfitabilityScoreSteps> {
    return this.http.put<ProfitabilityScoreSteps>(
      `${this.config.host}/${this.path}`,
      profitabilityScore,
    );
  }
}
