export const professionIds = {
  locomotiveProfessionId: '3636b653-2e16-4957-ac2f-7eaf20d6c609',
  vehicleProfessionId: 'b76de89f-b731-4c19-9389-c41c9e3be220',
  zwExcavatorProfessionId: 'd9b4a39e-fcbf-4b08-abe6-8e63a1f31a3b',
  uteProfessionId: 'e5b7a1c2-55c9-43d5-b9e3-6b57d1a3e017',
  wagonProfessionId: '65b6a1a5-7cb7-4394-8a87-238c1b0d8f9d',
  pileDriversProfessionId: 'd682e6a4-3c2d-44a8-93d3-9e5c417b5d69',
  riser9mProfessionId: 'eeb6c948-ebe4-4648-8519-8c8c3a7d9cf3',
  riser14mProfessionId: 'f5f6a8c4-c6b7-4d63-b57c-75a1f1d17b8d',
  riser17mProfessionId: 'dcb6b9e1-4bfa-4f4b-8f56-66c0a0d7c1a6',
  stirrupProfessionId: 'e4c6b5d9-b7b4-4f73-8411-6a6f1b5e8c0e',
  dozzlerProfessionId: 'f5e6b8d1-3f8a-46a1-9a68-2a7c5b4e9c5a',
  drumStandProfessionId: 'c6d7a8f3-2e5b-43f2-8d47-1e3b4b7e8d6c',
  pullingUnitProfessionId: 'd5e8c7f2-4b9a-45b1-9a37-6e3c5b8e9d7b',
  caneProfessionId: 'e6c9a7f1-1d3b-4e72-9a67-8c7b5b9d8e5a',
  promax14mProfessionId: 'f7d8a6b3-2f4a-45b3-9b68-1a2b5c7e8f4b',
  promax19mProfessionId: 'd8e9a5c4-3e5a-4f81-8a27-6a3c5d8f9b6d',
  sklSmallProfessionId: 'e9b8a4d5-4d6b-45c1-8a47-2b4d6e8f1c5a',
  sklLargeProfessionId: 'd9e7a3b6-2c5a-46e2-9a68-5d7e8f2c4b3a',
  truckLessThan7_5tProfessionId: 'e8b7a2d4-3e6a-47c1-8b57-3e5b7f9d2c4a',
  truckGreaterThan7_5tProfessionId: 'd7e6a1c3-2d4a-48b2-9b37-4f6c8d9f2e5a',
  baumaExtProfessionId: 'e6d5a4b2-1c4b-49e1-8b27-2f3c6d8e9a4b',
  winchProfessionId: 'd5c4a3e1-2b5a-41f1-8b47-6e8f2a9d3c7b',
  towerTruckProfessionId: 'e4b3a2d1-3c6a-43e2-8a57-1f4d7c8e5b9a',
  steigerProfessionId: 'd3e2a1c4-4b5a-44d1-8a67-8f6c2e5b1d3a',
  cableDrumTrailerProfessionId: 'e2d1a4b3-2c5a-45f2-9a77-3f7d6c8e4b5a',
  unimogDrillingRigProfessionId: 'd1c9a3e4-3d4b-41f1-8a87-5e9f3b8c6d7a',
  lightingMastProfessionId: 'e0a1b2c3-1d2e-48f1-9b97-7f6c8d3e5b4a',
};

export const professionIdsToFilter = [
  professionIds.locomotiveProfessionId,
  professionIds.vehicleProfessionId,
  professionIds.zwExcavatorProfessionId,
  professionIds.uteProfessionId,
  professionIds.wagonProfessionId,
  professionIds.pileDriversProfessionId,
  professionIds.riser9mProfessionId,
  professionIds.riser14mProfessionId,
  professionIds.riser17mProfessionId,
  professionIds.stirrupProfessionId,
  professionIds.dozzlerProfessionId,
  professionIds.drumStandProfessionId,
  professionIds.pullingUnitProfessionId,
  professionIds.caneProfessionId,
  professionIds.promax14mProfessionId,
  professionIds.promax19mProfessionId,
  professionIds.sklSmallProfessionId,
  professionIds.sklLargeProfessionId,
  professionIds.truckLessThan7_5tProfessionId,
  professionIds.truckGreaterThan7_5tProfessionId,
  professionIds.baumaExtProfessionId,
  professionIds.winchProfessionId,
  professionIds.towerTruckProfessionId,
  professionIds.steigerProfessionId,
  professionIds.cableDrumTrailerProfessionId,
  professionIds.unimogDrillingRigProfessionId,
  professionIds.lightingMastProfessionId,
];
