import { ActivityCategory } from '@wilson/interfaces';

export class InitializeFavoriteActivityCategoriesState {
  static readonly type =
    '[FavoriteActivityCategoriesState] InitializeFavoriteActivityCategoriesState';
}

export class AddFavoriteActivityCategory {
  static readonly type =
    '[FavoriteActivityCategoriesState] AddFavoriteActivityCategory';
  constructor(public readonly activityCategory: ActivityCategory) {}
}

export class RemoveFavoriteActivityCategory {
  static readonly type =
    '[FavoriteActivityCategoriesState] RemoveFavoriteActivityCategory';
  constructor(public readonly activityCategoryId: string) {}
}
