import { Injectable } from '@angular/core';
import { patch, updateItem } from '@ngxs/store/operators';
import {
  ResolvedShiftsStateModel,
  ResolvedShiftWithSyncStatus,
} from './state/resolved-shifts.state';

@Injectable({
  providedIn: 'root',
})
export class ShiftPostSyncOperatorFactory {
  public createPostSyncOperation(shift: { id?: string; delete?: boolean }) {
    switch (shift?.delete) {
      case true:
      case false:
      case undefined:
      default:
        return patch<ResolvedShiftsStateModel>({
          shifts: updateItem<ResolvedShiftWithSyncStatus>(
            (s) => s?.id === shift.id,
            patch<ResolvedShiftWithSyncStatus>({
              syncedAt: new Date().toISOString(),
            }),
          ),
        });
        break;
    }
  }
}
