import { FormControl } from '@ngneat/reactive-forms';
import { ConflictSetting } from './shift-plan.interface';

export const RangeShorterThanPlanDaysError = 'range_shorter_than_plan_days';

export interface HeaderForm {
  [HeaderFormKeys.Name]: FormControl<string>;
  [HeaderFormKeys.DateRange]: FormControl<[Date, Date]>;
  [HeaderFormKeys.Rolling]: boolean;
  [HeaderFormKeys.ConflictSetting]: ConflictSetting;
}

export enum HeaderFormKeys {
  Name = 'name',
  DateRange = 'dateRange',
  Rolling = 'rolling',
  ConflictSetting = 'conflictSetting',
}
