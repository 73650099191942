import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import {
  Action,
  NgxsAfterBootstrap,
  Selector,
  State,
  StateContext,
  createSelector,
} from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { catchError, finalize, of, take, tap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { LabelsGateway } from '../gateway/labels.gateway';
import {
  AddTempLabel,
  ClearLabels,
  ClearTempLabels,
  CreateLabel,
  InitLabels,
  RemoveLabels,
  UpdateLabel,
  UpdateTempLabel,
} from './labels.actions';
import { Label } from '@wilson/interfaces';

export interface LabelsStateModel {
  labels: Label[];
  tempLabels: Label[];
  isLoading: boolean;
}

const defaults: LabelsStateModel = {
  labels: [],
  tempLabels: [],
  isLoading: false,
};

const LABELS_STATE_NAME = 'label';

@State<LabelsStateModel>({
  name: LABELS_STATE_NAME,
  defaults,
})
@Injectable()
export class LabelsState implements NgxsAfterBootstrap {
  constructor(
    private readonly labelsGateway: LabelsGateway,
    private readonly storage: Storage,
  ) {}

  @Selector()
  static labels(state: LabelsStateModel): Label[] {
    return state.labels;
  }

  @Selector()
  static labelsAndTempLabels(state: LabelsStateModel): Label[] {
    return state.labels.concat(state.tempLabels);
  }

  static isDuplicatedLabelName(partialLabel: Pick<Label, 'id' | 'name'>) {
    return createSelector([LabelsState], (state: LabelsStateModel) => {
      return (
        state.labels.some(
          (label) =>
            label.id !== partialLabel.id && label.name === partialLabel.name,
        ) ||
        state.tempLabels.some(
          (tempLabel) =>
            tempLabel.id !== partialLabel.id &&
            tempLabel.name === partialLabel.name,
        )
      );
    });
  }

  static isTempLabel(id: string) {
    return createSelector([LabelsState], (state: LabelsStateModel) => {
      return state.tempLabels.some((tenpLabel) => tenpLabel.id === id);
    });
  }

  static isTempLabelReadyForCreate(id: string) {
    return createSelector([LabelsState], (state: LabelsStateModel) => {
      const tempLabel = LabelsState.getTempLabelById(id)(state);
      return tempLabel && !!tempLabel.name && !!tempLabel.colorCode;
    });
  }

  static getTempLabelById(id: string) {
    return createSelector(
      [LabelsState],
      (state: LabelsStateModel): Label | undefined => {
        return state.tempLabels.find((tempLabel) => tempLabel.id === id);
      },
    );
  }

  static getLabelById(id: string) {
    return createSelector(
      [LabelsState],
      (state: LabelsStateModel): Label | undefined => {
        return state.labels.find((label) => label.id === id);
      },
    );
  }

  static getLabelsByIds(labels: Label[]) {
    return createSelector([LabelsState], (state: LabelsStateModel) => {
      const idSet = new Set(labels.map((item) => item.id));
      const selectedLabels = state.labels.filter((item) => idSet.has(item.id));
      return selectedLabels;
    });
  }

  async ngxsAfterBootstrap(ctx: StateContext<LabelsStateModel>): Promise<void> {
    const labelsState: { labels: Label[] } | undefined = await this.storage.get(
      LABELS_STATE_NAME,
    );
    if (labelsState) ctx.patchState({ labels: labelsState.labels });
  }

  @Action(InitLabels)
  initializeLabelState(ctx: StateContext<LabelsStateModel>) {
    ctx.patchState({ isLoading: true });
    return this.labelsGateway.getLabels().pipe(
      take(1),
      tap((labels) => {
        const modifiedLabels = labels.map((label) => ({
          ...label,
          agGridRowId: uuidv4(),
        }));
        ctx.patchState({
          labels: modifiedLabels,
        });
        this.storage.set(LABELS_STATE_NAME, { labels: modifiedLabels });
      }),
      catchError(() => {
        return of(null);
      }),
      finalize(() => {
        ctx.patchState({ isLoading: false });
      }),
    );
  }

  @Action(ClearLabels)
  clearLabels(ctx: StateContext<LabelsStateModel>) {
    ctx.setState(
      patch<LabelsStateModel>({
        labels: [],
      }),
    );
    this.storage.remove(LABELS_STATE_NAME);
  }

  @Action(ClearTempLabels)
  clearTempLabels(ctx: StateContext<LabelsStateModel>) {
    ctx.setState(
      patch<LabelsStateModel>({
        tempLabels: [],
      }),
    );
  }

  @Action(AddTempLabel)
  addTempLabel(ctx: StateContext<LabelsStateModel>) {
    ctx.setState(
      patch<LabelsStateModel>({
        tempLabels: append([
          {
            id: uuidv4(),
            name: '',
            colorCode: '#878787',
            description: '',
            agGridRowId: uuidv4(),
          },
        ]),
      }),
    );
  }

  @Action(UpdateTempLabel)
  updateTempLabel(
    ctx: StateContext<LabelsStateModel>,
    action: UpdateTempLabel,
  ) {
    ctx.setState(
      patch({
        tempLabels: updateItem<Label>(
          (label) => label.id === action.partialLabel.id,
          patch(action.partialLabel),
        ),
      }),
    );
  }

  @Action(CreateLabel)
  createLabel(ctx: StateContext<LabelsStateModel>, action: CreateLabel) {
    ctx.setState(
      patch({
        tempLabels: removeItem<Label>(
          (tempLabel) => tempLabel.id === action.partialTempLabel.id,
        ),
        labels: append<Label>([
          {
            ...action.createdLabel,
            agGridRowId: action.partialTempLabel.agGridRowId,
          },
        ]),
      }),
    );
  }

  @Action(UpdateLabel)
  async updateLabel(ctx: StateContext<LabelsStateModel>, action: UpdateLabel) {
    ctx.setState(
      patch({
        labels: updateItem<Label>(
          (label) => label.id === action.partialLabel.id,
          patch(action.partialLabel),
        ),
      }),
    );
  }

  @Action(RemoveLabels)
  removeLabels(ctx: StateContext<LabelsStateModel>, action: RemoveLabels) {
    const currentState = ctx.getState();
    const updatedLabels = currentState.labels.filter(
      (label) =>
        !action.labels.some((actionLabel) => actionLabel.id === label.id),
    );
    const updatedTempLabels = currentState.tempLabels.filter(
      (tempLabel) =>
        !action.labels.some((actionLabel) => actionLabel.id === tempLabel.id),
    );

    ctx.setState({
      ...currentState,
      labels: updatedLabels,
      tempLabels: updatedTempLabels,
    });
  }
}
