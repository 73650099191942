import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { Observable } from 'rxjs';

export type ShiftId = string;
export interface Productivity {
  productiveMinutes: number;
  totalMinutes: number;
  productivityRatio: number;
}

export interface ProductivitiesResponse {
  usersProductivities: Record<string, Productivity>;
  overallProductivity: Productivity;
}

@Injectable({
  providedIn: 'root',
})
export class ProductivityKPIGatewayService {
  protected readonly path = 'v1/kpis';
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
  ) {}

  getProductivities(
    userIds: string[],
    timeRange: {
      startDatetime: string;
      endDatetime: string;
    },
  ): Observable<ProductivitiesResponse> {
    return this.httpClient.post<ProductivitiesResponse>(
      `${this.config.host}/${this.path}/productivities`,
      {
        userIds,
        from: timeRange.startDatetime,
        to: timeRange.endDatetime,
      },
    );
  }

  getProductivitiesInDraft(
    userShiftAssignments: {
      userId: string;
      shiftIds: ShiftId[];
    }[],
  ): Observable<ProductivitiesResponse> {
    return this.httpClient.post<ProductivitiesResponse>(
      `${this.config.host}/${this.path}/draft-productivities`,
      {
        userShiftAssignments,
      },
    );
  }
}
