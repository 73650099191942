import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullReportPipe } from './pipes/full-report.pipe';
import { StartReportPipe } from './pipes/start-report.pipe';
import { EndReportPipe } from './pipes/end-report.pipe';
import { ShiftReportPipe } from './pipes/shift-report.pipe';

@NgModule({
  declarations: [
    FullReportPipe,
    StartReportPipe,
    EndReportPipe,
    ShiftReportPipe,
  ],
  providers: [ShiftReportPipe],
  imports: [CommonModule],
  exports: [FullReportPipe, StartReportPipe, EndReportPipe, ShiftReportPipe],
})
export class ReportUtilitiesModule {}
