import { Pipe, PipeTransform } from '@angular/core';
import { Activity, ResolvedActivity } from '@wilson/interfaces';
import { isOneActivityStarted } from './is-one-activity-started.fn';

@Pipe({
  name: 'isOneActivityStarted',
})
export class IsOneActivityStartedPipe implements PipeTransform {
  transform(
    activities: Activity[] | ResolvedActivity[] | null | undefined,
  ): boolean {
    return isOneActivityStarted(activities);
  }
}
