import { PrefScoreView } from '@wilson/preferences/core';

export class FetchPreferenceSettings {
  static readonly type = '[PreferenceSettings] Fetch Preference Settings';
}

export class UpdateLocationPreference {
  static readonly type = '[PreferenceSettings] Update Location Preference';
  constructor(
    public payload: {
      value: boolean;
    },
  ) {}
}

export class UpdateVehiclePreference {
  static readonly type = '[PreferenceSettings] Update Vehicle Preference';
  constructor(
    public payload: {
      value: boolean;
    },
  ) {}
}

export class UpdatePreferenceSetting {
  static readonly type = '[PreferenceSettings] Update Preference Setting';
  constructor(
    public payload: {
      value: boolean;
    },
  ) {}
}

export class UpdateRestTimePreference {
  static readonly type = '[PreferenceSettings] Update Rest Time Preference';
  constructor(
    public payload: {
      value: boolean;
    },
  ) {}
}

export class UpdateRestTimePreferenceCount {
  static readonly type =
    '[PreferenceSettings] Update Rest Time Preference Count';
  constructor(
    public payload: {
      count: number;
    },
  ) {}
}

export class UpdateWorkTimePreference {
  static readonly type = '[PreferenceSettings] Update Work Time Preference';
  constructor(
    public payload: {
      value: boolean;
    },
  ) {}
}

export class UpdateShiftTimePreference {
  static readonly type = '[PreferenceSettings] Update Shift Time Preference';
  constructor(
    public payload: {
      value: boolean;
    },
  ) {}
}

export class UpdateWorkTimePreferenceCount {
  static readonly type =
    '[PreferenceSettings] Update Work Time Preference Count';
  constructor(
    public payload: {
      count: number;
    },
  ) {}
}

export class UpdatePreferenceViewRole {
  static readonly type = '[PreferenceSettings] Update Preference View Role';
  constructor(
    public payload: {
      preferenceId: string;
      isEnabled: boolean;
    },
  ) {}
}

export class UpdatePrefScoreView {
  static readonly type = '[PreferenceSettings] Update Pref Score View';
  constructor(
    public payload: {
      view: PrefScoreView;
    },
  ) {}
}

export class UpdateEndShiftAtHome {
  static readonly type = '[PreferenceSettings] Update  End Shift At Home';
  constructor(
    public payload: {
      value: boolean;
    },
  ) {}
}

export class UpdateEndAtHomeDistance {
  static readonly type = '[PreferenceSettings] Update End At Home Distance';
  constructor(
    public payload: {
      value: number;
    },
  ) {}
}

export class UpdateLongShiftPreference {
  static readonly type = '[PreferenceSettings] Update Long Shift Preference';
  constructor(
    public payload: {
      value: boolean;
    },
  ) {}
}

export class UpdateLongShiftDuration {
  static readonly type = '[PreferenceSettings] Update Long Shift Duration';
  constructor(
    public payload: {
      value: number;
    },
  ) {}
}
