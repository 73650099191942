import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BackendService } from '@wilson/base';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { ShiftPlan } from '@wilson/interfaces';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class ShiftPlanService extends BackendService<ShiftPlan> {
  protected readonly path = 'shift-plans';

  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
  ) {
    super();
  }

  public rolloutShiftPlan(shiftPlanId: string): Promise<ShiftPlan> {
    return firstValueFrom(
      this.http.patch<ShiftPlan>(
        `${this.config.host}/${this.path}/${shiftPlanId}/rollout`,
        {},
      ),
    );
  }

  public revertRollout(shiftPlanId: string): Promise<ShiftPlan> {
    return firstValueFrom(
      this.http.patch<ShiftPlan>(
        `${this.config.host}/${this.path}/${shiftPlanId}/revert`,
        {},
      ),
    );
  }
}
