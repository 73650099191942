import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Store } from '@ngxs/store';

export const authGuardFn: CanActivateFn = () => {
  const router = inject(Router);
  const store = inject(Store);
  const jwtHelperService = inject(JwtHelperService);

  const token = store.selectSnapshot((state) => state.auth.refreshToken);
  if (token && !jwtHelperService.isTokenExpired(token)) {
    return true;
  }

  return router.createUrlTree(['/auth']);
};
