import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BackendService } from '@wilson/base';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { Partner } from '@wilson/share/interfaces';

@Injectable({
  providedIn: 'root',
})
export class PartnershipGateway extends BackendService<Partner> {
  protected readonly path = 'partnerships';
  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
  ) {
    super();
  }
}
