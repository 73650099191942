import { Injectable } from '@angular/core';
import { StateOperator } from '@ngxs/store';
import { append, insertItem, patch, updateItem } from '@ngxs/store/operators';
import {
  activityCommentFactory,
  activityFactory,
  activityReportFactory,
} from '@wilson/activities/util';
import {
  Activity,
  ActivityComment,
  ActivityReportCategory,
  ActivityReportType,
  GeoLocation,
  OperationStatus,
  ResolvedActivity,
  ResolvedActivityReport,
  ResolvedActivityWithReports,
  WilsonApp,
} from '@wilson/interfaces';
import { createFullActivityObject } from '@wilson/non-domain-specific/activities-helpers/services';
import { ShiftNotSyncedOperatorFactory } from './shift-not-synced-operator.factory';
import { ResolvedShiftActions } from './state/resolved-shifts.action';
import {
  ResolvedShiftWithSyncStatus,
  ResolvedShiftsStateModel,
} from './state/resolved-shifts.state';

@Injectable({
  providedIn: 'root',
})
export class NewStartPauseLiveShiftStateOperatorFactory {
  constructor(
    private readonly shiftNotSyncedOperatorFactory: ShiftNotSyncedOperatorFactory,
  ) {}

  public createNewStartPauseLiveShiftOperation(
    action: ResolvedShiftActions.NewStartPauseActivityLiveShift,
    shift: ResolvedShiftWithSyncStatus,
  ): StateOperator<ResolvedShiftsStateModel>[] {
    const stateOperators: StateOperator<ResolvedShiftsStateModel>[] = [];
    stateOperators.push(
      this.shiftNotSyncedOperatorFactory.createShiftNotSyncedOperation(shift),
    );
    stateOperators.push(this.createCurrentActivityOperation(action, shift));
    stateOperators.push(this.createPauseActivityOperation(action, shift));
    if (
      action.duplicatedActivity &&
      action.duplicatedActivity.endDatetime &&
      action.duplicatedActivity.startDatetime
    ) {
      stateOperators.push(this.createDuplicateActivityOperation(action, shift));
    }
    return stateOperators;
  }

  private createCurrentActivityOperation(
    action: ResolvedShiftActions.NewStartPauseActivityLiveShift,
    shift: ResolvedShiftWithSyncStatus,
  ) {
    return patch<ResolvedShiftsStateModel>({
      shifts: updateItem<ResolvedShiftWithSyncStatus>(
        (s) => s?.id === shift.id,
        patch<ResolvedShiftWithSyncStatus>({
          activities: updateItem<ResolvedActivityWithReports>(
            (a) => a?.id === action.currentActivityId,
            patch<ResolvedActivityWithReports>({
              operationalStatus: OperationStatus.Completed,
              activityReports: append<ResolvedActivityReport>([
                activityReportFactory(
                  action.currentActivityId,
                  new Date().toISOString(),
                  action.pauseActivity.startLocation as GeoLocation,
                  action.pauseActivity.startLocationId as string,
                  ActivityReportCategory.End,
                  ActivityReportType.IsTimes,
                ),
              ]),
            }),
          ),
        }),
      ),
    });
  }

  private createPauseActivityOperation(
    action: ResolvedShiftActions.NewStartPauseActivityLiveShift,
    shift: ResolvedShiftWithSyncStatus,
  ) {
    const newPauseActivity = activityFactory(
      createFullActivityObject({
        activityCategory: action.pauseActivity.activityCategory,
        activityCategoryId: action.pauseActivity.activityCategoryId,
        activityComments: [],
        activityReports: [],
        createdFrom: WilsonApp.Mobile,
        endDatetime: action.pauseActivity.endDatetime,
        endLocation: action.pauseActivity.endLocation,
        endLocationId: action.pauseActivity.endLocationId,
        jobId: null,
        name: '',
        operationalStatus: OperationStatus.Ongoing,
        professionId: null,
        serviceId: null,
        shiftId: shift.id as string | null,
        startDatetime: action.pauseActivity.startDatetime,
        startLocation: action.pauseActivity.startLocation,
        startLocationId: action.pauseActivity.startLocationId,
        viaLocationIds: [],
      }),
    );
    const insertIndex =
      shift.activities.findIndex((a) => a.id === action.currentActivityId) + 1;

    return patch<ResolvedShiftsStateModel>({
      shifts: updateItem<ResolvedShiftWithSyncStatus>(
        (s) => s?.id === shift.id,
        patch<ResolvedShiftWithSyncStatus>({
          activities: insertItem<ResolvedActivityWithReports>(
            {
              ...newPauseActivity,
              activityReports: [
                activityReportFactory(
                  newPauseActivity.id as string,
                  new Date().toISOString(),
                  action.pauseActivity.startLocation as GeoLocation,
                  action.pauseActivity.startLocationId as string,
                  ActivityReportCategory.Start,
                  ActivityReportType.IsTimes,
                ),
              ],
            } as ResolvedActivityWithReports,
            insertIndex,
          ),
        }),
      ),
    });
  }

  private createDuplicateActivityOperation(
    action: ResolvedShiftActions.NewStartPauseActivityLiveShift,
    shift: ResolvedShiftWithSyncStatus,
  ) {
    const currentActivity = shift.activities.find(
      (a) => a.id === action.currentActivityId,
    ) as ResolvedActivityWithReports;
    const insertIndex =
      shift.activities.findIndex((a) => a.id === action.currentActivityId) + 2;
    const { activityComments, ...cleanCurrentActivity } = currentActivity;
    const newDuplicatedActivity = activityFactory({
      ...(cleanCurrentActivity as Activity),
      activityReports: [],
      createdFrom: WilsonApp.Mobile,
      endDatetime: action.duplicatedActivity?.endDatetime as string,
      operationalStatus: OperationStatus.NotStarted,
      startDatetime: action.duplicatedActivity?.startDatetime as string,
      startLocation: action.pauseActivity.endLocation,
      startLocationId: action.pauseActivity.endLocationId,
    });

    if (activityComments) {
      const newDuplicatedActivityComments: ActivityComment[] = [];
      activityComments.forEach((activityComment: ActivityComment) => {
        newDuplicatedActivityComments.push(
          activityCommentFactory({
            ...activityComment,
            activityId: newDuplicatedActivity.id as string,
          }),
        );
      });
      newDuplicatedActivity.activityComments = newDuplicatedActivityComments;
    }

    return patch<ResolvedShiftsStateModel>({
      shifts: updateItem<ResolvedShiftWithSyncStatus>(
        (s) => s?.id === shift.id,
        patch<ResolvedShiftWithSyncStatus>({
          activities: insertItem<ResolvedActivity>(
            newDuplicatedActivity as ResolvedActivityWithReports,
            insertIndex,
          ),
        }),
      ),
    });
  }
}
