export enum ShiftQuickCheckValidate {
  MaximumActivityLengthCheck = 'maximum-activity-length-check',
  MaximumProtectiveWorkingTimeCheck = 'maximum-protective-working-time-check',
  MaximumShiftLengthCheck = 'maximum-shift-length-check',
  BreakRequirementsCheck = 'break-requirements-check',
  MaxNightShiftsInRow = 'max-night-shifts-in-row',
  MaxTimeBetweenRestDays = 'max-time-between-rest-days',
  MaxWorkTimeInTimeRange = 'max-work-time-in-time-range',
  MinTimeBetweenShiftsV2 = 'min-time-between-shifts-v2',
  MinimumTimeBetweenShiftCheck = 'minimum-time-between-shift-check',
  ShiftQualificationCheck = 'shift-qualification-check',
  ShiftTimeLogicCheck = 'shift-time-logic-check',
  ShiftLocationLogicCheck = 'shift-location-logic-check',
  UserApprovalCheck = 'user-approval-check',
  ShiftProfessionCheck = 'shift-profession-check',
}
