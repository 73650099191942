import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import {
  Action,
  createSelector,
  NgxsAfterBootstrap,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { GeoLocationWithFavorite } from '@wilson/interfaces';
import { UserFavoriteLocationsService } from '@wilson/locations';
import { firstValueFrom, map, take, tap } from 'rxjs';
import {
  AddFavoriteLocation,
  InitializeFavoriteLocationsState,
  RemoveFavoriteLocation,
} from './favorite-locations.action';
import { TranslateService } from '@ngx-translate/core';
import { ToastController } from '@ionic/angular';
import { append, iif, patch, removeItem } from '@ngxs/store/operators';

interface FavoriteLocationsStateModal {
  favoriteLocations: GeoLocationWithFavorite[];
}
const STATE_NAME = 'favoriteLocations';
const defaultOperationsState: FavoriteLocationsStateModal = {
  favoriteLocations: [],
};

@State<FavoriteLocationsStateModal>({
  name: STATE_NAME,
  defaults: defaultOperationsState,
})
@Injectable()
export class FavoriteLocationsState implements NgxsAfterBootstrap {
  constructor(
    private readonly userFavoriteLocationsService: UserFavoriteLocationsService,
    private readonly store: Store,
    private readonly storage: Storage,
    private readonly translate: TranslateService,
    private readonly toastController: ToastController,
  ) {}

  @Selector()
  static favoriteLocations(state: FavoriteLocationsStateModal) {
    return state.favoriteLocations;
  }

  static isFavoriteLocation(locationId: string) {
    return createSelector(
      [FavoriteLocationsState.favoriteLocations],
      (favoriteLocations: GeoLocationWithFavorite[]) => {
        return favoriteLocations.some(
          (favoriteLocation) => favoriteLocation.id === locationId,
        );
      },
    );
  }

  async ngxsAfterBootstrap(
    ctx: StateContext<FavoriteLocationsStateModal>,
  ): Promise<void> {
    const favoriteLocationsState = await this.storage.get(STATE_NAME);
    if (favoriteLocationsState) ctx.patchState(favoriteLocationsState);
  }

  @Action(InitializeFavoriteLocationsState)
  initializeFavoriteLocationsState(
    ctx: StateContext<FavoriteLocationsStateModal>,
  ) {
    return this.userFavoriteLocationsService.getFavoriteLocations().pipe(
      take(1),
      map((userFavoriteLocations) => {
        const favoriteLocations: GeoLocationWithFavorite[] =
          userFavoriteLocations.map((userFavoriteLocation) => {
            return {
              ...userFavoriteLocation.location,
              isFavorite: true,
            };
          });
        return {
          favoriteLocations: favoriteLocations,
        };
      }),
      tap((locationsState) => {
        ctx.patchState(locationsState);
        this.updateStorage(ctx);
      }),
    );
  }

  @Action(AddFavoriteLocation)
  async addFavoriteLocation(
    ctx: StateContext<FavoriteLocationsStateModal>,
    action: AddFavoriteLocation,
  ) {
    try {
      await firstValueFrom(
        this.userFavoriteLocationsService.setFavoriteLocationForUser(
          action.location.id as string,
        ),
      );

      ctx.setState(
        patch<FavoriteLocationsStateModal>({
          favoriteLocations: iif<GeoLocationWithFavorite[]>(
            (favoriteLocations) =>
              !favoriteLocations.some(
                (location) => location.id === action.location.id,
              ),
            append<GeoLocationWithFavorite>([action.location]),
          ),
        }),
      );
      this.updateStorage(ctx);
    } catch (error) {
      console.error(error);
      const toast = await this.toastController.create({
        message: this.translate.instant('general.errorMessage'),
        duration: 3000,
        position: 'bottom',
      });
      await toast.present();
    }
  }

  @Action(RemoveFavoriteLocation)
  async removeFavoriteLocation(
    ctx: StateContext<FavoriteLocationsStateModal>,
    action: RemoveFavoriteLocation,
  ) {
    try {
      await firstValueFrom(
        this.userFavoriteLocationsService.removeFavoriteLocationFromUser(
          action.locationId,
        ),
      );

      ctx.setState(
        patch<FavoriteLocationsStateModal>({
          favoriteLocations: removeItem<GeoLocationWithFavorite>(
            (location) => location.id === action.locationId,
          ),
        }),
      );
      this.updateStorage(ctx);
    } catch (error) {
      console.error(error);
      const toast = await this.toastController.create({
        message: this.translate.instant('general.errorMessage'),
        duration: 3000,
        position: 'bottom',
      });
      await toast.present();
    }
  }

  private updateStorage(ctx: StateContext<FavoriteLocationsStateModal>) {
    this.storage.set(STATE_NAME, ctx.getState());
  }
}
