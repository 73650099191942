import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { FeatureFlagNumberPipe } from '@wilson/feature-flags';
import {
  ActivitiesAssignment,
  Score,
  ShiftAssignment,
} from '@wilson/interfaces';
import { stringify } from 'qs';
import { Observable, switchMap } from 'rxjs';

@Injectable()
export class ShiftAssignmentService {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
    private readonly featureFlagPurePipe: FeatureFlagNumberPipe,
  ) {}

  public getShiftAssignments(
    shiftId: string,
    ignoreUnavailabilities = false,
  ): Observable<ShiftAssignment[]> {
    return this.featureFlagPurePipe
      .transform('portal-shift-validation-v-2')
      .pipe(
        switchMap((isV2Enabled) => {
          const params: {
            ignoreUnavailabilities?: boolean;
            include_v2_validations?: boolean;
          } = {};
          if (ignoreUnavailabilities) {
            params.ignoreUnavailabilities = true;
          }

          if (isV2Enabled) {
            params.include_v2_validations = true;
          }

          return this.httpClient.get<ShiftAssignment[]>(
            `${this.config.host}/shifts/${shiftId}/assignments`,
            {
              params: new HttpParams({
                fromObject: params,
              }),
            },
          );
        }),
      );
  }

  public getShiftScoreByUserId(
    shiftId: string,
    userId: string,
  ): Observable<Score[]> {
    return this.httpClient.get<Score[]>(
      `${this.config.host}/shifts/${shiftId}/users/${userId}/calculate-scores`,
    );
  }

  public getActivitiesAssignments(
    activityIds: string[],
  ): Observable<ActivitiesAssignment[]> {
    const queryString = stringify({
      activityIds: activityIds,
    });
    return this.httpClient.get<ActivitiesAssignment[]>(
      `${this.config.host}/shifts/activities/assignments/bulk?${queryString}`,
    );
  }
}
