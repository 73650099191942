import { Base } from '@wilson/base';
import { EndShiftAtHomePreferenceRating } from '@wilson/interfaces';
import { LongShiftPreferenceRating } from './long-shift-preference.interface';

export enum PrefScoreView {
  Full = 'full',
  Flat = 'flat',
  None = 'none',
}

export interface RolePreferenceSetting {
  id: string;
  preferenceSettingId: string;
  roleId: string;
  isEnabled: boolean;
}

export interface PreferenceSettings extends Base {
  isEnabled: boolean;
  isLocationPrefEnabled: boolean;
  isLongShiftPrefEnabled: boolean;
  isEndShiftAtHomeEnabled: boolean;
  isVehiclePrefEnabled: boolean;
  isShiftTimePrefEnabled: boolean;
  isWorkTimePrefEnabled: boolean;
  isRestTimePrefEnabled: boolean;
  longShiftDurationInHours: number;
  userHomeLocationRadiusInKm: number;
  prefScoreView: PrefScoreView;
  maxCountWorkTimePrefMobile: number;
  maxCountRestTimePrefMobile: number;
  maxCountLocationPrefMobile: number;
  maxCountVehiclePrefMobile: number;
  organizationalUnitId: string;
  preferenceSettingRole: RolePreferenceSetting[];
}

export interface UserGeneralPreference {
  id: string;
  userId: string;
  endShiftAtHomeRating?: EndShiftAtHomePreferenceRating;
  longShiftRating?: LongShiftPreferenceRating;
}

export interface CreateUserGeneralPreferencePayload {
  endShiftAtHomeRating?: EndShiftAtHomePreferenceRating;
  longShiftRating?: LongShiftPreferenceRating;
}

export interface UpdateUserGeneralPreferencePayload {
  endShiftAtHomeRating?: EndShiftAtHomePreferenceRating;
  longShiftRating?: LongShiftPreferenceRating;
}
