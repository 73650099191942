import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Sector } from '@wilson/interfaces';
import { take, tap } from 'rxjs/operators';
import { SectorsService } from '../lib/sectors.service';
import { AddSector } from './sector.actions';

export class SectorModel {
  public sectors!: Sector[];
}

@State<SectorModel>({
  name: 'sector',
  defaults: {
    sectors: [],
  },
})
@Injectable()
export class SectorState {
  constructor(private readonly sectorsService: SectorsService) {}

  @Selector() static sectors(state: SectorModel) {
    return state.sectors || [];
  }

  @Selector() static firstSector(state: SectorModel) {
    return state.sectors[0];
  }

  @Action(AddSector)
  addSector(ctx: StateContext<SectorModel>) {
    return this.sectorsService.getSignedInUsersRootOrgUnitSector().pipe(
      take(1),
      tap((sectors) => {
        ctx.patchState({
          sectors,
        });
      }),
    );
  }
}
