import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import {
  Stay,
  StayAttachment,
  StayDTO,
  StaySearchOptions,
} from '@wilson/interfaces';
import { ShiftId } from '../productivity-kpis/productivity-kpis.gateway';
import { firstValueFrom } from 'rxjs';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { PdfGeneratedResponse } from '../invoicing/Invoices.gateway';

@Injectable({
  providedIn: 'root',
})
export class TimelineStayGateway {
  protected readonly path = 'stays';
  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
  ) {}

  public updateStay(stay: Partial<StayDTO> & { id: string }) {
    return this.http.patch<Stay>(
      `${this.config.host}/${this.path}/${stay.id}`,
      stay,
    );
  }

  public deleteStay(stayId: string) {
    return firstValueFrom(
      this.http.delete<Stay>(`${this.config.host}/${this.path}/${stayId}`),
    );
  }

  public createStay(params: Partial<StayDTO>) {
    return this.http.post<Stay>(`${this.config.host}/${this.path}`, params);
  }

  public fetchNecessaryStaysInRange({
    dateRange,
    userIds,
  }: {
    dateRange: {
      start: Date;
      end: Date;
    };
    userIds: string[];
  }) {
    return this.http.post<Record<ShiftId, boolean>>(
      `${this.config.host}/${this.path}/determine-necessary-stays`,
      {
        startDatetime: dateRange.start.toISOString(),
        endDatetime: dateRange.end.toISOString(),
        userIds: userIds,
      },
    );
  }

  public validateStays(stayIds: string[]) {
    return this.http.post<Record<string, boolean>>(
      `${this.config.host}/${this.path}/validate`,
      {
        stayIds,
      },
    );
  }

  public getStays(payload: Partial<StaySearchOptions>) {
    return this.http.post<Stay[]>(
      `${this.config.host}/${this.path}/get-stays`,
      payload,
    );
  }

  public getStaysFromLocationId(payload: Partial<StaySearchOptions>) {
    return this.http.post<Stay[]>(
      `${this.config.host}/${this.path}/get-accommodations-at-location`,
      payload,
    );
  }
  public bulkUpdateStays(stays: Partial<StayDTO & { id: string }>[]) {
    return this.http.patch<Stay>(`${this.config.host}/${this.path}`, {
      items: stays,
    });
  }

  public uploadStayAttachment(
    id: string,
    file: NzUploadFile | File,
    isInvoice: boolean | null,
  ) {
    const formData = new FormData();
    formData.append('file', file as unknown as File);
    if (isInvoice !== null) {
      formData.append('isInvoice', isInvoice.toString());
    }

    return this.http.post<StayAttachment>(
      `${this.config.host}/${this.path}/${id}/attachments`,
      formData,
    );
  }

  public deleteStayAttachment(stayId: string, attachmentId: string) {
    return firstValueFrom(
      this.http.delete<StayAttachment>(
        `${this.config.host}/${this.path}/${stayId}/attachments/${attachmentId}`,
      ),
    );
  }

  public createReimbursementPdf(stayId: string, note: string) {
    return this.http.post<PdfGeneratedResponse>(
      `${this.config.host}/${this.path}/${stayId}/reimbursement-pdf`,
      {
        note,
      },
    );
  }
}
