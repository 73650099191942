import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { firstValueFrom } from 'rxjs';
import {
  CreateUserLocationPreferenceDto,
  UserLocationPreference,
} from './user-location-preferences.interface';
import { Store } from '@ngxs/store';
import { AuthState } from '@wilson/auth/core';
import { stringify } from 'qs';

@Injectable({
  providedIn: 'root',
})
export class UserLocationPreferencesService {
  protected readonly path = (id: string) => `users/${id}/locations/preferences`;

  constructor(
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
    protected readonly http: HttpClient,
    protected readonly store: Store,
  ) {}

  public add(
    entity: CreateUserLocationPreferenceDto,
  ): Promise<UserLocationPreference> {
    const userId = this.store.selectSnapshot(AuthState.userId) as string;

    return firstValueFrom(
      this.http.post<UserLocationPreference>(
        `${this.config.host}/${this.path(userId)}`,
        entity,
      ),
    );
  }

  public getAll(
    userId: string,
    options?: { embed?: ('geoLocation' | 'user')[] },
  ) {
    const params = stringify(options);
    return this.http.get<UserLocationPreference[]>(
      `${this.config.host}/${this.path(userId)}?${params}`,
    );
  }

  public remove(entityId: string) {
    const userId = this.store.selectSnapshot(AuthState.userId) as string;

    return firstValueFrom(
      this.http.delete(`${this.config.host}/${this.path(userId)}/${entityId}`),
    );
  }
}
