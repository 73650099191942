<div #tooltip class="d-flex flex-row shift-validations-tooltip">
  <div class="left-content">
    <ng-content></ng-content>

    <ng-container *ngIf="!noValidationFound">
      <div class="d-flex flex-row align-items-center mb-1 px-2">
        <fa-icon
          class="icon title-icon me-2"
          [icon]="faValidationTitleIcon"
        ></fa-icon>
        <p class="text-uppercase m-0">
          <strong>{{ 'tooltip.shift.validation' | translate }}</strong>
        </p>
      </div>
      <wilson-validation-category-row
        [isValid]="areQualificationsSufficient && areProfessionsSufficient"
        [isSelected]="
          selectedCategory === ValidationCheckCategory.Qualifications
        "
        [title]="'page.shifts.qualifications_sufficient' | translate"
        (selected)="setSelectedCategory(ValidationCheckCategory.Qualifications)"
      ></wilson-validation-category-row>

      <wilson-validation-category-row
        [isValid]="areWorkingTimeRulesSufficient"
        [isSelected]="
          selectedCategory === ValidationCheckCategory.WorkingTimeRules
        "
        [title]="'page.shifts.working_time_rules_observed' | translate"
        (selected)="
          setSelectedCategory(ValidationCheckCategory.WorkingTimeRules)
        "
      >
      </wilson-validation-category-row>

      <wilson-validation-category-row
        *ngIf="shiftLogicValidations"
        [isValid]="areShiftLogicSufficient"
        [isSelected]="selectedCategory === ValidationCheckCategory.ShiftLogic"
        [title]="'page.shifts.content_ok' | translate"
        (selected)="setSelectedCategory(ValidationCheckCategory.ShiftLogic)"
      >
      </wilson-validation-category-row>
    </ng-container>
  </div>

  <div class="right-content" [ngClass]="!!selectedCategory ? 'expanded' : ''">
    <div
      class="details-container"
      [ngClass]="
        selectedCategory === ValidationCheckCategory.Qualifications
          ? 'details-container-expanded'
          : 'details-container-collapsed'
      "
    >
      <wilson-qualification-details
        [qualificationNames]="qualificationNames ?? []"
        [professionValidations]="professionValidations ?? []"
      >
      </wilson-qualification-details>
    </div>

    <div
      class="details-container"
      [ngClass]="
        selectedCategory === ValidationCheckCategory.WorkingTimeRules
          ? 'details-container-expanded'
          : 'details-container-collapsed'
      "
    >
      <wilson-working-time-details
        [workingTimeValidations]="workingTimeValidations"
      >
      </wilson-working-time-details>
    </div>

    <div
      class="details-container"
      [ngClass]="
        selectedCategory === ValidationCheckCategory.ShiftLogic
          ? 'details-container-expanded'
          : 'details-container-collapsed'
      "
    >
      <wilson-shift-logic-details
        [shiftLogicValidations]="shiftLogicValidations ?? []"
      >
      </wilson-shift-logic-details>
    </div>
  </div>
</div>
