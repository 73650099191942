import { Pipe, PipeTransform } from '@angular/core';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { distinctUntilChanged, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FeatureFlagsService } from '../feature-flags.service';

@Pipe({
  name: 'featureFlagPure',
})
export class FeatureFlagPurePipe implements PipeTransform {
  constructor(private readonly featureFlagsService: FeatureFlagsService) {}

  transform(value: string): Observable<boolean> {
    return this.featureFlagsService.flag$.pipe(
      map((flags: LDFlagSet) => flags[value]),
      distinctUntilChanged(),
    );
  }
}
