import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FeatureFlagPurePipe } from '@wilson/feature-flags';
import {
  QualificationCategory,
  QualificationName,
  ShiftValidationDetails,
} from '@wilson/interfaces';
import { ShiftValidationErrorService } from '@wilson/shifts';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { map, Observable } from 'rxjs';

@Component({
  selector: 'wilson-validation-renderer',
  template: `
    <div
      nz-tooltip
      [nzTooltipTitle]="tooltipInformation"
      nzTooltipPlacement="right"
      nzTooltipOverlayClassName="dark"
      [nzTooltipOverlayStyle]="{ 'min-width': '625px' }"
    >
      <span>
        <span class="text-primary" *ngIf="cellValue"
          ><i class="fas fa-check-circle me-2"></i>
          {{ 'shift.assignment.valid' | translate }}</span
        >
        <span class="text-danger" *ngIf="!cellValue"
          ><i class="fas fa-times-circle me-2"></i>
          {{ 'shift.assignment.invalid' | translate }}</span
        >
        <br />
      </span>
    </div>
    <!-- Tool Tip -->
    <ng-template #tooltipInformation>
      <wilson-qualification-employee-tooltip
        [header]="'tooltip.subheader.conditions_fulfilled' | translate"
        [shiftValidationDetails]="workingTimeValidation$ | async"
        [qualificationNames]="qualificationNames"
        [professionValidations]="professionValidations$ | async"
      >
      </wilson-qualification-employee-tooltip>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationRenderComponent implements AgRendererComponent {
  public cellValue: boolean;
  public headerValue: string;

  workingTimeValidation$: Observable<ShiftValidationDetails[]>;
  professionValidations$: Observable<ShiftValidationDetails[]>;
  workingTimeValidation: ShiftValidationDetails[];

  qualificationNames: QualificationName[] = [];
  qualificationCategory: QualificationCategory;

  constructor(
    private readonly shiftValidationErrorService: ShiftValidationErrorService,
    private readonly featureFlagPurePipe: FeatureFlagPurePipe,
  ) {}

  agInit(params: ICellRendererParams): void {
    this.cellValue = this.getValueToDisplay(params);
    this.headerValue = params.colDef.headerName;
  }

  refresh(params: ICellRendererParams) {
    this.cellValue = this.getValueToDisplay(params);
    this.headerValue = params.colDef.headerName;
    return true;
  }

  getValueToDisplay(params: ICellRendererParams) {
    let isValid = false;
    if (params.data?.shiftValidationErrors) {
      this.qualificationNames =
        this.shiftValidationErrorService.getQualificationName(
          params.data.shiftValidationErrors,
        );

      this.workingTimeValidation =
        ShiftValidationErrorService.getShiftWorkingTimeValidation(
          params.data.shiftValidationErrors,
        );

      this.workingTimeValidation$ = this.featureFlagPurePipe
        .transform('portal-shift-validation-user-approval')
        .pipe(
          map((isUserApprovalEnabled) => {
            if (!params.data.shiftValidationErrors) return;
            return ShiftValidationErrorService.getShiftWorkingTimeValidation(
              params.data.shiftValidationErrors,
              isUserApprovalEnabled,
            );
          }),
        );

      this.professionValidations$ = this.featureFlagPurePipe
        .transform('portal-shift-validation-user-professions')
        .pipe(
          map((isUserApprovalEnabled) => {
            if (!params.data.shiftValidationErrors) return;
            return this.shiftValidationErrorService.getShiftProfessionValidation(
              params.data.shiftValidationErrors,
              isUserApprovalEnabled,
            );
          }),
        );

      for (const data of params.data.shiftValidationErrors) {
        if (data.valid) {
          isValid = true;
        } else {
          isValid = false;
          break;
        }
      }
    }
    return isValid;
  }
}
