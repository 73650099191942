import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  ResetBulkEditDrawerState,
  SetBulkEditDrawerOpenStatus,
  SetBulkEditDrawerSaveStatus,
} from './bulk-edit-drawer.action';

export interface BulkEditDrawerStateModel {
  isOpen: boolean;
  isSaving: boolean;
}

const defaultBulkEditDrawerState: BulkEditDrawerStateModel = {
  isOpen: false,
  isSaving: false,
};

@State<BulkEditDrawerStateModel>({
  name: 'BulkEditDrawerStateModel',
  defaults: defaultBulkEditDrawerState,
})
@Injectable()
export class BulkEditDrawerState {
  @Selector()
  static isOpen(state: BulkEditDrawerStateModel): boolean {
    return state.isOpen;
  }

  @Selector()
  static isSaving(state: BulkEditDrawerStateModel): boolean {
    return state.isSaving;
  }

  @Action(ResetBulkEditDrawerState)
  resetBulkEditDrawerState({
    setState,
  }: StateContext<BulkEditDrawerStateModel>) {
    setState({
      isOpen: false,
      isSaving: false,
    });
  }

  @Action(SetBulkEditDrawerOpenStatus)
  setBulkEditDrawerOpenStatus(
    { patchState }: StateContext<BulkEditDrawerStateModel>,
    action: SetBulkEditDrawerOpenStatus,
  ) {
    patchState({
      isOpen: action.openStatus,
    });
  }

  @Action(SetBulkEditDrawerSaveStatus)
  setBulkEditDrawerSaveStatus(
    { patchState }: StateContext<BulkEditDrawerStateModel>,
    action: SetBulkEditDrawerSaveStatus,
  ) {
    patchState({
      isSaving: action.saveStatus,
    });
  }
}
