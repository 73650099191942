import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ROUTES } from '@wilson/utils';
import { filter, map, shareReplay } from 'rxjs/operators';

@Injectable()
export class CalendarNavigationService {
  readonly isCurrentViewTheCalendarTab$ = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    map(
      (event) =>
        (event as NavigationEnd).url === `/${ROUTES.tabs}/${ROUTES.calendar}`,
    ),
    shareReplay(1),
  );

  constructor(private readonly router: Router) {}
}
