import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class CreateNewViaDragAndDropService {
  static readonly MOCK_ABSENCE_ID = 'MOCK_ABSENCE';
  static readonly MOCK_SHIFT_ID = 'MOCK_SHIFT';
  static readonly MOCK_ACTIVITY_ID = 'MOCK_ACTIVITY';
  private isNewAbsenceQuickActionCardDraggableSubject = new BehaviorSubject(
    true,
  );
  private isNewActivityQuickActionCardDraggableSubject = new BehaviorSubject(
    true,
  );
  private enableNewAbsenceDropZoneSubject = new BehaviorSubject(false);
  private isQuickShiftDraggableSubject = new BehaviorSubject(true);
  private showEmptyShiftDropZoneSubject = new BehaviorSubject(false);
  private showNewActivityShiftDropZoneSubject = new BehaviorSubject(false);

  private showShiftTemplateDropZoneSubject = new BehaviorSubject(false);
  private updatingIdsSubject = new BehaviorSubject<string[]>([]);

  public isNewAbsenceQuickActionCardDraggable$ =
    this.isNewAbsenceQuickActionCardDraggableSubject.asObservable();
  public isNewActivityQuickActionCardDraggable$ =
    this.isNewActivityQuickActionCardDraggableSubject.asObservable();
  public enableNewAbsenceDropZone$ =
    this.enableNewAbsenceDropZoneSubject.asObservable();
  public isQuickShiftDraggable$ =
    this.isQuickShiftDraggableSubject.asObservable();
  public showEmptyShiftDropZone$ =
    this.showEmptyShiftDropZoneSubject.asObservable();

  public showNewActivityShiftDropZone$ =
    this.showNewActivityShiftDropZoneSubject.asObservable();
  public showShiftTemplateDropZone$ =
    this.showShiftTemplateDropZoneSubject.asObservable();
  public updatingIds$ = this.updatingIdsSubject.asObservable();

  setNewAbsenceQuickActionCardDraggable(isDraggable: boolean) {
    this.isNewAbsenceQuickActionCardDraggableSubject.next(isDraggable);
  }

  setNewActivityQuickActionCardDraggable(isDraggable: boolean) {
    this.isNewActivityQuickActionCardDraggableSubject.next(isDraggable);
  }
  setEnableNewAbsenceDropZone(isEnabled: boolean) {
    this.enableNewAbsenceDropZoneSubject.next(isEnabled);
  }

  setQuickShiftDraggable(isDraggable: boolean) {
    this.isQuickShiftDraggableSubject.next(isDraggable);
  }

  setEmptyShiftDropZone(showDropZone: boolean) {
    this.showEmptyShiftDropZoneSubject.next(showDropZone);
  }

  setNewActivityShiftDropZone(showDropZone: boolean) {
    this.showNewActivityShiftDropZoneSubject.next(showDropZone);
  }

  setShiftTemplateDropZone(showDropZone: boolean) {
    this.showShiftTemplateDropZoneSubject.next(showDropZone);
  }

  addUpdatingId(id: string) {
    this.updatingIdsSubject.next([...this.updatingIdsSubject.value, id]);
  }

  removeUpdatingId(id: string) {
    const indexToDelete = this.updatingIdsSubject.value.indexOf(id);
    if (indexToDelete > -1) {
      const clonedIds = [...this.updatingIdsSubject.value];
      clonedIds.splice(indexToDelete, 1);
      this.updatingIdsSubject.next(clonedIds);
    }
  }
}
