export interface VisualAttributes {
  isBorder: boolean;
  isElevated: boolean;
  isGrey: boolean;
  isPrimaryHighlight: boolean;
  isSelectable: boolean;
  isYellowHighlight: boolean;
  shiftDotType: ShiftDotType | null;
  statusPills: CalendarCardStatusPill[];
}

export interface CalendarCardStatusPill {
  imgSrc: string;
  translateKey: string;
}

export interface ShiftAttributes extends VisualAttributes {
  formattedShiftEndDateTime: string;
  formattedShiftStartDateTime: string;
  reportedShiftEndDateTime: string;
  reportedShiftEndLocation: string;
  reportedShiftStartDateTime: string;
  reportedShiftStartLocation: string;
  shiftEndLocation: string;
  shiftStartLocation: string;
  actualStartLocation: string;
  actualStartTime: string;
  actualEndLocation: string;
  actualEndTime: string;
  isActualStart: boolean;
  isActualEnd: boolean;
}

export enum ShiftDotType {
  yellow = 'yellow',
  green = 'green',
  gray = 'gray',
  not_filled = 'not_filled',
}
