import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';
import { UserLocationPreferencesState } from './user-location-preferences/user-location-preferences.state';
import { UserGeneralPreferencesState } from './user-general-preferences/user-general-preferences.state';
import { UserTimePreferencesState } from './user-time-preferences/user-time-preferences.state';
import { UserVehiclePreferencesState } from './user-vehicle-preferences/user-vehicle-preferences.state';
import { UserShiftTimePreferenceState } from './user-shift-time-preference/user-shift-time-preference.state';

export const PreferencesStateName = 'userPreferences';
@State({
  name: PreferencesStateName,
  children: [
    UserLocationPreferencesState,
    UserGeneralPreferencesState,
    UserTimePreferencesState,
    UserVehiclePreferencesState,
    UserShiftTimePreferenceState,
    UserShiftTimePreferenceState,
  ],
})
@Injectable()
export class UserPreferencesState {}
