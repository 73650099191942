import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { compose, patch, updateItem } from '@ngxs/store/operators';
import { ServiceSeriesGateway, ServicesGateway } from '@wilson/api/gateway';
import {
  Activity,
  ActivityTemplate,
  ResolvedService,
  ServiceSeries,
  convertWeekDayStringsInWeekdayObject,
} from '@wilson/interfaces';
import {
  Segment,
  SegmentActivity,
  ServiceEditModel,
  ServiceEditStateModel,
  ServiceFormModel,
} from '@wilson/services/interfaces';
import { DateTimeFormat } from '@wilson/utils';
import { addDays, addHours, differenceInDays, format } from 'date-fns';
import { chain } from 'lodash';
import { Observable, map, take, tap } from 'rxjs';
import {
  ClearServiceForm,
  InitializeServiceDuplicateEditState,
  InitializeServiceEditState,
  InitializeServiceSeriesDuplicateEditState,
  InitializeServiceSeriesEditState,
  SetOrganizationalUnit,
  SetServiceRecurring,
  SetServiceStartDate,
  UpdateSegmentDates,
} from './service-edit.actions';

export const SERVICES_STATE_NAME = 'services';
const defaultValue: ServiceEditModel = {
  name: null,
  comment: null,
  trainNumber: null,
  organizationalUnit: null,
  startDate: null,
  endDate: null,
  recurring: false,
  weekdays: null,
  seriesRules: null,
  segments: [
    {
      name: null,
      startDatetime: null,
      startLocation: null,
      startLocationId: null,
      endDatetime: null,
      endLocation: null,
      endLocationId: null,
      isDisabled: false,
      hasSharedActivity: false,
      viaLocationIds: [],
      activities: [],
    },
  ],
};

@State<ServiceEditStateModel>({
  name: SERVICES_STATE_NAME,
  defaults: {
    serviceForm: {
      model: defaultValue,
    },
  },
})
@Injectable()
export class ServiceEditState {
  constructor(
    private readonly servicesGateway: ServicesGateway,
    private readonly serviceSeriesGateway: ServiceSeriesGateway,
  ) {}

  @Selector()
  static serviceForm(state: ServiceEditStateModel): ServiceFormModel {
    return state.serviceForm;
  }

  @Selector()
  static service(state: ServiceEditStateModel): ResolvedService | undefined {
    return state.service;
  }

  @Selector()
  static serviceSeries(
    state: ServiceEditStateModel,
  ): ServiceSeries | undefined {
    return state.serviceSeries;
  }

  @Selector()
  static segments(state: ServiceEditStateModel): Segment[] | null | undefined {
    return state.serviceForm.model?.segments;
  }

  @Action(InitializeServiceEditState)
  initializeServiceEditState(
    ctx: StateContext<ServiceEditStateModel>,
    action: InitializeServiceEditState,
  ): Observable<ServiceEditStateModel | null> {
    return this.servicesGateway
      .getServices(
        {
          where: {
            id: action.serviceId,
          },
          relations: [
            'organizationalUnit',
            'activities',
            'activities.activityCategory',
            'activities.activityReports',
            'activities.activityReports.location',
            'activities.startLocation',
            'activities.endLocation',
            'activities.agreement',
            'activities.agreement.client',
            'activities.job',
            'activities.profession',
            'serviceSeries',
            'serviceSeries.serviceSeries',
          ],
          order: {},
          limit: 0,
          offset: 0,
        },
        {
          withActivityStatus: true,
          withOperationalStatus: true,
        },
      )
      .pipe(
        take(1),
        map((services) => {
          const service = services[0];
          const serviceEditStateModel: ServiceEditStateModel = {
            serviceForm: {
              model: {
                name: service.name,
                organizationalUnit: service.organizationalUnit,
                comment: service.comment,
                trainNumber: service.trainNumber,
                startDate: service.startDate,
                endDate: service.startDate,
                recurring: false,
                weekdays: null,
                seriesRules: [],
                segments: this.generateActivitySegments(service.activities),
              },
            },
            service: service,
          };
          return serviceEditStateModel;
        }),
        tap((serviceEditStateModel) => {
          ctx.setState(serviceEditStateModel);
        }),
      );
  }

  @Action(InitializeServiceDuplicateEditState)
  initializeServiceDuplicateEditState(
    ctx: StateContext<ServiceEditStateModel>,
    { preparedService }: InitializeServiceDuplicateEditState,
  ) {
    const serviceEditStateModel: ServiceEditStateModel = {
      serviceForm: {
        model: {
          name: preparedService.name,
          organizationalUnit: preparedService.organizationalUnit,
          comment: preparedService.comment,
          trainNumber: preparedService.trainNumber || null,
          startDate: preparedService.startDate,
          endDate: preparedService.startDate,
          recurring: false,
          weekdays: null,
          seriesRules: [],
          segments: this.generateActivitySegments(
            preparedService.activities || [],
          ),
        },
      },
    };
    ctx.setState(serviceEditStateModel);
    return serviceEditStateModel;
  }

  @Action(InitializeServiceSeriesEditState)
  initializeServiceSeriesEditState(
    ctx: StateContext<ServiceEditStateModel>,
    action: InitializeServiceSeriesEditState,
  ): Observable<ServiceEditStateModel | null> {
    return this.serviceSeriesGateway
      .getServiceSeriesWithRelationById(action.serviceSeriesId, {
        relations: [
          'organizationalUnit',
          'activityTemplates',
          'seriesRules',
          'activityTemplates.jobActivityTemplates',
          'activityTemplates.activityCategory',
          'activityTemplates.agreement',
          'activityTemplates.profession',
          'activityTemplates.agreement.client',
          'activityTemplates.startLocation',
          'activityTemplates.endLocation',
        ],
      })
      .pipe(
        take(1),
        map((series) => {
          const serviceSeries = series[0];
          const serviceEditStateModel: ServiceEditStateModel = {
            serviceForm: {
              model: {
                name: serviceSeries.name,
                organizationalUnit: serviceSeries.organizationalUnit,
                comment: serviceSeries.comment,
                trainNumber: serviceSeries.trainNumber,
                startDate: serviceSeries.validFrom,
                endDate: serviceSeries.validTill,
                recurring: true,
                weekdays: convertWeekDayStringsInWeekdayObject(
                  serviceSeries.recurringOn,
                ),
                seriesRules: serviceSeries.seriesRules,
                segments: this.generateActivityTemplateSegments(
                  serviceSeries.activityTemplates || [],
                ),
              },
            },
            serviceSeries: serviceSeries,
          };
          return serviceEditStateModel;
        }),
        tap((serviceEditStateModel) => {
          ctx.setState(serviceEditStateModel);
        }),
      );
  }

  @Action(InitializeServiceSeriesDuplicateEditState)
  initializeServiceSeriesDuplicateEditState(
    ctx: StateContext<ServiceEditStateModel>,
    action: InitializeServiceSeriesDuplicateEditState,
  ) {
    return this.serviceSeriesGateway
      .getServiceSeriesWithRelationById(action.serviceSeriesId, {
        relations: [
          'organizationalUnit',
          'activityTemplates',
          'seriesRules',
          'activityTemplates.jobActivityTemplates',
          'activityTemplates.activityCategory',
          'activityTemplates.agreement',
          'activityTemplates.profession',
          'activityTemplates.agreement.client',
          'activityTemplates.startLocation',
          'activityTemplates.endLocation',
        ],
      })
      .pipe(
        take(1),
        map(([serviceSeries]) => {
          const serviceEditStateModel: ServiceEditStateModel = {
            serviceForm: {
              model: {
                name: serviceSeries.name,
                organizationalUnit: serviceSeries.organizationalUnit,
                comment: serviceSeries.comment,
                trainNumber: serviceSeries.trainNumber,
                startDate: serviceSeries.validFrom,
                endDate: serviceSeries.validTill,
                recurring: true,
                weekdays: convertWeekDayStringsInWeekdayObject(
                  serviceSeries.recurringOn,
                ),
                seriesRules: serviceSeries.seriesRules?.map(
                  ({ ...seriesRules }) => ({
                    ...seriesRules,
                    serviceSeriesId: null,
                  }),
                ),
                segments: this.generateActivityTemplateSegments(
                  serviceSeries.activityTemplates || [],
                ),
              },
            },
          };
          return serviceEditStateModel;
        }),
        tap((serviceEditStateModel) => {
          ctx.setState(serviceEditStateModel);
        }),
      );
  }

  @Action(UpdateSegmentDates)
  updateSegmentDates(
    ctx: StateContext<ServiceEditStateModel>,
    action: UpdateSegmentDates,
  ) {
    const state = ctx.getState();
    const updateItems = state.serviceForm.model?.segments?.map((segment, i) =>
      updateItem<Segment>(
        i,
        patch<Segment>({
          startDatetime: addDays(
            new Date(
              `${format(
                action.newDate,
                DateTimeFormat.DatabaseDateFormat,
              )} ${format(new Date(segment.startDatetime), 'HH:mm')}`,
            ),
            differenceInDays(
              new Date(
                format(
                  new Date(segment.startDatetime),
                  DateTimeFormat.DatabaseDateFormat,
                ),
              ),
              new Date(
                format(action.oldDate, DateTimeFormat.DatabaseDateFormat),
              ),
            ),
          ).toISOString(),
          endDatetime: addDays(
            new Date(
              `${format(
                action.newDate,
                DateTimeFormat.DatabaseDateFormat,
              )} ${format(new Date(segment.endDatetime), 'HH:mm')}`,
            ),
            differenceInDays(
              new Date(
                format(
                  new Date(segment.endDatetime),
                  DateTimeFormat.DatabaseDateFormat,
                ),
              ),
              new Date(
                format(action.oldDate, DateTimeFormat.DatabaseDateFormat),
              ),
            ),
          ).toISOString(),
        }),
      ),
    );
    ctx.setState(
      patch<ServiceEditStateModel>({
        serviceForm: patch<ServiceFormModel>({
          model: patch<ServiceEditModel>({
            segments: compose(...updateItems),
          }),
        }),
      }),
    );
  }

  @Action(SetServiceStartDate)
  setServiceStartDate(
    ctx: StateContext<ServiceEditStateModel>,
    action: SetServiceStartDate,
  ) {
    const state = ctx.getState();
    ctx.setState(
      patch<ServiceEditStateModel>({
        serviceForm: {
          model: {
            ...state.serviceForm.model,
            startDate: action.startDate.toISOString(),
            segments: [
              ...[
                {
                  ...state.serviceForm.model.segments[0],
                  startDatetime: action.startDate.toISOString(),
                  endDatetime: addHours(action.startDate, 1).toISOString(),
                },
              ],
            ],
          },
        },
      }),
    );
  }

  @Action(SetOrganizationalUnit)
  setOrganizationalUnit(
    ctx: StateContext<ServiceEditStateModel>,
    action: SetOrganizationalUnit,
  ) {
    const state = ctx.getState();
    if (state.serviceForm.model) {
      ctx.setState(
        patch<ServiceEditStateModel>({
          serviceForm: {
            model: {
              ...state.serviceForm.model,
              organizationalUnit: action.organizationalUnit,
            },
          },
        }),
      );
    }
  }

  @Action(SetServiceRecurring)
  setServiceRecurring(
    ctx: StateContext<ServiceEditStateModel>,
    action: SetServiceRecurring,
  ) {
    const state = ctx.getState();
    if (state.serviceForm.model) {
      ctx.setState(
        patch<ServiceEditStateModel>({
          serviceForm: {
            model: {
              ...state.serviceForm.model,
              recurring: action.recurring,
            },
          },
        }),
      );
    }
  }

  @Action(ClearServiceForm)
  clearServiceForm(ctx: StateContext<ServiceEditStateModel>) {
    ctx.setState(
      patch<ServiceEditStateModel>({
        serviceForm: {
          model: defaultValue,
        },
      }),
    );
  }

  private generateActivityTemplateSegments(
    activities: ActivityTemplate[],
  ): Segment[] {
    return chain(activities)
      .groupBy(this.getGroupKey)
      .map((items: ActivityTemplate[]) => {
        return {
          ...this.generatePartialSegment(items),
          sharedActivityTemplates: items
            .filter(
              (item) =>
                item.jobActivityTemplates &&
                item.jobActivityTemplates.length > 0,
            )
            .map((item) => item.id)
            .filter((id): id is string => typeof id === 'string'),
          hasSharedActivity: false,
          isDisabled: items.some(
            (item) =>
              item.jobActivityTemplates && item.jobActivityTemplates.length > 0,
          ),
        };
      })
      .sortBy((segment) => segment.startDatetime)
      .value();
  }

  private generateActivitySegments(activities: Activity[]): Segment[] {
    return chain(activities)
      .groupBy(this.getGroupKey)
      .map((items: Activity[]) => {
        return {
          ...this.generatePartialSegment(items),
          hasSharedActivity:
            items.findIndex((activity) => activity?.jobId) !== -1,
          isDisabled:
            items.findIndex((activity) => activity?.activityReports?.length) !==
            -1,
        };
      })
      .sortBy((segment) => segment.startDatetime)
      .value();
  }

  private generatePartialSegment(
    items: (Activity | ActivityTemplate)[],
  ): Omit<Segment, 'isDisabled' | 'hasSharedActivity'> {
    const segment: Omit<Segment, 'isDisabled' | 'hasSharedActivity'> = {
      name: items[0].name,
      startDatetime: items[0].startDatetime,
      endDatetime: items[0].endDatetime,
      startLocationId: items[0].startLocationId,
      startLocation: items[0].startLocation,
      endLocationId: items[0].endLocationId,
      endLocation: items[0].endLocation,
      viaLocationIds: items[0].viaLocationIds ?? [],
      activities: items.map((activity) => {
        const segmentActivity: SegmentActivity = {
          id: activity.id,
          activityCategoryId: activity.activityCategoryId,
          activityCategory: activity.activityCategory,
          profession: activity.profession ?? null,
          professionId: activity.professionId ?? null,
          agreementId: activity.agreementId ?? null,
          jobId: 'jobId' in activity ? activity.jobId : null,
        };
        return segmentActivity;
      }),
    };
    return segment;
  }

  private getGroupKey(item: Activity | ActivityTemplate) {
    return `${item.startLocationId}${item.endLocationId}${item.startDatetime}${item.endDatetime}`;
  }
}
