export class InitializeProjectsState {
  static readonly type = '[Projects] Initialize';
}

export class ResetProjectsState {
  static readonly type = '[Projects] Reset';
}

export class FetchAndAddProjectIfNotInState {
  static readonly type = '[Projects] FetchAndAddProjectIfNotInState';
  constructor(public id: string) {}
}
