import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { stringify } from 'qs';
import { firstValueFrom } from 'rxjs';
import {
  CreateOrganizationalUnitLocationPreferenceDto,
  OrganizationalUnitLocationPreference,
} from './location-preferences-gateway.interface';

@Injectable({
  providedIn: 'root',
})
export class LocationPreferencesGatewayService {
  protected readonly path = 'organizational-units/locations/preferences';

  constructor(
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
    protected readonly http: HttpClient,
  ) {}

  public add(
    entity: CreateOrganizationalUnitLocationPreferenceDto,
  ): Promise<OrganizationalUnitLocationPreference> {
    return firstValueFrom(
      this.http.post<OrganizationalUnitLocationPreference>(
        `${this.config.host}/${this.path}`,
        entity,
      ),
    );
  }

  public getAll(options?: { embed?: ['geoLocation'] }) {
    const params = stringify(options);
    return this.http.get<OrganizationalUnitLocationPreference[]>(
      `${this.config.host}/${this.path}?${params}`,
    );
  }

  public remove(entityId: string) {
    return firstValueFrom(
      this.http.delete(`${this.config.host}/${this.path}/${entityId}`),
    );
  }
}
