import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AddressesService } from '@wilson/addresses';
import {
  Address,
  GeoLocation,
  OrganizationalUnitExtensionWithAddress,
} from '@wilson/interfaces';
import { ToastrService } from 'ngx-toastr';
import { LocationsService } from './locations.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SaveAddressService {
  constructor(
    private readonly translate: TranslateService,
    private readonly toastr: ToastrService,
    private readonly addressesService: AddressesService,
    private readonly locationsService: LocationsService,
  ) {}

  async saveAddress(orgUnits: OrganizationalUnitExtensionWithAddress[]) {
    for (const orgUnit of orgUnits) {
      if (orgUnit.location) {
        const address: Address[] =
          await this.addressesService.getAddressBySearch(
            orgUnit.location.address,
          );
        if (address.length) {
          orgUnit.location.addressId = address[0].id;
        } else {
          try {
            const address: Address[] =
              await this.addressesService.createNewAddress(
                orgUnit.location.address,
              );
            orgUnit.location.addressId = address[0].id;
          } catch {
            this.toastr.error(
              this.translate.instant('general.something_went_wrong'),
            );
          }
        }
        delete orgUnit.location.address;
        const geoLocation = await firstValueFrom(
          this.locationsService.getAll({
            where: {
              addressId: orgUnit.location.addressId,
              name: orgUnit.location.name,
            },
          }),
        );
        if (geoLocation.length) {
          orgUnit.locationId = geoLocation[0].id;
        } else {
          orgUnit.location.latitude = Number(orgUnit.location.latitude);
          orgUnit.location.longitude = Number(orgUnit.location.longitude);
          const location = orgUnit.location;
          try {
            const geoLocation = await this.locationsService.addMany(location);
            orgUnit.locationId = geoLocation[0].id;
          } catch {
            this.toastr.error(
              this.translate.instant('general.something_went_wrong'),
            );
          }
        }
        delete orgUnit.location;
        delete orgUnit.locationCategory;
      }
    }
    return orgUnits;
  }

  async getExistingLocationAddressOrCreateNewIfNoneExist(address: Address) {
    if (!address) return;
    const addressToCheck: Address = address;
    const existingAddress: Address[] =
      await this.addressesService.getAddressBySearch(addressToCheck);
    if (existingAddress.length) {
      addressToCheck.id = existingAddress[0].id;
    } else {
      try {
        const address: Address[] = await this.addressesService.createNewAddress(
          addressToCheck,
        );
        addressToCheck.id = address[0].id;
      } catch {
        this.toastr.error(
          this.translate.instant('general.something_went_wrong'),
        );
      }
    }
    return addressToCheck;
  }

  async getExistingGeoLocationOrCreateNewIfNoneExist(location: GeoLocation) {
    if (!location.addressId) return;
    const locationToCheck = location;
    const geoLocation = await firstValueFrom(
      this.locationsService.getAll({
        where: {
          addressId: location.addressId,
          name: location.name,
        },
      }),
    );
    if (geoLocation.length) {
      locationToCheck.id = geoLocation[0].id;
    } else {
      locationToCheck.latitude = Number(locationToCheck.latitude);
      locationToCheck.longitude = Number(locationToCheck.longitude);
      // eslint-disable-next-line
      const { address, ...locationToCheckWithoutAddress } = locationToCheck;
      try {
        const geoLocation = await this.locationsService.addMany(
          locationToCheckWithoutAddress,
        );
        locationToCheck.id = geoLocation[0].id;
      } catch {
        this.toastr.error(
          this.translate.instant('general.something_went_wrong'),
        );
      }
    }
    return locationToCheck;
  }
}
