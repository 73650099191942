import { Pipe, PipeTransform } from '@angular/core';
import { isSameDay, isValid } from 'date-fns';

@Pipe({
  name: 'dateIsSameDay',
  standalone: true,
})
export class DateIsSameDayPipe implements PipeTransform {
  transform(
    date: Date | null | undefined,
    dateToCompare: Date | null | undefined,
  ): boolean {
    return (
      !!date &&
      !!dateToCompare &&
      isValid(date) &&
      isValid(dateToCompare) &&
      isSameDay(date, dateToCompare)
    );
  }
}
