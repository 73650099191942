<div class="empty-state" [class.pt-5]="!params">
  <div
    class="d-flex justify-content-center"
    [ngClass]="!params ? 'mt-5 pt-4' : ''"
  >
    <nz-empty [nzNotFoundContent]="null"></nz-empty>
  </div>
  <div class="d-flex justify-content-center mt-3">
    <label id="headline-id" class="h5">
      {{ 'general.pretty_empty' | translate }}
    </label>
  </div>
  <div class="d-flex justify-content-center">
    <small id="content-id">
      {{ 'general.could_not_find_match' | translate }}
    </small>
  </div>
</div>
