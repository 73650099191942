<div class="labels-wrapper" *ngIf="selectedLabels$ | async as labels">
  <ng-container *ngIf="maxAmount !== 0; else showAll">
    <ng-container
      *ngFor="let label of labels.slice(0, maxAmount); let last = last"
    >
      <nz-tag [nzColor]="label.colorCode" class="label rounded-1">
        <span [style.color]="labelsHelperService.getContrast(label.colorCode)">
          {{ label.name }}
        </span>
      </nz-tag>
      <ng-container *ngIf="last && labels.length > maxAmount">
        <span
          nz-tooltip
          [nzTooltipTitle]="hiddenLabelsTemplate"
          nzTooltipOverlayClassName="dark"
          [nzTooltipPlacement]="tooltipDirection"
        >
          <nz-tag class="label more-tag rounded-1">
            {{ 'label.more' | translate : { num: labels.length - maxAmount } }}
          </nz-tag>
        </span>
        <ng-template #hiddenLabelsTemplate>
          <ng-container *ngFor="let hiddenLabel of labels.slice(maxAmount)">
            <span class="row hidden-label label label-tag">
              {{ hiddenLabel.name }}
            </span>
          </ng-container>
        </ng-template>
      </ng-container>
    </ng-container>
  </ng-container>
  <ng-template #showAll>
    <ng-container *ngFor="let label of labels">
      <nz-tag
        [nzColor]="label.colorCode"
        class="label rounded-1 label label-tag"
      >
        <span [style.color]="labelsHelperService.getContrast(label.colorCode)">
          {{ label.name }}
        </span>
      </nz-tag>
    </ng-container>
  </ng-template>
</div>
