import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import {
  ShiftTimeGatewayService,
  ShiftTimePreferenceSetting,
} from '@wilson/preferences/core';
import { firstValueFrom } from 'rxjs';
import {
  AddShiftTimePreferenceSettingAction,
  DeleteShiftTimePreferenceSettingAction,
  FetchShiftTimePreferenceSettingAction,
} from './shift-time-preference-setting.actions';
import { v4 as uuidv4 } from 'uuid';

export class ShiftTimePreferenceSettingStateModel {
  isLoading!: boolean;
  isLoadingSuccess!: boolean;
  isLoadingError!: boolean;
  public items!: ShiftTimePreferenceSetting[];
}

const defaults = {
  isLoading: false,
  isLoadingSuccess: false,
  isLoadingError: false,
  items: [],
};

@State<ShiftTimePreferenceSettingStateModel>({
  name: 'shiftTimePreferenceSetting',
  defaults,
})
@Injectable()
export class ShiftTimePreferenceSettingState {
  constructor(private shiftTimeGatewayService: ShiftTimeGatewayService) {}

  @Selector()
  static shiftTimePreferenceSettings(
    state: ShiftTimePreferenceSettingStateModel,
  ) {
    return state.items;
  }

  @Selector()
  static isLoading(state: ShiftTimePreferenceSettingStateModel) {
    return state.isLoading;
  }

  @Selector()
  static isLoadingSuccess(state: ShiftTimePreferenceSettingStateModel) {
    return state.isLoadingSuccess;
  }

  @Selector()
  static isLoadingError(state: ShiftTimePreferenceSettingStateModel) {
    return state.isLoadingError;
  }

  @Selector()
  static selectableUserShiftTimePreferences(
    state: ShiftTimePreferenceSettingStateModel,
  ): ShiftTimePreferenceSetting[] {
    return state.items;
  }

  @Action(AddShiftTimePreferenceSettingAction)
  add(
    {
      getState,
      patchState,
    }: StateContext<ShiftTimePreferenceSettingStateModel>,
    { payload }: AddShiftTimePreferenceSettingAction,
  ) {
    const currentSettings = getState().items;

    const newData: ShiftTimePreferenceSetting = {
      id: uuidv4(),
      startTime: payload.data.startTime,
      endTime: payload.data.endTime,
      name: payload.data.name,
    };

    patchState({
      items: [...getState().items, newData],
    });

    const request = this.shiftTimeGatewayService.create(newData);

    request.catch(() => {
      patchState({
        items: currentSettings,
      });
    });
    return request;
  }

  @Action(DeleteShiftTimePreferenceSettingAction)
  delete(
    {
      getState,
      patchState,
    }: StateContext<ShiftTimePreferenceSettingStateModel>,
    { payload }: DeleteShiftTimePreferenceSettingAction,
  ) {
    const currentSettings = getState().items;
    patchState({
      items: [
        ...currentSettings.filter((setting) => setting.id !== payload.id),
      ],
    });

    const request = this.shiftTimeGatewayService.remove(payload.id);

    request.catch(() => {
      patchState({
        items: currentSettings,
      });
    });
    return request;
  }

  @Action(FetchShiftTimePreferenceSettingAction)
  async fetch({
    patchState,
  }: StateContext<ShiftTimePreferenceSettingStateModel>) {
    patchState({
      isLoading: true,
      isLoadingSuccess: false,
      isLoadingError: false,
    });

    try {
      const data = await firstValueFrom(this.shiftTimeGatewayService.getAll());
      patchState({
        isLoading: false,
        isLoadingSuccess: true,
        isLoadingError: false,
        items: data,
      });
    } catch {
      patchState({
        isLoading: false,
        isLoadingSuccess: false,
        isLoadingError: true,
      });
    }
  }
}
