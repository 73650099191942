import { Injectable } from '@angular/core';
import {
  Absence,
  AccurateDateIntervalWithShift,
  Activity,
  GroupedUserData,
  GroupedUserDataV2,
  ItemViewModel,
  Stay,
} from '@wilson/interfaces';
import { isDebugEnabled } from '@wilson/non-domain-specific/logger/logger';
import {
  getMostLogicalEndDatetime,
  getMostLogicalStartDatetime,
} from '@wilson/non-domain-specific/overlap-helpers';

@Injectable({
  providedIn: 'root',
})
export class ChangedKeyManagerService {
  computeChangedKeyForActivities<T extends Activity[]>(
    activityWithOverlapLevel: T,
  ) {
    return activityWithOverlapLevel.reduce((currentKey, activity) => {
      return currentKey + this.computeChangedKeyForActivity(activity);
    }, '');
  }

  computeChangedKeyForGroupedUserData(groupedUserData: GroupedUserData) {
    const shiftViewsKey = groupedUserData.itemViews.reduce(
      (currentKey, shiftView) => {
        return currentKey + this.computeChangedKeyForShiftViewModel(shiftView);
      },
      '',
    );
    const absencesKey = groupedUserData.absences.reduce(
      (currentKey, absence) => {
        return currentKey + this.computeChangedKeyForAbsence(absence);
      },
      '',
    );

    return shiftViewsKey + absencesKey;
  }

  computeChangedKeyForGroupedUserDataV2(groupedUserData: GroupedUserDataV2) {
    const shiftViewsKey = groupedUserData.shifts.reduce(
      (currentKey, shiftView) => {
        return (
          currentKey + this.computeChangedKeyForShiftViewModelV2(shiftView)
        );
      },
      '',
    );

    const absencesKey = groupedUserData.absences.reduce(
      (currentKey, absence) => {
        return currentKey + this.computeChangedKeyForAbsence(absence.absence);
      },
      '',
    );

    const staysKey = groupedUserData.stays.reduce((currentKey, stay) => {
      return currentKey + this.computeChangedKeyForStay(stay.stay);
    }, '');

    return shiftViewsKey + absencesKey + staysKey;
  }

  computeChangedKeyForStay(stay: Stay) {
    return stay.id + stay.startDatetime + stay.endDatetime;
  }

  computeChangedKeyForAbsence(absence: Absence) {
    return absence.id + absence.absentFrom + absence.absentTo;
  }

  computeChangedKeyForShiftViewModel(shiftView: ItemViewModel) {
    const key =
      shiftView.shift.id +
      shiftView.shift.activities.reduce((currentKey, activity) => {
        return currentKey + this.computeChangedKeyForActivity(activity);
      }, '');

    return key;
  }

  computeChangedKeyForShiftViewModelV2(
    shiftView: AccurateDateIntervalWithShift,
  ) {
    const key =
      shiftView.shift.id +
      shiftView.accurateStartDateTime.toISOString() +
      shiftView.accurateEndDateTime.toISOString();

    return key;
  }

  computeChangedKeyForActivity<T extends Activity>(activity: T) {
    return (
      activity.id +
      getMostLogicalStartDatetime(activity, isDebugEnabled()).date +
      getMostLogicalEndDatetime(activity, isDebugEnabled()).date
    );
  }
}
