import { StateOperator } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import {
  ResolvedActivityWithReports,
  ResolvedActivityReport,
  ActivityComment,
} from '@wilson/interfaces';
import {
  ResolvedShiftsStateModel,
  ResolvedShiftWithSyncStatus,
} from '../state/resolved-shifts.state';

// eslint-disable-next-line
export function sortBy<T>(selector: (value?: T) => any): StateOperator<T[]> {
  return (existing: Readonly<T[]>): T[] => {
    if (Array.isArray(existing)) {
      return existing.slice().sort((a, b) => {
        if (selector(a) > selector(b)) return 1;
        else if (selector(a) < selector(b)) return -1;
        return 0;
      });
    }
    return existing as T[];
  };
}

export function sortReportsForActivity(
  shiftId: string,
  activityId: string,
): StateOperator<ResolvedShiftsStateModel> {
  return patch<ResolvedShiftsStateModel>({
    shifts: updateItem<ResolvedShiftWithSyncStatus>(
      (s) => s?.id === shiftId,
      patch<ResolvedShiftWithSyncStatus>({
        activities: updateItem<ResolvedActivityWithReports>(
          (a) => a?.id === activityId,
          patch<ResolvedActivityWithReports>({
            activityReports: sortBy<ResolvedActivityReport>(
              (r) => r?.createdAt,
            ),
          }),
        ),
      }),
    ),
  });
}

export function sortCommentsForActivity(
  shiftId: string,
  activityId: string,
): StateOperator<ResolvedShiftsStateModel> {
  return patch<ResolvedShiftsStateModel>({
    shifts: updateItem<ResolvedShiftWithSyncStatus>(
      (s) => s?.id === shiftId,
      patch<ResolvedShiftWithSyncStatus>({
        activities: updateItem<ResolvedActivityWithReports>(
          (a) => a?.id === activityId,
          patch<ResolvedActivityWithReports>({
            activityComments: sortBy<ActivityComment>((c) => c?.createdAt),
          }),
        ),
      }),
    ),
  });
}

export function sortActivitiesForShift(
  shiftId: string,
): StateOperator<ResolvedShiftsStateModel> {
  return patch<ResolvedShiftsStateModel>({
    shifts: updateItem<ResolvedShiftWithSyncStatus>(
      (s) => s?.id === shiftId,
      patch<ResolvedShiftWithSyncStatus>({
        activities: sortBy<ResolvedActivityWithReports>(
          (a) => a?.startDatetime,
        ),
      }),
    ),
  });
}
