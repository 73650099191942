import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { faExternalLink } from '@fortawesome/pro-light-svg-icons';
import { User } from '@wilson/interfaces';

@Component({
  selector: 'wilson-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDetailsComponent {
  public faLinkIcon = faExternalLink;

  @Input() user: User | null = null;
  @Input() hideProfileIcon = false;

  openUserDetail() {
    if (!this.user?.id) {
      return;
    }
    window.open('/employees/' + this.user.id, '_blank');
  }
}
