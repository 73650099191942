import { Component } from '@angular/core';
import { HostBinding } from '@angular/core';
import { Input } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';
export type LoadingStateTypes = 'default' | 'compact';

@Component({
  selector: 'wilson-loading-state',
  templateUrl: './loading-state.component.html',
  styleUrls: ['./loading-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingStateComponent {
  @HostBinding('class') get cssClass() {
    return `type-${this.type} ${this.behavior || ''}`;
  }

  /** whether the state component acts as modal (covering the view port) or normal component */
  @Input() behavior: 'modal' | undefined;

  /** the "look" of the loading state */
  @Input() type: LoadingStateTypes = 'default';

  @Input() loadingMessageTranslationKey = 'page.services.data_loading';
}
