import { Activity } from '@wilson/interfaces';
import { v4 as uuidv4 } from 'uuid';

export const activityFactory = (
  activityArgs: Omit<Activity, 'id' | 'createdAt' | 'updatedAt'>,
): Activity => ({
  ...activityArgs,
  id: uuidv4(),
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
});
