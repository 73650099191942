import { Base } from '@wilson/base';
import { AbsenceWithCategory } from '../absences/absence';
import { Shift } from '../shifts/shift';
import { PayrollCategory } from './payrollCategory';

export interface PayrollTransaction {
  id: string;
  transactionValue: number;
  transactionStatus: string;
  transactionDate: string;
  payrollCategoryId: string;
  payrollCategory?: PayrollCategory;
  userId: string;
  shift?: Shift;
  shiftId: string | null;
  absence?: AbsenceWithCategory;
  absenceId: string | null;
  createdByUserId: string | null;
}

export interface OvertimeCompensationTransaction {
  transactionValue: number;
  transactionDate: string;
  userId: string;
}

export interface PayrollProvider extends Base {
  name: string;
}

export enum PayrollCategoryName {
  ExtraHolidayHours = 'extra-holiday-hours',
  Holiday = 'holiday',
  HolidayBonus = 'holiday-bonus',
  IsWorkTime = 'is-work-time',
  TravelBonus = 'travel-bonus',
  StandbyBonus = 'standby-bonus',
  Etv1Bonus = 'etv1-bonus',
  SpontaneousShiftBonus = 'spontaneous-shift-bonus',
  OvernightStayBonus = 'overnight-stay-bonus',
  NightHours = 'night-hours',
  OvernightShiftBonus = 'overnight-shift-bonus',
  OvertimeHoursWeekday = 'overtime-hours-weekday',
  OvertimeRate = 'overtime-rate',
  TotalOvertimeRate = 'total-overtime-rate',
  OvertimeHoursHoliday = 'overtime-hours-holiday',
  OvertimeHoursNight = 'overtime-hours-night',
  OvertimeHoursSunday = 'overtime-hours-sunday',
  OvertimeCompensation = 'overtime-compensation',
  SaturdayHours = 'saturday-hours',
  SplitShiftBonus = 'split-shift-bonus',
  SundayBonus = 'sunday-bonus',
  SickDay = 'sick-day',
  PlannedWorktime = 'planned-worktime',
  PaidVacationDay = 'paid-vacation-day',
}

export enum PayrollProviderName {
  Datev = 'datev',
}

export enum PayrollProviderDisplayName {
  Datev = 'DATEV Lodas',
}

export enum PayrollProviderId {
  Csv = '556fb935-91a7-45cd-a4cb-6103f8cc69f8',
  Datev = 'f547b02b-858b-4016-872d-9687dd5b2a36',
  SapHR = 'd93535cd-4c43-46ee-9a6f-75ba99150f2d',
  Sage = '70535659-2915-4e0f-b990-f43088e64e4c',
}

export enum PayrollTransactionStatus {
  Final = 'final',
  Temporary = 'temporary',
}

export enum ShiftPayrollAcceptanceStatus {
  PartlySubmittedToPayroll = 'partly-submitted-to-payroll',
  ReportingMissing = 'reporting-missing',
  SubmittedToPayroll = 'submitted-to-payroll',
  WorkTimeCheckComplete = 'work-time-check-complete',
  WorkTimeCheckOpen = 'work-time-check-open',
}

export enum UnitName {
  Eur = 'eur',
  Hour = 'hour',
  Minute = 'minute',
  Kilometer = 'kilometer',
  Week = 'week',
  Month = 'month',
  Day = 'day',
}

export enum PayrollRecalculationType {
  Shift = 'shift',
  Absence = 'absence',
  PlannedWorkTime = 'planned-work-time',
}

export interface PayrollRecalculationRequest {
  userIds: string[];
  from: Date;
  to: Date;
  recalculateFor: PayrollRecalculationType[];
}
