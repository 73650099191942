import { Pipe, PipeTransform } from '@angular/core';
import { FilesService } from '@wilson/files';
import { User } from '@wilson/interfaces';
import { Observable, map, of } from 'rxjs';

@Pipe({
  name: 'getUserProfileImageUrl',
  standalone: true,
})
export class GetUserProfileImageUrlPipe implements PipeTransform {
  private readonly fallbackProfilePic = 'assets/img/profile/user-icon.svg';

  constructor(private readonly filesService: FilesService) {}

  transform(user: User): Observable<string | undefined> {
    if (!user) {
      return of(undefined);
    }

    return user?.profileImageUrl
      ? this.filesService
          .getFileAccess(user.profileImageUrl)
          .pipe(map((fileAccess) => fileAccess?.accessUrl))
      : of(this.fallbackProfilePic);
  }
}
