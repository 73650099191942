import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViaPointsToolTipComponent } from './via-points-tool-tip.component';
import { TranslateModule } from '@ngx-translate/core';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { ViaPointsListModule } from '../via-points-list/via-points-list.module';

@NgModule({
  declarations: [ViaPointsToolTipComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    NzToolTipModule,
    NzBadgeModule,
    ViaPointsListModule,
  ],
  exports: [ViaPointsToolTipComponent],
})
export class ViaPointsToolTipModule {}
