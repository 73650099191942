import { Inject, Injectable, InjectionToken, NgZone } from '@angular/core';
import { FeatureFlagJsonPipe } from '@wilson/feature-flags';
import { HolidayRegionCode } from '@wilson/interfaces';
import { DateTimeFormat } from '@wilson/utils';
import { format, isDate, isSameDay } from 'date-fns';
import Holidays, { HolidaysTypes } from 'date-holidays';
import { NzCascaderOption } from 'ng-zorro-antd/cascader';
import { Subject, firstValueFrom } from 'rxjs';

export const DATE_HOLIDAYS = new InjectionToken<Holidays>(
  'Wrapper to turn date holidays library into an injectable service',
  {
    providedIn: 'root',
    factory: () => new Holidays(),
  },
);

@Injectable({
  providedIn: 'root',
})
export class HolidaysService {
  private _countryStateRegionOptionsSubject = new Subject<NzCascaderOption[]>();
  private readonly LOCAL_STORAGE_CACHE_KEY = 'PUBLIC_HOLIDAY';
  private cache: Record<string, HolidaysTypes.Holiday['name'] | undefined> = {};

  constructor(
    @Inject(DATE_HOLIDAYS) private holidays: Holidays,
    private readonly featureFlagJsonPipe: FeatureFlagJsonPipe,
    private readonly zone: NgZone,
  ) {
    try {
      this.cache = JSON.parse(
        localStorage.getItem(this.LOCAL_STORAGE_CACHE_KEY) || '',
      );
    } catch (e) {
      console.warn('Failed to load public holiday cache', e);
    }
  }

  get countryStateRegionOptions$() {
    this.constructHolidayOptions();
    return this._countryStateRegionOptionsSubject.asObservable();
  }

  getPublicHolidayName({
    date,
    holidayRegions,
  }: {
    holidayRegions: HolidayRegionCode | undefined | null;
    date: Date | undefined;
  }): HolidaysTypes.Holiday['name'] | undefined {
    if (holidayRegions && holidayRegions?.length > 0 && isDate(date)) {
      const cacheKey = this.getCacheKey({ date: date as Date, holidayRegions });
      const hasCacheValue = Object.keys(this.cache).includes(cacheKey);

      if (hasCacheValue) {
        return this.cache[cacheKey];
      } else {
        this.holidays.init(
          holidayRegions[0],
          holidayRegions[1],
          holidayRegions[2],
        );
        const availableHolidays = this.holidays
          .getHolidays(date)
          ?.filter((holiday) => holiday.type === 'public');

        const holidayFound = availableHolidays?.find(
          (availableHoliday: HolidaysTypes.Holiday) => {
            return isSameDay(
              new Date(availableHoliday.date),
              new Date(date as Date),
            );
          },
        );
        this.zone.runOutsideAngular(() => {
          setTimeout(() => {
            this.cache[cacheKey] = holidayFound?.name || '';

            localStorage.setItem(
              this.LOCAL_STORAGE_CACHE_KEY,
              JSON.stringify(this.cache),
            );
          });
        });

        return holidayFound?.name;
      }
    } else {
      return undefined;
    }
  }

  decodeCountryStateRegion([
    countryCode,
    stateCode,
    regionCode,
  ]: HolidayRegionCode) {
    let text = '';

    if (countryCode) {
      text += this.holidays.getCountries()[countryCode];
    }

    if (countryCode && stateCode) {
      text += `, ${this.holidays.getStates(countryCode)?.[stateCode]}`;
    }

    if (countryCode && regionCode && stateCode) {
      text += `, ${
        this.holidays.getRegions(countryCode, stateCode)?.[regionCode]
      }`;
    }

    return text;
  }

  private getCacheKey({
    date,
    holidayRegions,
  }: {
    holidayRegions: HolidayRegionCode;
    date: Date;
  }) {
    const regionKey =
      holidayRegions[0] ||
      '' + holidayRegions[1] ||
      '' + holidayRegions[2] ||
      '';
    return format(date, DateTimeFormat.DatabaseDateFormat) + regionKey;
  }

  private async constructHolidayOptions(): Promise<void> {
    const countries = this.holidays.getCountries();
    const relevantCountries: string[] = (await firstValueFrom(
      this.featureFlagJsonPipe.transform('holiday-service-country-options'),
    )) as unknown as string[];
    const filteredCountries = relevantCountries?.length
      ? Object.entries(countries).filter(([countryCode]) =>
          relevantCountries.includes(countryCode),
        )
      : Object.entries(countries);
    const mappedCountryOptions = filteredCountries.map(([countryCode]) =>
      this.contructCountriesOption(countryCode, countries),
    );
    this._countryStateRegionOptionsSubject.next(mappedCountryOptions);
  }

  private contructCountriesOption(
    countryCode: string,
    countries: Record<string, string>,
  ): NzCascaderOption {
    const result: NzCascaderOption = {
      value: countryCode,
      label: countries[countryCode],
    };

    const states = this.constructStateOptions(
      countryCode,
      this.holidays.getStates(countryCode),
    );

    if (states) {
      result.children = states;
    } else {
      result.isLeaf = true;
    }

    return result;
  }

  private constructStateOptions(
    countryCode: string,
    states: Record<string, string> | undefined | null,
  ): NzCascaderOption[] | undefined {
    return states
      ? Object.entries(states).map(([stateCode, stateLabel]) => {
          const stateOption: NzCascaderOption = {
            value: stateCode,
            label: stateLabel,
          };

          const regions = this.constructRegionOptions(
            this.holidays.getRegions(countryCode, stateCode),
          );

          if (regions) {
            stateOption.children = regions;
          } else {
            stateOption.isLeaf = true;
          }

          return stateOption;
        })
      : undefined;
  }

  private constructRegionOptions(
    regions: Record<string, string> | undefined | null,
  ): NzCascaderOption[] | undefined {
    return regions
      ? Object.entries(regions).map(([regionCode, regionLabel]) => ({
          value: regionCode,
          label: regionLabel,
          isLeaf: true,
        }))
      : undefined;
  }
}
