import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'minutesToHHmmDuration',
  standalone: true,
})
export class MinutesToHHmmDurationPipe implements PipeTransform {
  constructor(private readonly translate: TranslateService) {}
  transform(minutes: number | null | undefined): string {
    const hoursTranslation = this.translate.instant('general.time.hours');
    const minutesTranslation = this.translate.instant('general.time.minutes');
    if (!minutes) return `00${hoursTranslation} 00${minutesTranslation}`;
    const hours = Math.abs(
      minutes < 0 ? Math.ceil(minutes / 60) : Math.floor(minutes / 60),
    );
    const remainingMinutes = Math.floor(Math.abs(minutes % 60));

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(remainingMinutes).padStart(2, '0');

    return `${
      minutes < 0 ? '-' : ''
    }${formattedHours}${hoursTranslation} ${formattedMinutes}${minutesTranslation}`;
  }
}
