import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { ReplaySubject, Subscription, combineLatest } from 'rxjs';

export enum ThemeMode {
  Dark = 'dark',
  Light = 'light',
  System = 'system',
}

const overrideColorSchemeStorageKey = 'OverrideColorScheme';

@Injectable({
  providedIn: 'root',
})
export class DarkModeService {
  private _themeMode$ = new ReplaySubject<ThemeMode>(1);
  public get themeMode$() {
    return this._themeMode$.asObservable();
  }
  private _isDarkTheme$ = new ReplaySubject<boolean>(1);
  public get isDarkTheme$() {
    return this._isDarkTheme$.asObservable();
  }
  private _isLightTheme$ = new ReplaySubject<boolean>(1);
  public get isLightTheme() {
    return this._isLightTheme$.asObservable();
  }

  private reactToColorSchemeChangesSub!: Subscription;
  private preferredColorScheme$ = new ReplaySubject<ThemeMode>(1);
  private _overrideColorScheme$ = new ReplaySubject<ThemeMode>(1);
  public get overrideColorScheme() {
    return this._overrideColorScheme$.asObservable();
  }

  private unlistenPreferredColorSchemeChanges!: () => void;

  private readonly preferredMediaQuery: string = '(prefers-color-scheme: dark)';
  private readonly darkModeClass: string = 'dark';

  private renderer2: Renderer2;

  constructor(
    private rendererFactory2: RendererFactory2,
    private readonly storage: Storage,
  ) {
    this.renderer2 = rendererFactory2.createRenderer(null, null);
  }

  public async initTheming() {
    this.detectPreferredColorSchemeChanges();
    this.reactToColorSchemeChanges();

    const preferred = window.matchMedia(this.preferredMediaQuery);
    const initialTheme = preferred.matches ? ThemeMode.Dark : ThemeMode.Light;

    this.preferredColorScheme$.next(initialTheme);

    const storedOverride: ThemeMode = await this.storage.get(
      overrideColorSchemeStorageKey,
    );

    if (
      storedOverride &&
      (storedOverride === ThemeMode.Light || storedOverride === ThemeMode.Dark)
    ) {
      this._overrideColorScheme$.next(storedOverride);
    } else {
      this._overrideColorScheme$.next(ThemeMode.System);
    }
  }

  public destroyTheming() {
    this.unlistenPreferredColorSchemeChanges();
    this._themeMode$.complete();
    this._isDarkTheme$.complete();
    this._isLightTheme$.complete();
    this.preferredColorScheme$.complete();
    this._overrideColorScheme$.complete();
    this.reactToColorSchemeChangesSub?.unsubscribe();
  }

  public setTheme(newThemeMode: ThemeMode) {
    this._overrideColorScheme$.next(newThemeMode);
    this.storage.set(overrideColorSchemeStorageKey, newThemeMode);
  }

  private detectPreferredColorSchemeChanges() {
    this.unlistenPreferredColorSchemeChanges = this.renderer2.listen(
      window.matchMedia('(prefers-color-scheme: dark)'),
      'change',
      (event) => {
        this.preferredColorScheme$.next(
          event.matches ? ThemeMode.Dark : ThemeMode.Light,
        );
      },
    );
  }

  private reactToColorSchemeChanges() {
    this.reactToColorSchemeChangesSub = combineLatest([
      this.preferredColorScheme$,
      this._overrideColorScheme$,
    ]).subscribe(([preferredColorScheme, overrideColorScheme]) => {
      let newThemeMode: ThemeMode = ThemeMode.Light;
      if (overrideColorScheme && overrideColorScheme !== ThemeMode.System) {
        newThemeMode = overrideColorScheme;
      } else if (preferredColorScheme) {
        newThemeMode = preferredColorScheme;
      }

      this.toggleDarkModeClass(newThemeMode);
      this._themeMode$.next(newThemeMode);
      this._isDarkTheme$.next(newThemeMode === ThemeMode.Dark ? true : false);
      this._isLightTheme$.next(newThemeMode === ThemeMode.Light ? true : false);
    });
  }

  private toggleDarkModeClass(theme: ThemeMode) {
    document.body.classList.toggle(
      this.darkModeClass,
      theme === ThemeMode.Dark ? true : false,
    );
  }
}
