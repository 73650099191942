import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BackendService, TypeOrmFindManyOptions } from '@wilson/base';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { RequestForProposal } from '@wilson/interfaces';
import { stringify } from 'qs';
import { firstValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RequestForProposalGateway extends BackendService<RequestForProposal> {
  protected readonly path = 'requests-for-proposals';

  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
  ) {
    super();
  }

  public getRequestForProposalById(
    id: string,
    options?: TypeOrmFindManyOptions,
  ): Observable<RequestForProposal> {
    const params = stringify(options);
    return this.http.get<RequestForProposal>(
      `${this.config.host}/${this.path}/${id}?${params}`,
    );
  }

  public updateRequestForProposal(
    id: string,
    payload: Partial<RequestForProposal>,
  ): Promise<Partial<RequestForProposal>> {
    return firstValueFrom(
      this.http.patch<Partial<RequestForProposal>>(
        `${this.config.host}/${this.path}/${id}`,
        payload,
      ),
    );
  }

  public getRequestsForProposals(
    options?: TypeOrmFindManyOptions,
  ): Observable<RequestForProposal[]> {
    const params = stringify(options);
    return this.http.get<RequestForProposal[]>(
      `${this.config.host}/${this.path}?${params}`,
    );
  }

  public getRequestsForProposalsInRange<T extends RequestForProposal>(
    options: Pick<TypeOrmFindManyOptions, 'relations' | 'where'>,
  ) {
    const params = stringify(options);
    return this.http.get<T[]>(
      `${this.config.host}/${this.path}/jobs/activities/time-range?${params}`,
    );
  }

  public getRequestsForProposalsWithTemplatesInRange(
    options: Pick<TypeOrmFindManyOptions, 'relations' | 'where'>,
  ) {
    const params = stringify(options);
    return this.http.get<RequestForProposal[]>(
      `${this.config.host}/${this.path}/jobs/activity-templates/time-range?${params}`,
    );
  }
}
