<p>{{ 'activity.edit.description' | translate }}</p>

<div class="activity-editor">
  <nz-form-item class="activity-category-editor">
    <nz-form-control [nzExtra]="'page.shifts.activity' | translate">
      <wilson-activity-edit-category
        (activityCategoryChange)="activity.activityCategoryId = $event?.id"
        [(activityCategory)]="activity.activityCategory"
        [disabled]="isSkippedByUser || activityCategoryEditDisabled"
      ></wilson-activity-edit-category>
    </nz-form-control>
  </nz-form-item>

  <ng-container *ngIf="isSkippedByUser; else skipActivityButton">
    <button
      (click)="unskipActivity()"
      class="unskip-activity-button"
      nz-button
      nzType="text"
    >
      <i class="me-2 far fa-smile"></i>
      {{ 'activity.unskip' | translate }}
    </button>
  </ng-container>

  <ng-template #skipActivityButton>
    <button
      (click)="skipActivity()"
      [disabled]="skipDisabled"
      nz-button
      nzDanger
      nzType="text"
    >
      <i class="me-2 far fa-frown"></i>
      {{ 'activity.skip' | translate }}
    </button>
  </ng-template>
</div>
<ng-container
  *ngIf="{
    sectorName: sectorName$ | async
  } as data"
>
  <div class="activity-category-editor">
    <nz-form-item class="activity-report-location-editor">
      <nz-form-control [nzExtra]="'page.activity.activity_name' | translate">
        <input
          nz-input
          class="form-contraol"
          type="text"
          [placeholder]="'page.activity.activity_name' | translate"
          [(ngModel)]="activity.name"
        />
      </nz-form-control>
    </nz-form-item>
  </div>
  <div class="activity-report-editor">
    <nz-form-item class="activity-report-location-editor">
      <nz-form-control
        [nzExtra]="'activity.reported.location_start' | translate"
      >
        <wilson-location-selects
          [sector]="data.sectorName"
          [disabled]="isSkippedByUser"
          [selectedLocation]="startActivityReport.location"
          (locationChange)="updateReportedActivityStartLocation($event)"
        ></wilson-location-selects>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control [nzExtra]="'activity.reported.start' | translate">
        <wilson-activity-edit-datetime
          [(datetime)]="startActivityReport.dateTime"
          [disabled]="isSkippedByUser"
          [invalid]="invalidTimes"
        ></wilson-activity-edit-datetime>
      </nz-form-control>
    </nz-form-item>
  </div>

  <div class="activity-report-editor">
    <nz-form-item class="activity-report-location-editor">
      <nz-form-control [nzExtra]="'activity.reported.location_end' | translate">
        <wilson-location-selects
          [sector]="data.sectorName"
          [disabled]="isSkippedByUser"
          [selectedLocation]="endActivityReport.location"
          (locationChange)="updateReportedActivityEndLocation($event)"
        ></wilson-location-selects>
      </nz-form-control>
    </nz-form-item>
    <nz-form-item>
      <nz-form-control [nzExtra]="'activity.reported.end' | translate">
        <wilson-activity-edit-datetime
          [(datetime)]="endActivityReport.dateTime"
          [disabled]="isSkippedByUser"
          [invalid]="invalidTimes"
        ></wilson-activity-edit-datetime>
      </nz-form-control>
    </nz-form-item>
  </div>
</ng-container>
