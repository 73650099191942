import { Base } from '@wilson/base';
import { VacationStatus } from '../absences/vacation-status';
import { Address } from '../addresses/address';
import { UserRole } from '../authorization/role';
import { Contract } from '../contracts/contract';
import { GeoLocation } from '../locations/geoLocation';
import { OrganizationalUnit } from '../organizational-units/organizationalUnit';
import { ShiftPayrollAcceptanceStatus } from '../payroll/payroll-enums';
import { UserVacation } from './user-vacation.interface';
import { Label } from '../labels/labels';

export const NO_USER_CONSTANT = 'no-user';

export interface User extends Base {
  personnelId: string | null;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phone: string | null;
  birthDate: string | null;
  profileImageUrl: string | null;
  approvedToWork: boolean;
  isOnline: boolean;
  isConfirmed: boolean;
  canAccessWilson: boolean;
  lastLogin: Date | null;
  organizationalUnit?: OrganizationalUnit;
  organizationalUnitId: string;
  address?: Address;
  addressId: string | null;
  contract?: Contract;
  contractId: string;
  roleIds?: string[];
  userRoles?: UserRole[];
  autoSkipActivity: boolean;
  about: string | null;
  comment: string | null;
  userSetting?: UserSetting;
  accessibleOrganizationalUnits?: OrganizationalUnit[]; // TODO - Currently complete object due to cascade true in BE
  homeLocation?: GeoLocation | null;
  homeLocationId?: string | null;
  publicHolidaysRegion?: string[];
  authOption: UserAuthOption;
  professionIds: string[];
  labels?: Label[];
}

export interface ResolvedUser extends User {
  organizationalUnit: OrganizationalUnit;
  address: Address;
  contract: Contract;
}

export interface UserWithShiftAcceptanceStatus extends User {
  numPublishedShifts: number;
  numAcceptedShifts: number;
  numPaidShifts: number;
  acceptanceStatus: ShiftPayrollAcceptanceStatus;
  timeSheetFilePath?: string;
  userPayrollMonthlyClosures?: UserPayrollMonthlyClosure[];
}

export interface UserWithUserWorkingTimeModel extends User {
  isAdded: boolean;
  activeFrom: Date | null;
}

export interface UserWithPayrollTimeSheet {
  startDate: string;
  endDate: string;
  userIds: string[];
}

export interface UserWithVacationStatus extends User {
  vacationStatus: UserVacation | VacationStatus;
}

export interface ChatUsers extends User {
  isChecked: boolean;
}
export interface UserSetting extends Base {
  canDoSelfDispatching: boolean;
  canViewServiceActivitiesOnMobile: boolean;
  userId: string;
}

export enum UserAuthOption {
  Wilson = 'wilson',
  Azure = 'azure',
}

export interface UserPayrollMonthlyClosure {
  yearAndMonth: string;
  status: MonthlyClosureStatusEnum;
  userId: string;
}

export enum MonthlyClosureStatusEnum {
  InProgress = 'in-progress',
  SuccessfullyClosed = 'successfully-closed',
  Failed = 'failed',
}
