import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import {
  Action,
  NgxsAfterBootstrap,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import {
  AbsenceCategory,
  ActivityCategory,
  OperativeReportsCategory,
  QualificationCategory,
  QualificationSetting,
} from '@wilson/interfaces';
import { MasterData, MasterDataService } from '@wilson/master-data/services';
import {
  BehaviorSubject,
  catchError,
  finalize,
  Observable,
  of,
  tap,
} from 'rxjs';
import { InitializeMasterDataState } from './master-data.action';

export const MASTER_DATA_STATE_NAME = 'MasterDataState';

@State<MasterData>({
  name: MASTER_DATA_STATE_NAME,
  defaults: {
    activityCategories: [],
    absenceCategories: [],
    qualificationCategories: [],
    locationCategories: [],
    operativeReportCategories: [],
    qualificationCategorySettings: [],
  },
})
@Injectable()
export class MasterDataState implements NgxsAfterBootstrap {
  public syncingMasterData$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly masterDataService: MasterDataService,
    private readonly storage: Storage,
  ) {}

  @Selector()
  static activityCategories(state: MasterData): ActivityCategory[] {
    return state.activityCategories;
  }

  @Selector()
  static absenceCategories(state: MasterData): AbsenceCategory[] {
    return state.absenceCategories;
  }

  @Selector()
  static qualificationCategories(state: MasterData): QualificationCategory[] {
    return state.qualificationCategories;
  }

  @Selector()
  static qualificationCategorySettings(
    state: MasterData,
  ): QualificationSetting[] {
    return state.qualificationCategorySettings;
  }

  @Selector()
  static operativeReportCategories(
    state: MasterData,
  ): OperativeReportsCategory[] {
    return state.operativeReportCategories;
  }

  async ngxsAfterBootstrap(ctx: StateContext<MasterData>): Promise<void> {
    const masterData = await this.storage.get(MASTER_DATA_STATE_NAME);
    if (masterData) ctx.patchState(masterData);
  }

  @Action(InitializeMasterDataState)
  initializeMobileMasterDataState(
    ctx: StateContext<MasterData>,
  ): Observable<MasterData | null> {
    this.syncingMasterData$.next(true);
    return this.masterDataService.getMasterData().pipe(
      tap((masterData) => {
        ctx.patchState(masterData);
        this.storage.set(MASTER_DATA_STATE_NAME, masterData);
      }),
      catchError(() => {
        return of(null);
      }),
      finalize(() => {
        this.syncingMasterData$.next(false);
      }),
    );
  }
}
