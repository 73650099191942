<div class="shift-logic-details">
  <h6 class="mb-3 title">
    {{ 'page.shifts.content_ok' | translate }}
  </h6>
  <ng-container
    *ngIf="!!shiftLogicValidations?.length; else emptyStateNoWorkingTime"
  >
    <div
      class="d-flex align-items-center item"
      *ngFor="let item of shiftLogicValidations"
    >
      <fa-icon
        *ngIf="item.valid"
        class="icon validIcon"
        [icon]="faValidIcon"
      ></fa-icon>
      <fa-icon
        *ngIf="!item.valid"
        class="icon inValidIcon"
        [icon]="faInValidIcon"
      ></fa-icon>
      <p class="item-text m-0">
        {{ 'enum.shift_validation.' + item.checkName | translate }}
      </p>
    </div>
  </ng-container>

  <ng-template #emptyStateNoWorkingTime>
    {{ 'tooltip.subheader.no_working_time_regulations_deposited' | translate }}
  </ng-template>
</div>
