import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VersionUpdateComponent } from './components/version-update/version-update.component';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [VersionUpdateComponent],
  imports: [CommonModule, IonicModule, TranslateModule.forChild()],
})
export class VersionUpdateModule {}
