import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import {
  ActivityQualifications,
  QualifiablesWithCategory,
  QualificationCategoryName,
} from '@wilson/interfaces';
import { TransformMasterDataTranslationService } from '@wilson/non-domain-specific/master-data-translate';
import { firstValueFrom, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActivityQualificationsService {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
    private readonly transformMasterDataTranslationService: TransformMasterDataTranslationService,
  ) {}

  getActivityQualifications(
    activityId: string,
  ): Observable<ActivityQualifications[]> {
    return this.httpClient.post<ActivityQualifications[]>(
      `${this.config.host}/activity-qualifications/resolved`,
      { activityId },
    );
  }

  getAllSelectableQualifications(): Observable<QualifiablesWithCategory[]> {
    return this.httpClient
      .post<QualifiablesWithCategory[]>(
        `${this.config.host}/qualification-categories/qualifications`,
        {},
      )
      .pipe(
        map((qualifiablesWithCategory) => {
          return qualifiablesWithCategory.map((qualifiableWithCategory) => {
            qualifiableWithCategory.qualificationCategory =
              this.transformMasterDataTranslationService.transform([
                qualifiableWithCategory.qualificationCategory,
              ])[0];
            return qualifiableWithCategory;
          });
        }),
      );
  }

  updateActivityQualifications(
    activityId: string,
    activityQualifications: ActivityQualifications[],
  ): Promise<ActivityQualifications[]> {
    return firstValueFrom(
      this.httpClient.patch<ActivityQualifications[]>(
        `${this.config.host}/activity-qualifications/${activityId}`,
        { activityQualifications },
      ),
    );
  }

  createActivityQualificationByCategory(
    activityQualifications: ActivityQualifications[],
    category: QualificationCategoryName,
  ): Promise<ActivityQualifications[]> {
    const qualificationCategory =
      category === QualificationCategoryName.More
        ? 'other-qualification'
        : category;

    return firstValueFrom(
      this.httpClient.post<ActivityQualifications[]>(
        `${this.config.host}/activity-${qualificationCategory}-qualifications`,
        {
          items: activityQualifications,
        },
      ),
    );
  }

  deleteActivityQualificationByCategory(
    activityQualificationId: string,
    category: QualificationCategoryName,
  ): Promise<ActivityQualifications[]> {
    const qualificationCategory =
      category === QualificationCategoryName.More
        ? 'other-qualification'
        : category;

    return firstValueFrom(
      this.httpClient.delete<ActivityQualifications[]>(
        `${this.config.host}/activity-${qualificationCategory}-qualifications/${activityQualificationId}`,
      ),
    );
  }
}
