import { BankDetails } from '@wilson/interfaces';

export class FetchBankDetails {
  static readonly type = '[BankDetails] Fetch Bank Details';
}

export class UpdateBankDetails {
  static readonly type = '[BankDetails] Update Bank Details';
  constructor(public bankDetails: BankDetails) {}
}

export class CreateBankDetails {
  static readonly type = '[BankDetails] Create Bank Details';
  constructor(public bankDetails: BankDetails) {}
}
export class DeleteBankDetail {
  static readonly type = '[BankDetails] Delete Bank Details';
  constructor(public bankDetailId: string) {}
}
