import { Activity, OperationStatus } from '@wilson/interfaces';

export function areAllActivitiesFinished(
  activities: Pick<Activity, 'operationalStatus'>[],
): boolean {
  let isCompleted = true;
  if (!activities?.length) return false;
  for (const activity of activities) {
    if (
      activity.operationalStatus !== OperationStatus.Completed &&
      activity.operationalStatus !== OperationStatus.SkippedByUser &&
      activity.operationalStatus !== OperationStatus.Cancelled
    ) {
      isCompleted = false;
      break;
    }
  }
  return isCompleted;
}
