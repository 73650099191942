import { StateContext } from '@ngxs/store';
import {
  addDays,
  compareAsc,
  differenceInSeconds,
  isBefore,
  isWithinInterval,
  max,
  min,
  subDays,
} from 'date-fns';
import { ResolvedShiftsStateModel } from '../resolved-shifts.state';
import * as Sentry from '@sentry/capacitor';
import { CaptureContext } from '@sentry/types';

/**
 * Quickcheck to determine if hydration is required
 *
 * @export
 * @param {StateContext<ResolvedShiftsStateModel>} ctx
 * @param {number} skipIfLastHydratedWithinSeconds
 * @param {Date} startDate
 * @param {Date} endDate
 * @returns {boolean} Returns true if a valid hydration history entry is found that covers desired range
 */
export function shouldSkipHydration(
  ctx: StateContext<ResolvedShiftsStateModel>,
  skipIfLastHydratedWithinSeconds: number,
  startDate: Date,
  endDate: Date,
): boolean {
  if (skipIfLastHydratedWithinSeconds) {
    try {
      const hydrationHistory = [...ctx.getState().history.hydration].sort(
        (a, b) => compareAsc(new Date(a.date), new Date(b.date)),
      );

      const hydrationLogsContainingRange = hydrationHistory.filter(
        (log) =>
          isWithinInterval(startDate, {
            start: new Date(log.startDate),
            end: new Date(log.endDate),
          }) &&
          isWithinInterval(endDate, {
            start: new Date(log.startDate),
            end: new Date(log.endDate),
          }),
      );
      if (hydrationLogsContainingRange?.length) {
        const latestHydrationContainingRange =
          hydrationLogsContainingRange[hydrationLogsContainingRange.length - 1];
        const secondsSinceLastHydration = differenceInSeconds(
          new Date(),
          new Date(latestHydrationContainingRange.date),
        );
        if (secondsSinceLastHydration < skipIfLastHydratedWithinSeconds) {
          return true;
        }
      }
    } catch (error) {
      const hint: CaptureContext = {
        level: 'error',
        tags: {
          area: 'resolved-shift-state: shouldSkipHydration',
        },
      };
      Sentry.captureException(error, hint);
      console.error(error);
      return false;
    }
  }

  return false;
}

/**
 * Determines which parts of desired range are covered by valid hydrations in history
 * and returns array of missing ranges
 *
 * @export
 * @param {StateContext<ResolvedShiftsStateModel>} ctx
 * @param {number} skipIfLastHydratedWithinSeconds
 * @param {Date} startDate
 * @param {Date} endDate
 * @returns {{}} array of date ranges that have outdated or no cache present
 */
export function determinePartialHydrationRanges(
  ctx: StateContext<ResolvedShiftsStateModel>,
  skipIfLastHydratedWithinSeconds: number,
  startDate: Date,
  endDate: Date,
): { startDate: Date; endDate: Date }[] {
  let dateRangesToHydrate: { startDate: Date; endDate: Date }[] = [];

  try {
    const hydrationHistory = [...ctx.getState().history.hydration].sort(
      (a, b) => compareAsc(new Date(a.date), new Date(b.date)),
    );

    const recentHydratedIntervals = hydrationHistory.filter((log) => {
      const logStartDate = new Date(log.startDate);
      const logEndDate = new Date(log.endDate);
      return (
        isWithinInterval(startDate, { start: logStartDate, end: logEndDate }) ||
        isWithinInterval(endDate, { start: logStartDate, end: logEndDate }) ||
        (isBefore(logStartDate, startDate) && isBefore(endDate, logEndDate))
      );
    });

    const validHydratedIntervals = recentHydratedIntervals.filter((log) => {
      const secondsSinceLastHydration = differenceInSeconds(
        new Date(),
        new Date(log.date),
      );
      return secondsSinceLastHydration < skipIfLastHydratedWithinSeconds;
    });

    const sortedIntervals = validHydratedIntervals.sort((a, b) =>
      compareAsc(new Date(a.startDate), new Date(b.startDate)),
    );

    let currentStart = startDate;

    for (const interval of sortedIntervals) {
      const intervalStartDate = new Date(interval.startDate);
      const intervalEndDate = new Date(interval.endDate);
      if (isBefore(currentStart, intervalStartDate)) {
        dateRangesToHydrate.push({
          startDate: currentStart,
          endDate: min([endDate, subDays(intervalStartDate, 1)]),
        });
      }

      currentStart = max([currentStart, addDays(intervalEndDate, 1)]);
    }

    if (
      isBefore(currentStart, endDate) ||
      currentStart.getTime() === endDate.getTime()
    ) {
      dateRangesToHydrate.push({ startDate: currentStart, endDate });
    }
  } catch (error) {
    const hint: CaptureContext = {
      level: 'error',
      tags: {
        area: 'resolved-shift-state: determinePartialHydrationRanges',
      },
    };
    Sentry.captureException(error, hint);
    console.error(error);
    dateRangesToHydrate = [
      {
        startDate: startDate,
        endDate: endDate,
      },
    ];
  }

  return dateRangesToHydrate;
}
