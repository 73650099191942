import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { ActivityReport } from '@wilson/interfaces';
import { Observable, firstValueFrom } from 'rxjs';

@Injectable()
export class ActivityReportsService {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
  ) {}

  public create(...reports: ActivityReport[]): Promise<ActivityReport> {
    return firstValueFrom(
      this.httpClient.post<ActivityReport>(
        `${this.config.host}/activity-reports`,
        {
          items: reports,
        },
      ),
    );
  }

  public update(
    report: Partial<ActivityReport> & { id: string },
  ): Promise<ActivityReport> {
    return firstValueFrom(
      this.httpClient.patch<ActivityReport>(
        `${this.config.host}/activity-reports/${report.id}`,
        report,
      ),
    );
  }

  public delete(id: string): Promise<ActivityReport> {
    return firstValueFrom(
      this.httpClient.delete<ActivityReport>(
        `${this.config.host}/activity-reports/${id}`,
      ),
    );
  }

  public getActivityReportsByActivityId(
    activityId: string,
  ): Observable<ActivityReport[]> {
    return this.httpClient.post<ActivityReport[]>(
      `${this.config.host}/activity-reports/search`,
      { activityId },
    );
  }
}
