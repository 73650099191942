/* eslint-disable @nx/enforce-module-boundaries */
import { Injectable } from '@angular/core';
import {
  State,
  Action,
  StateContext,
  Selector,
  createSelector,
} from '@ngxs/store';
import { QualificationsInitialize } from './qualifications.actions';
import { finalize, map, take, tap } from 'rxjs';
import { QualificationsStateModel, SelectableQualification } from '../types';
import { ActivityQualificationsService } from '@wilson/activity-qualifications';
import { TranslateQualificationPipe } from '@wilson/qualifications/pipes';

const defaults: QualificationsStateModel = {
  isLoading: false,
  selectableQualifications: [],
};

@State<QualificationsStateModel>({
  name: 'qualifications',
  defaults,
})
@Injectable()
export class QualificationsState {
  constructor(
    private readonly activityQualificationsService: ActivityQualificationsService,
    private readonly translateQualificationPipe: TranslateQualificationPipe,
  ) {}

  @Selector()
  static selectableQualifications(
    state: QualificationsStateModel,
  ): SelectableQualification[] {
    return state.selectableQualifications;
  }

  @Selector()
  static isLoading(state: QualificationsStateModel): boolean {
    return state.isLoading;
  }

  static getCategoryOfQualificationById(id: string) {
    return createSelector(
      [QualificationsState],
      (state: QualificationsStateModel) => {
        return state.selectableQualifications.find(
          (selectableQualification) => {
            return selectableQualification.qualifications.some(
              (q) => q.id === id,
            );
          },
        );
      },
    );
  }

  @Action(QualificationsInitialize)
  initialize({ patchState }: StateContext<QualificationsStateModel>) {
    patchState({ isLoading: true });
    return this.activityQualificationsService
      .getAllSelectableQualifications()
      .pipe(
        take(1),
        map((data) => {
          return (data as unknown as SelectableQualification[]).sort(
            this.sortSelectableQualificationsByCategory.bind(this),
          );
        }),
        map((data) => {
          (data as unknown as SelectableQualification[]).forEach(
            (selectable) => {
              selectable.qualifications.sort(
                this.sortSelectableQualificationsByQualification.bind(this),
              );
            },
          );
          return data;
        }),
        tap((data) => {
          patchState({
            selectableQualifications:
              data as unknown as SelectableQualification[],
          });
        }),
        finalize(() => {
          patchState({ isLoading: false });
        }),
      );
  }

  private sortSelectableQualificationsByCategory(
    a: SelectableQualification,
    b: SelectableQualification,
  ): number {
    const aTranslated = a.qualificationCategory.translatedName;
    const bTranslated = b.qualificationCategory.translatedName;

    if (aTranslated < bTranslated) return -1;
    else if (aTranslated > bTranslated) return 1;
    else return 0;
  }

  private sortSelectableQualificationsByQualification(
    a: { id: string; name: string },
    b: { id: string; name: string },
  ): number {
    const aTranslated = this.translateQualificationPipe.transform(a);
    const bTranslated = this.translateQualificationPipe.transform(b);

    if (aTranslated < bTranslated) return -1;
    else if (aTranslated > bTranslated) return 1;
    else return 0;
  }
}
