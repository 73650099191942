import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { AuthState } from '@wilson/auth/core';
import {
  CreateUserGeneralPreferencePayload,
  GeneralUserPreferencesGatewayService,
  UpdateUserGeneralPreferencePayload,
  UserGeneralPreference,
} from '@wilson/preferences/core';
import { catchError, finalize, map, of } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import {
  FetchUserGeneralPreferences,
  ResetEndShiftAtHomePreferenceAction,
  ResetLongShiftPreferenceAction,
  SetEndShiftAtHomePreferenceAction,
  SetLongShiftPreferenceAction,
} from './user-general-preferences.actions';

export interface UserGeneralPreferenceStateModel {
  isLoading: boolean;
  isLoadingDone: boolean;
  isLoadingFailed: boolean;
  selectedPreference: UserGeneralPreference | null;
}

const defaults = {
  isLoading: false,
  isLoadingDone: false,
  isLoadingFailed: false,
  selectedPreference: null,
};

@State<UserGeneralPreferenceStateModel>({
  name: 'userGeneralPreferences',
  defaults,
})
@Injectable()
export class UserGeneralPreferencesState {
  constructor(
    private generalUserPreferencesService: GeneralUserPreferencesGatewayService,
    private store: Store,
  ) {}

  @Selector()
  static selectedPreference(state: UserGeneralPreferenceStateModel) {
    return state.selectedPreference;
  }

  @Selector()
  static isLoadingFailed(state: UserGeneralPreferenceStateModel) {
    return state.isLoadingFailed;
  }

  @Selector()
  static isLoading(state: UserGeneralPreferenceStateModel) {
    return state.isLoading;
  }

  @Selector()
  static isLoadingDone(state: UserGeneralPreferenceStateModel) {
    return state.isLoadingDone;
  }

  @Action(FetchUserGeneralPreferences)
  fetchUserGeneralPreference({
    patchState,
  }: StateContext<UserGeneralPreferenceStateModel>) {
    patchState({
      isLoading: true,
      isLoadingDone: false,
      isLoadingFailed: false,
    });
    const userId = this.store.selectSnapshot(AuthState.userId) as string;
    return this.generalUserPreferencesService.get(userId).pipe(
      map((preference) => {
        patchState({
          selectedPreference: preference,
        });
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status !== 404) {
          patchState({
            isLoadingFailed: true,
          });
        }

        return of(null);
      }),
      finalize(() => {
        patchState({
          isLoading: false,
          isLoadingDone: true,
        });
      }),
    );
  }

  @Action(SetEndShiftAtHomePreferenceAction)
  async set(
    ctx: StateContext<UserGeneralPreferenceStateModel>,
    { payload }: SetEndShiftAtHomePreferenceAction,
  ) {
    const currentState = ctx.getState();

    if (currentState.isLoadingDone && !currentState.isLoadingFailed) {
      if (currentState.selectedPreference) {
        return this.updateUserGeneralPreferences(ctx, {
          endShiftAtHomeRating: payload.rating,
        });
      } else {
        return this.createUserGeneralPreference(ctx, {
          endShiftAtHomeRating: payload.rating,
        });
      }
    }
  }

  @Action(SetLongShiftPreferenceAction)
  async setLongShiftPreferenceAction(
    ctx: StateContext<UserGeneralPreferenceStateModel>,
    { payload }: SetLongShiftPreferenceAction,
  ) {
    const currentState = ctx.getState();

    if (currentState.isLoadingDone && !currentState.isLoadingFailed) {
      if (currentState.selectedPreference) {
        return this.updateUserGeneralPreferences(ctx, {
          longShiftRating: payload.rating,
        });
      }
      return this.createUserGeneralPreference(ctx, {
        longShiftRating: payload.rating,
      });
    }
  }

  @Action(ResetEndShiftAtHomePreferenceAction)
  reset(ctx: StateContext<UserGeneralPreferenceStateModel>) {
    return this.updateUserGeneralPreferences(ctx, {
      endShiftAtHomeRating: null,
    });
  }

  @Action(ResetLongShiftPreferenceAction)
  resetLongShiftPreferenceAction(
    ctx: StateContext<UserGeneralPreferenceStateModel>,
  ) {
    return this.updateUserGeneralPreferences(ctx, {
      longShiftRating: 3,
    });
  }

  private async createUserGeneralPreference(
    context: StateContext<UserGeneralPreferenceStateModel>,
    payload: CreateUserGeneralPreferencePayload,
  ) {
    const userId = this.store.selectSnapshot(AuthState.userId) as string;
    const newPreference: UserGeneralPreference = {
      id: uuidv4(),
      userId,
      ...payload,
    };

    context.patchState({
      selectedPreference: newPreference,
    });

    try {
      return await this.generalUserPreferencesService.create(
        userId,
        newPreference,
      );
    } catch (e) {
      context.patchState({
        selectedPreference: null,
      });
      return Promise.reject(e);
    }
  }

  private async updateUserGeneralPreferences(
    { patchState, getState }: StateContext<UserGeneralPreferenceStateModel>,
    payload: UpdateUserGeneralPreferencePayload,
  ) {
    const userId = this.store.selectSnapshot(AuthState.userId) as string;
    const currentSetting = getState().selectedPreference;
    const preferenceId = currentSetting?.id;

    if (preferenceId) {
      patchState({
        selectedPreference: {
          ...currentSetting,
          ...payload,
        },
      });

      try {
        return await this.generalUserPreferencesService.update(
          userId,
          preferenceId,
          payload,
        );
      } catch (e) {
        patchState({
          selectedPreference: currentSetting,
        });
        return Promise.reject(e);
      }
    } else {
      return null;
    }
  }
}
