import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { FeatureFlagPurePipe } from '@wilson/feature-flags';
import { GeoLocation } from '@wilson/interfaces';
import { LocationsService, SaveAddressService } from '@wilson/locations';
import { CustomLocationsState } from '@wilson/locations-v2/state';
import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  map,
  of,
  switchMap,
} from 'rxjs';
import { GeoLocationFilterService } from './geo-location-filter.service';
import { GooglePlacesService } from './google-places.service';
import { combinedActivitiesCompareFn } from './utils/combinedLocations-fn';

@Injectable({
  providedIn: 'root',
})
export class LocationSuggestionAutocompleteService {
  constructor(
    private readonly store: Store,
    private placesService: GooglePlacesService,
    private readonly locationsService: LocationsService,
    private readonly saveAddressService: SaveAddressService,
    private readonly geoLocationFilterService: GeoLocationFilterService,
    private readonly featureFlagPurePipe: FeatureFlagPurePipe,
  ) {}

  search(text: string, ignoreFeatureFlag?: boolean) {
    const text$ = of(text);
    return text$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((term) =>
        term.length < 3
          ? this.store.select(CustomLocationsState.highlightedCustomLocations)
          : this.searchLocation(term, ignoreFeatureFlag),
      ),
    );
  }

  searchLocation(term: string, ignoreFeatureFlag?: boolean) {
    const isCustomLocationsEnabled$ = ignoreFeatureFlag
      ? of(true)
      : this.featureFlagPurePipe.transform(
          'custom-locations-search-google-places',
        );
    return combineLatest([
      isCustomLocationsEnabled$.pipe(
        switchMap((flag) => {
          return flag ? this.placesService.addressEvent(term) : of([]);
        }),
      ),
      this.customLocationSearchStream(term),
      this.geoLocationFilterService.filterGeoLocations(
        term,
        this.store.select(CustomLocationsState.customLocations),
      ),
    ]).pipe(
      map(([googleAddress, locations, customLocations]) => {
        let filteredRailLocations = [];

        const customLocationIds = new Set(
          customLocations.map((customLocation) => customLocation.id),
        );

        filteredRailLocations = locations.filter(
          (location) => !customLocationIds.has(location.id),
        );

        const locationsArray: (
          | google.maps.places.QueryAutocompletePrediction
          | Pick<GeoLocation, 'name' | 'locationCode' | 'id'>
        )[] = [];

        return locationsArray
          .concat(googleAddress, filteredRailLocations, customLocations)
          .sort(combinedActivitiesCompareFn);
      }),
    );
  }

  searchFromInternalSystem(text: string) {
    const text$ = of(text);
    return text$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      switchMap((term) =>
        term.length < 3
          ? this.store.select(CustomLocationsState.highlightedCustomLocations)
          : this.createInternalSystemSearchStream(text),
      ),
    );
  }

  async selectedUserLocation(
    location: GeoLocation | google.maps.places.QueryAutocompletePrediction,
  ): Promise<GeoLocation | undefined> {
    return new Promise((resolve, reject) => {
      if ('locationCategoryId' in location && 'locationCode' in location) {
        resolve(location);
      } else {
        try {
          this.placesService.getGeoLocationFromGooglePlace(
            location,
            async (transformedLocation: GeoLocation) => {
              if (transformedLocation.address) {
                transformedLocation.addressId = (
                  await this.saveAddressService.getExistingLocationAddressOrCreateNewIfNoneExist(
                    transformedLocation.address,
                  )
                )?.id;
              }
              const getOrCreatedLocation =
                await this.saveAddressService.getExistingGeoLocationOrCreateNewIfNoneExist(
                  transformedLocation,
                );

              resolve(getOrCreatedLocation);
            },
          );
        } catch {
          reject(undefined);
        }
      }
    });
  }

  private createInternalSystemSearchStream(text: string) {
    return combineLatest([
      this.customLocationSearchStream(text),
      this.geoLocationFilterService.filterGeoLocations(
        text,
        this.store.select(CustomLocationsState.customLocations),
      ),
    ]).pipe(
      map(([locations, customLocations]) => {
        let filteredRailLocations = [];

        const customLocationIds = new Set(
          customLocations.map((customLocation) => customLocation.id),
        );

        filteredRailLocations = locations.filter(
          (location) => !customLocationIds.has(location.id),
        );

        const locationsArray: Pick<
          GeoLocation,
          'name' | 'locationCode' | 'id'
        >[] = [];

        return locationsArray
          .concat(filteredRailLocations, customLocations)
          .sort(combinedActivitiesCompareFn);
      }),
    );
  }

  private customLocationSearchStream(text: string) {
    return this.featureFlagPurePipe
      .transform('custom-locations-search-rail')
      .pipe(
        switchMap((flag) => {
          return flag
            ? this.geoLocationFilterService.filterGeoLocations(
                text,
                this.locationsService.getRailGeoLocationsByName(text),
              )
            : of([]);
        }),
      );
  }
}
