import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CopyButtonComponent } from './copy-button.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  declarations: [CopyButtonComponent],
  imports: [CommonModule, FontAwesomeModule],
  exports: [CopyButtonComponent],
})
export class CopyButtonModule {}
