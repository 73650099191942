import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  WritableSignal,
  computed,
  signal,
} from '@angular/core';
import { faBars } from '@fortawesome/pro-light-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@wilson/forms';
import { GeoLocation } from '@wilson/interfaces';
import { GetLocationAction, LocationState } from '@wilson/locations';
import { CustomLocationsModule } from '@wilson/locations-v2/custom-locations';
import { first, firstValueFrom } from 'rxjs';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FeatureFlagsModule } from '@wilson/feature-flags';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { LocationNamePipe } from '@wilson/pipes';
import { Store } from '@ngxs/store';

export interface InputData {
  viaLocationIds: string[];
  sectorName: string;
  startLocation: GeoLocation;
  endLocation: GeoLocation;
}

@Component({
  selector: 'wilson-via-points',
  templateUrl: './via-points.component.html',
  styleUrls: ['./via-points.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    CustomLocationsModule,
    FormsModule,
    DragDropModule,
    FontAwesomeModule,
    FeatureFlagsModule,
  ],
  providers: [LocationNamePipe],
})
export class ViaPointsComponent implements OnInit {
  public startLocationName!: string;
  public endLocationName!: string;
  public viaLocations: WritableSignal<GeoLocation[]> = signal([]);
  public selectedLocation!: GeoLocation | null;
  public sectorName!: string | undefined;
  public faBars = faBars;
  public viaLocationIds = computed(() =>
    this.viaLocations().map((location) => location.id),
  );

  constructor(
    private readonly store: Store,
    private readonly cdRef: ChangeDetectorRef,
    @Inject(NZ_MODAL_DATA)
    private data: InputData,
    private locationNamePipe: LocationNamePipe,
  ) {}

  async ngOnInit(): Promise<void> {
    this.sectorName = this.data.sectorName;

    if (this.sectorName === 'rail') {
      this.startLocationName = this.locationNamePipe.transform(
        this.data.startLocation,
      );
      this.endLocationName = this.locationNamePipe.transform(
        this.data.endLocation,
      );
    } else {
      this.startLocationName = this.data.startLocation.name;
      this.endLocationName = this.data.endLocation.name;
    }

    if (this.data.viaLocationIds?.length) {
      this.viaLocations.set([]);
      for (const id of this.data.viaLocationIds) {
        this.store.dispatch(new GetLocationAction(id));

        const location = await firstValueFrom(
          this.store.select(LocationState.location(id)).pipe(first((m) => !!m)),
        );

        this.viaLocations.update((currentLocations: GeoLocation[]) => {
          return [...currentLocations, location];
        });
      }
      this.cdRef.detectChanges();
    }
  }

  setLocation(location: GeoLocation | null) {
    if (location) {
      this.selectedLocation = location;
      this.cdRef.detectChanges();
      this.viaLocations.update((locations) => [...locations, location]);
      this.selectedLocation = null;
      this.cdRef.detectChanges();
    }
  }

  changeLocation(location: GeoLocation | null, index: number) {
    this.viaLocations.update((locations) => {
      const clonedLocations = [...locations];
      if (!location) {
        clonedLocations.splice(index);
      } else {
        clonedLocations[index] = location;
      }

      return clonedLocations;
    });
  }

  removeLocation(index: number) {
    this.viaLocations.update((locations) => {
      const clonedLocations = [...locations];
      clonedLocations.splice(index, 1);

      return clonedLocations;
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    this.viaLocations.update((locations) => {
      const clonedLocations = [...locations];
      moveItemInArray(clonedLocations, event.previousIndex, event.currentIndex);
      return clonedLocations;
    });
    this.cdRef.detectChanges();
  }
}
