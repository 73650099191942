import { Injectable } from '@angular/core';
import { Ability } from '@casl/ability';
import { State, StateContext, Store, Action, NgxsOnInit } from '@ngxs/store';
import { AuthState, AuthStateModel } from '@wilson/auth/core';
import { Permissions } from '@wilson/interfaces';
import { Observable, of } from 'rxjs';
import { finalize, take, tap } from 'rxjs/operators';
import { PermissionsService } from '../permissions.service';
import { InitializePermissionsState } from './permissions.action';

@State<Permissions>({
  name: 'permissions',
  defaults: {
    featurePermissions: [],
    rolePermissions: [],
  },
})
@Injectable()
export class PermissionsState implements NgxsOnInit {
  private isFetchingPermissions!: boolean;

  constructor(
    private readonly store: Store,
    private readonly permissionsService: PermissionsService,
    private readonly ability: Ability,
  ) {}

  async ngxsOnInit(ctx?: StateContext<Permissions>): Promise<void> {
    /**
     * Even though portal.component is dispatching the InitializePermissionsState action
     * we need to call it in this lifecycle method as well
     *
     * portal.component is not initialized when blocked by the guard
     * and the guard is waiting for the state
     *
     * This call breaks the following cycle:
     * page -> guard -> state -> page
     */
    await this.initialzeState(ctx)
      .toPromise()
      .catch(() => {
        console.warn('expectedly, could not initialize state');
      });
  }

  @Action(InitializePermissionsState)
  initialzeState(
    ctx?: StateContext<Permissions>,
  ): Observable<Permissions | null> {
    if (this.isFetchingPermissions) return of(null);
    const userId = this.store.selectSnapshot<AuthStateModel['userId']>(
      AuthState.userId,
    );
    if (!userId) return of(null);
    this.isFetchingPermissions = true;

    return this.permissionsService.getUserPermissions(userId).pipe(
      take(1),
      tap((permissions) => {
        this.ability.update(permissions.rolePermissions);
        ctx.setState(permissions);
      }),
      finalize(() => (this.isFetchingPermissions = false)),
    );
  }
}
