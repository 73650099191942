import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AccountService } from '@wilson/account';
import { AuthState } from '@wilson/auth/core';
import { OrganizationalUnit, User } from '@wilson/interfaces';
import { keyBy } from 'lodash';
import { forkJoin, map, Observable, of } from 'rxjs';

export interface UserData {
  organizationalUnits: Record<string, OrganizationalUnit>;
}

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  constructor(
    private readonly store: Store,
    private readonly accountService: AccountService,
  ) {}

  public getUserData(): Observable<UserData | null> {
    const userId = this.store.selectSnapshot(AuthState.userId);
    if (!userId) return of(null);

    return forkJoin([
      this.accountService
        .getUser(userId, {
          relations: ['accessibleOrganizationalUnits'],
        })
        .pipe(
          map(
            (user: User) =>
              user.accessibleOrganizationalUnits as OrganizationalUnit[],
          ),
        ),
    ]).pipe(
      map((data: [OrganizationalUnit[]]) => {
        return {
          organizationalUnits: keyBy(data[0], (o) => o.id as string),
        };
      }),
    );
  }
}
