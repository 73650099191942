import { Base } from '@wilson/base';

export enum filekind {
  ProfileImg = 'profile-image',
  VehicleImg = 'vehicle-image',
  AbsenceAttachment = 'absence-attachment',
  QualificationDocument = 'qualification-document',
  ProjectDocument = 'project-document',
}

export interface FileObject extends Base {
  name: string;
  filekind: filekind;
}

export interface FileAccess {
  accessUrl: string;
  filePath: string;
  httpMethod: string;
}
