import { ResolvedShiftWithReports } from '@wilson/interfaces';
import isBefore from 'date-fns/isBefore';

export const shiftsCompareFn = (
  shiftA: ResolvedShiftWithReports,
  shiftB: ResolvedShiftWithReports,
) => {
  if (
    isBefore(
      new Date(shiftB.activities[0].startDatetime),
      new Date(shiftA.activities[0].startDatetime),
    )
  )
    return 1;
  else return -1;
};
