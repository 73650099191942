import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { ActivityTemplate } from '@wilson/interfaces';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ActivityTemplatesGateway {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
  ) {}

  public updateActivityTemplate(activityTemplate: Partial<ActivityTemplate>) {
    return firstValueFrom(
      this.httpClient.patch<ActivityTemplate>(
        `${this.config.host}/activity-templates/${activityTemplate.id}`,
        activityTemplate,
      ),
    );
  }
}
