import { OrganizationalUnit, PreparedService } from '@wilson/interfaces';

export class InitializeServiceEditState {
  static readonly type = '[ServiceEdit] InitializeServiceEditState';
  constructor(public serviceId: string) {}
}

export class InitializeServiceDuplicateEditState {
  static readonly type = '[ServiceEdit] InitializeServiceDuplicateEditState';
  constructor(public preparedService: PreparedService) {}
}

export class InitializeServiceSeriesEditState {
  static readonly type = '[ServiceEdit] InitializeServiceSeriesEditState';
  constructor(public serviceSeriesId: string) {}
}

export class InitializeServiceSeriesDuplicateEditState {
  static readonly type =
    '[ServiceEdit] InitializeServiceSeriesDuplicateEditState';
  constructor(public serviceSeriesId: string) {}
}

export class SetServiceStartDate {
  static readonly type = '[ServiceEdit] SetServiceStartDate';
  constructor(public startDate: Date) {}
}

export class SetOrganizationalUnit {
  static readonly type = '[ServiceEdit] SetOrganizationalUnit';
  constructor(public organizationalUnit: OrganizationalUnit) {}
}

export class UpdateSegmentDates {
  static readonly type = '[ServiceEdit] UpdateSegmentDates';
  constructor(public oldDate: Date, public newDate: Date) {}
}

export class SetServiceRecurring {
  static readonly type = '[ServiceEdit] SetServiceRecurring';
  constructor(public recurring: boolean) {}
}

export class ClearServiceForm {
  static readonly type = '[ServiceEdit] ClearServiceForm';
}
