import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisplayLabelComponent } from './components/display-labels/display-labels.component';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { SelectLabelComponent } from './components/select-labels/select-labels.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { DisplayLabelAgGridComponent } from './components/display-labels-ag-grid/display-labels-ag-grid.component';
import { LabelsHelperService } from './services/labels-helper.service';

@NgModule({
  imports: [
    CommonModule,
    NzTagModule,
    NzToolTipModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NzSelectModule,
    NzFormModule,
  ],
  declarations: [
    DisplayLabelComponent,
    SelectLabelComponent,
    DisplayLabelAgGridComponent,
  ],
  exports: [
    DisplayLabelComponent,
    SelectLabelComponent,
    DisplayLabelAgGridComponent,
  ],
  providers: [LabelsHelperService],
})
export class LabelsModule {}
