<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="'compact'">
    <wilson-loading-spinner class="my-4"></wilson-loading-spinner
    ><ng-content></ng-content>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <div class="fw-bold fs-5">
      {{ 'page.services.please_wait' | translate }}
    </div>
    <wilson-loading-spinner class="my-4"></wilson-loading-spinner>
    <div class="fw-light fs-6">
      <div #ref><ng-content></ng-content></div>
      <span *ngIf="!ref?.innerHTML?.trim()">
        {{ loadingMessageTranslationKey | translate }}
      </span>
    </div>
  </ng-container>
</ng-container>
