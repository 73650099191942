<div class="card">
  <div class="card-body">
    <div class="d-flex justify-content-between">
      <div class="card-title text-dark fw-bold mb-3">
        <i class="fa fa-user text-secondary me-2"></i>
        {{ 'shift.assignment.occupy-shift' | translate }}: {{ shift?.name }} -
        {{ shift?.startDate | date : 'dd.MM.yyyy' }}
      </div>
      <div class="w-25 d-flex justify-content-end mb-4">
        <input
          type="text"
          class="search-bar shadow-none w-100 rounded form-control bg-white py-1 px-2"
          [placeholder]="'account.search' | translate"
          [(ngModel)]="search"
          (keyup)="applySearchFilter($event.target.value)"
        />
        <span class="position-absolute mt-1 me-2 border-0">
          <i class="fas fa-search search-icon"></i>
        </span>
      </div>
    </div>

    <div class="list-height">
      <div [class.d-none]="isEmptyState">
        <!-- suitable results -->
        <div class="mb-5">
          <span class="text-dark fw-bold">
            {{ 'page.shift.suitable_results' | translate }}
          </span>
          <i
            nz-tooltip
            [nzTooltipTitle]="tooltipShiftAssignment"
            nzTooltipPlacement="right"
            nzTooltipOverlayClassName="dark"
            [nzTooltipOverlayStyle]="{ 'min-width': '500px' }"
            class="fas fa-info-circle ms-1 text-secondary"
          ></i>
          <ng-template #tooltipShiftAssignment>
            <wilson-tooltip
              [header]="'page.shift.suitable_results' | translate"
              [details]="
                'tooltip.subheader.generated_suggestions_based_availability'
                  | translate
              "
            >
            </wilson-tooltip>
          </ng-template>
          <wilson-assign-staff-list
            [shiftAssignment]="shiftBestAssignments$ | async"
            (selectStaff)="selectStaff($event)"
          >
          </wilson-assign-staff-list>
        </div>

        <!-- further results -->
        <div class="mb-5">
          <span class="text-dark fw-bold">
            {{ 'page.shift.further_results' | translate }}
          </span>
          <i
            nz-tooltip
            [nzTooltipTitle]="tooltipShiftFurtherAssignment"
            nzTooltipPlacement="right"
            nzTooltipOverlayClassName="dark"
            [nzTooltipOverlayStyle]="{ 'min-width': '500px' }"
            class="fas fa-info-circle ms-1 text-secondary"
          ></i>
          <ng-template #tooltipShiftFurtherAssignment>
            <wilson-tooltip
              [header]="'page.shift.further_results' | translate"
              [details]="
                'tooltip.subheader.generated_suggestions_based_availability'
                  | translate
              "
            >
            </wilson-tooltip>
          </ng-template>
          <wilson-assign-staff-list
            [shiftAssignment]="shiftAssignments$ | async"
            (selectStaff)="selectStaff($event)"
          >
          </wilson-assign-staff-list>
        </div>
      </div>
      <wilson-empty-state [class.d-none]="!isEmptyState"></wilson-empty-state>
    </div>

    <div class="d-flex justify-content-between mt-3">
      <div class="mt-3 form-text">
        {{ 'page.shift.matching_requirements_text' | translate }}
      </div>
      <div class="d-flex justify-content-end mt-2">
        <button
          type="button"
          class="btn btn-dark w-btn p-1 me-2 fw-bold"
          (click)="decline()"
        >
          {{ 'general.cancel' | translate }}
        </button>
        <button
          type="button"
          class="btn btn-primary w-btn text-dark p-1 fw-bold"
          (click)="accept()"
          [disabled]="!selectedAssignment"
        >
          {{ 'general.accept' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
