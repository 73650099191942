export class ResetUsersState {
  static readonly type = '[UsersState] ResetUsersState';
}

export class FetchAndAddUserIfNotInUsersState {
  static readonly type = '[UsersState] FetchAndAddUserIfNotInUsersState';
  constructor(public userId: string) {}
}

export class FetchAllUsers {
  static readonly type = '[UsersState] FetchAllUsers';
}
