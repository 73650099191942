import { Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-organization-renderer',
  template: `<span>{{
    (cellValue | organizationNamePure | async) ?? '---'
  }}</span>`,
})
export class OrganizationRendererComponent implements AgRendererComponent {
  public cellValue: string | undefined | null;

  agInit(params: ICellRendererParams): void {
    this.cellValue = params.value;
  }

  refresh(params: ICellRendererParams) {
    this.cellValue = params.value;
    return true;
  }
}
