import { Pipe, PipeTransform } from '@angular/core';
import { NzCascaderOption } from 'ng-zorro-antd/cascader';

@Pipe({
  name: 'selectedAgreementName',
  standalone: true,
})
export class SelectedAgreementNamePipe implements PipeTransform {
  transform(
    agreementId: string | null | undefined,
    agreementOptions: NzCascaderOption[],
  ): string {
    let result = '';
    for (const option of agreementOptions) {
      const clientName = option.label;
      const agreementIndex = option.children?.findIndex(
        (op) => op.value === agreementId,
      );

      if (agreementIndex !== -1) {
        result = clientName + ' / ' + option.children[agreementIndex].label;
        break;
      }
    }
    return result;
  }
}
