import { AbsenceWithCategory } from '../absences/absence';
import { TranslatedAbsenceCategory } from '../absences/absenceCategory';
import { OrganizationalUnit } from '../organizational-units/organizationalUnit';
import { ShiftTemplate } from '../shift-templates/shift-template';
import { PublicationStatus } from '../shifts/publicationStatus';
import {
  Shift,
  ShiftValidationDetails,
  ShiftWithActivitiesWithLocations,
} from '../shifts/shift';

export enum ShiftDispositionAssignableTypes {
  Absence = 'absence',
  Shift = 'shift',
  ShiftTemplate = 'shift-template',
}

export enum CalendarDisplayMode {
  Week = 'week',
  TwoWeek = '2week',
  Month = 'month',
}

export type ShiftDispositionFilters = ShiftsFilters & UserFilters;

export interface ShiftsFilters {
  orgUnits: string[];
  shiftCategoryIds: string[];
  publicationStatus: PublicationStatus[];
  absenceCategories: string[];
  groups: string[];
  shiftPlans: string[];
}

export interface UserFilters {
  orgUnits: string[];
  searchTerm: string;
  userRoleIds: string[];
  userIds: string[];
}

export interface ShiftDispositionFilterResolvedMetaData {
  orgUnits?: OrganizationalUnit[];
  userIds?: string[];
  userRoleIds?: string[];
  shiftPlans?: Shift[];
  groups?: string[];
  absenceCategories?: TranslatedAbsenceCategory[];
  shiftCategoryIds?: string[];
}

export type ShiftDispositionAssignable = {
  template?: boolean;
  date: Date;
  formattedDate?: string;
} & (
  | ShiftDispositionAssignableShift
  | ShiftDispositionAssignableAbsence
  | ShiftDispositionAssignableShiftTemplate
);

export type ShiftDispositionAssignableShifts = Pick<
  ShiftDispositionAssignable,
  'date' | 'template' | 'formattedDate'
> &
  ShiftDispositionAssignableShift;

export interface ShiftDispositionAssignableShift {
  type: ShiftDispositionAssignableTypes.Shift;
  record: ShiftWithActivitiesWithLocations;
  issues?: ShiftValidationDetails[];
}

export interface ShiftDispositionAssignableAbsence {
  type: ShiftDispositionAssignableTypes.Absence;
  record: AbsenceWithCategory;
}

export interface AssignableTasksWithDate {
  date: Date;
  items: ShiftDispositionAssignable[];
}

export interface AssignableShiftsWithDate {
  date: Date;
  items: ShiftDispositionAssignableShifts[];
}

export interface ShiftDispositionAssignableShiftTemplate {
  type: ShiftDispositionAssignableTypes.ShiftTemplate;
  record: ShiftTemplate;
}

export type ShiftDispositionTask = Record<
  string,
  Record<string, ShiftDispositionAssignable[]>
>;

export type AffectedShifts = Record<
  string,
  {
    previousStatus: PublicationStatus;
  }
>;
