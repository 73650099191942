import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { GeneralCompanySettings } from '@wilson/interfaces';
import { firstValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GeneralCompanySettingsGatewayService {
  protected readonly path = 'general-settings';

  constructor(
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
    protected readonly http: HttpClient,
  ) {}

  getAll(): Observable<GeneralCompanySettings> {
    return this.http.get<GeneralCompanySettings>(
      `${this.config.host}/${this.path}`,
    );
  }

  updateGeneralSettings(
    settingId: string,
    settings: Partial<GeneralCompanySettings>,
  ): Promise<GeneralCompanySettings> {
    return firstValueFrom(
      this.http.patch<GeneralCompanySettings>(
        `${this.config.host}/${this.path}/${settingId}`,
        settings,
      ),
    );
  }

  createGeneralSettings(
    settings: GeneralCompanySettings,
  ): Promise<GeneralCompanySettings> {
    return firstValueFrom(
      this.http.post<GeneralCompanySettings>(
        `${this.config.host}/${this.path}/`,
        settings,
      ),
    );
  }

  addCompanyLogo(settingId: string, file: File) {
    const formData = new FormData();
    formData.append('file', file as unknown as File);

    return firstValueFrom(
      this.http.patch<GeneralCompanySettings>(
        `${this.config.host}/${this.path}/${settingId}`,
        formData,
      ),
    );
  }
}
