// eslint-disable-next-line
interface Array<T> {
  last(): T;
  flatten(): T;
}

Array.prototype.last = function <T>(this: T[]) {
  return this[this.length - 1];
};

Array.prototype.flatten = function <T>(this: T[][]) {
  return [].concat(...this);
};
