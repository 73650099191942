export enum AbsenceCategoryEnum {
  RestDay = '6f2da463-368e-4898-8410-2b7f7052fb56',
  AbsentWithoutReason = 'a6c9a1e0-ddf7-4ae9-a538-e0e97c80bdf4',
  BusinessTrip = 'a59545e6-217d-4308-84b8-cd2f2e1caad6',
  EducationVacation = '1f2798c5-1f6b-4f2b-af42-b464d6b12e65',
  EmploymentBan = 'b9b28617-5fca-435a-84cf-e6f94e638939',
  ExternalRestDay = '72cb4eea-f365-417c-a123-7523459bff5e',
  MaternityLeave = '0e08ff2f-6ba4-4074-ba13-9bad82e06571',
  OutOfNightShift = '6f1c41a4-8c98-48cc-85f0-0b35e74645b5',
  ParentalLeave = '9616dd40-8e08-4d7f-9227-51325404d9c9',
  PublicHoliday = 'ae2fde74-821d-4268-b0cf-034e485c3d14',
  ReducedOverHours = 'c7b98675-d918-4c73-b78c-00552208b48d',
  RemoteWork = 'e4a49c0e-6fc9-41f3-927a-e72fa709d812',
  SickLeave = 'b83e4eca-5d2e-40cf-bf60-3f99d35885fc',
  SickLeaveChild = '56ff7221-99dc-4386-a950-a22bdea02972',
  SickLeaveWithoutPay = 'b202874d-3969-46b8-b50f-f4f65cac6d14',
  SickLeaveWorkInjury = '09c3392b-bd50-47a3-912b-25e41ed3950b',
  SpecialVacation = '6f06f497-1538-4446-9789-ca08f4c9b75b',
  UnpaidVacation = 'd31eff76-7a67-4303-ad10-19fde7b2720d',
  Vacation = 'e3bbd273-c7bb-4fd3-99ba-d9daf1b11f64',
  Training = 'fd0593c3-68b3-4bdc-99b4-fa8244a8b56e',
  More = '6b74430d-0635-48dd-88f6-ea2e35c550bd',
  LeaveOfAbsence = 'dc17918f-a96d-450c-92e0-0f6bdd5cedc2',
  Strike = '2b0c846f-dd07-4c90-8117-cce2b70eed45',
  RequestedRestDay = '7d4f42f4-7db7-495b-adec-449416f1a554',
  CompensationalRestDay = '4a7f79a8-0f7b-49b1-bf22-eba57a3a470a',
  WorksCouncil = 'a0be63aa-77e1-497f-a0cb-0a6cc8dc7712',
  HealthRecovery = '05e9d36e-9402-4019-8de7-fb4d8faa582b',
  SickLeaveWithoutAU = '62486ae8-cd02-4bd1-9406-589fc84b2e7c',
  Quarantine = '7db32323-6c48-4b7c-b906-da9b51dedc69',
  VocationalTraining = '6684c7ea-aa1f-4d0a-aede-49baf58f108b',
  OnCall = '29ca1a58-63a5-43a8-bc61-cf3510815f6a',
  NoServiceAssignment = '17d2ea1c-4a06-4e87-adcf-5c13bf89ea5e',
  EmployeeLeasingPause = '8b22424d-85af-48de-a2e3-6bb7988e6ea2',
  CompanyDoctor = '7b667d67-82e4-42d3-9429-39b12f6c2d84',
  OnSiteBriefing = '3bd25f1a-bc87-4d8c-8eed-e9604bfccbd9',
  RestDayOnCall = 'a6c9949c-f6ab-4afc-a09d-83d20d9e0edb',
  CourtHearing = 'c5da8649-4d00-4235-a80e-078b64e26cdd',
  EmergencyService = '33f91da8-6aab-482e-a65b-230c99310bc4',
}
