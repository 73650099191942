export enum LocationType {
  Rail = 'rail',
  Address = 'address',
  Home = 'home',
  Custom = 'custom',
}

export enum LocationCategoryIdsMap {
  Rail = 'df48928c-e7dd-4690-8821-4971071d2891',
  Address = '0e892550-509f-491f-9021-d37860d8e6e0',
  Home = '416ad14a-4825-46e6-a99a-e5f653a71493',
  Custom = '7642cff7-8129-4c55-ac3d-c70d2954de86',
}
