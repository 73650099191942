import { ActivityComment } from '@wilson/interfaces';
import { v4 as uuidv4 } from 'uuid';

export const activityCommentFactory = (
  activityCommentArgs: Omit<ActivityComment, 'id' | 'createdAt' | 'updatedAt'>,
): ActivityComment => ({
  ...activityCommentArgs,
  id: uuidv4(),
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
});
