import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { stringify } from 'qs';
import { firstValueFrom } from 'rxjs';
import { ShiftTimePreference } from './shift-time-gateway.interface';
import {
  AddUserShiftTimePreference,
  CreateUserShiftTimePreferenceDto,
} from './user-shift-time-preferences.interface';

@Injectable({
  providedIn: 'root',
})
export class UserShiftTimePreferencesGatewayService {
  protected readonly path = (userId: string) =>
    `users/${userId}/shift-times/preferences`;

  constructor(
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
    protected readonly http: HttpClient,
  ) {}

  get(
    userId: string,
    options?: {
      embed?: ('organizationalUnitShiftTimePreference' | 'user')[];
    },
  ) {
    const params = stringify(options);
    return this.http.get<ShiftTimePreference[]>(
      `${this.config.host}/${this.path(userId)}?${params}`,
    );
  }

  create(userId: string, payload: CreateUserShiftTimePreferenceDto) {
    return firstValueFrom(
      this.http.post<CreateUserShiftTimePreferenceDto>(
        `${this.config.host}/${this.path(userId)}`,
        payload,
      ),
    );
  }

  update(
    userId: string,
    organizationalUnitShiftTimePreferenceId: string,
    selectedPreferenceId: string,
  ) {
    return firstValueFrom(
      this.http.patch<AddUserShiftTimePreference>(
        `${this.config.host}/${this.path(
          userId,
        )}/${organizationalUnitShiftTimePreferenceId}`,
        {
          organizationalUnitShiftTimePreferenceId: selectedPreferenceId,
        },
      ),
    );
  }

  delete(userId: string) {
    return firstValueFrom(
      this.http.delete(`${this.config.host}/${this.path(userId)}`),
    );
  }
}
