import { DateTimeFormat } from '@wilson/utils';
import { format, startOfMinute } from 'date-fns';

export function getDateFromPixelPosition({
  pixelPosition,
  timelineDates,
  frameLength,
}: {
  pixelPosition: number;
  timelineDates: Date[];
  frameLength: number;
}) {
  const startTimestamp = new Date(
    `${format(timelineDates[0], DateTimeFormat.DatabaseDateFormat)}T00:00`,
  ).getTime();
  const endTimestamp = new Date(
    `${format(
      timelineDates[timelineDates.length - 1],
      DateTimeFormat.DatabaseDateFormat,
    )}T23:59`,
  ).getTime();

  const totalDurationInMillisecond = endTimestamp - startTimestamp;

  const millisecondsFromStart =
    (pixelPosition / frameLength) * totalDurationInMillisecond;

  const date = startOfMinute(new Date(startTimestamp + millisecondsFromStart));
  return date;
}
