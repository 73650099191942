import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { BankDetails } from '@wilson/interfaces';
import { firstValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BankDetailsGatewayService {
  protected readonly path = 'bank-accounts';

  constructor(
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
    protected readonly http: HttpClient,
  ) {}

  getBankDetails(): Observable<BankDetails[]> {
    return this.http.get<BankDetails[]>(`${this.config.host}/${this.path}`);
  }

  updateBankDetails(bankDetails: BankDetails) {
    return firstValueFrom(
      this.http.patch(
        `${this.config.host}/${this.path}/${bankDetails.id}`,
        bankDetails,
      ),
    );
  }

  createBankDetails(bankDetails: BankDetails): Promise<BankDetails> {
    return firstValueFrom(
      this.http.post<BankDetails>(
        `${this.config.host}/${this.path}/`,
        bankDetails,
      ),
    );
  }

  deleteBankDetails(bankDetailId: string) {
    return firstValueFrom(
      this.http.delete(`${this.config.host}/${this.path}/${bankDetailId}`),
    );
  }
}
