import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class TimelineInteractionService {
  private hoveredRowIndexSubject = new Subject<number>();

  public hoveredRowIndex$ = this.hoveredRowIndexSubject.asObservable();

  setHoveredRowIndex(index: number) {
    this.hoveredRowIndexSubject.next(index);
  }
}
