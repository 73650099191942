import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  UrlTree,
} from '@angular/router';
import { Store } from '@ngxs/store';
import { FeatureName } from '@wilson/interfaces';
import { Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';

export const featurePermissionGuardFn: CanActivateFn = (
  route: ActivatedRouteSnapshot,
): Observable<boolean | UrlTree> | UrlTree => {
  const store = inject(Store);
  const router = inject(Router);
  const routePermission: FeatureName = route.data.featurePermission;
  if (!routePermission) {
    return router.createUrlTree(['/']);
  }

  return store
    .select((state) => state.permissions.featurePermissions)
    .pipe(
      skipWhile((features) => !features.length),
      map((features) => {
        if (features.includes(routePermission)) {
          return true;
        }
        return router.createUrlTree(['/']);
      }),
    );
};
