<div
  class="d-flex flex-row align-items-center justify-content-between validation-category-row px-2"
  [ngClass]="{ selected: isSelected }"
  (click)="onClick()"
>
  <div class="d-flex flex-row align-items-center">
    <fa-icon
      *ngIf="isValid"
      class="icon validIcon me-2"
      [icon]="faValidIcon"
    ></fa-icon>
    <fa-icon
      *ngIf="!isValid"
      class="icon inValidIcon me-2"
      [icon]="faInValidIcon"
    ></fa-icon>
    <p class="m-0">
      <strong>{{ title }}</strong>
    </p>
  </div>
  <fa-icon class="arrowIcon me-2" [icon]="faChevronRightIcon"></fa-icon>
</div>
