/* eslint-disable @typescript-eslint/no-empty-function */
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GeoLocation } from '@wilson/interfaces';
import { LocationsService } from '@wilson/locations';
import { Observable, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
} from 'rxjs/operators';
interface AutocompleteDataSourceItem {
  value: string;
  label: string;
}

@Component({
  selector: 'wilson-location-autocomplete',
  templateUrl: './location-autocomplete.component.html',
  styleUrls: ['./location-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocationAutocompleteComponent),
      multi: true,
    },
  ],
})
export class LocationAutocompleteComponent implements ControlValueAccessor {
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() invalid = false;
  @Input() warning = false;
  @Input() placeholder = 'general.addressPlaceholder';
  @Input() dropDownMinWidth = false;

  // GeoLocation object
  @Input() set selectedLocation(location: GeoLocation | null | undefined) {
    const loc = location ? location : null;
    if (loc?.id !== this._selectedLocation?.id) {
      this._isSelectedLocation = true;
    }
    this._selectedLocation = loc;
    if (loc && this._isSelectedLocation) {
      this._isSelectedLocation = false;
      this.dataSourceLocation = {
        value: loc.id as string,
        label: this.formatter(location),
      };
    }
    this.propagateChange(loc);
    this.locationChange.emit(loc);
  }

  get selectedLocation(): GeoLocation | null {
    return this._selectedLocation;
  }

  // AutocompleteDataSourceItem object for locally use
  set dataSourceLocation(location: AutocompleteDataSourceItem) {
    const loc = location ? location : null;
    this._dataSourceLocation = loc;
    if (loc && this._storedRailGeoLocations.length) {
      const storedLocation = this._storedRailGeoLocations.find(
        (storedRailGeoLocation) => storedRailGeoLocation.id === location.value,
      );
      if (!(this.selectedLocation?.id === storedLocation?.id)) {
        this.selectedLocation = storedLocation;
      }
    } else {
      this.selectedLocation = null;
    }
  }
  get dataSourceLocation(): AutocompleteDataSourceItem {
    return this._dataSourceLocation;
  }

  @Output() locationChange = new EventEmitter<GeoLocation | null>();
  @Output() touched = new EventEmitter<boolean>();

  // Public
  railGeoLocationDataSource$: Observable<AutocompleteDataSourceItem[]> = of([]);
  nzMinWidthConfig = { 'min-width': '240px' };
  // Private
  private _dataSourceLocation!: AutocompleteDataSourceItem;
  private _selectedLocation: GeoLocation | null = null;
  private _storedRailGeoLocations: GeoLocation[] = [];
  private _isSelectedLocation = true;

  constructor(private readonly locationsService: LocationsService) {}

  formatter = (result) => result.locationCode + ' - ' + result.name;

  public changeLocation(term: string) {
    this.railGeoLocationDataSource$ = of(term).pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((term) =>
        term.length < 3
          ? of([])
          : this.locationsService.getRailGeoLocationsByName(term).pipe(
              map((geoLocations) => {
                this._storedRailGeoLocations = geoLocations;
                geoLocations.sort((location) =>
                  location?.locationCode?.localeCompare(term),
                );
                const locationDataSource = [];
                geoLocations.forEach((railGeoLocation) =>
                  locationDataSource.push({
                    value: railGeoLocation.id,
                    label: this.formatter(railGeoLocation),
                  }),
                );
                return locationDataSource;
              }),
            ),
      ),
    );
  }

  setLocation(event) {
    this.dataSourceLocation = event ? event : null;
  }

  // eslint-disable-next-line
  propagateChange = (_: GeoLocation | null) => {}; // needed for form controle registration

  writeValue() {} // needed for form controle registration

  registerOnChange(fn: () => void) {
    // needed for form controle registration
    this.propagateChange = fn;
  }

  registerOnTouched() {} // needed for form controle registration

  public clearLocation() {
    if (!(typeof this.dataSourceLocation === 'object'))
      this.dataSourceLocation = null;
  }
}
