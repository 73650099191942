import { Base } from '@wilson/base';
import { MobileSettingsAbsenceCategory } from '../absences/absenceCategory';
import { OrganizationalUnit } from '../organizational-units/organizationalUnit';

export interface MobileSetting extends Base {
  allowSpacesBetweenActivities: boolean;
  requireRestPeriodBetweenShifts: boolean;
  checkBreakRegulations: boolean;
  allowAutomaticInterruptionActivity: boolean;
  shiftStartBeforePlannedInMinutes: number;
  allowAbsenceCreation: boolean;
  requireSickLeaveAttachment: boolean;
  organizationalUnit?: OrganizationalUnit;
  organizationalUnitId: OrganizationalUnit['id'];
  absenceCategoryIds: string[];
  mobileSettingAbsenceCategories?: MobileSettingsAbsenceCategory[];
  autoShiftReporting: boolean;
  minBreakTimeInMinutesViolation: MobileSettingMinBreakTimeInMinutesViolation | null;
  allowUsersToWithdrawShiftSubmissions: boolean;
  canDeclineShift: boolean | null;
  requireDeclineReasons: boolean | null;
  shiftDeclineReasons: string[];
  trackShiftLastSeen: boolean;
  canConfirmShift: boolean;
}

export enum MobileSettingMinBreakTimeInMinutesViolation {
  AllowedWithoutComment = 'allowed-without-comment',
  AllowedWithComment = 'allowed-with-comment',
  NotAllowed = 'not-allowed',
}
