import { Activity, OperationStatus, WilsonApp } from '@wilson/interfaces';

export function createFullActivityObject(
  partialActivity?: Partial<Activity>,
): Activity {
  return {
    activityCategoryId: null,
    createdFrom: WilsonApp.Portal,
    endDatetime: '',
    endLocationId: null,
    name: '',
    operationalStatus: OperationStatus.NotStarted,
    serviceId: null,
    shiftId: null,
    startDatetime: '',
    startLocationId: null,
    viaLocationIds: [],
    cargo: null,
    track: null,
    note: null,
    externalNumber: null,
    priority: null,
    travelDirection: null,
    tonnage: null,
    numberOfWagons: null,
    agreementId: null,
    jobId: null,
    vehicleEmissionClassId: null,
    professionId: null,
    organizationalUnitTransportGoodsId: null,
    ...partialActivity,
  };
}
