import { Injectable } from '@angular/core';
import { AnyAbility } from '@casl/ability';
import { AblePurePipe } from '@casl/angular';
import { MatomoTracker } from 'ngx-matomo-client';
import { TranslateService } from '@ngx-translate/core';
import { FeaturePurePipe, RolesPipe } from '@wilson/authorization';
import { FeatureFlagPurePipe } from '@wilson/feature-flags';
import {
  Activity,
  ActivityCategory,
  ActivityReport,
  OperationStatus,
  Shift,
  FeatureName,
  RoleAction,
  RolePermissionSubject,
} from '@wilson/interfaces';
import { EventCategory } from '@wilson/matomo';
import { ShiftUtilityService } from '@wilson/shifts';
import { isAfter } from 'date-fns';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { BehaviorSubject, combineLatest, first, map, tap } from 'rxjs';
import { ActivitiesGateway } from '@wilson/api/gateway';
import { ActivityReportsService } from '../activity-reports.service';
import {
  ActivityEditorComponent,
  ActivityEditorInput,
} from '../components/activity-edit-modal/activity-editor.component';
import { ActivityValidationService } from './activity-validation.service';
import { ActivityRefreshService } from './activity-refresh.service';

@Injectable()
export class ActivityEditService {
  activityCategories: ActivityCategory[];

  private readonly loadingActivityCategories = new BehaviorSubject(false);
  readonly loadingActivityCategories$ =
    this.loadingActivityCategories.asObservable();

  constructor(
    private readonly activityRefreshService: ActivityRefreshService,
    private readonly ablePurePipe: AblePurePipe<AnyAbility>,
    private readonly activitiesService: ActivitiesGateway,
    private readonly activityReportsService: ActivityReportsService,
    private readonly activityValidationService: ActivityValidationService,
    private readonly featureFlagPurePipe: FeatureFlagPurePipe,
    private readonly featurePurePipe: FeaturePurePipe,
    private readonly matomoTracker: MatomoTracker,
    private readonly nzModalService: NzModalService,
    private readonly rolesPipe: RolesPipe,
    private readonly shiftUtilityService: ShiftUtilityService,
    private readonly translateService: TranslateService,
  ) {
    this.loadActivityCategories();
  }

  canBeEdited(shift: Shift) {
    return combineLatest([
      this.ablePurePipe.transform(
        RoleAction.Update,
        RolePermissionSubject.Shift,
      ),
      this.featureFlagPurePipe.transform('portal-edit-activity-reports'),
      this.featurePurePipe.transform(FeatureName.WorkTimeManagement),
      this.rolesPipe.transform([
        'super-admin',
        'company-admin',
        'dispatcher-personnel',
      ]),
    ]).pipe(
      map((permissions) => {
        const hasPermissions = !permissions.includes(false);
        const isAccepted = this.shiftUtilityService.isAccepted(shift);
        const isAssigned = !!shift.userId;
        const isInFuture = isAfter(
          new Date(shift.activities[0].startDatetime),
          new Date(),
        );
        const isPublished = this.shiftUtilityService.isPublished(shift);

        return (
          hasPermissions &&
          isAssigned &&
          isPublished &&
          !isAccepted &&
          !isInFuture
        );
      }),
    );
  }

  private loadActivityCategories() {
    this.activitiesService
      .getEnabledActivityCategories()
      .pipe(
        first(),
        tap({
          subscribe: () => this.loadingActivityCategories.next(true),
          complete: () => this.loadingActivityCategories.next(false),
        }),
      )
      .subscribe((activityCategories) => {
        this.activityCategories = activityCategories;
      });
  }

  openEditModal(activity: Activity, shift: Shift) {
    const modal = this.nzModalService.create<
      ActivityEditorComponent,
      ActivityEditorInput
    >({
      nzContent: ActivityEditorComponent,
      nzData: { activity, shift },
      nzClosable: false,
      nzTitle: this.translateService.instant('activity.edit.title'),
      nzWidth: 640,
    });

    modal.updateConfig({
      nzFooter: [
        {
          label: this.translateService.instant('general.cancel'),
          type: 'default',
          onClick: () => modal.close(),
        },
        {
          autoLoading: true,
          type: 'primary',
          label: this.translateService.instant('general.save'),
          disabled: (componentInstance) =>
            this.saveDisabledFn(componentInstance),
          onClick: (componentInstance) =>
            this.onClickSaveFn(componentInstance, shift, modal),
        },
      ],
    });
  }

  private onClickSaveFn = async (
    {
      activity,
      startActivityReport,
      endActivityReport,
    }: ActivityEditorComponent,
    shift: Shift,
    modal: NzModalRef<ActivityEditorComponent>,
  ) => {
    await this.save(activity, startActivityReport, endActivityReport);

    modal.close();

    this.activityRefreshService.triggerRefresh();

    this.trackMatomoEvents(activity, shift);
  };

  private saveDisabledFn = (componentInstance: ActivityEditorComponent) => {
    const { isSkippedByUser, startActivityReport, endActivityReport } =
      componentInstance;

    return (
      !isSkippedByUser &&
      !this.activityValidationService.validateTimes(
        startActivityReport.dateTime,
        endActivityReport.dateTime,
      )
    );
  };

  private async save(
    {
      activityCategoryId,
      activityReports,
      id,
      operationalStatus,
      name,
    }: Activity,
    startActivityReport: ActivityReport,
    endActivityReport: ActivityReport,
  ) {
    const isSkippedByUser = operationalStatus === OperationStatus.SkippedByUser;

    await this.activitiesService.updateActivity({
      activityCategoryId,
      id,
      operationalStatus: isSkippedByUser
        ? OperationStatus.SkippedByUser
        : OperationStatus.Completed,
      name,
    });

    if (isSkippedByUser) {
      if (activityReports) {
        Promise.all(
          activityReports.map((activityReport) =>
            this.activityReportsService.delete(activityReport.id),
          ),
        );
      }
    } else {
      delete startActivityReport.location;
      delete endActivityReport.location;

      await this.activityReportsService.create(
        startActivityReport,
        endActivityReport,
      );
    }
  }

  private trackMatomoEvents(activity: Activity, shift: Shift) {
    this.matomoTracker.trackEvent(
      EventCategory.PortalShift,
      `edit-activity-of-${shift.state}-shift`,
    );
    this.matomoTracker.trackEvent(
      EventCategory.PortalShift,
      `edit-${activity.operationalStatus}-activity`,
    );
  }
}
