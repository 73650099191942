import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { Activity, ActivityReport } from '@wilson/interfaces';
import {
  formatISO,
  getDate,
  getHours,
  getMinutes,
  getMonth,
  getYear,
  set,
  startOfMinute,
} from 'date-fns';

@Component({
  selector: 'wilson-activity-edit-datetime',
  templateUrl: './activity-edit-datetime.component.html',
  styleUrls: ['./activity-edit-datetime.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityEditDatetimeComponent implements OnChanges {
  @Input() datetime:
    | Activity['startDatetime']
    | Activity['endDatetime']
    | ActivityReport['dateTime']
    | null;
  @Output() datetimeChange = new EventEmitter<
    | Activity['startDatetime']
    | Activity['endDatetime']
    | ActivityReport['dateTime']
    | null
  >();

  @Input() disabled = false;
  @Input() invalid = false;
  @Input() warning = false;

  date: Date | string | null;

  ngOnChanges() {
    this.date = this.datetime && startOfMinute(new Date(this.datetime));
  }

  onDateChange(date: Date | string) {
    const _date = new Date(date);

    this.datetime = date
      ? formatISO(
          set(new Date(this.datetime || date), {
            date: getDate(_date),
            month: getMonth(_date),
            year: getYear(_date),
            seconds: 0,
          }),
        )
      : null;

    this.datetimeChange.emit(this.datetime);
  }

  onTimeChange(date: Date | string) {
    const _date = new Date(date);

    this.datetime = date
      ? formatISO(
          set(new Date(this.datetime || date), {
            hours: getHours(_date),
            minutes: getMinutes(_date),
            seconds: 0,
          }),
        )
      : null;

    this.datetimeChange.emit(this.datetime);
  }
}
