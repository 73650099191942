export enum AbsenceType {
  AbsentWithoutReason = 'absent-without-reason',
  ReducedOverHours = 'reduced-over-hours',
  RestDay = 'rest-day',
  SickLeave = 'sick-leave',
  Vacation = 'vacation',
  Other = 'other',
  ExternalRestDay = 'external-rest-day',
  More = 'more',
  ParentalLeave = 'parental-leave',
  PublicHoliday = 'public-holiday',
  SickLeaveChild = 'sick-leave-child',
  SpecialVacation = 'special-vacation',
  BusinessTrip = 'business-trip',
  EducationVacation = 'education-vacation',
  EmploymentBan = 'employment-ban',
  MaternityLeave = 'maternity-leave',
  RemoteWork = 'remote-work',
  SickLeaveWithoutPay = 'sick-leave-without-pay',
  SickLeaveWorkInjury = 'sick-leave-work-injury',
  UnpaidVacation = 'unpaid-vacation',
  LeaveOfAbsence = 'leave-of-absence',
  Strike = 'strike',
  OutOfNightShift = 'out-of-night-shift',
  RequestedRestDay = 'requested-rest-day',
  CompensationalRestDay = 'compensational-rest-day',
  WorksCouncil = 'works-council',
  HealthRecovery = 'health-recovery',
  SickLeaveWithoutAU = 'sick-leave-without-au',
  Quarantine = 'quarantine',
  VocationalTraining = 'vocational-training',
  OnCall = 'on-call',
  NoServiceAssignment = 'no-service-assignment',
  EmployeeLeasingPause = 'employee-leasing-pause',
  Training = 'training',
  CompanyDoctor = 'company-doctor',
  OnSiteBriefing = 'on-site-briefing',
  RestDayOnCall = 'rest-day-on-call',
  CourtHearing = 'court-hearing',
  EmergencyService = 'emergency-service',
}

export const absenceTypes = Object.keys(AbsenceType);
