import { GeneralCompanySettings } from '@wilson/interfaces';

export class FetchGeneralCompanySettings {
  static readonly type =
    '[GeneralCompanySettings] Fetch General Company Settings';
}

export class UpdateGeneralGeneralCompanySettings {
  static readonly type =
    '[GeneralCompanySettings] Update General Company Settings';
  constructor(public generalCompanySettings: GeneralCompanySettings) {}
}

export class CreateGeneralGeneralCompanySettings {
  static readonly type =
    '[GeneralCompanySettings] Create General Company Settings';
  constructor(public generalCompanySettings: GeneralCompanySettings) {}
}
