export interface WeekDays {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

export enum WeekDaysEnum {
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
  Sunday = 'sunday',
}

export const returnSelectedWeekdaysAsStrings = (weekdays: WeekDays) => {
  const weekdaysArray: WeekDaysEnum[] = [];
  if (weekdays.monday) weekdaysArray.push(WeekDaysEnum.Monday);
  if (weekdays.tuesday) weekdaysArray.push(WeekDaysEnum.Tuesday);
  if (weekdays.wednesday) weekdaysArray.push(WeekDaysEnum.Wednesday);
  if (weekdays.thursday) weekdaysArray.push(WeekDaysEnum.Thursday);
  if (weekdays.friday) weekdaysArray.push(WeekDaysEnum.Friday);
  if (weekdays.saturday) weekdaysArray.push(WeekDaysEnum.Saturday);
  if (weekdays.sunday) weekdaysArray.push(WeekDaysEnum.Sunday);
  return weekdaysArray;
};

export const convertWeekDayStringsInWeekdayObject = (
  weekdaysArray: string[],
) => {
  const weekDays: WeekDays = {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  };
  if (weekdaysArray.find((w) => w === WeekDaysEnum.Monday))
    weekDays.monday = true;
  if (weekdaysArray.find((w) => w === WeekDaysEnum.Tuesday))
    weekDays.tuesday = true;
  if (weekdaysArray.find((w) => w === WeekDaysEnum.Wednesday))
    weekDays.wednesday = true;
  if (weekdaysArray.find((w) => w === WeekDaysEnum.Thursday))
    weekDays.thursday = true;
  if (weekdaysArray.find((w) => w === WeekDaysEnum.Friday))
    weekDays.friday = true;
  if (weekdaysArray.find((w) => w === WeekDaysEnum.Saturday))
    weekDays.saturday = true;
  if (weekdaysArray.find((w) => w === WeekDaysEnum.Sunday))
    weekDays.sunday = true;
  return weekDays;
};
