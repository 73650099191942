import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BackendService, UpdateResult } from '@wilson/base';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { UserTimePreference } from '@wilson/interfaces';
import { stringify } from 'qs';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserTimePreferencesService extends BackendService<UserTimePreference> {
  protected readonly path = 'user-time-preferences';

  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
  ) {
    super();
  }

  createUserTimePreference(userTimePreference: UserTimePreference) {
    return firstValueFrom(
      this.http.post<UserTimePreference[]>(`${this.config.host}/${this.path}`, {
        items: [userTimePreference],
      }),
    );
  }

  createUserTimePreferences(userTimePreferences: UserTimePreference[]) {
    return firstValueFrom(
      this.http.post<UserTimePreference[]>(`${this.config.host}/${this.path}`, {
        items: userTimePreferences,
      }),
    );
  }

  getUserTimePreferences(userId: string) {
    const params = stringify({ where: { userId } });
    return this.http.get<UserTimePreference[]>(
      `${this.config.host}/${this.path}?${params}`,
    );
  }

  updateUserTimePreference(
    id: string,
    userTimePreference: Partial<UserTimePreference>,
  ) {
    return firstValueFrom(
      this.http.patch<UpdateResult>(
        `${this.config.host}/${this.path}/${id}`,
        userTimePreference,
      ),
    );
  }

  deleteUserTimePreference(id: string) {
    return firstValueFrom(
      this.http.delete<UserTimePreference>(
        `${this.config.host}/${this.path}/${id}`,
      ),
    );
  }
}
