import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { firstValueFrom } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthState } from '@wilson/auth/core';
import { createEmbedQueryParamString } from '@wilson/utils';
import {
  OrganizationalUnitVechiclePreference,
  VehiclePreferences,
} from './user-vehicle-preferences-gateway.interface';

@Injectable({
  providedIn: 'root',
})
export class UserVehiclePreferencesGatewayService {
  protected readonly path = (id: string) => `users/${id}/vehicle-preferences`;

  constructor(
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
    protected readonly http: HttpClient,
    protected readonly store: Store,
  ) {}

  public add(
    entity: OrganizationalUnitVechiclePreference,
  ): Promise<OrganizationalUnitVechiclePreference> {
    const userId = this.store.selectSnapshot(AuthState.userId) as string;
    return firstValueFrom(
      this.http.post<OrganizationalUnitVechiclePreference>(
        `${this.config.host}/${this.path(userId)}`,
        {
          id: entity.id,
          organizationalUnitVehicleModelId:
            entity.organizationalUnitVehicleModelId,
        },
      ),
    );
  }

  public getAll(userId: string) {
    const queryParams = createEmbedQueryParamString([
      'organizationalUnitVehicleModel',
      'organizationalUnitVehicleModel.vehicleModel',
    ]);

    return this.http.get<VehiclePreferences[]>(
      `${this.config.host}/${this.path(userId)}?${queryParams}`,
    );
  }

  public remove(entityId: string) {
    const userId = this.store.selectSnapshot(AuthState.userId) as string;

    return firstValueFrom(
      this.http.delete(`${this.config.host}/${this.path(userId)}/${entityId}`),
    );
  }

  public removeAll() {
    const userId = this.store.selectSnapshot(AuthState.userId) as string;

    return firstValueFrom(
      this.http.delete(`${this.config.host}/${this.path(userId)}`),
    );
  }
}
