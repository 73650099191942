import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { QualificationName, ShiftValidationDetails } from '@wilson/interfaces';

@Component({
  selector: 'wilson-qualification-employee-tooltip',
  templateUrl: './qualification-employee-tooltip.component.html',
  styleUrls: ['./qualification-employee-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QualificationEmployeeTooltipComponent {
  @Input() header!: string;
  @Input() qualificationNames: QualificationName[] = [];
  @Input() shiftValidationDetails: ShiftValidationDetails[] = [];
  @Input() professionValidations: ShiftValidationDetails[] = [];
}
