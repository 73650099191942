import { Pipe, PipeTransform } from '@angular/core';
import { ShiftState } from '@wilson/interfaces';

@Pipe({
  name: 'isShiftSubmittedPipe',
})
export class IsShiftSubmittedPipe implements PipeTransform {
  transform(shiftState: ShiftState): boolean {
    return shiftState === ShiftState.Submitted;
  }
}
