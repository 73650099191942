import { activitiesCompareFn } from '@wilson/activities/util';
import { Activity, OperationStatus } from '@wilson/interfaces';
import isPast from 'date-fns/isPast';

export function shouldAtLeastOneActivityStarted(
  activities: Pick<Activity, 'startDatetime' | 'operationalStatus'>[],
): boolean {
  if (!activities?.length) {
    return false;
  } else {
    const clonedActivities: Pick<
      Activity,
      'startDatetime' | 'operationalStatus'
    >[] = [...activities];
    const sortedActivities = clonedActivities.sort(activitiesCompareFn);
    const firstActivityIsInPast = isPast(
      new Date(sortedActivities[0].startDatetime),
    );
    const allActivitiesAreNotStarted = sortedActivities.every(
      (activity) => activity.operationalStatus === OperationStatus.NotStarted,
    );
    return firstActivityIsInPast && allActivitiesAreNotStarted;
  }
}
