import { UserShiftTimePreference } from '@wilson/preferences/core';

export class SetUserShiftTimePreferenceAction {
  static readonly type =
    '[UserShiftTimePreference] Set User Shift Time Preference';
  constructor(
    public payload: {
      shiftTimePreferenceId: string;
    },
  ) {}
}

export class FetchUserShiftTimePreferenceAction {
  static readonly type =
    '[UserShiftTimePreference] Fetch User Shift Time Preference';
}

export class FetchSelectedShiftTimes {
  static readonly type = '[UserShiftTimePreference] Fetch selected shift';
}

export class SelectShiftTimes {
  static readonly type = '[UserShiftTimePreference] Select shift times';
  constructor(
    public payload: {
      shiftTime: UserShiftTimePreference;
    },
  ) {}
}

export class ResetShiftTimePreferenceAction {
  static readonly type =
    '[UserShiftTimePreference] Reset shift time preference';
}
