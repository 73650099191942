/* eslint-disable @nx/enforce-module-boundaries */
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ResolvedActivity } from '@wilson/interfaces';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { ServiceDetailState } from '@wilson/services/state';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { BulkEditDrawerState } from '@wilson/activities/states';

@Component({
  selector: 'wilson-activity-service-profession-renderer',
  template: `
    <div class="col-2 ps-0">
      <div class="d-flex align-items-center action-icons">
        <div
          *ngIf="
            cellValue && {
              isRecurring: isRecurring$ | async
            } as data
          "
          class="d-flex align-items-center action-icons actions-row"
          (click)="$event.stopPropagation()"
        >
          <wilson-profession-tag
            *ngIf="'portal-professions' | featureFlagPure | async"
            [isEditable]="(drawerIsOpen$ | async) === false"
            [activity]="cellValue"
            [isSeries]="data.isRecurring"
          ></wilson-profession-tag>
        </div>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityServiceProfessionRendererComponent
  implements ICellRendererAngularComp
{
  @Select(BulkEditDrawerState.isOpen)
  drawerIsOpen$!: Observable<boolean>;

  constructor(private readonly store: Store) {}

  public cellValue!: ResolvedActivity;
  public isRecurring$: Observable<boolean>;

  async agInit(params: ICellRendererParams) {
    this.isRecurring$ = this.store.select(ServiceDetailState.isRecurring);
    this.cellValue = params.data;
  }

  refresh(params: ICellRendererParams) {
    this.cellValue = params.data;
    return true;
  }
}
