import {
  ActivityReportCategory,
  ActivityReportType,
  GeoLocation,
  ResolvedActivityReport,
} from '@wilson/interfaces';
import { startOfMinute } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

export const activityReportFactory = (
  activityId: string,
  dateTime: string,
  location: GeoLocation,
  locationId: string,
  reportCategory: ActivityReportCategory,
  reportType: ActivityReportType,
): ResolvedActivityReport => ({
  activityId,
  createdAt: new Date().toISOString(),
  dateTime: dateTime && startOfMinute(new Date(dateTime)).toISOString(),
  id: uuidv4(),
  location,
  locationId,
  reportCategory,
  reportType,
  updatedAt: new Date().toISOString(),
});
