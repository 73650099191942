import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProfessionGateway } from '@wilson/api/gateway';
import { FeatureFlagPurePipe } from '@wilson/feature-flags';
import { Profession } from '@wilson/interfaces';
import { combineLatest, map, Observable, shareReplay } from 'rxjs';
import { professionIdsToFilter } from './professions-actually-assets';

@Injectable({
  providedIn: 'root',
})
export class ProfessionHelperService {
  constructor(
    private readonly translate: TranslateService,
    private readonly professionGateway: ProfessionGateway,
    private readonly featureFlagPurePipe: FeatureFlagPurePipe,
  ) {}

  getProfessions(): Observable<
    (Profession & { nameTranslationKey: string })[]
  > {
    const professionsActuallyAssetsFlag = this.featureFlagPurePipe.transform(
      'professions-actually-assets',
    );
    const professionsObservable = this.professionGateway.getAll();

    return combineLatest([
      professionsActuallyAssetsFlag,
      professionsObservable,
    ]).pipe(
      map(([professionsActuallyAssetsFlag, professions]) => {
        let professionsWithTranslation = professions as (Profession & {
          nameTranslationKey: string;
        })[];
        professionsWithTranslation.map(
          (profession) =>
            (profession.nameTranslationKey = 'profession.' + profession.id),
        );

        if (!professionsActuallyAssetsFlag) {
          professionsWithTranslation = professionsWithTranslation.filter(
            (profession) => !professionIdsToFilter.includes(profession.id),
          );
        }

        professionsWithTranslation.sort((a, b) =>
          this.translate
            .instant(a.nameTranslationKey)
            .localeCompare(this.translate.instant(b.nameTranslationKey)),
        );

        return professionsWithTranslation;
      }),
      shareReplay(1),
    );
  }

  compareProfessionFn = (
    selectedProfession: Profession,
    profession: Profession,
  ) =>
    selectedProfession && profession
      ? selectedProfession.id === profession.id
      : selectedProfession === profession;
}
