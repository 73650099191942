import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'wilson-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingSpinnerComponent {
  @Input() showSmallSpinner = false;
}
