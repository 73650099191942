<ng-container
  *ngIf="
    'custom-locations' | featureFlagPure | async;
    else existingStartLocations
  "
>
  <wilson-custom-locations-autocomplete
    [tabindexValue]="tabindexValue"
    [placeholder]="placeholder || 'customLocations.input.placeholder'"
    [invalid]="invalid"
    [warning]="warning"
    [disabled]="disabled"
    [readonly]="readonly"
    [selectedLocation]="location"
    [ignoreFeatureFlag]="ignoreFeatureFlag"
    (locationChange)="handleLocationSelect($event)"
    (touched)="touched.emit($event)"
  ></wilson-custom-locations-autocomplete>
</ng-container>
<ng-template #existingStartLocations>
  <wilson-location-autocomplete
    *ngIf="sector === 'rail'; else startAddressAutoComplete"
    [placeholder]="placeholder || 'general.addressPlaceholder'"
    [invalid]="invalid"
    [warning]="warning"
    [disabled]="disabled"
    [readonly]="readonly"
    [selectedLocation]="location"
    (locationChange)="handleLocationSelect($event)"
    (touched)="touched.emit($event)"
  >
  </wilson-location-autocomplete>
  <ng-template #startAddressAutoComplete>
    <wilson-address-autocomplete
      [placeholder]="placeholder || 'general.addressPlaceholder'"
      [invalid]="invalid"
      [warning]="warning"
      [selectedLocation]="location"
      [disabled]="disabled"
      [readonly]="readonly"
      (selectLocation)="handleLocationSelect($event)"
      (touched)="touched.emit($event)"
    ></wilson-address-autocomplete>
  </ng-template>
</ng-template>
