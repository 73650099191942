import { Injectable } from '@angular/core';
import { GeoLocation } from '@wilson/interfaces';
import { UserLocationPreference } from '@wilson/preferences/core';

@Injectable({
  providedIn: 'root',
})
export class UserLocationPreferencesStateHelperService {
  filterUnselected(
    selectedLocations: (UserLocationPreference & {
      geoLocation: GeoLocation;
    })[],
    selectedLocationPreferenceId: string,
  ) {
    const indexToDelete = selectedLocations.findIndex((selectedLocation) => {
      return selectedLocation.id === selectedLocationPreferenceId;
    });
    const removedItem = selectedLocations[indexToDelete];

    const result = [];
    for (let i = 0; i < selectedLocations.length; i++) {
      if (i !== indexToDelete) {
        result.push(selectedLocations[i]);
      }
    }

    return {
      filteredResult: result,
      removedItem,
    };
  }
}
