<div class="comment-container" [class.me]="isMe">
  <div *ngIf="!isMe" class="user-image">
    <wilson-user-avatar
      class="img"
      [s3Urn]="isAbleToLoadImage ? comment.sender.profileImageUrl : ''"
    ></wilson-user-avatar>
  </div>
  <div class="comment">
    <div class="comment-info">
      <span *ngIf="isMe">{{ 'general.you' | translate }}</span>
      <span *ngIf="!isMe"
        >{{ comment?.sender?.firstName }} {{ comment?.sender?.lastName }}</span
      >
      -
      <span>{{ comment?.createdAt | date : 'dd.MM.YYY' }}</span>
      -
      <span>{{ comment?.createdAt | date : 'HH:mm' }}</span>
    </div>
    <div class="comment-content">
      {{ comment.comment }}
    </div>
  </div>
</div>
