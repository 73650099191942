import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivitiesValueGetterService } from './activities-value-getter.service';
import { ActivityCategoryAndNameComponent } from './components/activity-category-and-name.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [ActivityCategoryAndNameComponent],
  providers: [ActivitiesValueGetterService],
  exports: [ActivityCategoryAndNameComponent],
})
export class ActivitiesAgGridModule {}
