import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import {
  Action,
  createSelector,
  NgxsAfterBootstrap,
  State,
  StateContext,
} from '@ngxs/store';
import { catchError, Observable, of, tap } from 'rxjs';
import { InitializeUserDataState } from './user-data.action';
import { UserData, UserDataService } from './user-data.service';

export const UserDataStateName = 'userData';

@State<UserData>({
  name: UserDataStateName,
  defaults: {
    organizationalUnits: {},
  },
})
@Injectable()
export class UserDataState implements NgxsAfterBootstrap {
  constructor(
    private readonly storage: Storage,
    private readonly userDataService: UserDataService,
  ) {}

  static organization(id: string) {
    return createSelector([UserDataState], (state: UserData) => {
      return state.organizationalUnits[id] || null;
    });
  }

  async ngxsAfterBootstrap(ctx: StateContext<UserData>) {
    const userData = await this.storage.get(UserDataStateName);
    if (userData) ctx.patchState(userData);
  }

  @Action(InitializeUserDataState)
  initializeUserDataState(
    ctx: StateContext<UserData>,
  ): Observable<UserData | null> {
    return this.userDataService.getUserData().pipe(
      tap((userData) => {
        if (!userData) return;
        ctx.patchState(userData);
        this.storage.set(UserDataStateName, userData);
      }),
      catchError(() => {
        return of(null);
      }),
    );
  }
}
