import { Injectable } from '@angular/core';
import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { append, patch } from '@ngxs/store/operators';
import { User } from '@wilson/interfaces';
import { catchError, filter, Observable, of, switchMap, take, tap } from 'rxjs';
import { AccountService } from '../account.service';
import {
  FetchAllUsers,
  FetchAndAddUserIfNotInUsersState,
  ResetUsersState,
} from './users-state.action';

export interface UsersStateModel {
  users: User[];
  loading: boolean;
}

const defaultUsersState: UsersStateModel = {
  users: [],
  loading: false,
};

@State<UsersStateModel>({
  name: 'UsersState',
  defaults: defaultUsersState,
})
@Injectable()
export class UsersState {
  constructor(
    private readonly accountService: AccountService,
    private readonly store: Store,
  ) {}

  @Selector()
  static isLoading(state: UsersStateModel) {
    return state.loading;
  }

  @Selector()
  static users(state: UsersStateModel): User[] {
    return state.users;
  }

  static userById(
    userId: string,
  ): (state: UsersStateModel) => User | undefined {
    return createSelector([UsersState], (state: UsersStateModel) => {
      return state.users.find((user) => user.id === userId);
    });
  }

  static userByIdExists(userId: string): (state: UsersStateModel) => boolean {
    return createSelector([UsersState], (state: UsersStateModel) => {
      return state.users.some((user) => user.id === userId);
    });
  }

  @Action(ResetUsersState)
  resetUsersState({ setState }: StateContext<UsersStateModel>) {
    setState(defaultUsersState);
  }

  @Action(FetchAllUsers)
  fetchAllUsers({ setState }: StateContext<UsersStateModel>) {
    setState(
      patch<UsersStateModel>({
        loading: true,
      }),
    );

    return this.accountService.getUsers().pipe(
      tap((users) => {
        setState(
          patch<UsersStateModel>({
            users,
            loading: false,
          }),
        );
      }),
    );
  }

  @Action(FetchAndAddUserIfNotInUsersState)
  fetchAndAddUserIfNotInUsersState(
    { setState, getState }: StateContext<UsersStateModel>,
    action: FetchAndAddUserIfNotInUsersState,
  ): Observable<User | undefined> {
    return this.store.select(UsersState.isLoading).pipe(
      filter((isLoading) => !isLoading),
      take(1),
      switchMap(() => {
        if (UsersState.userByIdExists(action.userId)(getState())) {
          const existingUser = UsersState.userById(action.userId)(getState());
          return of(existingUser);
        }

        setState(
          patch<UsersStateModel>({
            loading: true,
          }),
        );

        return this.accountService.getUser(action.userId).pipe(
          tap((user) => {
            setState(
              patch<UsersStateModel>({
                users: append<User>([user]),
                loading: false,
              }),
            );
          }),
          catchError(() => {
            setState(
              patch<UsersStateModel>({
                loading: false,
              }),
            );
            return of(undefined);
          }),
        );
      }),
    );
  }
}
