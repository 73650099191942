import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translateQualification',
})
export class TranslateQualificationPipe implements PipeTransform {
  constructor(private readonly translate: TranslateService) {}

  transform(value: { id: string; name: string }): string {
    const translationKey = `masterdata.qualification.${value.id}`;
    let translation = this.translate.instant(translationKey);

    if (translation === translationKey) {
      translation = value.name;
    }

    return translation;
  }
}
