import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

/**
 * session = stored for the session, should be cleared when user is logged out
 * persistent = global data independent of the current user
 */
export type StorageType = 'session' | 'persistent';

/**
 * storage wrapper with built-in differentiation between data for a session (e.g. related to logged-in user) and "global" values
 * Keep in mind that all data persists in the storage even when the app is closed
 */
@Injectable({
  providedIn: 'root',
})
export class StorageService {
  static prefixes = {
    session: String.fromCharCode(17), // using ascii control character to prevent overlap with manual prefixing
  };

  /** set or retrieve a session variable */
  public async session<T>(key: string, value?: T) {
    return arguments.length === 2
      ? this.set<T>(key, value, 'session')
      : this.get<T>(key, 'session');
  }

  /** set or retrieve a value which persists between normal clears / sessions */
  public async persistent<T>(key: string, value?: T) {
    return arguments.length === 2
      ? this.set<T>(key, value, 'persistent')
      : this.get<T>(key, 'persistent');
  }

  public async get<T>(key: string, type: StorageType = 'session'): Promise<T> {
    if (type === 'session') key = StorageService.prefixes.session + key;

    const res = await Preferences.get({ key });
    return JSON.parse(res.value) as T;
  }

  public async set<T>(
    key: string,
    value: T,
    type: StorageType = 'session',
  ): Promise<T> {
    if (type === 'session') key = StorageService.prefixes.session + key;

    await Preferences.set({
      key,
      value: JSON.stringify(value),
    });

    return value;
  }

  public async keys(type: StorageType = 'session'): Promise<string[]> {
    const all = await Preferences.keys();

    if (type === 'session') {
      return all.keys
        .filter((key) => key[0] === StorageService.prefixes.session)
        .map((k) => k.substring(1));
    }

    return all.keys.filter((key) => key[0] !== StorageService.prefixes.session);
  }

  public async remove(key: string, type: StorageType = 'session') {
    if (type === 'session') key = StorageService.prefixes.session + key;
    return Preferences.remove({ key });
  }

  /** clears session values */
  public async clearSession() {
    const promises = [];
    for (const key of await this.keys()) {
      promises.push(Preferences.remove({ key }));
    }
    return await Promise.all(promises);
  }

  /** clears all data, including persistent values */
  public async wipe() {
    return Preferences.clear();
  }
}
