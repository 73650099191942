import { InjectionToken } from '@angular/core';
import {
  AccurateDateIntervalWithAbsence,
  AccurateDateIntervalWithShift,
  AccurateDateIntervalWithStay,
  UserTimelines,
} from '../timeline/shift-timeline-custom-types';

export const DetermineAssignmentsOverlapWebWorker =
  new InjectionToken<DetermineAssignmentsOverlapWorkerWrapper>(
    'DetermineAssignmentsOverlapWebWorker',
  );

export interface DetermineAssignmentsOverlapWorkerWrapper {
  execute: (
    data: Pick<
      UserTimelines,
      | 'shiftsWithoutActivitiesDictionary'
      | 'absencesDictionary'
      | 'staysDictionary'
      | 'id'
    >[],
  ) => Promise<
    {
      userId: string;
      rows: {
        shifts: AccurateDateIntervalWithShift[];
        absences: AccurateDateIntervalWithAbsence[];
        stays: AccurateDateIntervalWithStay[];
      }[];
      resultTimestamp: number;
    }[]
  >;
}
