import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import {
  Action,
  NgxsAfterBootstrap,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { AuthState, AuthStateModel } from '@wilson/auth/core';
import { CompanySettingsService } from '@wilson/company-settings/core';
import { MobileSetting } from '@wilson/interfaces';
import {
  BehaviorSubject,
  Observable,
  catchError,
  finalize,
  of,
  tap,
} from 'rxjs';
import { InitializeMobileCompanySettingsState } from './mobile-company-settings.action';

export const MOBILE_COMPANY_SETTINGS_NAME = 'mobileCompanySettings';

@State<MobileSetting>({
  name: MOBILE_COMPANY_SETTINGS_NAME,
  defaults: {
    absenceCategoryIds: [],
    allowAbsenceCreation: false,
    allowAutomaticInterruptionActivity: false,
    allowSpacesBetweenActivities: false,
    autoShiftReporting: false,
    checkBreakRegulations: false,
    mobileSettingAbsenceCategories: [],
    organizationalUnitId: '',
    requireRestPeriodBetweenShifts: false,
    requireSickLeaveAttachment: false,
    shiftStartBeforePlannedInMinutes: 0,
    minBreakTimeInMinutesViolation: null,
    allowUsersToWithdrawShiftSubmissions: false,
    canDeclineShift: null,
    requireDeclineReasons: null,
    shiftDeclineReasons: [],
    trackShiftLastSeen: false,
    canConfirmShift: false,
  },
})
@Injectable()
export class MobileCompanySettingsState implements NgxsAfterBootstrap {
  public syncingMobileSetting$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly companySettingsService: CompanySettingsService,
    private readonly storage: Storage,
    private readonly store: Store,
  ) {}

  @Selector()
  static mobileCompanySettingsState(state: MobileSetting) {
    return state;
  }

  @Selector()
  static allowAbsenceCreation(state: MobileSetting) {
    return state.allowAbsenceCreation;
  }
  @Selector()
  static allowUsersToWithdrawShiftSubmissions(state: MobileSetting) {
    return state.allowUsersToWithdrawShiftSubmissions;
  }

  @Selector()
  static minBreakTimeInMinutesViolation(state: MobileSetting) {
    return state.minBreakTimeInMinutesViolation;
  }

  @Selector()
  static declineReasons(state: MobileSetting): string[] {
    return state.shiftDeclineReasons;
  }

  @Selector()
  static trackShiftLastSeen(state: MobileSetting) {
    return state.trackShiftLastSeen;
  }

  @Selector()
  static canConfirmShift(state: MobileSetting) {
    return state.canConfirmShift;
  }

  async ngxsAfterBootstrap(
    context: StateContext<MobileSetting>,
  ): Promise<void> {
    const mobileSetting = await this.storage.get(MOBILE_COMPANY_SETTINGS_NAME);
    if (mobileSetting) context.patchState(mobileSetting);
  }

  @Action(InitializeMobileCompanySettingsState)
  initializeMobileCompanySettingsState(
    context: StateContext<MobileSetting>,
  ): Observable<MobileSetting[] | null> {
    const user = this.store.selectSnapshot<AuthStateModel['user']>(
      AuthState.user,
    );

    if (!user) return of(null);

    this.syncingMobileSetting$.next(true);

    return this.companySettingsService
      .getMobileSettings({
        relations: ['mobileSettingAbsenceCategories'],
      })
      .pipe(
        tap(([mobileSetting]) => {
          context.patchState(mobileSetting);
          this.storage.set(MOBILE_COMPANY_SETTINGS_NAME, mobileSetting);
        }),
        finalize(() => {
          this.syncingMobileSetting$.next(false);
        }),
        catchError(() => {
          return of(null);
        }),
      );
  }
}
