export enum EventCategory {
  MobileAbsence = 'mobile-absence',
  MobileActivityReporting = 'mobile-activity-reporting',
  MobileActivity = 'mobile-activity',
  MobileHome = 'home',
  MobilePreferences = 'preferences',
  MobileProfile = 'mobile-profile',
  MobileQualifications = 'mobile-qualifications',
  MobileSelfDispatching = 'mobile-self-dispatching',
  MobileShiftReporting = 'mobile-shift-reporting',
  MobileShift = 'mobile-shift',
  PortalShift = 'portal-shift',
  Shift = 'shift',
  Service = 'service',
  WilsonShare = 'wilson-share',
  Invoicing = 'invoicing',
}
