import { CreateShiftTimePreferenceSettingDto } from '@wilson/preferences/core';

export class AddShiftTimePreferenceSettingAction {
  static readonly type =
    '[ShiftTimePreferenceSetting] Add Shift Time Preference Setting';
  constructor(
    public payload: {
      data: CreateShiftTimePreferenceSettingDto;
    },
  ) {}
}

export class DeleteShiftTimePreferenceSettingAction {
  static readonly type =
    '[ShiftTimePreferenceSetting] Delete Shift Time Preference Setting';
  constructor(
    public payload: {
      id: string;
    },
  ) {}
}

export class FetchShiftTimePreferenceSettingAction {
  static readonly type =
    '[ShiftTimePreferenceSetting] Fetch Shift Time Preference Setting';
}
