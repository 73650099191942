<div class="p-4">
  <div
    class="d-flex flex-column justify-content-between assign-activity-header"
  >
    <div class="d-flex mb-2">
      <div class="ml-3 fw-bold text-dark">
        {{ 'profession.change_modal.title' | translate }}
      </div>
    </div>
    <div class="mt-0 mb-3">
      {{ 'profession.change_modal.description' | translate }}
    </div>
    <div class="mt-0 mb-3">
      <nz-form-item class="mb-0">
        <nz-form-control>
          <nz-select
            [nzPlaceHolder]="'general.select.placeholder' | translate"
            nzShowSearch
            nzAllowClear
            [nzShowArrow]="true"
            [(ngModel)]="profession"
            [compareWith]="professionHelperService.compareProfessionFn"
          >
            <nz-option
              *ngFor="let profession of professions$ | async"
              [nzValue]="profession"
              [nzLabel]="'profession.' + profession.id | translate"
            >
            </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
  <div class="d-flex justify-content-end">
    <div class="d-flex justify-content-between mt-2">
      <button
        type="button"
        class="btn btn-dark p-1 me-2 fw-bold"
        (click)="cancel()"
      >
        {{ 'general.cancel' | translate }}
      </button>
      <button
        [disabled]="submitting"
        type="button"
        class="btn btn-primary p-1 fw-bold"
        (click)="saveProfession()"
      >
        {{ (submitting ? 'general.saving' : 'general.save') | translate }}
      </button>
    </div>
  </div>
</div>
