import { isAfter, isBefore, isEqual } from 'date-fns';
import { AccurateDateInterval, DateInterval } from '@wilson/interfaces';

interface ItemWithAccurateDateInterval {
  id: string;
  accurateStartDateTime: Date;
  accurateEndDateTime: Date;
}

export function sortIntervalsByAccurateStartTimeAndLength(
  itemA: ItemWithAccurateDateInterval,
  itemB: ItemWithAccurateDateInterval,
) {
  const intervalSortResult = sortIntervalByAccurateStartTime(itemA, itemB);
  return intervalSortResult === 0
    ? itemA.id.localeCompare(itemB.id)
    : intervalSortResult;
}

export function sortIntervalByAccurateStartTime(
  itemA: AccurateDateInterval,
  itemB: AccurateDateInterval,
): 1 | 0 | -1 {
  if (isBefore(itemA.accurateStartDateTime, itemB.accurateStartDateTime)) {
    return -1;
  }
  if (isAfter(itemA.accurateStartDateTime, itemB.accurateStartDateTime)) {
    return 1;
  }

  if (isEqual(itemA.accurateStartDateTime, itemB.accurateStartDateTime)) {
    if (isBefore(itemA.accurateEndDateTime, itemB.accurateEndDateTime)) {
      return 1;
    }
    if (isAfter(itemA.accurateEndDateTime, itemB.accurateEndDateTime)) {
      return -1;
    }
  }

  return 0;
}

export function sortIntervalByStartTime(
  itemA: DateInterval,
  itemB: DateInterval,
): 1 | 0 | -1 {
  if (isBefore(itemA.startDatetime, itemB.startDatetime)) {
    return -1;
  }
  if (isAfter(itemA.startDatetime, itemB.startDatetime)) {
    return 1;
  }

  if (isEqual(itemA.startDatetime, itemB.startDatetime)) {
    if (isBefore(itemA.endDatetime, itemB.endDatetime)) {
      return 1;
    }
    if (isAfter(itemA.endDatetime, itemB.endDatetime)) {
      return -1;
    }
  }

  return 0;
}
