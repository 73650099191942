import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, map, Observable, Subscription } from 'rxjs';
import { CalendarService } from './calendar.service';
import { withLatestFrom } from 'rxjs/operators';
import { addDays, eachDayOfInterval } from 'date-fns';
import { DayTasks } from '@wilson/interfaces';

type TaskMap = Record<string, DayTasks>;

@Injectable()
export class CalendarTasksService implements OnDestroy {
  private taskStoreSubject = new BehaviorSubject<TaskMap>({});

  private taskMap$ = this.calendarService.dayTasksStream$.pipe(
    map((newDayTasks) => {
      const newTaskMap: TaskMap = {};
      newDayTasks.forEach((task) => {
        newTaskMap[this.toDateKey(task.date)] = task;
      });
      return newTaskMap;
    }),
    withLatestFrom(this.taskStoreSubject),
    map(([newTasks, currentTasks]: [TaskMap, TaskMap]) => {
      const clonedCurrentTasks = {
        ...currentTasks,
      };

      // Update old task map new task map, this process will also remove outdated data in range.
      for (const [newTaskDayKey, newTask] of Object.entries(newTasks)) {
        clonedCurrentTasks[newTaskDayKey] = newTask;
      }

      return clonedCurrentTasks;
    }),
  );

  private taskMapSubscription: Subscription;

  constructor(private calendarService: CalendarService) {
    this.taskMapSubscription = this.taskMap$.subscribe((newTasks) => {
      this.taskStoreSubject.next(newTasks);
    });
  }

  getTask(date: Date): Observable<DayTasks> {
    return this.taskStoreSubject.pipe(
      map((tasks) => this.lookForTask(tasks, date)),
    );
  }

  getTasks(startDate: Date, offset: number): Observable<DayTasks[]> {
    const futureDate = addDays(startDate, offset);
    const days = eachDayOfInterval({
      start: startDate,
      end: futureDate,
    });

    return this.taskStoreSubject.pipe(
      map((tasks) => days.map((day) => this.lookForTask(tasks, day))),
    );
  }

  resetStore() {
    this.taskStoreSubject.next({});
  }

  ngOnDestroy(): void {
    this.taskMapSubscription.unsubscribe();
  }

  private toDateKey(date: Date) {
    return `${date?.getUTCDate()}-${date?.getUTCMonth()}-${date?.getUTCFullYear()}`;
  }

  private lookForTask(tasks: TaskMap, date: Date) {
    const existingTaskInMap = tasks[this.toDateKey(date)];

    if (existingTaskInMap) {
      return existingTaskInMap;
    } else {
      return this.calendarService.createDayTaskFor(date);
    }
  }
}
