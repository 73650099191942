import { Pipe, PipeTransform } from '@angular/core';
import {
  AffectedStop,
  ServiceDeviation,
} from '@wilson/service-deviation/interfaces';
import { addMinutes } from 'date-fns';

export interface DeviationTime {
  timeWithDeviation: string;
  deviationInMinutes?: number;
}

@Pipe({
  name: 'deviationTime',
})
export class DeviationTimePipe implements PipeTransform {
  transform(
    serviceDeviations: ServiceDeviation[],
    affectedStop: AffectedStop,
    plannedDate: string,
  ): DeviationTime {
    if (!affectedStop || !serviceDeviations || serviceDeviations.length === 0) {
      return {
        timeWithDeviation: '',
        deviationInMinutes: 0,
      };
    }

    const deviation = serviceDeviations.find(
      (dev) => dev.affectedStop === affectedStop,
    );

    if (deviation?.deviationInMinutes) {
      const date = new Date(plannedDate);
      const timeWithDeviation = addMinutes(
        date,
        deviation.deviationInMinutes,
      ).toString();

      return {
        timeWithDeviation,
        deviationInMinutes: deviation.deviationInMinutes,
      };
    }

    return {
      timeWithDeviation: '',
      deviationInMinutes: 0,
    };
  }
}
