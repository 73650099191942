import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { BackendLabel } from '@wilson/interfaces';

export type LabelCreatePayload = Pick<BackendLabel, 'name' | 'colorCode'> &
  Partial<Pick<BackendLabel, 'description'>>;
export type LabelUpdatePayload = Pick<BackendLabel, 'id'> &
  Partial<Pick<BackendLabel, 'name' | 'colorCode' | 'description'>>;

@Injectable({
  providedIn: 'root',
})
export class LabelsGateway {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
  ) {}

  getLabels() {
    return this.httpClient.get<BackendLabel[]>(`${this.config.host}/labels`);
  }

  createLabel(createLabelPayload: LabelCreatePayload) {
    return this.httpClient.post<BackendLabel>(
      `${this.config.host}/labels`,
      createLabelPayload,
    );
  }

  updateLabel(updateLabelPayload: LabelUpdatePayload) {
    return this.httpClient.patch<BackendLabel>(
      `${this.config.host}/labels/${updateLabelPayload.id}`,
      updateLabelPayload,
    );
  }

  removeLabels(removeLabelPayload: string[]) {
    const params = removeLabelPayload.map((id) => `ids[]=${id}`).join('&');
    return this.httpClient.delete<{ count: string }>(
      `${this.config.host}/labels?${params}`,
    );
  }
}
