import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-pref-score-renderer',
  styleUrls: ['./renderers.scss'],
  template: `
    <div class="d-flex justify-content-between align-items-center">
      <div class="text-dark ">{{ cellValue?.score }}</div>
      <div class="score-bar">
        <div [ngStyle]="styleObject" class="display-score"></div>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrefScoreRendererComponent implements AgRendererComponent {
  maximumValue = 5;
  styleObject = {};
  public cellValue: {
    score: number;
  };

  agInit(params: ICellRendererParams): void {
    this.cellValue = this.getValueToDisplay(params);
  }

  refresh(params: ICellRendererParams) {
    this.cellValue = this.getValueToDisplay(params);
    return true;
  }

  getColorByValue(value: number) {
    if (value < 2.5) return 'var(--red-5)';
    if (value < 3.5) return 'var(--gold-4)';
    else return 'var(--primary)';
  }
  getWithOfBar(value: number) {
    return (100 / this.maximumValue) * value + '%';
  }

  createStyleObject(score: number) {
    const width = this.getWithOfBar(score);
    const backgroundColor = this.getColorByValue(score);
    this.styleObject = { width: width, background: backgroundColor };
  }

  getValueToDisplay(params: ICellRendererParams) {
    const scoreObj = params?.data?.scores.filter(
      (score) => score.name === 'shift-preference-score',
    );
    const score =
      scoreObj.length && scoreObj[0].score
        ? Number(scoreObj[0].score.toFixed(1))
        : 0;
    this.createStyleObject(score);
    return { score };
  }
}
