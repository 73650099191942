import {
  Absence,
  AccurateDateInterval,
  AccurateDateIntervalWithAbsence,
  AccurateDateIntervalWithShift,
  AccurateDateIntervalWithStay,
  AccurateTimeDetails,
  ActivityDeviationStatus,
  Shift,
  Stay,
  UnassignedShift,
  WithPreparedAttributes,
} from '@wilson/interfaces';
import {
  areIntervalsOverlapping,
  differenceInMinutes,
  isBefore,
} from 'date-fns';

export function determineAssignmentsOverlaps({
  shifts,
  absences,
  stays,
  isDebugEnabled,
}: {
  shifts: (
    | (Shift &
        WithPreparedAttributes & {
          id: string;
        })
    | UnassignedShift
  )[];
  absences: (Absence & { id: string })[];
  stays: Stay[];
  isDebugEnabled: boolean;
}): {
  shifts: AccurateDateIntervalWithShift[];
  absences: AccurateDateIntervalWithAbsence[];
  stays: AccurateDateIntervalWithStay[];
}[] {
  const items = {
    shifts: shifts.map((shift) => getShiftInterval(shift, isDebugEnabled)),
    absences: absences.map(getAbsenceInterval),
    stays: stays.map(getStaysInterval),
  };
  return constructRows(items, isDebugEnabled) as {
    shifts: AccurateDateIntervalWithShift[];
    absences: AccurateDateIntervalWithAbsence[];
    stays: AccurateDateIntervalWithStay[];
  }[];
}

export function constructRows(
  items: Record<string, AccurateDateInterval[]>,
  isDebugEnabled: boolean,
): Record<string, AccurateDateInterval[]>[] {
  const rows = [] as Record<string, AccurateDateInterval[]>[];
  Object.entries(items).forEach(([outerKey, accurateDateIntervalItems]) => {
    accurateDateIntervalItems.forEach((accurateDateIntervalItem) => {
      const newRow: Record<string, AccurateDateInterval[]> = {};
      Object.keys(items).forEach((innerKey) => {
        newRow[innerKey] =
          outerKey === innerKey ? [accurateDateIntervalItem] : [];
      });

      if (rows.length === 0) {
        rows.push(newRow);
      } else {
        const rowIndex = getItemRowIndex(
          rows,
          accurateDateIntervalItem,
          isDebugEnabled,
        );
        if (rows[rowIndex]) {
          rows[rowIndex][outerKey].push(accurateDateIntervalItem);
        } else {
          rows[rowIndex] = newRow;
        }
      }
    });
  });

  return rows;
}

export function getStaysInterval<T extends Stay>(
  stay: T,
): AccurateDateInterval & { stay: T } {
  return {
    accurateStartDateTime: createDateTimeWithoutSeconds(stay.startDatetime),
    accurateEndDateTime: createDateTimeWithoutSeconds(stay.endDatetime),
    stay,
  };
}

export function getAbsenceInterval<T extends Absence & { id: string }>(
  absence: T,
): AccurateDateInterval & { absence: T } {
  return {
    accurateStartDateTime: createDateTimeWithoutSeconds(absence.absentFrom),
    accurateEndDateTime: createDateTimeWithoutSeconds(absence.absentTo),
    absence,
  };
}

export function createDateTimeWithoutSeconds(time: string) {
  const date = new Date(time);
  date.setSeconds(0);
  return date;
}

export function getShiftInterval<
  T extends
    | (Shift &
        WithPreparedAttributes & {
          id: string;
        })
    | UnassignedShift,
>(shift: T, isDebugEnabled: boolean): AccurateDateInterval & { shift: T } {
  const { startDatetime, endDatetime } = determineShiftRenderDatetime(
    shift,
    isDebugEnabled,
  );

  return {
    accurateStartDateTime: createDateTimeWithoutSeconds(startDatetime.date),
    accurateEndDateTime: createDateTimeWithoutSeconds(endDatetime.date),
    shift,
  };
}

export function determineShiftRenderDatetime(
  shift:
    | (Shift &
        WithPreparedAttributes & {
          id: string;
        })
    | UnassignedShift,
  isDebugEnabled: boolean,
): {
  startDatetime: AccurateTimeDetails;
  endDatetime: AccurateTimeDetails;
} {
  const startDatetime = getStartDatetime(shift);
  const endDatetime = getEndDatetime(shift);

  if (isBefore(new Date(startDatetime.date), new Date(endDatetime.date))) {
    return {
      startDatetime,
      endDatetime,
    };
  } else {
    if (isDebugEnabled) {
      console.info(
        `Bad time interval, start datetime: ${startDatetime.date} is behind end datetime: ${endDatetime.date}. Using planned time instead.`,
      );
    }
    return {
      startDatetime: getPlannedStartDatetime(shift),
      endDatetime: getPlannedEndDatetime(shift),
    };
  }
}

export function getItemRowIndex(
  rows: Record<string, AccurateDateInterval[]>[],
  item: AccurateDateInterval,
  isDebugEnabled: boolean,
): number {
  let itemRowIndex = 0;

  rows.forEach((row, index) => {
    if (itemRowIndex === index) {
      try {
        const isOverlappingWithExistingItemsInRow = Object.keys(row).some(
          (itemKey) => {
            const itemsOfThisTypeInRow = row[itemKey];

            return isOverlappingWithOneItem(itemsOfThisTypeInRow, item);
          },
        );

        if (isOverlappingWithExistingItemsInRow) {
          itemRowIndex = index + 1;
        } else {
          itemRowIndex = index;
        }
      } catch (e) {
        if (isDebugEnabled) {
          console.warn(e);
          console.warn(item);
        }
      }
    }
  });

  return itemRowIndex;
}

export function getStartDatetime({
  startDatetime,
  deviatedStartDatetime,
  reportedStartDatetime,
  reportedStartLocation,
  startLocation,
}: Pick<
  WithPreparedAttributes | UnassignedShift,
  | 'startDatetime'
  | 'deviatedStartDatetime'
  | 'reportedStartDatetime'
  | 'reportedStartLocation'
  | 'startLocation'
>): AccurateTimeDetails {
  if (reportedStartDatetime) {
    const timeDifference = differenceInMinutes(
      new Date(reportedStartDatetime),
      new Date(startDatetime),
    );
    return {
      date: reportedStartDatetime,
      location: reportedStartLocation || startLocation,
      type: ActivityDeviationStatus.Reported,
      timeDifference: timeDifference,
    };
  } else if (deviatedStartDatetime) {
    const timeDifference = differenceInMinutes(
      new Date(deviatedStartDatetime),
      new Date(startDatetime),
    );
    return {
      date: deviatedStartDatetime,
      location: startLocation,
      type: ActivityDeviationStatus.Deviated,
      timeDifference: timeDifference,
    };
  } else {
    return getPlannedStartDatetime({
      startDatetime,
      startLocation,
    });
  }
}

export function getPlannedStartDatetime({
  startDatetime,
  startLocation,
}: Pick<
  WithPreparedAttributes | UnassignedShift,
  'startDatetime' | 'startLocation'
>) {
  return {
    date: startDatetime,
    location: startLocation,
    type: ActivityDeviationStatus.Planned,
    timeDifference: 0,
  };
}

export function isOverlappingWithOneItem(
  items: AccurateDateInterval[],
  itemToCompareWith: AccurateDateInterval,
) {
  return (
    items.findIndex((item) => isOverlapDetected(item, itemToCompareWith)) > -1
  );
}

export function isOverlapDetected(
  itemInterval: AccurateDateInterval,
  determinedItemInterval: AccurateDateInterval,
) {
  return areIntervalsOverlapping(
    {
      start: itemInterval.accurateStartDateTime,
      end: itemInterval.accurateEndDateTime,
    },
    {
      start: determinedItemInterval.accurateStartDateTime,
      end: determinedItemInterval.accurateEndDateTime,
    },
  );
}

export function getPlannedEndDatetime({
  endDatetime,
  endLocation,
}: Pick<
  WithPreparedAttributes | UnassignedShift,
  'endDatetime' | 'endLocation'
>) {
  return {
    date: endDatetime,
    location: endLocation,
    type: ActivityDeviationStatus.Planned,
    timeDifference: 0,
  };
}

export function getEndDatetime({
  endDatetime,
  deviatedEndDatetime,
  reportedEndDatetime,
  reportedEndLocation,
  endLocation,
}: Pick<
  WithPreparedAttributes | UnassignedShift,
  | 'endDatetime'
  | 'deviatedEndDatetime'
  | 'reportedEndDatetime'
  | 'reportedEndLocation'
  | 'endLocation'
>): AccurateTimeDetails {
  if (reportedEndDatetime) {
    const timeDifference = differenceInMinutes(
      new Date(reportedEndDatetime),
      new Date(endDatetime),
    );
    return {
      date: reportedEndDatetime,
      location: reportedEndLocation || endLocation,
      type: ActivityDeviationStatus.Reported,
      timeDifference: timeDifference,
    };
  } else if (deviatedEndDatetime) {
    const timeDifference = differenceInMinutes(
      new Date(deviatedEndDatetime),
      new Date(endDatetime),
    );
    return {
      date: deviatedEndDatetime,
      location: endLocation,
      type: ActivityDeviationStatus.Deviated,
      timeDifference: timeDifference,
    };
  } else {
    return getPlannedEndDatetime({
      endDatetime,
      endLocation,
    });
  }
}
