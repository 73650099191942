import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { DeleteResult } from '@wilson/base';
import { NotificationsService } from '@wilson/notifications';
import { Observable, of } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { DeviceRegistration, DeviceRemoval } from './device.action';

@State<string>({
  name: 'device',
  defaults: '',
})
@Injectable()
export class DeviceState {
  constructor(private notificationsService: NotificationsService) {}

  @Action(DeviceRegistration)
  deviceregistration(ctx: StateContext<string>, data: { deviceId: string }) {
    const deviceId = data.deviceId;
    ctx.setState(deviceId);
    return of(null);
  }

  @Action(DeviceRemoval)
  deviceRemoval(ctx: StateContext<string>): Observable<DeleteResult> {
    const deviceId = ctx.getState();
    ctx.setState('');
    return this.notificationsService.removeDeviceToken(deviceId).pipe(
      take(1),
      catchError(() => of(null)),
    );
  }
}
