import { Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { ActivityCategory } from '@wilson/interfaces';

@Pipe({
  name: 'translateActivityCategory',
})
export class TranslateActivityCategoryPipe implements PipeTransform {
  constructor(private readonly translatePipe: TranslatePipe) {}

  transform(
    value:
      | string
      | { activityCategoryId: string }
      | { id: string }
      | ActivityCategory,
  ): string {
    let translation;

    if (typeof value === 'string') {
      translation = this.translatePipe.transform(
        `masterdata.activity.categories.${value}`,
      );
    } else if (value && 'activityCategoryId' in value) {
      translation = this.translatePipe.transform(
        `masterdata.activity.categories.${value.activityCategoryId}`,
      );
    } else if (value && 'id' in value) {
      translation = this.translatePipe.transform(
        `masterdata.activity.categories.${value.id}`,
      );
    }

    return translation;
  }
}
