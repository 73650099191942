import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { firstValueFrom } from 'rxjs/internal/firstValueFrom';
import { SharedUserApprovalStatus } from './client-partnership.gateway';

export type SharedUsersWithYouPayload = Partial<
  Pick<ExternalUser, 'clientNotes' | 'approvalStatus' | 'professionIds'>
>;
export interface ExternalUser {
  id: string;
  userId: string;
  clientPartnershipId: string;
  approvedAt: string;
  sharedAt: string;
  approvalStatus: SharedUserApprovalStatus;
  professionIds: string[];
  clientNotes: string;
  user: {
    firstName: string;
    lastName: string;
  };
}

@Injectable()
export class SharedUsersWithYouService {
  protected readonly path = 'shared-users';

  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
  ) {}

  updateUser(user: Pick<ExternalUser, 'id'>, data: SharedUsersWithYouPayload) {
    return firstValueFrom(
      this.http.patch(`${this.config.host}/${this.path}/${user.id}`, data),
    );
  }
}
