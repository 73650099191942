import { Injectable } from '@angular/core';
import { activitiesCompareFn } from '@wilson/activities/util';
import { Activity, ActivityReport, ExpectedTimes } from '@wilson/interfaces';
import { add, differenceInMinutes } from 'date-fns';
import { ReportUtilityService } from '../../report-utilities/services/report-utility.service';

type ActivityWithReports = Activity & { activityReports: ActivityReport[] };

@Injectable()
export class ExpectedTimesService {
  get EMPTY() {
    return {
      activityId: null,
      startDatetime: null,
      endDatetime: null,
      startFromReport: false,
      endFromReport: false,
    };
  }

  constructor(private reportUtility: ReportUtilityService) {}

  forActivity(
    activity: ActivityWithReports,
    shiftActivities: ActivityWithReports[],
    sorted = false,
  ): ExpectedTimes {
    let expected = this.getExpected(activity);

    if (typeof expected === 'function') {
      if (!sorted) {
        shiftActivities = JSON.parse(JSON.stringify(shiftActivities));
        shiftActivities.sort(activitiesCompareFn);
      }

      const index = shiftActivities.findIndex((act) => act.id === activity.id);

      expected =
        index === 0
          ? expected(activity.startDatetime)
          : expected(
              this.forActivity(
                shiftActivities[index - 1],
                shiftActivities,
                true,
              ).endDatetime,
            );
    }

    return expected;
  }

  private getExpected(
    activity: Activity,
  ): ExpectedTimes | ((start: string) => ExpectedTimes);
  private getExpected(activity: Activity, startDatetime: string): ExpectedTimes;
  private getExpected(
    activity: Activity,
    startDatetime?: string,
  ): ExpectedTimes | ((start: string) => ExpectedTimes) {
    const report = this.reportUtility.toFullReport(activity.activityReports);

    if (report && report.startDatetime && report.endDatetime)
      return {
        activityId: activity.id,
        startDatetime: report.startDatetime,
        endDatetime: report.endDatetime,
        startFromReport: true,
        endFromReport: true,
      };

    const duration = differenceInMinutes(
      new Date(activity.endDatetime),
      new Date(activity.startDatetime),
    );

    if (report && report.startDatetime && !report.endDatetime)
      return {
        activityId: activity.id,
        startDatetime: report.startDatetime,
        endDatetime: add(new Date(report.startDatetime), {
          minutes: duration,
        }).toISOString(),
        startFromReport: true,
      };

    if (startDatetime == null)
      return (startDatetime: string) => ({
        activityId: activity.id,
        startDatetime: startDatetime,
        endDatetime: add(new Date(startDatetime), {
          minutes: duration,
        }).toISOString(),
      });

    return {
      activityId: activity.id,
      startDatetime: startDatetime,
      endDatetime: add(new Date(startDatetime), {
        minutes: duration,
      }).toISOString(),
    };
  }
}
