import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { ChangeProfessionModalComponent } from './change-profession-modal/change-profession-modal.component';
import { SortProfessionIdsPipe } from './pipe/sort-profession-ids.pipe';
import { SortProfessionsPipe } from './pipe/sort-professions.pipe';
import { ProfessionTagComponent } from './profession-tag/profession-tag.component';

@NgModule({
  declarations: [
    ChangeProfessionModalComponent,
    ProfessionTagComponent,
    SortProfessionsPipe,
    SortProfessionIdsPipe,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NzTagModule,
    NzSelectModule,
    NzFormModule,
  ],
  exports: [
    ChangeProfessionModalComponent,
    ProfessionTagComponent,
    SortProfessionsPipe,
    SortProfessionIdsPipe,
  ],
})
export class ProfessionCoreModule {}
