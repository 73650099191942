import { Injectable } from '@angular/core';
import { Action, createSelector, State, StateContext } from '@ngxs/store';
import { Filters } from '@wilson/interfaces';
import { SaveFilterParamQueriesInCache } from './filter-settings.actions';

export interface FilterSettingsModel {
  filterSettings: {
    [userId in string]: Partial<Filters>;
  };
}

const defaults: FilterSettingsModel = {
  filterSettings: {},
};

@State({
  name: 'filterSettings',
  defaults: defaults,
})
@Injectable()
export class FilterSettingsState {
  static getFilterSettings(userId: string) {
    return createSelector(
      [FilterSettingsState],
      (state: FilterSettingsModel) => {
        return state?.filterSettings ? state.filterSettings[userId] : {};
      },
    );
  }

  @Action(SaveFilterParamQueriesInCache)
  saveFilterParamQueriesInCache(
    ctx: StateContext<FilterSettingsModel>,
    { userId, filterParamQueries }: SaveFilterParamQueriesInCache,
  ) {
    ctx.patchState({
      filterSettings: {
        ...ctx.getState().filterSettings,
        [userId]: filterParamQueries,
      },
    });
  }
}
