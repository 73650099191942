import { Service } from '@wilson/interfaces';
import { ServiceDetailStateModel } from '@wilson/services/interfaces';
import { ServiceSeriesBulkUpdateDto } from '../types';

export class InitializeServiceDetailState {
  static readonly type = '[ServiceDetail] InitializeState';
  constructor(public serviceId: string) {}
}

export class InitializeServiceSeriesDetailState {
  static readonly type = '[ServiceSeriesDetail] InitializeState';
  constructor(public serviceSeriesId: string) {}
}

export class ClearServiceDetailState {
  static readonly type = '[ServiceDetail] ClearState';
}

export class BulkUpdateServiceSeries {
  static readonly type = '[ServiceSeries] BulkUpdate';
  constructor(
    public activitiyTemplateIds: string[],
    public updateDto: Partial<ServiceSeriesBulkUpdateDto>,
  ) {}
}
export class UpdateSeries {
  static readonly type = '[ServiceSeries] Update';
  constructor(public updateDto: Service) {}
}

export class GetUnitTransportGoods {
  static readonly type = '[ServiceSeries] Get Unit Transport Goods';
  constructor(public serviceDetail: ServiceDetailStateModel) {}
}
