import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmptyStateComponent } from './empty-state.component';
import { TranslateModule } from '@ngx-translate/core';
import { NzEmptyModule } from 'ng-zorro-antd/empty';

@NgModule({
  declarations: [EmptyStateComponent],
  imports: [CommonModule, TranslateModule, NzEmptyModule],
  exports: [EmptyStateComponent],
})
export class EmptyStateModule {}
