import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ImpactStyle } from '@capacitor/haptics';
import { PushNotificationSchema } from '@capacitor/push-notifications';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns';
import { HapticService } from './haptic.service';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationHelperService {
  presentingNotification = false;

  constructor(
    private readonly translate: TranslateService,
    private readonly alertCtrl: AlertController,
    private readonly modalCtrl: ModalController,
    private readonly hapticService: HapticService,
    private readonly router: Router,
  ) {}

  async presentNotification(notificationToPresent: PushNotificationSchema) {
    if (notificationToPresent?.data) {
      let notification = notificationToPresent.data as Record<string, string>;

      this.presentingNotification = true;

      notification = this.formatNotificationParams(notification);

      const alert = await this.alertCtrl.create({
        header: this.translate.instant(notification.title, notification),
        message: this.translate.instant(notification.body, notification),
        buttons: [
          {
            text: this.translate.instant('general.close'),
            role: 'cancel',
            handler: () => {
              this.presentingNotification = false;
            },
          },
          {
            text: this.translate.instant('general.selectUpper'),
            handler: async () => {
              this.hapticService.hapticImpact(ImpactStyle.Medium);
              if (
                (notification?.mobileUrl as string)?.length &&
                !notification?.mobileUrl?.includes('activity/')
              ) {
                this.router.navigate([notification.mobileUrl]);
              } else {
                this.router.navigate(['/tabs/notifications']);
              }
            },
          },
        ],
      });
      await alert.present();
    } else {
      return;
    }
  }

  async dismissAllModals() {
    while (await this.modalCtrl.getTop()) {
      await this.modalCtrl.dismiss();
    }
  }

  formatNotificationParams(notification: Record<string, string>) {
    if (notification.startDate) {
      notification.startDate = format(
        new Date(notification.startDate),
        'dd.MM.yyyy',
      );
    }
    if (notification.endDate) {
      notification.endDate = format(
        new Date(notification.endDate),
        'dd.MM.yyyy',
      );
    }
    if (notification.billingDeadline) {
      notification.billingDeadline = format(
        new Date(notification.billingDeadline),
        'dd.MM.yyyy',
      );
    }
    return notification;
  }
}
