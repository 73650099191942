import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BackendService } from '@wilson/base';
import { ConfigOptions, ConfigService } from '@wilson/config';
import {
  GeoLocation,
  LocationCategory,
  LocationCategoryIdsMap,
  LocationType,
} from '@wilson/interfaces';
import { sortBy } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LocationsService extends BackendService<GeoLocation> {
  protected readonly path = 'locations';
  private railLocationCategoryId = LocationCategoryIdsMap.Rail;
  private homeLocationCategoryId = LocationCategoryIdsMap.Home;

  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
  ) {
    super();
  }

  public getGeoLocationById(id: string): Observable<GeoLocation> {
    return this.http.get<GeoLocation>(`${this.config.host}/locations/${id}`);
  }

  public getGeoLocationsByIds(ids: string[]): Observable<GeoLocation[]> {
    return this.http
      .post<GeoLocation[]>(`${this.config.host}/locations/search`, { id: ids })
      .pipe(
        map((geoLocations) =>
          sortBy(geoLocations, (geoLocation) => ids.indexOf(geoLocation.id)),
        ),
      );
  }

  public getRailGeoLocationsByName(
    searchString: string,
  ): Observable<GeoLocation[]> {
    // This filter needs to be removed after BE can handle it
    return this.getAll({
      search: searchString,
      order: { locationCode: 'ASC' },
    }).pipe(
      map((locations) => {
        return locations.filter(
          (location) =>
            location.locationCategoryId === this.homeLocationCategoryId ||
            location.locationCategoryId === this.railLocationCategoryId,
        );
      }),
    );
  }

  public getLocationCategory(id: string): Observable<LocationCategory> {
    return this.http.get<LocationCategory>(
      `${this.config.host}/location-categories/${id}`,
    );
  }

  public getLocationCategories(): Observable<LocationCategory[]> {
    return this.http
      .get<LocationCategory[]>(`${this.config.host}/location-categories`)
      .pipe(
        map((locationCategories) =>
          locationCategories.filter(
            (locationCategory) => locationCategory.name !== LocationType.Home,
          ),
        ),
      );
  }
}
