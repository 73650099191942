import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Absence } from '@wilson/interfaces';
import { AbsencesService } from '../absences.service';
import { isSameDay, parseISO } from 'date-fns';

@Pipe({
  name: 'getAbsenceTimelineCardTitle',
  standalone: true,
})
export class GetAbsenceTimelineCardTitlePipe implements PipeTransform {
  constructor(
    private readonly absencesService: AbsencesService,
    private readonly translateService: TranslateService,
  ) {}

  transform(
    partialAbsence: Pick<
      Absence,
      | 'halfDayStart'
      | 'halfDayEnd'
      | 'absenceCategoryId'
      | 'absentFrom'
      | 'absentTo'
    >,
  ): string {
    const absenceCategoryNameTranslation = this.translateService.instant(
      this.absencesService.getAbsenceCategoryTranslationKeyById(
        partialAbsence.absenceCategoryId,
      ),
    );
    let absenceCardTitle = absenceCategoryNameTranslation;

    if (partialAbsence.halfDayStart || partialAbsence.halfDayEnd) {
      const halfDayTranslation = this.translateService.instant(
        'page.shift_timeline.absence.title.half_day',
      );
      const generalStartTranslation =
        this.translateService.instant('general.start');
      const generalEndTranslation =
        this.translateService.instant('general.end');

      const isSameDayAbsence = isSameDay(
        parseISO(partialAbsence.absentFrom),
        parseISO(partialAbsence.absentTo),
      );

      const getHalfDayText = () => {
        if (
          partialAbsence.halfDayStart &&
          partialAbsence.halfDayEnd &&
          !isSameDayAbsence
        ) {
          return `${halfDayTranslation} ${generalStartTranslation}, ${halfDayTranslation} ${generalEndTranslation}`;
        }
        if (partialAbsence.halfDayStart) {
          return `${halfDayTranslation} ${
            isSameDayAbsence ? generalEndTranslation : generalStartTranslation
          }`;
        }
        if (partialAbsence.halfDayEnd) {
          return `${halfDayTranslation} ${
            isSameDayAbsence ? generalStartTranslation : generalEndTranslation
          }`;
        }
        return '';
      };

      const halfDayText = getHalfDayText();
      absenceCardTitle = `${absenceCategoryNameTranslation}${
        halfDayText ? ` (${halfDayText})` : ''
      }`;
    }

    return absenceCardTitle;
  }
}
