import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { Route } from '@wilson/interfaces';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class RoutesService {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
  ) {}

  public getRoute(id: string): Observable<Route> {
    return this.httpClient.get<Route>(`${this.config.host}/routes/${id}`);
  }

  public searchRoute(conditions: Partial<Route>) {
    return this.httpClient.post<Route[]>(
      `${this.config.host}/routes/search`,
      conditions,
    );
  }

  public createRoutes(routes: Route) {
    return this.httpClient
      .post<Route[]>(`${this.config.host}/routes`, { items: [routes] })
      .toPromise();
  }

  public getAllRoutesById(rootId) {
    return this.httpClient
      .post<Route[]>(`${this.config.host}/routes/resolved`, {
        organizationalUnitId: rootId,
      })
      .pipe(take(1))
      .toPromise();
  }
}
