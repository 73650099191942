import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { ActivityComment } from '@wilson/interfaces';
import { Observable, firstValueFrom, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ActivityCommentsService {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
  ) {}

  public create(...comment: ActivityComment[]): Promise<ActivityComment> {
    return firstValueFrom(
      this.httpClient.post<ActivityComment>(
        `${this.config.host}/activity-comments`,
        {
          items: comment,
        },
      ),
    );
  }

  public update(
    comment: Partial<ActivityComment> & { id: string },
  ): Promise<ActivityComment> {
    return firstValueFrom(
      this.httpClient.patch<ActivityComment>(
        `${this.config.host}/activity-comments/${comment.id}`,
        comment,
      ),
    );
  }

  public get(commentId: string): Observable<ActivityComment> {
    return this.httpClient.get<ActivityComment>(
      `${this.config.host}/activity-comments/${commentId}`,
    );
  }

  public getAllResolvedForActivity(
    activityId: string,
  ): Observable<ActivityComment[]> {
    return this.httpClient
      .post<ActivityComment[]>(
        `${this.config.host}/activity-comments/resolved`,
        { activityId },
      )
      .pipe(
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status == 404) return of([]);
          }
          return throwError(() => error);
        }),
      );
  }

  public getLatestForActivity(activityId: string): Observable<ActivityComment> {
    return this.httpClient.get<ActivityComment>(
      `${this.config.host}/activities/${activityId}/comments/latest`,
    );
  }

  public delete(commentId: string): Promise<ActivityComment> {
    return firstValueFrom(
      this.httpClient.delete<ActivityComment>(
        `${this.config.host}/activity-comments/${commentId}`,
      ),
    );
  }
}
