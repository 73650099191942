import { Pipe, PipeTransform } from '@angular/core';
import { Shift, ShiftState } from '@wilson/interfaces';

@Pipe({
  name: 'isShiftInModifiableShiftState',
  standalone: true,
})
export class IsShiftInModifiableShiftStatePipe implements PipeTransform {
  transform(shift: Pick<Shift, 'state'>): boolean {
    return (
      shift.state === ShiftState.NotSubmitted ||
      shift.state === ShiftState.Reopened
    );
  }
}
