import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { faCheck, faCopy } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'wilson-copy-button',
  templateUrl: './copy-button.component.html',
  styleUrls: ['./copy-button.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyButtonComponent {
  constructor(private cdRef: ChangeDetectorRef) {}

  public faCopyIcon = faCopy;
  public faCheckIcon = faCheck;
  public isBeingCopied = false;

  @Input() value = '';

  async copyValueToClipboard() {
    await navigator.clipboard.writeText(this.value);
    this.isBeingCopied = true;
    this.cdRef.detectChanges();

    setTimeout(() => {
      this.isBeingCopied = false;
      this.cdRef.detectChanges();
    }, 1000);
  }
}
