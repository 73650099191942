import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { FilesService } from '@wilson/files';
import { map, Observable, shareReplay } from 'rxjs';

@Component({
  selector: 'wilson-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageModalComponent implements OnInit {
  @Input() title!: string;
  @Input() subtitle!: string;
  @Input() src!: string;
  @Input() shouldAccessSrc = false;
  @Input() withDownloadButton = false;

  srcWithAccess$!: Observable<string>;

  constructor(private readonly filesService: FilesService) {}

  ngOnInit(): void {
    this.initSrcAccess();
  }

  initSrcAccess() {
    this.srcWithAccess$ = this.filesService.getFileAccess(this.src).pipe(
      map(({ accessUrl }) => accessUrl),
      shareReplay(1),
    );
  }
}
