import { Base } from '@wilson/base';
import { User } from '../accounts/User';
import { Certificate } from '../certificates/certificate';
import { Country } from '../countries/country';
import { Language } from '../languages/language';
import { GeoLocation } from '../locations/geoLocation';
import { QualificationCategory } from '../qualification-categories/qualificationCategory';
import { QualificationSetting } from '../qualification-settings/qualificationSetting';
import { Route } from '../routes/routes';
import { SafetyCertificate } from '../safety-certificate/SafetyCertificate';
import { Training } from '../trainings/training';
import { VehicleModel } from '../vehicles/vehicle';
import { DriverLicense } from './DriverLicense';
import { FurtherEducation } from './FurtherEducation';
import { MedicalExamination } from './MedicalExamination';
import { OtherSubQualification } from './OtherSubQualifications';

export type UserQualifications =
  | VehicleQualification
  | RouteQualification
  | LanguageQualification
  | TrainingQualification
  | CountryQualification
  | CertificateQualification
  | LocationQualification
  | DriverLicenseQualification
  | FurtherEducationQualification
  | MedicalExaminationQualification
  | OtherQualification
  | SafetyCertificateQualification;

export interface UserQualification extends Base {
  acquiredAt: string;
  validFrom: string;
  validTill: string;
  userId: string;
  qualificationCategoryId: string;
  qualificationCategoryName?: string;
  categoryRouteParams?: string;
  signedByUser: boolean;
  user?: User;
  attachmentUrls?: string[] | null;
  qualificationCategory?: QualificationCategory;
  executedById: string;
  executedBy?: User;
  notes?: string;
}

export type ProcessedUserQualification = {
  qualificationCategoryName: string;
  categoryRouteParams: string;
  name?: string;
  status?: QualificationStatus;
} & UserQualifications;

export interface QualificationExportPayload {
  qualifications: QualificationExportEntitiy[];
  categories: QualificationExportEntitiy[];
}

export interface QualificationExportEntitiy {
  id: string;
  name: string;
}

export interface UserQualificationWithSetting extends UserQualification {
  qualificationSetting: QualificationSetting;
}

export enum QualificationStatus {
  valid = 'valid',
  expiresSoon = 'expiresSoon',
  expired = 'expired',
}

export enum QualificationCategoryName {
  Certificate = 'certificate',
  Country = 'country',
  DriverLicense = 'driver-license',
  FurtherEducation = 'further-education',
  Language = 'language',
  Location = 'location',
  MedicalExamination = 'medical-examination',
  More = 'more',
  Route = 'route',
  Training = 'training',
  Vehicle = 'vehicle',
  SafetyCertificate = 'safety-certificate',
}

export enum QualificationCategoryIdsMap {
  Certificate = 'bd005fa5-36a7-45b6-b672-c0ea1d11a787',
  Country = '200ae5c9-6ea8-4691-980d-98e6a445add9',
  DriverLicense = '5822cfaa-732f-4492-bc17-315ae17fef2b',
  FurtherEducation = 'e0670ca2-2f04-409d-a354-1005fc8e916d',
  Language = '8722e204-3387-4a9c-88f7-7e8b30089a63',
  Location = 'dbb90b82-d01a-4e96-9845-155bae3db217',
  MedicalExamination = 'a78734db-9d31-4f3b-a46f-f07dd7cba24d',
  More = '3fb80e3d-779c-4296-aa33-218d1d28036d',
  Route = '21ee9d97-d44b-4d79-8df2-d48d20655621',
  Training = '71774255-32e9-45f7-b3e1-cf56cabacb06',
  Vehicle = '5670dac7-0373-485f-8fbc-13039cd53239',
  SafetyCertificate = '6491d332-ed60-486a-a846-a577d91ec05c',
}

export interface VehicleQualification extends UserQualification {
  vehicleModelId: string;
  vehicleModel: VehicleModel;
}

export interface RouteQualification extends UserQualification {
  routeId: string;
  route: Route;
}

export interface LanguageQualification extends UserQualification {
  languageId: string;
  language: Language;
}

export interface LocationQualification extends UserQualification {
  geoLocationId: string;
  geoLocation: GeoLocation;
}

export interface CountryQualification extends UserQualification {
  countryId: string;
  country: Country;
}

export interface TrainingQualification extends UserQualification {
  training: Training;
  trainingId: Training['id'];
}

export interface CertificateQualification extends UserQualification {
  certificateId: string;
  certificate: Certificate;
}

export interface DriverLicenseQualification extends UserQualification {
  driverLicenseId: string;
  driverLicense: DriverLicense;
}

export interface FurtherEducationQualification extends UserQualification {
  furtherEducationId: string;
  furtherEducation: FurtherEducation;
}
export interface MedicalExaminationQualification extends UserQualification {
  medicalExaminationId: string;
  medicalExamination: MedicalExamination;
}
export interface OtherQualification extends UserQualification {
  otherQualificationSubCategoryId: string;
  otherQualificationSubCategory: OtherSubQualification;
}

export interface SafetyCertificateQualification extends UserQualification {
  safetyCertificateId: string;
  safetyCertificate: SafetyCertificate;
}
