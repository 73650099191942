import { ShiftPlanStatus } from '../shift-plan/shift-plan.interface';
import { ShiftPlanStatusTranslationKey } from './statusTranslationKey';

export const ShiftPlanStatusTranslationMap: {
  [key in ShiftPlanStatus]: ShiftPlanStatusTranslationKey;
} = {
  [ShiftPlanStatus.Draft]: ShiftPlanStatusTranslationKey.Draft,
  [ShiftPlanStatus.Reverted]: ShiftPlanStatusTranslationKey.Reverted,
  [ShiftPlanStatus.RolledOut]: ShiftPlanStatusTranslationKey.RolledOut,
};
