<ng-container *ngIf="!isLoading; else loadingTpl">
  <img *ngIf="signedImageUrl; else fallbackTpl" [src]="signedImageUrl" alt="" />
</ng-container>

<ng-template #fallbackTpl [ngSwitch]="fallback.type">
  <i [class]="fallbackIcon" *ngSwitchCase="'icon'"></i>
  <img
    id="fallbackImageTag"
    [src]="fallbackImage"
    alt=""
    *ngSwitchCase="'image'"
  />
</ng-template>

<ng-template #loadingTpl>
  <div class="d-flex justify-content-center align-items-center h-100">
    <div class="spinner-border text-secondary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</ng-template>
