import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrefScoreComponent } from './pref-score/pref-score.component';

@NgModule({
  imports: [CommonModule],
  declarations: [PrefScoreComponent],
  exports: [PrefScoreComponent],
})
export class PreferencesCoreModule {}
