import { Score } from '../assignments/scores';
import { ShiftAssignmentOption } from './shift-assignment-option';
import { ResolvedUser } from '../accounts/User';
import { Shift, ShiftValidationDetails } from './shift';
import { Activity } from '../activities/activity';

export interface ShiftAssignment {
  shift: Shift;
  user: ResolvedUser;
  scores: Score[];
  shiftValidationErrors: ShiftValidationDetails[];
  suggestedByWilsonLearn: boolean;
}

export interface AssignShiftInRange {
  startDatetime: string;
  endDatetime: string;
  organizationalUnitId: string[];
  assignmentOption: ShiftAssignmentOption;
}

export interface ShiftAssignmentSuggestions {
  shiftAssignments: Record<string, string | null>;
  userIds: string[];
  dateRange: {
    startDatetime: Date;
    endDatetime: Date;
  };
  shiftAssignmentOption: ShiftAssignmentOption;
}

export interface ActivitiesAssignment {
  shift: Shift;
  user: ResolvedUser;
  newActivities: Activity[];
  transferDurationToAssignment: number;
  transferDurationFromAssignment: number;
  scores: Score[];
  shiftValidationErrors: ShiftValidationDetails[];
  tags: ShiftAssignmentTags[];
  isExistingShift: boolean;
  availability: AssignmentAvailabilityStatus;
}

export interface AssignmentTimeRange {
  start: string;
  end: string;
}

export enum AssignmentAvailabilityStatus {
  Available = 'Available',
  PartlyAvailable = 'PartlyAvailable',
  NotAvailable = 'NotAvailable',
}

export enum ShiftAssignmentTags {
  TransferNecessary = 'transfer-necessary',
  BestSmartScore = 'best-smart-score',
  BestPrefScore = 'best-pref-score',
  StartLate = 'start-late',
  HotelNecessary = 'hotel-necessary',
}
