<div class="user-details">
  <div
    class="user-details-section d-flex flex-row align-items-center justify-content-between"
  >
    <div class="d-flex flex-row align-items-center">
      <wilson-user-avatar
        [s3Urn]="user?.profileImageUrl || ''"
        [fallbackIcon]="'fas fa-user-circle text-secondary'"
        class="staff-icon-square me-2"
      ></wilson-user-avatar>

      <h6 class="mb-0 me-2">{{ user?.firstName }} {{ user?.lastName }}</h6>
    </div>
    <fa-icon
      *ngIf="!hideProfileIcon"
      class="link-icon"
      [icon]="faLinkIcon"
      (click)="openUserDetail()"
    >
    </fa-icon>
  </div>

  <div class="user-details-section" *ngIf="!!user?.email || !!user?.phone">
    <div *ngIf="!!user?.email">
      <p class="text-uppercase m-0">
        <strong>{{ 'account.email' | translate }}</strong>
      </p>
      <div class="d-flex flex-row align-items-center justify-content-between">
        <a [href]="'mailto:' + user?.email" class="email me-1">
          <p class="m-0">{{ user?.email }}</p>
        </a>
        <wilson-copy-button [value]="user?.email || ''"></wilson-copy-button>
      </div>
    </div>
    <div class="mt-2" *ngIf="!!user?.phone">
      <p class="text-uppercase m-0">
        <strong>{{ 'account.phone' | translate }}</strong>
      </p>
      <div class="d-flex flex-row align-items-center justify-content-between">
        <p class="m-0 phone">{{ user?.phone }}</p>
        <wilson-copy-button [value]="user?.phone || ''"></wilson-copy-button>
      </div>
    </div>
  </div>
  <div
    *ngIf="('portal-labels' | featureFlagPure | async) && user?.labels?.length"
    class="mt-2"
  >
    <wilson-display-labels
      [selectedLabels]="user.labels"
      [maxAmount]="3"
    ></wilson-display-labels>
  </div>
</div>
