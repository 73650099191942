import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '@wilson/pipes';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { CustomLocationsAutocompleteComponent } from './custom-locations-autocomplete.component';
import { LocationListModule } from '../location-list/location-list.module';

@NgModule({
  declarations: [CustomLocationsAutocompleteComponent],
  imports: [
    CommonModule,
    NzAutocompleteModule,
    TranslateModule,
    FormsModule,
    NzToolTipModule,
    PipesModule,
    LocationListModule,
  ],
  exports: [CustomLocationsAutocompleteComponent],
})
export class CustomLocationsModule {}
