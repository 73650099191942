import { Injectable } from '@angular/core';
import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { Logout } from '@wilson/auth/core';
import { Bucket, BucketStateModel, BucketType } from '@wilson/share/interfaces';
import { union } from 'lodash';
import {
  ClearBucket,
  DeleteAllItemsFromId,
  DeleteItemFromBucket,
  InsertActivitiesToBucket,
  InsertToBucket,
} from './new-wilson-job-bucket.actions';

export const NEW_WILSON_JOB_BUCKET_STATE_NAME = 'newWilsonJobBucket';

@State<BucketStateModel>({
  name: NEW_WILSON_JOB_BUCKET_STATE_NAME,
  defaults: {
    bucket: [],
    bucketType: BucketType.Service,
  },
})
@Injectable()
export class NewWilsonJobBucketState {
  @Selector()
  static itemIds(state: BucketStateModel): string[] {
    const itemIds: string[] = [];
    state.bucket.forEach((b) => itemIds.push(...b.itemIds));
    return itemIds;
  }

  static doesIdExistInBucket(itemId: string) {
    return createSelector(
      [NewWilsonJobBucketState],
      (state: BucketStateModel) => {
        return state.bucket?.some((bucket) => bucket.itemIds.includes(itemId));
      },
    );
  }

  @Selector()
  static bucketIds(state: BucketStateModel): string[] {
    return state.bucket.map((b) => b.id);
  }

  @Selector()
  static bucket(state: BucketStateModel): Bucket[] {
    return state.bucket;
  }

  @Selector()
  static bucketType(state: BucketStateModel): BucketType {
    return state.bucketType;
  }

  @Action([ClearBucket, Logout])
  async ClearBucket(ctx: StateContext<BucketStateModel>) {
    ctx.setState(
      patch<BucketStateModel>({
        bucket: [],
        bucketType: BucketType.Service,
      }),
    );
  }

  @Action(InsertToBucket)
  async insertToBucket(
    ctx: StateContext<BucketStateModel>,
    action: InsertToBucket,
  ) {
    this.insertOrUpdateBucket(
      ctx,
      action.id,
      action.itemIds,
      action.bucketType,
    );
  }

  @Action(InsertActivitiesToBucket)
  async insertActivitiesToBucket(
    ctx: StateContext<BucketStateModel>,
    action: InsertActivitiesToBucket,
  ) {
    action.activities.forEach((activity) => {
      this.insertOrUpdateBucket(
        ctx,
        activity.serviceId as string,
        [activity.id] as string[],
        BucketType.Service,
      );
    });
  }

  @Action(DeleteItemFromBucket)
  async deleteItemFromBucket(
    ctx: StateContext<BucketStateModel>,
    action: DeleteItemFromBucket,
  ) {
    const state = ctx.getState();
    const index = state.bucket.findIndex((b) => b.id === action.id);
    if (index > -1) {
      ctx.setState(
        patch<BucketStateModel>({
          bucket:
            state.bucket[index].itemIds.length > 1
              ? updateItem(index, {
                  id: action.id,
                  itemIds: state.bucket[index].itemIds.filter(
                    (itemId) => itemId !== action.itemId,
                  ),
                })
              : removeItem((bucket) => bucket?.id === action.id),
        }),
      );
    }
  }

  @Action(DeleteAllItemsFromId)
  async DeleteAllItemsFromId(
    ctx: StateContext<BucketStateModel>,
    action: DeleteAllItemsFromId,
  ) {
    ctx.setState(
      patch<BucketStateModel>({
        bucket: removeItem((bucket) => bucket?.id === action.id),
      }),
    );
  }

  private insertOrUpdateBucket(
    ctx: StateContext<BucketStateModel>,
    id: string,
    itemIdsToInsert: string[],
    bucketType: BucketType,
  ) {
    const state = ctx.getState();
    const index = state.bucket.findIndex((b) => b.id === id);
    ctx.setState(
      patch<BucketStateModel>({
        bucket:
          index === -1
            ? append([
                {
                  id: id,
                  itemIds: itemIdsToInsert,
                },
              ])
            : updateItem(index, {
                id: id,
                itemIds: union(state.bucket[index].itemIds, itemIdsToInsert),
              }),
        bucketType,
      }),
    );
  }
}
