import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { ShiftValidationDetails } from '@wilson/interfaces';

@Component({
  selector: 'wilson-shift-logic-details',
  templateUrl: './shift-logic-details.component.html',
  styleUrls: ['./shift-logic-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShiftLogicDetailsComponent {
  public faValidIcon = faCheckCircle;
  public faInValidIcon = faTimesCircle;

  @Input() shiftLogicValidations: ShiftValidationDetails[] = [];
}
