<div
  [class]="['shift-category-tag', 'shift-category-tag-' + size]"
  class="shift-category-tag"
>
  <div
    [class]="['dot', 'dot-' + size]"
    [style.background-color]="shiftCategoriesColorCode[shiftCategoryId]"
  ></div>
  <span>
    {{ 'shift_category.' + shiftCategoryId | translate }}
  </span>
</div>
