import { JwtConfig } from '@auth0/angular-jwt';
import { Store } from '@ngxs/store';
import { AuthState } from '@wilson/auth/core';
import { firstValueFrom } from 'rxjs';
import { environment } from '../environments/environment';

export function JwtOptionsFactory(store: Store) {
  const domain = new URL(environment.backend.host).host;

  return {
    tokenGetter: () => {
      return firstValueFrom(store.select<string>(AuthState.accessToken));
    },
    allowedDomains: [domain],
    disallowedRoutes: [/\/assets\/.+\.json/],
  } as JwtConfig;
}
