import { Injectable } from '@angular/core';
import { State, Action, StateContext, Store, Selector } from '@ngxs/store';
import { AuthState } from '@wilson/auth/core';
import {
  ShiftTimePreference,
  UserShiftTimePreferencesGatewayService,
} from '@wilson/preferences/core';
import { firstValueFrom } from 'rxjs';
import {
  FetchUserShiftTimePreferenceAction,
  ResetShiftTimePreferenceAction,
  SetUserShiftTimePreferenceAction,
} from './user-shift-time-preference.actions';
import { v4 as uuidv4 } from 'uuid';

export interface UserShiftTimePreferenceStateModel {
  isFetching: boolean;
  isFetchingError: boolean;
  isFetchingSuccess: boolean;
  item: ShiftTimePreference;
}

const defaults: UserShiftTimePreferenceStateModel = {
  isFetching: false,
  isFetchingError: false,
  isFetchingSuccess: false,
  item: null,
};

@State<UserShiftTimePreferenceStateModel>({
  name: 'userShiftTimePreference',
  defaults,
})
@Injectable()
export class UserShiftTimePreferenceState {
  constructor(
    private store: Store,
    private userShiftTimePreferencesGatewayService: UserShiftTimePreferencesGatewayService,
  ) {}

  @Selector()
  static isFetchingError(state: UserShiftTimePreferenceStateModel) {
    return state.isFetchingError;
  }

  @Selector()
  static selectedUserShiftTimePreferences(
    state: UserShiftTimePreferenceStateModel,
  ): ShiftTimePreference {
    return state.item;
  }

  @Action(SetUserShiftTimePreferenceAction)
  async set(
    context: StateContext<UserShiftTimePreferenceStateModel>,
    { payload }: SetUserShiftTimePreferenceAction,
  ) {
    const currentState = context.getState();

    if (currentState.isFetching && !currentState.isFetchingError) {
      if (currentState.item) {
        return this.updateSetting(context, payload.shiftTimePreferenceId);
      } else {
        return this.createSetting(context, payload.shiftTimePreferenceId);
      }
    }
  }

  private async updateSetting(
    context: StateContext<UserShiftTimePreferenceStateModel>,
    shiftTimePreferenceId: string,
  ) {
    const userId = this.store.selectSnapshot(AuthState.userId) as string;
    const currentPreference = context.getState().item;

    context.patchState({
      item: {
        ...currentPreference,
        organizationalUnitShiftTimePreferenceId: shiftTimePreferenceId,
      },
    });

    try {
      return await this.userShiftTimePreferencesGatewayService.update(
        userId,
        currentPreference.id,
        shiftTimePreferenceId,
      );
    } catch (e) {
      context.patchState({
        item: currentPreference,
      });
      return Promise.reject(e);
    }
  }

  private async createSetting(
    context: StateContext<UserShiftTimePreferenceStateModel>,
    shiftTimePreferenceId: string,
  ) {
    const userId = this.store.selectSnapshot(AuthState.userId) as string;

    // eslint-disable-next-line
    const newPreference: any = {
      // THIS IS A BAD ONE. The object is incomplete and it might be accessed before it is completely filled out.
      id: uuidv4(),
      userId,
      organizationalUnitShiftTimePreferenceId: shiftTimePreferenceId,
    };

    context.patchState({
      item: newPreference,
    });

    try {
      return await this.userShiftTimePreferencesGatewayService.create(
        userId,
        newPreference,
      );
    } catch (e) {
      context.patchState({
        item: null,
      });
      return Promise.reject(e);
    }
  }

  @Action(FetchUserShiftTimePreferenceAction)
  async fetch({ patchState }: StateContext<UserShiftTimePreferenceStateModel>) {
    patchState({
      isFetching: true,
      isFetchingError: false,
      isFetchingSuccess: false,
    });

    try {
      const data = await firstValueFrom(
        this.userShiftTimePreferencesGatewayService.get(
          this.store.selectSnapshot(AuthState.userId),
          {
            embed: ['organizationalUnitShiftTimePreference'],
          },
        ),
      );
      patchState({
        isFetching: true,
        isFetchingError: false,
        isFetchingSuccess: false,
        item: data[0],
      });
    } catch {
      patchState({
        isFetching: true,
        isFetchingError: false,
        isFetchingSuccess: false,
      });
    }
  }

  @Action(ResetShiftTimePreferenceAction)
  reset({
    patchState,
    getState,
  }: StateContext<UserShiftTimePreferenceStateModel>) {
    const userId = this.store.selectSnapshot(AuthState.userId) as string;
    const currentSetting = getState().item;

    if (currentSetting?.id) {
      patchState({
        item: null,
      });

      try {
        return this.userShiftTimePreferencesGatewayService.delete(userId);
      } catch {
        patchState({
          item: currentSetting,
        });
        return Promise.reject();
      }
    } else {
      return null;
    }
  }
}
