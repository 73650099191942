import { Activity } from '@wilson/interfaces';

export function groupActivitiesByServiceAndJob(activities: Activity[] | null): {
  serviceActivities: Map<string, Activity[]>;
  jobActivities: Map<string, Activity[]>;
} {
  const response = {
    serviceActivities: new Map(),
    jobActivities: new Map(),
  };
  if (activities) {
    activities.reduce<{
      serviceActivities: Map<string, Activity[]>;
      jobActivities: Map<string, Activity[]>;
    }>((result, activity) => {
      if (activity.jobId) {
        upsertJobActivity(result.jobActivities, activity);
      } else if (activity.serviceId) {
        upsertServiceActivity(result.serviceActivities, activity);
      }

      return result;
    }, response);
  }

  return response;
}

function upsertServiceActivity(
  serviceActivitiesMap: Map<string, Activity[]>,
  activity: Activity,
) {
  if (serviceActivitiesMap.has(activity.serviceId as string)) {
    const existingActivities = serviceActivitiesMap.get(
      activity.serviceId as string,
    );
    existingActivities?.push(activity);
  } else {
    serviceActivitiesMap.set(activity.serviceId as string, [activity]);
  }
}

function upsertJobActivity(
  jobActivitiesMap: Map<string, Activity[]>,
  activity: Activity,
) {
  if (jobActivitiesMap.has(activity.jobId as string)) {
    const existingActivities = jobActivitiesMap.get(activity.jobId as string);
    existingActivities?.push(activity);
  } else {
    jobActivitiesMap.set(activity.jobId as string, [activity]);
  }
}
