import { Injectable } from '@angular/core';
import { ShiftForWorkTimes } from '@wilson/api/gateway';
import { ValueGetterParams } from 'ag-grid-community';

/**
 * @deprecated The class name says ActivitiesValueGetterService but it has nothing to do with Activities anymore it should be moved to @wilson/shift/ag-grid
 */
@Injectable()
export class ActivitiesValueGetterService {
  plannedAndReportedStartTimeValueGetter = (
    params: ValueGetterParams<ShiftForWorkTimes>,
  ): Date[] => {
    const dates: Date[] = [];
    dates.push(new Date(params.data?.dates.plannedStart as string));

    if (params.data?.dates.isStart) {
      dates.push(new Date(params.data?.dates.isStart as string));
    }

    return dates;
  };

  plannedAndReportedEndTimeValueGetter = (
    params: ValueGetterParams<ShiftForWorkTimes>,
  ): Date[] => {
    const dates: Date[] = [];
    dates.push(new Date(params.data?.dates.plannedEnd as string));

    if (params.data?.dates.isEnd) {
      dates.push(new Date(params.data?.dates.isEnd as string));
    }

    return dates;
  };

  deviationAndWorktimeViolation = (
    params: ValueGetterParams<ShiftForWorkTimes>,
  ) => {
    const deviation = params.data?.deviation;
    const shiftReportComment = params.data?.shiftReportComment;
    const shiftReportViolationIds = params.data?.shiftReportViolationIds;
    return { deviation, shiftReportComment, shiftReportViolationIds };
  };
}
