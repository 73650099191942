import { AbsenceTemplate } from '../absences/absenceTemplate';
import { Base } from '@wilson/base';
import { ColumnIndex, RowIndex } from './ColumnRowType';
import { TemplateType } from './TemplateType';
import { HoursMinutes } from '../time-unit/hours-minutes';

export interface ShiftPlanTemplate extends Base {
  [ShiftPlanTemplateKey.Positions]: [RowIndex, ColumnIndex][];
  absenceTemplate?: AbsenceTemplate;
  [ShiftPlanTemplateKey.AbsenceTemplateId]: AbsenceTemplate['id'] | null;
  //shiftTemplate?: ShiftTemplate;
  [ShiftPlanTemplateKey.ShiftTemplateId]: string | null;
  [ShiftPlanTemplateKey.ShiftPlanId]?: string | null;
}

export enum ShiftPlanTemplateKey {
  AbsenceTemplateId = 'absenceTemplateId',
  ShiftTemplateId = 'shiftTemplateId',
  ShiftPlanId = 'shiftPlanId',
  Positions = 'positions',
}

export interface ShiftPlanCalendarTemplate {
  id: string;
  type: TemplateType;
  categoryId: string;
  name: string;
  startTime: HoursMinutes | null;
  endTime: HoursMinutes | null;
  overlapDays: number;
}
