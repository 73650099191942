import { Pipe, PipeTransform } from '@angular/core';
import { Client } from '@wilson/clients/interfaces';
import { Observable, of } from 'rxjs';
import { ClientsV2Service } from '../clients-v2.service';

@Pipe({
  name: 'clientById',
  standalone: true,
})
export class ClientByIdPipe implements PipeTransform {
  constructor(private readonly clientsService: ClientsV2Service) {}

  transform(id: string | null | undefined): Observable<Client | null> {
    if (!id) {
      return of(null);
    }
    return this.clientsService.get(id);
  }
}
