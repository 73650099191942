import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Haptics, ImpactStyle, NotificationType } from '@capacitor/haptics';

@Injectable({
  providedIn: 'root',
})
export class HapticService {
  private get hasHaptics() {
    return (
      (this.platform.is('android') || this.platform.is('ios')) &&
      !this.platform.is('mobileweb')
    );
  }

  constructor(private platform: Platform) {}

  userHaptic() {
    if (this.hasHaptics) {
      this.hapticImpact(ImpactStyle.Medium);
    }
  }

  hapticImpact(style = ImpactStyle.Medium) {
    if (this.hasHaptics) {
      Haptics.impact({
        style,
      });
    }
  }

  hapticNotification(type: NotificationType) {
    if (this.hasHaptics) {
      Haptics.notification({ type });
    }
  }

  hapticSelect() {
    if (this.hasHaptics) {
      Haptics.selectionChanged();
    }
  }

  vibrate() {
    if (this.hasHaptics) {
      Haptics.vibrate();
    }
  }
}
