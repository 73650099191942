import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateFnsModule } from 'ngx-date-fns';
import { AbsenceCalendarCardTimePipe } from './absence-calendar-card-time/absence-calendar-card-time.pipe';

@NgModule({
  imports: [CommonModule, DateFnsModule],
  providers: [],
  declarations: [AbsenceCalendarCardTimePipe],
  exports: [AbsenceCalendarCardTimePipe],
})
export class AbsencesModule {}
