import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TypeOrmFindManyOptions } from '@wilson/base';
import { ConfigOptions, ConfigService } from '@wilson/config';
import {
  CertificateQualification,
  CountryQualification,
  DriverLicense,
  DriverLicenseQualification,
  FurtherEducation,
  FurtherEducationQualification,
  LanguageQualification,
  LocationQualification,
  MedicalExamination,
  MedicalExaminationQualification,
  OtherQualification,
  OtherSubQualification,
  QualificationCategoryIdsMap,
  QualificationCategoryName,
  QualificationExportPayload,
  QualificationStatus,
  RouteQualification,
  SafetyCertificateQualification,
  TrainingQualification,
  UserQualification,
  UserQualifications,
  VehicleQualification,
} from '@wilson/interfaces';
import { TransformMasterDataTranslationService } from '@wilson/non-domain-specific/master-data-translate';
import { format } from 'date-fns';
import { stringify } from 'qs';
import { Observable, firstValueFrom } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

@Injectable()
export class QualificationsService {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
    private readonly transformMasterDataTranslationService: TransformMasterDataTranslationService,
  ) {}

  public getUserQualifications(userId: string) {
    return this.httpClient.post<UserQualification[]>(
      `${this.config.host}/user-qualifications/search`,
      { userId },
    );
  }

  /**
   * WARNING THIS FUNCTION IS NOT RESOLVED AT ALL!!!
   */
  public getResolvedUserQualifications(userId: string) {
    return this.httpClient.get<UserQualification[]>(
      `${this.config.host}/user-qualifications/user/${userId}`,
    );
  }

  public getUserQualificationsByCategory(userId: string, categoryId: string) {
    return this.httpClient.get<UserQualification[]>(
      `${this.config.host}/user-qualifications/user/${userId}/qualification/${categoryId}`,
    );
  }

  public getUserQualification(qualificationId: string) {
    return this.httpClient.get<UserQualification>(
      `${this.config.host}/user-qualifications/${qualificationId}`,
    );
  }

  public getUserQualificationByCategoryId(
    category: string,
    options?: TypeOrmFindManyOptions,
  ) {
    const qualificationCategory =
      category === QualificationCategoryName.More ? 'other' : category;
    const params = stringify(options);
    return this.httpClient.get<UserQualification[]>(
      `${this.config.host}/${qualificationCategory}-qualifications?${params}`,
    );
  }

  public updateUserQualification(
    qualification: Partial<UserQualification>,
    qualificationId: string,
  ) {
    return this.httpClient
      .patch<UserQualification>(
        `${this.config.host}/user-qualifications/${qualificationId}`,
        qualification,
      )
      .toPromise();
  }

  public updateUserQualificationByType(
    category: QualificationCategoryName,
    qualification: Partial<UserQualifications>,
    qualificationId: string,
  ) {
    const qualificationCategory =
      category === QualificationCategoryName.More ? 'other' : category;

    return this.httpClient
      .patch<UserQualification>(
        `${this.config.host}/${qualificationCategory}-qualifications/${qualificationId}`,
        qualification,
      )
      .toPromise();
  }

  public deleteUserQualification(qualificationId: string) {
    return this.httpClient
      .delete<UserQualification[]>(
        `${this.config.host}/user-qualifications/${qualificationId}`,
      )
      .toPromise();
  }

  public deleteUserQualificationByType(
    categoryId: QualificationCategoryIdsMap,
    qualificationId: string,
  ) {
    let qualificationKey = Object.keys(QualificationCategoryIdsMap)
      .find((k) => QualificationCategoryIdsMap[k] === categoryId)
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      .toLowerCase();

    if (qualificationKey === QualificationCategoryName.More) {
      qualificationKey = 'other';
    }

    if (!qualificationKey) {
      throw new Error('Key not found for the given qualification category');
    }
    const url = `${
      this.config.host
    }/${qualificationKey.toLowerCase()}-qualifications/${qualificationId}`;
    return firstValueFrom(this.httpClient.delete<UserQualification[]>(url));
  }

  public getOrganizationalUnitQualifications(id: string) {
    return this.httpClient.get<UserQualifications[]>(
      `${this.config.host}/organizational-units/${id}/qualifications`,
    );
  }

  public getOrganizationalUnitsHistory(
    rootId: string,
  ): Observable<UserQualifications[]> {
    return this.httpClient
      .get<UserQualifications[]>(
        `${this.config.host}/organizational-units/${rootId}/qualifications/history`,
      )
      .pipe(
        map((qualifications) => {
          return qualifications.map((qualification) => {
            qualification.qualificationCategory =
              this.transformMasterDataTranslationService.transform([
                qualification.qualificationCategory,
              ])[0];
            return qualification;
          });
        }),
      );
  }

  public getQualificationStatus(
    qualification: UserQualification,
  ): QualificationStatus {
    const validUntil = new Date(qualification.validTill);
    const expireSoonDate = new Date(Date.now() + 90 * 86400000);
    if (validUntil.getTime() < new Date().getTime()) {
      return QualificationStatus.expired;
    } else if (validUntil.getTime() <= expireSoonDate.getTime()) {
      return QualificationStatus.expiresSoon;
    } else {
      return QualificationStatus.valid;
    }
  }

  // ********* CERTIFICATE QUALIFICATION **********

  public createCertificateUserQualification(
    certificate: CertificateQualification,
  ): Promise<CertificateQualification[]> {
    return this.httpClient
      .post<CertificateQualification[]>(
        `${this.config.host}/certificate-qualifications`,
        {
          items: [certificate],
        },
      )
      .toPromise();
  }

  public updateCertificateUserQualification(
    certificate: CertificateQualification,
    qualificationId: string,
  ): Promise<CertificateQualification> {
    return this.httpClient
      .patch<CertificateQualification>(
        `${this.config.host}/certificate-qualifications/${qualificationId}`,
        certificate,
      )
      .toPromise();
  }

  public getCertificateUserQualification(
    qualificationId: string,
  ): Observable<CertificateQualification> {
    return this.httpClient.get<CertificateQualification>(
      `${this.config.host}/certificate-qualifications/${qualificationId}`,
    );
  }

  public getCertificateUserQualificationHistory(
    qualificationId: string,
    options?: TypeOrmFindManyOptions,
  ): Observable<CertificateQualification[]> {
    const params = stringify(options);
    return this.httpClient.get<CertificateQualification[]>(
      `${this.config.host}/certificate-qualifications/${qualificationId}/history?${params}`,
    );
  }

  public getSharedUserQualificationHistory(
    qualificationId: string,
    qualificationCategoryId: string,
    options?: TypeOrmFindManyOptions,
  ): Observable<UserQualifications[]> {
    const params = stringify(options);
    return this.httpClient.get<UserQualifications[]>(
      `${this.config.host}/shared-users/qualifications/${qualificationId}/category/${qualificationCategoryId}?${params}`,
    );
  }

  // ********* VEHICLE QUALIFICATION **********

  public createVehicleUserQualification(
    vehicle: VehicleQualification,
  ): Promise<VehicleQualification[]> {
    return this.httpClient
      .post<VehicleQualification[]>(
        `${this.config.host}/vehicle-qualifications`,
        {
          items: [vehicle],
        },
      )
      .pipe(take(1))
      .toPromise();
  }

  public updateVehicleUserQualification(
    vehicle: VehicleQualification,
    qualificationId: string,
  ): Promise<VehicleQualification> {
    return this.httpClient
      .patch<VehicleQualification>(
        `${this.config.host}/vehicle-qualifications/${qualificationId}`,
        vehicle,
      )
      .pipe(take(1))
      .toPromise();
  }

  public getVehicleUserQualification(
    qualificationId: string,
  ): Observable<VehicleQualification> {
    return this.httpClient.get<VehicleQualification>(
      `${this.config.host}/vehicle-qualifications/${qualificationId}`,
    );
  }

  public getVehicleUserQualificationHistory(
    qualificationId: string,
    options?: TypeOrmFindManyOptions,
  ): Observable<VehicleQualification[]> {
    const params = stringify(options);
    return this.httpClient.get<VehicleQualification[]>(
      `${this.config.host}/vehicle-qualifications/${qualificationId}/history?${params}`,
    );
  }

  // ********* COUNTRY QUALIFICATION **********

  public createCountryUserQualification(
    country: CountryQualification,
  ): Promise<CountryQualification[]> {
    return this.httpClient
      .post<CountryQualification[]>(
        `${this.config.host}/country-qualifications`,
        { items: [country] },
      )
      .toPromise();
  }

  public updateCountryUserQualification(
    country: CountryQualification,
    qualificationId: string,
  ): Promise<CountryQualification> {
    return firstValueFrom(
      this.httpClient.patch<CountryQualification>(
        `${this.config.host}/country-qualifications/${qualificationId}`,
        country,
      ),
    );
  }

  public getCountryUserQualification(
    qualificationId: string,
  ): Observable<CountryQualification> {
    return this.httpClient.get<CountryQualification>(
      `${this.config.host}/country-qualifications/${qualificationId}`,
    );
  }

  public getCountryUserQualificationHistory(
    qualificationId: string,
    options?: TypeOrmFindManyOptions,
  ): Observable<CountryQualification[]> {
    const params = stringify(options);
    return this.httpClient.get<CountryQualification[]>(
      `${this.config.host}/country-qualifications/${qualificationId}/history?${params}`,
    );
  }

  // ********* SAFETY CERTIFICATE QUALIFICATION **********
  public updateSafetyCertificateQualification(
    safetyCertificate: SafetyCertificateQualification,
    qualificationId: string,
  ): Promise<CountryQualification> {
    return firstValueFrom(
      this.httpClient.patch<CountryQualification>(
        `${this.config.host}/safety-certificate-qualifications/${qualificationId}`,
        safetyCertificate,
      ),
    );
  }

  public getSafetyCertificateUserQualificationHistory(
    qualificationId: string,
    options?: TypeOrmFindManyOptions,
  ): Observable<SafetyCertificateQualification[]> {
    const params = stringify(options);
    return this.httpClient.get<SafetyCertificateQualification[]>(
      `${this.config.host}/safety-certificate-qualifications/${qualificationId}/history?${params}`,
    );
  }

  // ********* LANGUAGE QUALIFICATION **********

  public createLanguageUserQualification(
    language: LanguageQualification,
  ): Promise<LanguageQualification[]> {
    return firstValueFrom(
      this.httpClient.post<LanguageQualification[]>(
        `${this.config.host}/language-qualifications`,
        { items: [language] },
      ),
    );
  }

  public updateLanguageUserQualification(
    language: LanguageQualification,
    qualificationId: string,
  ): Promise<LanguageQualification> {
    return firstValueFrom(
      this.httpClient.patch<LanguageQualification>(
        `${this.config.host}/language-qualifications/${qualificationId}`,
        language,
      ),
    );
  }

  public getLanguageUserQualification(
    qualificationId: string,
  ): Observable<LanguageQualification> {
    return this.httpClient.get<LanguageQualification>(
      `${this.config.host}/language-qualifications/${qualificationId}`,
    );
  }

  public getLanguageUserQualificationHistory(
    qualificationId: string,
    options?: TypeOrmFindManyOptions,
  ): Observable<LanguageQualification[]> {
    const params = stringify(options);
    return this.httpClient.get<LanguageQualification[]>(
      `${this.config.host}/language-qualifications/${qualificationId}/history?${params}`,
    );
  }

  // ********* LOCATION QUALIFICATION **********

  public createLocationUserQualification(
    locations: LocationQualification,
  ): Promise<LocationQualification[]> {
    return firstValueFrom(
      this.httpClient.post<LocationQualification[]>(
        `${this.config.host}/location-qualifications`,
        { items: [locations] },
      ),
    );
  }

  public updateLocationUserQualification(
    locations: LocationQualification,
    qualificationId: string,
  ): Promise<LocationQualification> {
    return firstValueFrom(
      this.httpClient.patch<LocationQualification>(
        `${this.config.host}/location-qualifications/${qualificationId}`,
        locations,
      ),
    );
  }

  public getLocationUserQualification(
    qualificationId: string,
  ): Observable<LocationQualification> {
    return this.httpClient.get<LocationQualification>(
      `${this.config.host}/location-qualifications/${qualificationId}`,
    );
  }

  public getLocationUserQualificationHistory(
    qualificationId: string,
    options?: TypeOrmFindManyOptions,
  ): Observable<LocationQualification[]> {
    const params = stringify(options);
    return this.httpClient.get<LocationQualification[]>(
      `${this.config.host}/location-qualifications/${qualificationId}/history?${params}`,
    );
  }

  // ********* TRAINING QUALIFICATION **********

  public createTrainingUserQualification(
    training: TrainingQualification,
  ): Promise<TrainingQualification[]> {
    return firstValueFrom(
      this.httpClient.post<TrainingQualification[]>(
        `${this.config.host}/training-qualifications`,
        { items: [training] },
      ),
    );
  }

  public updateTrainingUserQualification(
    training: TrainingQualification,
    qualificationId: string,
  ): Promise<TrainingQualification> {
    return firstValueFrom(
      this.httpClient.patch<TrainingQualification>(
        `${this.config.host}/training-qualifications/${qualificationId}`,
        training,
      ),
    );
  }

  public getTrainingUserQualification(
    id: string,
  ): Observable<TrainingQualification> {
    return this.httpClient.get<TrainingQualification>(
      `${this.config.host}/training-qualifications/${id}`,
    );
  }

  public getTrainingUserQualificationHistory(
    qualificationId: string,
    options?: TypeOrmFindManyOptions,
  ): Observable<TrainingQualification[]> {
    const params = stringify(options);
    return this.httpClient.get<TrainingQualification[]>(
      `${this.config.host}/training-qualifications/${qualificationId}/history?${params}`,
    );
  }

  // ********* ROUTE QUALIFICATION **********

  public createRouteUserQualification(
    route: RouteQualification,
  ): Promise<RouteQualification[]> {
    return firstValueFrom(
      this.httpClient.post<RouteQualification[]>(
        `${this.config.host}/route-qualifications`,
        {
          items: [route],
        },
      ),
    );
  }

  public updateRouteUserQualification(
    route: RouteQualification,
    qualificationId: string,
  ): Promise<RouteQualification> {
    return firstValueFrom(
      this.httpClient.patch<RouteQualification>(
        `${this.config.host}/route-qualifications/${qualificationId}`,
        route,
      ),
    );
  }

  public getRouteUserQualification(id: string): Observable<RouteQualification> {
    return this.httpClient.get<RouteQualification>(
      `${this.config.host}/route-qualifications/${id}`,
    );
  }

  public getRouteUserQualificationHistory(
    qualificationId: string,
    options?: TypeOrmFindManyOptions,
  ): Observable<RouteQualification[]> {
    const params = stringify(options);
    return this.httpClient.get<RouteQualification[]>(
      `${this.config.host}/route-qualifications/${qualificationId}/history?${params}`,
    );
  }

  // ********* DRIVER LICENSES **********

  public getDriverLicenses(): Observable<DriverLicense[]> {
    return this.httpClient.get<DriverLicense[]>(
      `${this.config.host}/driver-licenses`,
    );
  }

  public getDriverLicense(id: string): Observable<DriverLicense> {
    return this.httpClient.get<DriverLicense>(
      `${this.config.host}/driver-licenses/${id}`,
    );
  }

  public createDriverLicenseQualification(
    driverLicense: DriverLicenseQualification,
  ): Promise<DriverLicenseQualification[]> {
    return firstValueFrom(
      this.httpClient.post<DriverLicenseQualification[]>(
        `${this.config.host}/driver-license-qualifications`,
        { items: [driverLicense] },
      ),
    );
  }

  public updateDriverLicenseQualification(
    driverLicense: DriverLicenseQualification,
    qualificationId: string,
  ): Promise<DriverLicenseQualification> {
    return firstValueFrom(
      this.httpClient.patch<DriverLicenseQualification>(
        `${this.config.host}/driver-license-qualifications/${qualificationId}`,
        driverLicense,
      ),
    );
  }

  public getDriverLicenseQualification(
    id: string,
  ): Observable<DriverLicenseQualification> {
    return this.httpClient.get<DriverLicenseQualification>(
      `${this.config.host}/driver-license-qualifications/${id}`,
    );
  }

  public getDriverLicenseUserQualificationHistory(
    qualificationId: string,
    options?: TypeOrmFindManyOptions,
  ): Observable<DriverLicenseQualification[]> {
    const params = stringify(options);
    return this.httpClient.get<DriverLicenseQualification[]>(
      `${this.config.host}/driver-license-qualifications/${qualificationId}/history?${params}`,
    );
  }

  // ********* FURTHER EDUCATION **********

  public getFurtherEducations(): Observable<FurtherEducation[]> {
    return this.httpClient.get<FurtherEducation[]>(
      `${this.config.host}/further-educations`,
    );
  }

  public getFurtherEducation(id: string): Observable<FurtherEducation> {
    return this.httpClient.get<FurtherEducation>(
      `${this.config.host}/further-educations/${id}`,
    );
  }

  public createFurtherEducationQualification(
    furtherEducation: FurtherEducationQualification,
  ): Promise<FurtherEducationQualification[]> {
    return firstValueFrom(
      this.httpClient.post<FurtherEducationQualification[]>(
        `${this.config.host}/further-education-qualifications`,
        { items: [furtherEducation] },
      ),
    );
  }

  public updateFurtherEducationQualification(
    furtherEducation: FurtherEducationQualification,
    qualificationId: string,
  ): Promise<FurtherEducationQualification> {
    return firstValueFrom(
      this.httpClient.patch<FurtherEducationQualification>(
        `${this.config.host}/further-education-qualifications/${qualificationId}`,
        furtherEducation,
      ),
    );
  }

  public getFurtherEducationQualification(
    id: string,
  ): Observable<FurtherEducationQualification> {
    return this.httpClient.get<FurtherEducationQualification>(
      `${this.config.host}/further-education-qualifications/${id}`,
    );
  }

  public getFurtherEducationUserQualificationHistory(
    qualificationId: string,
    options?: TypeOrmFindManyOptions,
  ): Observable<FurtherEducationQualification[]> {
    const params = stringify(options);
    return this.httpClient.get<FurtherEducationQualification[]>(
      `${this.config.host}/further-education-qualifications/${qualificationId}/history?${params}`,
    );
  }

  // ********* MEDICAL EXAMIMATIONS **********

  public getMedicalExaminations(): Observable<MedicalExamination[]> {
    return this.httpClient.get<MedicalExamination[]>(
      `${this.config.host}/medical-examinations`,
    );
  }

  public getMedicalExamination(id: string): Observable<MedicalExamination> {
    return this.httpClient.get<MedicalExamination>(
      `${this.config.host}/medical-examinations/${id}`,
    );
  }

  public createMedicalExaminationQualification(
    medicalExamination: MedicalExaminationQualification,
  ): Promise<MedicalExaminationQualification[]> {
    return firstValueFrom(
      this.httpClient.post<MedicalExaminationQualification[]>(
        `${this.config.host}/medical-examination-qualifications`,
        { items: [medicalExamination] },
      ),
    );
  }

  public updateMedicalExaminationQualification(
    medicalExamination: MedicalExaminationQualification,
    qualificationId: string,
  ): Promise<MedicalExaminationQualification> {
    return firstValueFrom(
      this.httpClient.patch<MedicalExaminationQualification>(
        `${this.config.host}/medical-examination-qualifications/${qualificationId}`,
        medicalExamination,
      ),
    );
  }

  public getMedicalExaminationQualification(
    id: string,
  ): Observable<MedicalExaminationQualification> {
    return this.httpClient.get<MedicalExaminationQualification>(
      `${this.config.host}/medical-examination-qualifications/${id}`,
    );
  }

  public getMedicalExaminationUserQualificationHistory(
    qualificationId: string,
    options?: TypeOrmFindManyOptions,
  ): Observable<MedicalExaminationQualification[]> {
    const params = stringify(options);
    return this.httpClient.get<MedicalExaminationQualification[]>(
      `${this.config.host}/medical-examination-qualifications/${qualificationId}/history?${params}`,
    );
  }

  // ********* OTHER QUALIFICATION **********

  public getOtherSubQualifications(): Observable<OtherSubQualification[]> {
    return this.httpClient.get<OtherSubQualification[]>(
      `${this.config.host}/other-qualification-sub-categories`,
    );
  }

  public getOtherSubQualification(
    id: string,
  ): Observable<OtherSubQualification> {
    return this.httpClient.get<OtherSubQualification>(
      `${this.config.host}/other-qualification-sub-categories/${id}`,
    );
  }

  public createOtherQualification(
    other: OtherQualification,
  ): Promise<OtherQualification[]> {
    return firstValueFrom(
      this.httpClient.post<OtherQualification[]>(
        `${this.config.host}/other-qualifications`,
        {
          items: [other],
        },
      ),
    );
  }

  public updateOtherQualification(
    other: OtherQualification,
    qualificationId: string,
  ): Promise<OtherQualification> {
    return firstValueFrom(
      this.httpClient.patch<OtherQualification>(
        `${this.config.host}/other-qualifications/${qualificationId}`,
        other,
      ),
    );
  }

  public getOtherQualification(
    qualificationId: string,
  ): Observable<OtherQualification> {
    return this.httpClient.get<OtherQualification>(
      `${this.config.host}/other-qualifications/${qualificationId}`,
    );
  }

  public getOtherUserQualificationHistory(
    qualificationId: string,
    options?: TypeOrmFindManyOptions,
  ): Observable<OtherQualification[]> {
    const params = stringify(options);
    return this.httpClient.get<OtherQualification[]>(
      `${this.config.host}/other-qualifications/${qualificationId}/history?${params}`,
    );
  }

  public exportUserQualifications(
    exportUserQualificationsPayload: QualificationExportPayload,
    userId: string,
    firstName: string,
    lastName: string,
    partialFileName: string,
  ): Observable<Blob> {
    return this.httpClient
      .post<Blob>(
        `${this.config.host}/user-qualifications/users/${userId}/export`,
        {
          translations: exportUserQualificationsPayload,
        },
        {
          responseType: 'blob' as 'json',
        },
      )
      .pipe(
        tap((data: Blob) => {
          this.downloadFile(data, firstName, lastName, partialFileName, 'zip');
        }),
      );
  }

  public exportSharedUserQualifications(
    exportUserQualificationsPayload: QualificationExportPayload,
    sharedUserId: string,
    firstName: string,
    lastName: string,
    partialFileName: string,
  ): Observable<Blob> {
    return this.httpClient
      .post<Blob>(
        `${this.config.host}/shared-users/${sharedUserId}/qualifications/export`,
        {
          translations: exportUserQualificationsPayload,
        },
        {
          responseType: 'blob' as 'json',
        },
      )
      .pipe(
        tap((data: Blob) => {
          this.downloadFile(data, firstName, lastName, partialFileName, 'zip');
        }),
      );
  }

  public createComplementaryCertificate(
    translationsPayload: QualificationExportPayload,
    sharedUserId,
    firstName: string,
    lastName: string,
    partialFileName: string,
  ): Observable<Blob> {
    return this.httpClient
      .post<Blob>(
        `${this.config.host}/shared-users/${sharedUserId}/complementary-certificates/export`,
        {
          translatedQualifications: translationsPayload.qualifications,
        },
        {
          responseType: 'blob' as 'json',
        },
      )
      .pipe(
        tap((data: Blob) => {
          this.downloadFile(data, firstName, lastName, partialFileName, 'pdf');
        }),
      );
  }

  private downloadFile(
    data: Blob,
    firstName: string,
    lastName: string,
    partialFileName: string,
    fileType: string,
  ) {
    const currentDate = format(new Date(), 'yyMMdd');
    const fileName = `${currentDate}-${firstName}-${lastName}-${partialFileName}.${fileType}`;
    const url = window.URL.createObjectURL(data);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
}
