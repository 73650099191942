<input
  nz-input
  [placeholder]="readonly ? '---' : (placeholder | translate)"
  (input)="search($event.target.value)"
  [disabled]="readonly"
  [ngClass]="disabled ? 'form-control-disabled' : 'form-control'"
  class="text-dark"
  [class.is-invalid]="invalid"
  [class.location-warning]="warning"
  (ngModelChange)="setSelectedLocation($event)"
  [nzAutocomplete]="customLocationsAutoComplete"
  (focus)="onTouch()"
  [tabindex]="tabindexValue"
  [ngModel]="
    selectedValue | async | locationName : { emptyStringOnError: true }
  "
/>
<nz-autocomplete #customLocationsAutoComplete>
  <nz-auto-option
    *ngFor="let location of locationOptions$ | async"
    [nzLabel]="location | locationName"
    [nzValue]="location"
    nz-tooltip
    [nzTooltipOverlayClassName]="'light'"
    [nzTooltipPlacement]="'right'"
    [nzTooltipTitle]="location | locationName"
  >
    <wilson-location-list [location]="location"></wilson-location-list>
  </nz-auto-option>
</nz-autocomplete>
