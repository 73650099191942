import { inject } from '@angular/core';
import { CanMatchFn, Route, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FeatureFlagsService } from '../feature-flags.service';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { map } from 'rxjs/operators';
import { NzNotificationService } from 'ng-zorro-antd/notification';

export const canMatchFeatureGuardFn: CanMatchFn = (route: Route) => {
  const featureFlagsService = inject(FeatureFlagsService);
  const translate = inject(TranslateService);
  const toastr = inject(NzNotificationService);
  const router = inject(Router);

  return featureFlagsService.flag$.pipe(
    map((flags: LDFlagSet) => {
      const featureFlag: string = route.data?.['featureFlag'];

      const isFeatureEnabled = flags[featureFlag];

      if (!isFeatureEnabled) {
        toastr.error(translate.instant('error.forbidden'), '');
        return router.createUrlTree(['/']);
      } else {
        return isFeatureEnabled;
      }
    }),
  );
};
