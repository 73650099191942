import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Pipe({
  name: 'formControlInvalid',
  pure: false,
})
export class FormControlInvalidPipe implements PipeTransform {
  transform(
    control: AbstractControl,
    disableTouchAndDirtyCheck = false,
  ): boolean {
    if (disableTouchAndDirtyCheck) {
      return control.invalid;
    } else {
      return control.invalid && (control.dirty || control.touched);
    }
  }
}
