import { Injectable } from '@angular/core';
import { State, Action, StateContext, Store, Selector } from '@ngxs/store';
import { patch, updateItem, removeItem } from '@ngxs/store/operators';
import { UserTimePreferencesService } from '@wilson/api/gateway';
import { AuthState } from '@wilson/auth/core';
import { UserTimePreference } from '@wilson/interfaces';
import { BehaviorSubject, catchError, map, of, tap } from 'rxjs';
import { PreferencesStateName } from '../user-preferences.state';
import {
  CreateUserTimePreference,
  DeleteUserTimePreference,
  FetchUserTimePreferences,
  UpdateUserTimePreference,
} from './user-time-preferences.actions';
import { Storage } from '@ionic/storage';

export const UserTimePreferencesStateName = 'userTimePreferences';

export interface UserTimePreferenceSettingStateModel {
  selectedPreferences: UserTimePreference[];
  loadingPreference: boolean;
  loadingPreferenceSuccess: boolean;
  loadingPreferenceError: boolean;
}
const defaults = {
  selectedPreferences: [],
  loadingPreference: false,
  loadingPreferenceSuccess: false,
  loadingPreferenceError: false,
};

@State<UserTimePreferenceSettingStateModel>({
  name: UserTimePreferencesStateName,
  defaults,
})
@Injectable()
export class UserTimePreferencesState {
  private readonly syncing = new BehaviorSubject<boolean>(false);
  readonly syncing$ = this.syncing.asObservable();

  constructor(
    private readonly storage: Storage,
    private readonly userTimePreferencesService: UserTimePreferencesService,
    private readonly store: Store,
  ) {}

  @Selector()
  static loadingPreference(state: UserTimePreferenceSettingStateModel) {
    return state.loadingPreference;
  }

  @Selector()
  static selectedUserTimePreferences(
    state: UserTimePreferenceSettingStateModel,
  ): UserTimePreference[] {
    return state.selectedPreferences;
  }

  @Action(FetchUserTimePreferences)
  FetchUserTimePreferences(
    context: StateContext<UserTimePreferenceSettingStateModel>,
  ) {
    const userId = this.store.selectSnapshot(AuthState.userId);
    if (!userId) return of(null);

    this.syncing.next(true);

    return this.userTimePreferencesService.getUserTimePreferences(userId).pipe(
      map((selectedPreferences) => ({ selectedPreferences })),
      tap({
        next: (preferences) => {
          context.patchState(preferences);
          this.storage.set(PreferencesStateName, preferences);
        },
        finalize: () => this.syncing.next(false),
      }),
      catchError(() => of(null)),
    );
  }

  @Action(CreateUserTimePreference) async createUserTimePreference(
    context: StateContext<UserTimePreferenceSettingStateModel>,
    { userTimePreference }: CreateUserTimePreference,
  ) {
    const currentState = context.getState().selectedPreferences;

    context.patchState({
      selectedPreferences: [...currentState, userTimePreference],
    });

    return this.userTimePreferencesService.createUserTimePreference(
      userTimePreference,
    );
  }

  @Action(UpdateUserTimePreference) async updateUserTimePreference(
    context: StateContext<UserTimePreferenceSettingStateModel>,
    { userTimePreference }: UpdateUserTimePreference,
  ) {
    context.setState(
      patch({
        selectedPreferences: updateItem<UserTimePreference>(
          ({ id }) => userTimePreference.id === id,
          userTimePreference,
        ),
      }),
    );

    this.storage.set(PreferencesStateName, context.getState());

    return this.userTimePreferencesService.updateUserTimePreference(
      userTimePreference.id as string,
      userTimePreference,
    );
  }

  @Action(DeleteUserTimePreference) async deleteUserTimePreference(
    context: StateContext<UserTimePreferenceSettingStateModel>,
    { id }: DeleteUserTimePreference,
  ) {
    context.setState(
      patch({
        selectedPreferences: removeItem<UserTimePreference>((p) => id === p.id),
      }),
    );

    this.storage.set(PreferencesStateName, context.getState());

    return this.userTimePreferencesService.deleteUserTimePreference(id);
  }
}
