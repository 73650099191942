import { Injectable } from '@angular/core';
import { StateOperator } from '@ngxs/store';
import { append, patch, updateItem } from '@ngxs/store/operators';
import { activityReportFactory } from '@wilson/activities/util';
import {
  ActivityReportCategory,
  ActivityReportType,
  OperationStatus,
  ResolvedActivityReport,
  ResolvedActivityWithReports,
} from '@wilson/interfaces';
import { ResolvedShiftActions } from './state/resolved-shifts.action';
import {
  ResolvedShiftsStateModel,
  ResolvedShiftWithSyncStatus,
} from './state/resolved-shifts.state';

@Injectable()
export class NextLiveShiftActivityStateService {
  patchNextLiveShiftActivity(
    action: ResolvedShiftActions.StartNextActivityLiveShift,
    shift: ResolvedShiftWithSyncStatus,
  ): StateOperator<ResolvedShiftsStateModel>[] {
    const currentActivity = action.currentActivity;
    const nextActivity = action.nextActivity;
    const pausedActivity = action.pausedActivity;

    let stateOperators: StateOperator<ResolvedShiftsStateModel>[] = [];

    stateOperators = [
      ...stateOperators,
      patch<ResolvedShiftsStateModel>({
        shifts: updateItem<ResolvedShiftWithSyncStatus>(
          (s) => s?.id === shift.id,
          patch<ResolvedShiftWithSyncStatus>({
            syncedAt: null,
          }),
        ),
      }),
    ];

    if (pausedActivity?.id) {
      stateOperators = [
        ...stateOperators,
        patch<ResolvedShiftsStateModel>({
          shifts: updateItem<ResolvedShiftWithSyncStatus>(
            (s) => s?.id === shift.id,
            patch<ResolvedShiftWithSyncStatus>({
              activities: updateItem<ResolvedActivityWithReports>(
                (a) => a?.id === pausedActivity.id,
                patch<ResolvedActivityWithReports>({
                  operationalStatus: OperationStatus.Completed,
                  activityReports: append<ResolvedActivityReport>([
                    activityReportFactory(
                      pausedActivity.id,
                      action.currentReportedStart.dateTime,
                      pausedActivity.endLocation,
                      pausedActivity.endLocationId,
                      ActivityReportCategory.End,
                      ActivityReportType.IsTimes,
                    ),
                  ]),
                }),
              ),
            }),
          ),
        }),
      ];
    }

    stateOperators = [
      ...stateOperators,
      patch<ResolvedShiftsStateModel>({
        shifts: updateItem<ResolvedShiftWithSyncStatus>(
          (s) => s?.id === shift.id,
          patch<ResolvedShiftWithSyncStatus>({
            activities: updateItem<ResolvedActivityWithReports>(
              (a) => a?.id === currentActivity.id,
              patch<ResolvedActivityWithReports>({
                operationalStatus: OperationStatus.Completed,
                activityReports: append<ResolvedActivityReport>([
                  activityReportFactory(
                    currentActivity.id as string,
                    action.dateTime,
                    currentActivity.endLocation,
                    currentActivity.endLocationId,
                    ActivityReportCategory.End,
                    ActivityReportType.IsTimes,
                  ),
                ]),
              }),
            ),
          }),
        ),
      }),
    ];

    stateOperators = [
      ...stateOperators,
      patch<ResolvedShiftsStateModel>({
        shifts: updateItem<ResolvedShiftWithSyncStatus>(
          (s) => s?.id === shift.id,
          patch<ResolvedShiftWithSyncStatus>({
            activities: updateItem<ResolvedActivityWithReports>(
              (a) => a?.id === nextActivity.id,
              patch<ResolvedActivityWithReports>({
                operationalStatus: OperationStatus.Ongoing,
                activityReports: append<ResolvedActivityReport>([
                  activityReportFactory(
                    nextActivity.id as string,
                    action.dateTime,
                    nextActivity.startLocation,
                    nextActivity.startLocationId,
                    ActivityReportCategory.Start,
                    ActivityReportType.IsTimes,
                  ),
                ]),
              }),
            ),
          }),
        ),
      }),
    ];

    return stateOperators;
  }
}
