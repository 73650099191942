import { GeoLocation } from '@wilson/interfaces';

export class FetchAndResolveLocationSettingsRemotely {
  static readonly type =
    '[LocationPreferences] Fetch and resolve remotely location settings';
}

export class CreatePreselectedLocations {
  static readonly type = '[LocationPreferences] Create Preselected Locations';
  constructor(
    public payload: {
      geoLocation: GeoLocation;
    },
  ) {}
}

export class RemovePreselectedLocations {
  static readonly type = '[LocationPreferences] Remove Preselected Locations';
  constructor(
    public payload: {
      settingId: string;
    },
  ) {}
}
