import { Storage } from '@ionic/storage-angular';
import { Store } from '@ngxs/store';
import { InitAuthState } from '@wilson/auth/core';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { firstValueFrom } from 'rxjs';

export const storageAndAuthStateInitializer =
  (storage: Storage, store: Store) => async () => {
    await storage.defineDriver(CordovaSQLiteDriver);
    await storage.create();
    return firstValueFrom(store.dispatch(new InitAuthState()));
  };
