export class ResetBulkEditDrawerState {
  static readonly type =
    '[ActivityLikeBulkEditDrawer] ResetBulkEditDrawerState';
}

export class SetBulkEditDrawerOpenStatus {
  static readonly type =
    '[ActivityLikeBulkEditDrawer] SetBulkEditDrawerOpenStatus';
  constructor(public openStatus: boolean) {}
}

export class SetBulkEditDrawerSaveStatus {
  static readonly type =
    '[ActivityLikeBulkEditDrawer] SetBulkEditDrawerSaveStatus';
  constructor(public saveStatus: boolean) {}
}
