import { Injectable } from '@angular/core';
import {
  Action,
  Selector,
  State,
  StateContext,
  createSelector,
} from '@ngxs/store';
import { PartnershipGateway } from '@wilson/api/gateway';
import { Logout } from '@wilson/auth/core';
import { Activity } from '@wilson/interfaces';
import { Partner } from '@wilson/share/interfaces';
import { catchError, finalize, of, take, tap } from 'rxjs';
import {
  ClearPartnerships,
  InitializePartnerships,
} from './partnerships.action';

export interface PartnershipsStateModel {
  partners: Partner[];
  isLoading: boolean;
}

const defaults: PartnershipsStateModel = {
  partners: [],
  isLoading: false,
};

@State<PartnershipsStateModel>({
  name: 'clients',
  defaults: defaults,
})
@Injectable()
export class PartnershipsState {
  constructor(private readonly partnershipGateway: PartnershipGateway) {}

  @Selector()
  static partners(state: PartnershipsStateModel): Partner[] {
    return state.partners;
  }

  @Selector()
  static isLoading(state: PartnershipsStateModel): boolean {
    return state.isLoading;
  }

  static canEditActivities(activities: Activity[]) {
    return createSelector(
      [PartnershipsState],
      (state: PartnershipsStateModel): boolean | undefined => {
        return activities.every((activity) => {
          if (!activity.job || !activity.job.organizationalUnitId) {
            return true;
          }
          const partnerOrgUnitId = activity.job.organizationalUnitId;
          const matchingPartner = state.partners.find(
            (partner) =>
              partner.partnerOrganizationalUnit.id === partnerOrgUnitId,
          );
          return matchingPartner
            ? matchingPartner.partnerShareSettings.canEditActivity
            : false;
        });
      },
    );
  }

  static canEditActivity(
    partnerOrgUnitId: string,
  ): (state: PartnershipsStateModel) => boolean {
    return createSelector(
      [PartnershipsState],
      (state: PartnershipsStateModel): boolean => {
        const matchingPartner = state.partners.find(
          (partner) =>
            partner.partnerOrganizationalUnit.id === partnerOrgUnitId,
        );
        return matchingPartner
          ? !!matchingPartner.partnerShareSettings.canEditActivity
          : false;
      },
    );
  }

  static canSplitActivity(
    partnerOrgUnitId: string,
  ): (state: PartnershipsStateModel) => boolean {
    return createSelector(
      [PartnershipsState],
      (state: PartnershipsStateModel): boolean => {
        const matchingPartner = state.partners.find(
          (partner) =>
            partner.partnerOrganizationalUnit.id === partnerOrgUnitId,
        );
        return matchingPartner
          ? !!matchingPartner.partnerShareSettings.canSplitActivity
          : false;
      },
    );
  }

  @Action(InitializePartnerships)
  initializePartnerships(ctx: StateContext<PartnershipsStateModel>) {
    ctx.patchState({ isLoading: true });
    return this.partnershipGateway
      .getAll({ relations: ['clientPartnerships'] })
      .pipe(
        take(1),
        tap((partners) => {
          ctx.patchState({
            partners: partners,
          });
        }),
        catchError(() => {
          return of(null);
        }),
        finalize(() => {
          ctx.patchState({ isLoading: false });
        }),
      );
  }

  @Action([ClearPartnerships, Logout])
  clearPartnerships(ctx: StateContext<PartnershipsStateModel>) {
    ctx.setState(defaults);
  }
}
