import { Base } from '@wilson/base';
import { User } from '../accounts/User';
import { AbsenceCategory } from './absenceCategory';

export enum AbsenceStates {
  'open' = 'open',
  'accepted' = 'accepted',
  'declined' = 'declined',
  'withdrawn' = 'withdrawn',
}

export enum AbsenceCategoriesIdsMap {
  SickLeave = 'b83e4eca-5d2e-40cf-bf60-3f99d35885fc',
  Vacation = 'e3bbd273-c7bb-4fd3-99ba-d9daf1b11f64',
}

export interface AbsenceEntity {
  absentFrom: string;
  absentTo: string;
  status: AbsenceStates;
  userId: string;
  absenceCategoryId: AbsenceCategory['id'];
  halfDayStart?: boolean;
  halfDayEnd?: boolean;
  requestNote?: string;
  responseNote?: string;
}

export interface AbsenceFields extends AbsenceEntity {
  attachementUrl?: string;
  remark?: string;
  user?: User;
}

export type AbsenceWithCategory = Absence & {
  absenceCategory: AbsenceCategory;
};

export type AbsenceWithConsumedDays = Absence & {
  consumedVacations: Date[];
};

export type AbsenceWithConsumedDaysAndCategory = AbsenceWithConsumedDays &
  AbsenceWithCategory;

export interface Absence extends AbsenceFields, Base {}

export enum DayTypes {
  FullDay = 'full_day',
  FirstHalf = 'first_half',
  SecondHalf = 'second_half',
}
