import { EndShiftAtHomePreferenceRating } from '@wilson/interfaces';
import { LongShiftPreferenceRating } from '@wilson/preferences/core';

export class FetchUserGeneralPreferences {
  static readonly type = '[UserGeneralPreference] FetchUserGeneralPreference';
}
export class SetEndShiftAtHomePreferenceAction {
  static readonly type = '[UserGeneralPreference] SetEndShiftAtHomePreference';
  constructor(
    public payload: {
      rating: EndShiftAtHomePreferenceRating;
    },
  ) {}
}

export class ResetEndShiftAtHomePreferenceAction {
  static readonly type =
    '[UserGeneralPreference] Reset End Shift At HomePreference';
}

export class SetLongShiftPreferenceAction {
  static readonly type = '[UserGeneralPreference] SetLongShiftPreference';
  constructor(
    public payload: {
      rating: LongShiftPreferenceRating;
    },
  ) {}
}

export class ResetLongShiftPreferenceAction {
  static readonly type =
    '[UserGeneralPreference] ResetLongShiftPreferenceAction';
}
