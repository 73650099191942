import {
  DRAG_IMAGE_HEIGHT,
  DRAG_IMAGE_OFFSCREEN_TOP,
  DRAG_IMAGE_OFFSET_X,
  DRAG_IMAGE_OFFSET_Y,
  DRAG_IMAGE_TIMEOUT,
  DRAG_IMAGE_WIDTH,
} from '@wilson/interfaces';

export function createDragImageForLargeElements(event: DragEvent) {
  const target = event.target as HTMLElement;

  const newDragImage = document.createElement('div');
  const computedStyle = window.getComputedStyle(target);
  newDragImage.style.width = `${DRAG_IMAGE_WIDTH}px`;
  newDragImage.style.height = `${DRAG_IMAGE_HEIGHT}px`;
  newDragImage.style.backgroundColor = computedStyle.backgroundColor;
  newDragImage.style.border = computedStyle.border;
  newDragImage.style.padding = computedStyle.padding;
  newDragImage.style.margin = computedStyle.margin;
  newDragImage.style.position = 'absolute';
  newDragImage.style.top = `${DRAG_IMAGE_OFFSCREEN_TOP}px`;

  document.body.appendChild(newDragImage);

  event.dataTransfer?.setDragImage(
    newDragImage,
    DRAG_IMAGE_OFFSET_X,
    DRAG_IMAGE_OFFSET_Y,
  );

  setTimeout(() => {
    document.body.removeChild(newDragImage);
  }, DRAG_IMAGE_TIMEOUT);
}
