import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthState } from '@wilson/auth/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { OrganizationalUnitSector, Sector } from '@wilson/interfaces';
import { Observable, of } from 'rxjs';
import { catchError, map, skipWhile, switchMap } from 'rxjs/operators';

@Injectable()
export class SectorsService {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
    private readonly store: Store,
  ) {}

  public getSignedInUsersRootOrgUnitSector(): Observable<Sector[]> {
    return this.store.select(AuthState.rootOrgUnit).pipe(
      skipWhile((orgUnit) => !orgUnit),
      switchMap((orgUnit) => this.getOrganizationalUnitSectors(orgUnit.id)),
      map((os) => os.map((o) => o.sector)),
      catchError(() => of([])),
    );
  }

  getSectors(): Observable<Sector[]> {
    return this.httpClient.get<Sector[]>(`${this.config.host}/sectors`);
  }

  getOrganizationalUnitSectors(
    organizationalUnitId: string,
  ): Observable<OrganizationalUnitSector[]> {
    return this.httpClient.post<OrganizationalUnitSector[]>(
      `${this.config.host}/organizational-unit-sectors/search`,
      { organizationalUnitId },
    );
  }

  createOrganizationalUnitSector(
    organizationalUnitId: string,
    sectorIds: string[],
  ): Promise<OrganizationalUnitSector[]> {
    const organizationalUnitSectors: OrganizationalUnitSector[] = sectorIds.map(
      (sectorId) =>
        ({ organizationalUnitId, sectorId } as OrganizationalUnitSector),
    );
    return this.httpClient
      .post<OrganizationalUnitSector[]>(
        `${this.config.host}/organizational-unit-sectors`,
        { items: organizationalUnitSectors },
      )
      .toPromise();
  }

  deleteOrganizationalUnitSector(
    organizationalUnitSectorId: string,
  ): Promise<OrganizationalUnitSector> {
    return this.httpClient
      .delete<OrganizationalUnitSector>(
        `${this.config.host}/organizational-unit-sectors/${organizationalUnitSectorId}`,
      )
      .toPromise();
  }
}
