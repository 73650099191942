import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'wilson-smart-score-renderer',
  styleUrls: ['./renderers.scss'],
  template: `
    <div class="d-flex justify-content-between align-items-center">
      <div class="text-dark ">{{ cellValue?.score }}</div>
      <div
        class="score-bar"
        nz-tooltip
        [nzTooltipTitle]="tooltipInformation"
        nzTooltipOverlayClassName="dark"
        nzTooltipPlacement="right"
        [nzTooltipOverlayStyle]="{ 'min-width': '400px' }"
      >
        <div [ngStyle]="styleObject" class="display-score"></div>
      </div>
    </div>
    <ng-template #tooltipInformation>
      <wilson-tooltip
        [header]="'tooltip.header.smart_score' | translate"
        [details]="'tooltip.subheader.route_information_enter' | translate"
        [shiftScoreCard]="this.toolTipDetails"
        [isShiftScoreCard]="true"
      >
      </wilson-tooltip>
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartScoreRendererComponent implements AgRendererComponent {
  maximumValue = 5;
  styleObject = {};
  public cellValue: {
    score: number;
  };
  public toolTipDetails = [];

  agInit(params: ICellRendererParams): void {
    this.cellValue = this.getValueToDisplay(params);
    // Tool Tip Calculation
    const cellDetail = params.data?.scores;
    cellDetail.forEach((cell) => {
      if (cell.name === 'shift-transfer-score') {
        if (cell.score === 1) {
          this.toolTipDetails.push({
            name: 'tooltip.no_transfers_necessary',
            color: 'bg-primary',
          });
        } else if (cell.score >= 0.5) {
          this.toolTipDetails.push({
            name: 'tooltip.transfers_necessary',
            color: 'bg-warning',
          });
        } else if (cell.score < 0.5) {
          this.toolTipDetails.push({
            name: 'tooltip.long_transfers_necessary',
            color: 'bg-danger',
          });
        }
      }
      if (cell.name === 'shift-productivity-score') {
        if (cell.score >= 0.7) {
          this.toolTipDetails.push({
            name: 'tooltip.high_shift_productivity',
            color: 'bg-primary',
          });
        } else if (cell.score < 0.2) {
          this.toolTipDetails.push({
            name: 'tooltip.very_low_shift_productivity',
            color: 'bg-danger',
          });
        } else if (cell.score < 0.5) {
          this.toolTipDetails.push({
            name: 'tooltip.low_shift_productivity',
            color: 'bg-warning',
          });
        }
      }
      if (cell.name === 'shift-worktime-score') {
        if (cell.score >= 0.7) {
          this.toolTipDetails.push({
            name: 'tooltip.user_still_has_available_hours_during_week',
            color: 'bg-primary',
          });
        } else if (cell.score < 0.2) {
          this.toolTipDetails.push({
            name: 'tooltip.user_already_has_high_number_of_hours_week',
            color: 'bg-danger',
          });
        } else if (cell.score < 0.5) {
          this.toolTipDetails.push({
            name: 'tooltip.user_has_average_workload_week',
            color: 'bg-warning',
          });
        }
      }
      if (cell.name === 'shift-requirements-score') {
        if (cell.score === 1) {
          this.toolTipDetails.push({
            name: 'tooltip.user_fulfills_all_shift_requirements',
            color: 'bg-primary',
          });
        } else if (cell.score < 1) {
          this.toolTipDetails.push({
            name: 'tooltip.user_does_not_fulfill_all_shift_requirements',
            color: 'bg-danger',
          });
        }
      }
    });
  }

  refresh(params: ICellRendererParams) {
    this.cellValue = this.getValueToDisplay(params);
    return true;
  }

  getColorByValue(value: number) {
    if (value < 2.5) return 'var(--red-5)';
    if (value < 3.5) return 'var(--gold-4)';
    else return 'var(--primary)';
  }
  getWithOfBar(value: number) {
    return (100 / this.maximumValue) * value + '%';
  }

  createStyleObject(score: number) {
    const width = this.getWithOfBar(score);
    const backgroundColor = this.getColorByValue(score);
    this.styleObject = { width: width, background: backgroundColor };
  }

  getValueToDisplay(params: ICellRendererParams) {
    const scoreObj = params?.data?.scores.filter(
      (score) => score.name === 'shift-smart-score',
    );
    const score =
      scoreObj.length && scoreObj[0].score
        ? Number((scoreObj[0].score * 5).toFixed(1))
        : 0;
    this.createStyleObject(score);
    return { score };
  }
}
