import { Injectable } from '@angular/core';
import {
  State,
  Action,
  StateContext,
  createSelector,
  Selector,
} from '@ngxs/store';
import { GeoLocation } from '@wilson/interfaces';
import { LocationsService } from '../locations.service';
import { GetLocationAction } from './location.actions';
import { catchError, finalize, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
export interface LocationStateModel {
  items: {
    [key in string]: GeoLocation;
  };
}

const defaults = {
  items: {},
};

@State<LocationStateModel>({
  name: 'location',
  defaults,
})
@Injectable()
export class LocationState {
  private inflightRequests = new Map<string, Observable<GeoLocation | null>>();

  constructor(private locationsService: LocationsService) {}

  @Selector()
  static locationItems(state: LocationStateModel) {
    return state.items;
  }

  static location(id: string) {
    return createSelector(
      [LocationState.locationItems],
      (items: {
        [key in string]: GeoLocation;
      }) => {
        return items[id];
      },
    );
  }

  @Action(GetLocationAction)
  fetchGeolocation(
    { getState, patchState }: StateContext<LocationStateModel>,
    { id }: GetLocationAction,
  ) {
    const state = getState();
    if (this.inflightRequests.has(id)) {
      return this.inflightRequests.get(id);
    } else {
      const request = this.locationsService.get(id).pipe(
        tap((location) => {
          patchState({
            items: {
              ...state.items,
              [id]: location,
            },
          });
        }),
        catchError(() => of(null)),
        finalize(() => {
          this.inflightRequests.delete(id);
        }),
      );
      this.inflightRequests.set(id, request);
      return request;
    }
  }
}
