import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShiftsService } from './shifts.service';
import { ShiftSeriesService } from './shift-series.service';
import { ShiftAssignmentService } from './shift-assignment.service';
import { ShiftUtilityService } from './shift-utility.service';
import { ShiftValidationErrorService } from './shift-validation-error.service';
import { DragAndDropModeService } from '@wilson/shift-timeline/services';

@NgModule({
  imports: [CommonModule],
  providers: [
    ShiftsService,
    ShiftSeriesService,
    ShiftAssignmentService,
    ShiftUtilityService,
    ShiftValidationErrorService,
    DragAndDropModeService,
  ],
})
export class ShiftsModule {}
