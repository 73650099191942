import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AccountService } from '@wilson/account';
import { AuthState } from '@wilson/auth/core';
import { User } from '@wilson/interfaces';
import Talk from 'talkjs';
import {
  firstValueFrom,
  forkJoin,
  Observable,
  of,
  ReplaySubject,
  shareReplay,
  Subscriber,
  switchMap,
} from 'rxjs';
import { GroupDataToEdit, TalkJsConfig } from './types';
import { TalkJsConfigToken } from './symbols';
import { TalkjsGateway } from './talkjs.gateway';
import { LangState } from '@wilson/languages';

type NonEmptyArray<T> = [T, ...T[]];

@Injectable({
  providedIn: 'root',
})
export class TalkjsService {
  private session$: ReplaySubject<Talk.Session> | null = null;

  constructor(
    @Inject(TalkJsConfigToken)
    private readonly config: TalkJsConfig,
    private readonly store: Store,
    private readonly accountService: AccountService,
    private readonly talkjsGateway: TalkjsGateway,
  ) {}

  private async createUser(
    user: Pick<User, 'id' | 'firstName'>,
  ): Promise<Talk.User> {
    await Talk.ready;

    const locale = this.store.selectSnapshot(LangState.getLang);

    return new Talk.User({
      id: `${user.id}`,
      email: null,
      name: user.firstName,
      photoUrl: null,
      role: this.config?.role,
      locale,
    });
  }

  private async initializeTalkjsSession(): Promise<Talk.Session> {
    await Talk.ready;

    const user = await firstValueFrom(
      this.store
        .select(AuthState.user)
        .pipe(switchMap((user) => (user ? of(user) : of()))),
    );
    const signature = await firstValueFrom(
      this.talkjsGateway.getLoggedInUserSignature(),
    );
    return new Talk.Session({
      appId: this.config?.talkjsAppId,
      me: await this.createUser(user),
      signature,
    });
  }

  /**
   * @deprecated use getSession instead
   */
  public async getOrCreateTalkjsSession(): Promise<Talk.Session> {
    return this.getSession();
  }

  public async getSession(reinitialize = false): Promise<Talk.Session> {
    if (!this.session$ || reinitialize) {
      if (reinitialize) {
        await this.destroyTalkjsSession();
      }

      this.session$ = new ReplaySubject(1);

      const session = await this.initializeTalkjsSession();
      this.session$.next(session);
    }

    return firstValueFrom(this.session$.asObservable().pipe(shareReplay(1)));
  }

  async destroyTalkjsSession(): Promise<void> {
    const session = await this.getSession();
    session.destroy();
    this.session$ = null;
  }

  private async getOrCreateConversation(conversationPartner: Talk.User) {
    const session = await this.getSession();
    const conversation = session.getOrCreateConversation(
      Talk.oneOnOneId(session.me as Talk.User, conversationPartner),
    );
    conversation.setParticipant(session.me as Talk.User);
    conversation.setParticipant(conversationPartner);
    return conversation;
  }

  public async createInboxWithAnotherUser(
    conversationPartner: Pick<User, 'id' | 'firstName'>,
  ): Promise<Talk.Inbox> {
    const session = await this.getSession();
    const conversation = await this.getOrCreateConversation(
      await this.createUser(conversationPartner),
    );

    return session.createInbox({
      selected: conversation,
      showChatHeader: true,
      showMobileBackButton: false,
    });
  }

  private async createNewGroup(
    conversationPartners: Pick<User, 'id' | 'firstName'>[],
    title: string,
  ) {
    const session = await this.getSession();
    const conversation = session.getOrCreateConversation(
      Talk.oneOnOneId(session.me as Talk.User, title),
    );
    conversation.setParticipant(session.me as Talk.User);
    const talkjsUsers = await Promise.all(
      conversationPartners.map((partner) => this.createUser(partner)),
    );
    for (const user of talkjsUsers) {
      conversation.setParticipant(user);
    }
    conversation.setAttributes({
      photoUrl: null,
      subject: title,
      custom: {
        ownerId: (session.me as Talk.User).id,
      },
    });
    return conversation;
  }

  editGroup(groupData: GroupDataToEdit) {
    return new Observable((editedGroup$: Subscriber<void>) => {
      const editTasks$: Promise<void>[] = [];
      if (!groupData || !groupData.conversationId) {
        editedGroup$.next();
        editedGroup$.complete();
        return;
      }
      groupData?.usersToAdd?.forEach((user) => {
        editTasks$.push(
          this.addUserToConversation(groupData.conversationId, `${user.id}`),
        );
      });
      if (groupData?.conversationSubject) {
        this.setConversationSubject(
          groupData.conversationId,
          groupData.conversationSubject,
        );
      }
      if (editTasks$.length) {
        forkJoin(editTasks$).subscribe(() => {
          editedGroup$.next();
          editedGroup$.complete();
          return;
        });
      } else {
        editedGroup$.next();
        editedGroup$.complete();
        return;
      }
    });
  }

  public async createInboxWithotherUsers(
    conversationPartners: NonEmptyArray<Pick<User, 'id' | 'firstName'>>,
    subject: string,
  ) {
    const session = await this.getSession();
    const conversation = await this.createNewGroup(
      conversationPartners,
      subject,
    );

    return session.createInbox({
      selected: conversation,
      showChatHeader: true,
      showMobileBackButton: false,
    });
  }

  async addUserToConversation(
    conversationId: string,
    userId: string,
  ): Promise<void> {
    const session = await this.getSession();
    const userToBeAdded = await firstValueFrom(
      this.accountService.getUser(userId),
    );
    const talkjsUserToBeAdded = await this.createUser({
      id: userId,
      firstName: userToBeAdded.firstName,
    });
    session
      ?.getOrCreateConversation(conversationId)
      .setParticipant(talkjsUserToBeAdded);
  }

  private async setConversationSubject(
    conversationId: string,
    conversationSubject: string,
  ): Promise<void | undefined> {
    const session = await this.getSession();
    return session
      ?.getOrCreateConversation(conversationId)
      .setAttributes({ subject: conversationSubject });
  }
}
