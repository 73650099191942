import { DEBUG_QUERY_PARAM_KEY } from './types';

let _wilsonIsDebugEnabled: boolean | undefined = undefined;

export function isDebugEnabled(): boolean {
  if (_wilsonIsDebugEnabled === undefined) {
    const urlParams = new URLSearchParams(window.location.search);
    _wilsonIsDebugEnabled = !!urlParams.get(DEBUG_QUERY_PARAM_KEY);
    if (_wilsonIsDebugEnabled) {
      console.log('DEBUG MODE ENABLED');
    }
  }

  return _wilsonIsDebugEnabled;
}
