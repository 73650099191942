import { Injectable } from '@angular/core';
import { Locale, Settings } from '../interfaces/settings.interface';
import { StorageService } from './storage.service';
import { Store } from '@ngxs/store';
import { SelectLanguage } from '@wilson/languages';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private storage: StorageService, private store: Store) {}

  async getSettings() {
    const res = await this.storage.persistent<Settings | null>('settings');
    return res;
  }

  async setLanguage(lang: Locale) {
    const settings = await this.getSettings();

    this.store.dispatch(new SelectLanguage(lang));

    await this.storage.persistent('settings', {
      ...settings,
      selectedLanguage: lang,
    });
  }
}
