import { Capacitor } from '@capacitor/core';
import { Store } from '@ngxs/store';
import { BackgroundFetch } from '@transistorsoft/capacitor-background-fetch';
import { RefreshAccessToken } from '@wilson/auth/core';
import { ResolvedShiftActions } from '@wilson/shifts-mobile';
import { firstValueFrom } from 'rxjs';
import { SetupSyncForAllUnrecievedOperativeReports } from '../modules/operative-reports/state/operative-reports.action';

export const backgroundTaskInitializer = (store: Store) => async () => {
  if (!Capacitor.isNativePlatform()) {
    return;
  }

  try {
    const status = await BackgroundFetch.configure(
      {
        minimumFetchInterval: 15,
      },
      async (taskId) => {
        await firstValueFrom(store.dispatch(new RefreshAccessToken()));
        await firstValueFrom(
          store.dispatch(
            new ResolvedShiftActions.SetupSyncForAllUnsychronizedShifts(),
          ),
        );
        await firstValueFrom(
          store.dispatch(new SetupSyncForAllUnrecievedOperativeReports()),
        );
        BackgroundFetch.finish(taskId);
      },
      async (taskId) => {
        BackgroundFetch.finish(taskId);
      },
    );

    if (status !== BackgroundFetch.STATUS_AVAILABLE) {
      if (status === BackgroundFetch.STATUS_DENIED) {
        console.warn(
          'The user explicitly disabled background behavior for this app or for the whole system.',
        );
      } else if (status === BackgroundFetch.STATUS_RESTRICTED) {
        console.warn(
          'Background updates are unavailable and the user cannot enable them again.',
        );
      }
    }
  } catch {
    return;
  }
};
