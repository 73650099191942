import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigService } from './config.service';

export interface ConfigOptions {
  host: string;
  application: string;
}

@NgModule({
  imports: [CommonModule],
})
export class ConfigModule {
  static forRoot(config: ConfigOptions): ModuleWithProviders<ConfigModule> {
    return {
      ngModule: ConfigModule,
      providers: [
        {
          provide: ConfigService,
          useValue: config,
        },
      ],
    };
  }
}
