import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Ability, PureAbility } from '@casl/ability';
import { AbilityModule } from '@casl/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { PermissionsService } from './permissions.service';
import { RolesService } from './roles.service';
import { ConfigModule } from '@wilson/config';
import { FeaturePurePipe } from './pipes/feature-pure.pipe';
import { RolesPipe } from './pipes/roles.pipe';

@NgModule({
  imports: [
    CommonModule,
    ConfigModule,
    ToastrModule,
    TranslateModule,
    AbilityModule,
  ],
  providers: [
    RolesService,
    PermissionsService,
    { provide: Ability, useValue: new Ability() },
    { provide: PureAbility, useExisting: Ability },
    FeaturePurePipe,
    RolesPipe,
  ],
  exports: [AbilityModule, FeaturePurePipe, RolesPipe],
  declarations: [FeaturePurePipe, RolesPipe],
})
export class AuthorizationModule {}
