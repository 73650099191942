import { Pipe, PipeTransform } from '@angular/core';
import { WilsonApp } from '@wilson/interfaces';

@Pipe({
  name: 'createdFromMobile',
})
export class CreatedFromMobilePipe implements PipeTransform {
  transform(value: unknown): unknown {
    if (typeof value !== 'string') return false;
    return value === WilsonApp.Mobile;
  }
}
