import { Injectable } from '@angular/core';
import { sortBy, findLast } from 'lodash';
import { ActivityReport, ActivityReportCategory } from '@wilson/interfaces';

@Injectable()
export class PlannedAndReportedTimeService {
  public reportedStartTime(activityReports: ActivityReport[]): string | null {
    if (!activityReports.length) return null;

    // @TODO remove sorting when backend is able to sort nested relations in /shifts endpoint
    activityReports = sortBy(activityReports, (a) => a.createdAt);

    const lastStartReport = findLast(
      activityReports,
      (a) => a.reportCategory === ActivityReportCategory.Start,
    );

    return lastStartReport ? lastStartReport.dateTime : null;
  }

  public reportedEndTime(activityReports: ActivityReport[]): string | null {
    if (!activityReports.length) return null;

    // @TODO remove sorting when backend is able to sort nested relations in /shifts endpoint
    activityReports = sortBy(activityReports, (a) => a.createdAt);

    const lastEndReport = findLast(
      activityReports,
      (a) => a.reportCategory === ActivityReportCategory.End,
    );
    return lastEndReport ? lastEndReport.dateTime : null;
  }
}
