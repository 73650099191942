import { Pipe, PipeTransform } from '@angular/core';
import {
  NotificationContentParams,
  QualificationCategoryName,
} from '@wilson/interfaces';
import { format } from 'date-fns';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'notificationContentParams',
})
export class NotificationContentParamsPipe implements PipeTransform {
  constructor(private readonly translate: TranslateService) {}

  transform(contentParams: NotificationContentParams) {
    contentParams = contentParams.startDate
      ? {
          ...contentParams,
          startDate: format(new Date(contentParams.startDate), 'dd.MM.yyyy'),
        }
      : contentParams;

    contentParams = contentParams.endDate
      ? {
          ...contentParams,
          endDate: format(new Date(contentParams.endDate), 'dd.MM.yyyy'),
        }
      : contentParams;

    contentParams = contentParams.qualificationName
      ? {
          ...contentParams,
          qualificationName: this.translateQualificationName(contentParams),
        }
      : contentParams;
    return contentParams;
  }

  translateQualificationName(contentParams: NotificationContentParams) {
    let qualificationName = contentParams.qualificationName;
    switch (
      contentParams?.qualificationCategory
        ? contentParams.qualificationCategory
        : ''
    ) {
      case QualificationCategoryName.Certificate:
        qualificationName = this.translate.instant(
          `certificate.${contentParams.qualificationName}`,
        );
        break;
      case QualificationCategoryName.DriverLicense:
        qualificationName = this.translate.instant(
          `driverLicense.${contentParams.qualificationName}`,
        );
        break;
      case QualificationCategoryName.FurtherEducation:
        qualificationName = this.translate.instant(
          `furtherEducation.${contentParams.qualificationName}`,
        );
        break;
      case QualificationCategoryName.Language:
        qualificationName = this.translate.instant(
          `language.${contentParams.qualificationName}`,
        );
        break;
      case QualificationCategoryName.MedicalExamination:
        qualificationName = this.translate.instant(
          `medicalExamination.${contentParams.qualificationName}`,
        );
        break;
      case QualificationCategoryName.More:
        qualificationName = this.translate.instant(
          `otherQualification.${contentParams.qualificationName}`,
        );
        break;
      case QualificationCategoryName.Training:
        qualificationName = this.translate.instant(
          `training.${contentParams.qualificationName}`,
        );
        break;
      default:
        break;
    }
    const data =
      qualificationName +
      ` (${this.translate.instant(
        `qualification_category.${contentParams.qualificationCategory}`,
      )})`;
    return data;
  }
}
