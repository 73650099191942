import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
} from '@angular/core';
import {
  Activity,
  ActivityReport,
  ActivityReportCategory,
  ActivityReportType,
  OperationStatus,
  Sector,
  Shift,
} from '@wilson/interfaces';
import { ReportUtilityService } from '../../../modules/report-utilities/services/report-utility.service';
import { activityReportFactory } from '@wilson/activities/util';
import { ActivityValidationService } from '../../services/activity-validation.service';
import { NZ_MODAL_DATA } from 'ng-zorro-antd/modal';
import { Store } from '@ngxs/store';
import { map, Observable } from 'rxjs';
import { SectorState } from '@wilson/sectors';
import { LocationChangedEvent } from '@wilson/non-domain-specific/activities-helpers/components';

export interface ActivityEditorInput {
  activity: Activity;
  shift: Shift;
}

@Component({
  selector: 'wilson-activity-editor',
  templateUrl: './activity-editor.component.html',
  styleUrls: ['./activity-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityEditorComponent implements OnInit {
  activity: Activity;
  startActivityReport: ActivityReport;
  endActivityReport: ActivityReport;
  protected shift: Shift;
  protected sectorName$: Observable<string>;

  get activityCategoryEditDisabled() {
    return !!(this.activity.jobId || this.activity.serviceId);
  }

  get isSkippedByUser() {
    return (
      this.activity.operationalStatus === OperationStatus.SkippedByUser ||
      this.activity.operationalStatus === OperationStatus.Cancelled
    );
  }

  get skipDisabled() {
    const shiftHasOtherNonSkippedActivity = this.shift.activities?.some(
      (activity) => {
        const isSkipped =
          activity.operationalStatus === OperationStatus.SkippedByUser ||
          activity.operationalStatus === OperationStatus.Cancelled;
        const isOtherActivity = activity.id !== this.activity.id;
        return !isSkipped && isOtherActivity;
      },
    );
    return !shiftHasOtherNonSkippedActivity;
  }

  get invalidTimes() {
    return !this.activityValidationService.validateTimes(
      this.startActivityReport.dateTime,
      this.endActivityReport.dateTime,
    );
  }

  constructor(
    private readonly activityValidationService: ActivityValidationService,
    private readonly reportUtilityService: ReportUtilityService,
    private readonly store: Store,
    @Inject(NZ_MODAL_DATA) private data: ActivityEditorInput,
  ) {}

  ngOnInit(): void {
    this.sectorName$ = this.store
      .select<Sector[]>(SectorState.sectors)
      .pipe(map((sector) => sector[0]?.name));
    this.activity = this.data.activity;
    this.shift = this.data.shift;
    this.initActivityReports();
  }

  initActivityReports() {
    const startActivityReport = this.reportUtilityService.latestStartReport(
      this.activity.activityReports || [],
    );
    this.startActivityReport = startActivityReport
      ? activityReportFactory(
          this.activity.id as string,
          startActivityReport.dateTime,
          startActivityReport.location,
          startActivityReport.locationId,
          ActivityReportCategory.Start,
          ActivityReportType.IsTimes,
        )
      : activityReportFactory(
          this.activity.id as string,
          null,
          this.activity.startLocation,
          this.activity.startLocationId,
          ActivityReportCategory.Start,
          ActivityReportType.IsTimes,
        );

    const endActivityReport = this.reportUtilityService.latestEndReport(
      this.activity.activityReports || [],
    );
    this.endActivityReport = endActivityReport
      ? activityReportFactory(
          this.activity.id as string,
          endActivityReport.dateTime,
          endActivityReport.location,
          endActivityReport.locationId,
          ActivityReportCategory.End,
          ActivityReportType.IsTimes,
        )
      : activityReportFactory(
          this.activity.id as string,
          null,
          this.activity.endLocation,
          this.activity.endLocationId,
          ActivityReportCategory.End,
          ActivityReportType.IsTimes,
        );
  }

  updateReportedActivityStartLocation(event: LocationChangedEvent) {
    this.startActivityReport.location = event.location;
    this.startActivityReport.locationId = event.locationId;
  }

  updateReportedActivityEndLocation(event: LocationChangedEvent) {
    this.endActivityReport.location = event.location;
    this.endActivityReport.locationId = event.locationId;
  }

  skipActivity() {
    this.activity.operationalStatus = OperationStatus.SkippedByUser;
  }

  unskipActivity() {
    this.activity.operationalStatus = OperationStatus.Completed;
  }
}
