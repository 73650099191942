import { Injectable, NgZone } from '@angular/core';
import { Network } from '@capacitor/network';
import {
  Action,
  NgxsAfterBootstrap,
  State,
  StateContext,
  StateOperator,
} from '@ngxs/store';
import { compose, insertItem, patch, updateItem } from '@ngxs/store/operators';
import { OperativeReportsGateway } from '@wilson/api/gateway';
import { OperativeReport } from '@wilson/interfaces';
import { SYNC_TIMEOUT } from '@wilson/shifts-mobile';
import {
  BehaviorSubject,
  firstValueFrom,
  Subscription,
  timer,
  timeout,
  take,
  of,
} from 'rxjs';
import {
  ClearOperativeReportsState,
  CreateOperativeReport,
  SetupSyncForAllUnrecievedOperativeReports,
} from './operative-reports.action';
import { Storage } from '@ionic/storage-angular';
import { FeatureFlagPurePipe } from '@wilson/feature-flags';

export interface OperativeReportsStateModel {
  reports: OperativeReport[];
}

export const OPERATIVE_REPORTS_STATE_NAME = 'operativeReportsState';

@State<OperativeReportsStateModel>({
  name: OPERATIVE_REPORTS_STATE_NAME,
  defaults: {
    reports: [],
  },
})
@Injectable()
export class OperativeReportsState implements NgxsAfterBootstrap {
  public static readonly SYNC_TIMEOUT = 10 * 1000;
  public static readonly SYNC_TIMER = 20 * 1000;
  private operativeReportSyncSubscriptions: Record<string, Subscription> = {};
  private operativeReportsSyncingDataSubject = new BehaviorSubject<boolean>(
    false,
  );
  readonly operativeReportsSyncingData$ =
    this.operativeReportsSyncingDataSubject.asObservable();

  constructor(
    private readonly storage: Storage,
    private readonly ngZone: NgZone,
    private readonly operativeReportsGateway: OperativeReportsGateway,
    private readonly featureFlagPurePipe: FeatureFlagPurePipe,
  ) {}

  @Action(ClearOperativeReportsState)
  clearOperativeReportsState(
    ctx: StateContext<OperativeReportsStateModel>,
  ): Promise<void[]> {
    ctx.patchState({
      reports: [],
    });
    return this.updateStorage(ctx);
  }

  @Action(SetupSyncForAllUnrecievedOperativeReports)
  async startOneTimeSyncForAllUnrecievedOperativeReports(
    ctx: StateContext<OperativeReportsStateModel>,
  ): Promise<void[]> {
    const flag = await firstValueFrom(
      this.featureFlagPurePipe.transform(
        'mobile-operative-reports-offline-mode',
      ),
    );
    if (flag) {
      const nonRecievedReports = ctx
        .getState()
        .reports.filter((report) => !report.receivedAt);

      return Promise.all(
        nonRecievedReports.map((report) =>
          this.sendOperativeReportToBackend(ctx, report),
        ),
      );
    } else {
      return firstValueFrom(of([]));
    }
  }

  @Action(CreateOperativeReport)
  createOperativeReport(
    ctx: StateContext<OperativeReportsStateModel>,
    action: CreateOperativeReport,
  ) {
    const newOperativeReport: OperativeReport = {
      ...action.newOperativeReport,
    };
    const stateOperators: StateOperator<OperativeReportsStateModel>[] = [];
    stateOperators.push(
      patch<OperativeReportsStateModel>({
        reports: insertItem<OperativeReport>(newOperativeReport),
      }),
    );
    ctx.setState(compose(...stateOperators));
    this.updateStorage(ctx);
    this.setupSyncForOperativeReport(ctx, newOperativeReport.occurredAt);
  }

  async ngxsAfterBootstrap(
    ctx: StateContext<OperativeReportsStateModel>,
  ): Promise<void> {
    this.featureFlagPurePipe
      .transform('mobile-operative-reports-offline-mode')
      .pipe(take(1))
      .subscribe(async (flag) => {
        if (flag) {
          const reports = await this.storage.get(OPERATIVE_REPORTS_STATE_NAME);
          if (reports) ctx.patchState(reports);
          this.setupSyncForAllNonRecievedOperativeReports(ctx);
        }
      });
  }

  private updateStorage(ctx: StateContext<OperativeReportsStateModel>) {
    return this.storage.set(OPERATIVE_REPORTS_STATE_NAME, ctx.getState());
  }

  setupSyncForAllNonRecievedOperativeReports(
    ctx: StateContext<OperativeReportsStateModel>,
  ) {
    const shiftsToSync = ctx
      .getState()
      .reports.filter((report) => !report.receivedAt);

    shiftsToSync.forEach((report) => {
      this.setupSyncForOperativeReport(ctx, report.occurredAt);
    });
  }

  private setupSyncForOperativeReport(
    ctx: StateContext<OperativeReportsStateModel>,
    operativeReportOccurredAt: Date,
  ): void {
    this.ngZone.runOutsideAngular(() => {
      if (
        this.operativeReportSyncSubscriptions[
          operativeReportOccurredAt.toString()
        ]
      )
        this.unsubscribeAndDeleteSyncSubscription(operativeReportOccurredAt);

      this.operativeReportSyncSubscriptions[
        operativeReportOccurredAt.toString()
      ] = timer(0, OperativeReportsState.SYNC_TIMER).subscribe(() => {
        this.ngZone.run(() => {
          const operativeReport: OperativeReport = ctx
            .getState()
            .reports.find(
              (report) =>
                report.occurredAt === operativeReportOccurredAt &&
                !report.receivedAt,
            );
          if (!operativeReport) {
            this.unsubscribeAndDeleteSyncSubscription(
              operativeReportOccurredAt,
            );
            return;
          }
          this.sendOperativeReportToBackend(ctx, operativeReport)
            .then(() => {
              this.unsubscribeAndDeleteSyncSubscription(
                operativeReportOccurredAt,
              );
            })
            .catch(() => {
              console.log(
                `Catching a failed sync attempt for operative report with occurredAt Date: ${operativeReportOccurredAt}`,
              );
            });
        });
      });
    });
  }

  private unsubscribeAndDeleteSyncSubscription(
    operativeReportOccurredAt: Date,
  ) {
    this.operativeReportSyncSubscriptions[
      operativeReportOccurredAt.toString()
    ]?.unsubscribe();
    delete this.operativeReportSyncSubscriptions[
      operativeReportOccurredAt.toString()
    ];
  }

  private async sendOperativeReportToBackend(
    ctx: StateContext<OperativeReportsStateModel>,
    operativeReport: OperativeReport,
  ): Promise<void> {
    const status = await Network.getStatus();
    if (!status.connected) new Promise((resolve, reject) => reject(null));

    return firstValueFrom(
      this.operativeReportsGateway
        .createOperativeReport(operativeReport)
        .pipe(timeout(SYNC_TIMEOUT)),
    ).then((operativeReportFromServer: OperativeReport) => {
      ctx.setState(
        patch<OperativeReportsStateModel>({
          reports: updateItem<OperativeReport>((report) => {
            return report?.occurredAt === operativeReport.occurredAt;
          }, operativeReportFromServer),
        }),
      );
      this.updateStorage(ctx);
    });
  }
}
