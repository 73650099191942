import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'wilson-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent {
  @Input() header!: string;
  @Input() details!: string;
  @Input() shiftScoreCard = [];
  @Input() isShiftScoreCard = false;
  @Input() multiDetails: string[] = [];
  @Input() isMultiDetail = false;
}
