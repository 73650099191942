import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileItemPanelComponent } from './mobile-item-panel.component';
import { IonicModule } from '@ionic/angular';
import { MobileItemWrapperComponent } from './mobile-item-wrapper/mobile-item-wrapper.component';

@NgModule({
  declarations: [MobileItemPanelComponent, MobileItemWrapperComponent],
  exports: [MobileItemPanelComponent, MobileItemWrapperComponent],
  imports: [CommonModule, IonicModule],
})
export class MobileItemPanelModule {}
