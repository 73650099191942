import { Injectable } from '@angular/core';
import { User } from '@wilson/interfaces';
import { ValueGetterParams } from 'ag-grid-community';

@Injectable()
export class AgGridSharedService {
  nameGetter(params: ValueGetterParams) {
    let user: User;
    if (params.data?.user) {
      user = params.data.user;
    } else {
      user = params.data;
    }
    return user ? `${user.firstName} ${user.lastName}` : null;
  }
}
