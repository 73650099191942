import { GeoLocationWithFavorite } from '@wilson/interfaces';

export class InitializeFavoriteLocationsState {
  static readonly type =
    '[FavoriteLocationsState] InitializeFavoriteLocationsState';
}

export class AddFavoriteLocation {
  static readonly type = '[FavoriteLocationsState] AddFavoriteLocation';
  constructor(public readonly location: GeoLocationWithFavorite) {}
}

export class RemoveFavoriteLocation {
  static readonly type = '[FavoriteLocationsState] RemoveFavoriteLocation';
  constructor(public readonly locationId: string) {}
}
