import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { de, enUS, fr } from 'date-fns/locale';
import { DateFnsConfigurationService } from 'ngx-date-fns';
import { SelectLanguage } from './lang.actions';
import { de_DE, en_US, fr_FR, NzI18nService } from 'ng-zorro-antd/i18n';

type LangType = 'de-DE' | 'en-US' | 'fr-FR' | 'pl-PL';
export class LangStateModel {
  public lang: LangType = 'de-DE';
}

@State<LangStateModel>({
  name: 'lang',
  defaults: {
    lang: 'de-DE',
  },
})
@Injectable()
export class LangState {
  constructor(
    private readonly translateService: TranslateService,
    private readonly dateConfig: DateFnsConfigurationService,
    private readonly i18n: NzI18nService,
  ) {}

  @Selector()
  static getLang(state: LangStateModel): LangType {
    return state.lang;
  }

  async ngxsOnInit(ctx: StateContext<LangStateModel>): Promise<void> {
    const state = ctx.getState();
    this.translateService.setDefaultLang('de-DE');
    this.dateConfig.setLocale(de);
    this.patchLanguage(ctx, state.lang);
  }

  @Action(SelectLanguage)
  selectLanguage(ctx: StateContext<LangStateModel>, action: SelectLanguage) {
    this.patchLanguage(ctx, action.lang);
  }

  private patchLanguage(
    ctx: StateContext<LangStateModel>,
    lang: LangStateModel['lang'],
  ) {
    this.translateService.use(lang);

    switch (lang) {
      case 'en-US':
        this.i18n.setLocale(en_US);
        this.i18n.setDateLocale(enUS);
        this.dateConfig.setLocale(enUS);
        break;
      case 'fr-FR':
        this.i18n.setLocale(fr_FR);
        this.i18n.setDateLocale(fr);
        this.dateConfig.setLocale(fr);
        break;
      case 'de-DE':
      default:
        this.i18n.setLocale(de_DE);
        this.i18n.setDateLocale(de);
        this.dateConfig.setLocale(de);
        break;
    }

    ctx.patchState({
      lang,
    });
  }
}
