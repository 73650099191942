import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpectedTimesPipe } from './pipes/expected-times.pipe';
import { ExpectedTimesService } from './services/expected-times.service';
import { ReportUtilitiesModule } from '../report-utilities/report-utilities.module';

@NgModule({
  declarations: [ExpectedTimesPipe],
  imports: [CommonModule, ReportUtilitiesModule],
  providers: [ExpectedTimesService],
  exports: [ExpectedTimesPipe],
})
export class ExpectedTimesModule {}
