import { Base } from '@wilson/base';
import { User } from '../accounts/User';

export interface Role extends Base {
  name: RoleName;
  translation?: string;
  userRole?: UserRole[];
}

export interface UserRole extends Base {
  user?: User;
  userId: User['id'];
  role?: Role;
  roleId: Role['id'];
}

export enum RoleName {
  SuperAdmin = 'super-admin',
  DispatcherOperations = 'dispatcher-operations',
  DispatcherPersonnel = 'dispatcher-personnel',
  HumanResources = 'human-resources',
  OperationalStaff = 'operational-staff',
  CompanyAdmin = 'company-admin',
  Viewer = 'viewer',
}

export enum RoleIDsMap {
  DispatcherOperations = '8f581d64-2613-4ad6-b186-76b3faa2b4fa',
  DispatcherPersonnel = '8d12372d-c435-46db-b4b7-07e717cdcef3',
  HumanResources = 'b96326c7-22b3-4297-a449-86e14584dd75',
  OperationalStaff = '1f11b5db-35a7-4660-8de1-f4bc9bdf820d',
  CompanyAdmin = 'b1d3e999-0fb2-495b-9555-5cff4ada6361',
}
