import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Shift, ShiftAssignment } from '@wilson/interfaces';
import { ShiftAssignmentService } from '@wilson/shifts';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'wilson-assign-staff-popup',
  templateUrl: './assign-staff-popup.component.html',
  styleUrls: ['./assign-staff-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignStaffPopupComponent implements OnInit {
  public searchFilter = new BehaviorSubject<string>('');
  public search!: string;
  public isFiltered = false;
  public shift!: Pick<Shift, 'id' | 'name' | 'startDate'>;
  public shiftAssignments$!: Observable<ShiftAssignment[]>;
  public shiftBestAssignments$!: Observable<ShiftAssignment[]>;
  public selectedAssignment!: ShiftAssignment;
  public isEmptyState = false;

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly shiftAssignmentService: ShiftAssignmentService,
    private readonly cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (this.shift && this.shift.id) {
      this.getAssignment(this.shift.id);
    }
  }

  public getAssignment(shiftId: string) {
    const shiftAssignments$ =
      this.shiftAssignmentService.getShiftAssignments(shiftId);

    this.shiftAssignments$ = combineLatest([
      shiftAssignments$,
      this.searchFilter,
    ]).pipe(
      map(([shiftAssignments, searchFilter]) => {
        if (searchFilter) {
          shiftAssignments = this.filterShiftAssignment(
            shiftAssignments,
            searchFilter,
          );
        } else {
          this.isFiltered = false;
        }
        this.isEmptyState = shiftAssignments.length === 0;
        const shiftBestAssignments = shiftAssignments.filter(
          (shiftAssignment) => {
            return shiftAssignment.shiftValidationErrors.every(
              (shiftValidationError) => shiftValidationError.valid,
            );
          },
        );
        const bestAssignmentsWithoutWilsonLearnSuggestion =
          shiftBestAssignments.filter(
            (assignment) => !assignment.suggestedByWilsonLearn,
          );
        bestAssignmentsWithoutWilsonLearnSuggestion.sort(
          (a, b) =>
            b.scores[b.scores.findIndex((x) => x.name === 'shift-smart-score')]
              .score -
            a.scores[a.scores.findIndex((x) => x.name === 'shift-smart-score')]
              .score,
        );
        const bestAssignmentsWithWilsonLearnSuggestion =
          shiftBestAssignments.filter(
            (assignment) => assignment.suggestedByWilsonLearn,
          );
        bestAssignmentsWithWilsonLearnSuggestion.sort(
          (a, b) =>
            b.scores[b.scores.findIndex((x) => x.name === 'shift-smart-score')]
              .score -
            a.scores[a.scores.findIndex((x) => x.name === 'shift-smart-score')]
              .score,
        );

        this.shiftBestAssignments$ = of([
          ...bestAssignmentsWithWilsonLearnSuggestion,
          ...bestAssignmentsWithoutWilsonLearnSuggestion,
        ]);

        const shiftFurtherAssignments = shiftAssignments.filter(
          (shiftAssignment) => {
            return shiftAssignment.shiftValidationErrors.some(
              (shiftValidationError) => !shiftValidationError.valid,
            );
          },
        );
        const assignmentsWithoutWilsonLearnSuggestion =
          shiftFurtherAssignments.filter(
            (assignment) => !assignment.suggestedByWilsonLearn,
          );
        assignmentsWithoutWilsonLearnSuggestion.sort(
          (a, b) =>
            b.scores[b.scores.findIndex((x) => x.name === 'shift-smart-score')]
              .score -
            a.scores[a.scores.findIndex((x) => x.name === 'shift-smart-score')]
              .score,
        );
        const assignmentsWithWilsonLearnSuggestion =
          shiftFurtherAssignments.filter(
            (assignment) => assignment.suggestedByWilsonLearn,
          );
        assignmentsWithWilsonLearnSuggestion.sort(
          (a, b) =>
            b.scores[b.scores.findIndex((x) => x.name === 'shift-smart-score')]
              .score -
            a.scores[a.scores.findIndex((x) => x.name === 'shift-smart-score')]
              .score,
        );

        return [
          ...assignmentsWithWilsonLearnSuggestion,
          ...assignmentsWithoutWilsonLearnSuggestion,
        ];
      }),
      tap(() => {
        this.cdRef.markForCheck();
      }),
    );
    this.cdRef.detectChanges();
  }
  public applySearchFilter(value: string): void {
    this.isFiltered = true;
    this.searchFilter.next(value);
  }

  public filterShiftAssignment(
    shfitAssignment: ShiftAssignment[],
    searchFilter: string,
  ) {
    let filteredShiftAssignments: ShiftAssignment[] = [];
    const lowerCasedSearch = searchFilter.toLowerCase();
    const splitSearchWord = lowerCasedSearch.split(' ');
    splitSearchWord.forEach((word) => {
      if (word && word.trim() != '') {
        filteredShiftAssignments = shfitAssignment.filter(
          (assignment) =>
            assignment?.user.firstName?.toLowerCase().includes(word) ||
            assignment?.user.lastName?.toLowerCase().includes(word) ||
            assignment?.user?.organizationalUnit?.name
              .toLowerCase()
              .includes(word),
        );
      }
    });
    return filteredShiftAssignments;
  }

  decline() {
    this.activeModal.close(false);
  }

  accept() {
    this.activeModal.close(this.selectedAssignment);
  }

  selectStaff(assignment: ShiftAssignment) {
    this.selectedAssignment = assignment;
  }
}
