import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import {
  Project,
  ProjectCreatePayload,
  ProjectMany,
  ProjectUpdatePayload,
  ProjectsOptions,
} from '@wilson/interfaces';
import { stringify } from 'qs';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProjectsGateway {
  protected readonly path = 'projects';
  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
  ) {}

  getProjects(options?: ProjectsOptions): Observable<ProjectMany> {
    const params = stringify(options, {
      arrayFormat: 'brackets',
    });
    return this.http.get<ProjectMany>(
      `${this.config.host}/${this.path}?${params}`,
    );
  }

  getProjectById(id: string): Observable<Project> {
    const options: ProjectsOptions = {
      where: {
        id: id,
      },
    };
    return this.getProjects(options).pipe(map((projects) => projects.data[0]));
  }

  createProject(payload: ProjectCreatePayload) {
    return this.http.post<Project>(`${this.config.host}/${this.path}`, payload);
  }

  deleteOne(id: string): Observable<Project> {
    return this.http.delete<Project>(`${this.config.host}/${this.path}/${id}`);
  }

  updateProject(id: string, payload: ProjectUpdatePayload) {
    return this.http.patch<Project>(
      `${this.config.host}/${this.path}/${id}`,
      payload,
    );
  }
}
