import { Injectable } from '@angular/core';
import { Action, State, StateContext, createSelector } from '@ngxs/store';
import {
  AssignmentLayoutStyle,
  KPIOptions,
  PreferredSetting,
} from '@wilson/interfaces';
import { cloneDeep } from 'lodash';
import { SetPreferredSetting } from './preferred-settings.actions';

export interface PreferredSettingsModel {
  preferredSettings: {
    [userId in string]: PreferredSetting;
  };
}

const defaults: PreferredSettingsModel = {
  preferredSettings: {},
};

@State<PreferredSettingsModel>({
  name: 'preferredSettings',
  defaults,
})
@Injectable()
export class ShiftTimelinePreferredSettingsState {
  static showOvertimeKpi(userId: string) {
    return createSelector(
      [ShiftTimelinePreferredSettingsState.getPreferredSetting(userId)],
      (preferredSetting: PreferredSetting) => {
        return preferredSetting?.mainKPIs.includes(KPIOptions.Overtime);
      },
    );
  }

  static showProductivityKpi(userId: string) {
    return createSelector(
      [ShiftTimelinePreferredSettingsState.getPreferredSetting(userId)],
      (preferredSetting: PreferredSetting) => {
        return preferredSetting?.mainKPIs.includes(KPIOptions.Productivity);
      },
    );
  }

  static getMinimumThreshold(userId: string) {
    return createSelector(
      [ShiftTimelinePreferredSettingsState.getPreferredSetting(userId)],
      (preferredSetting: PreferredSetting) => {
        return preferredSetting?.threshold.min;
      },
    );
  }

  static getMaximumThreshold(userId: string) {
    return createSelector(
      [ShiftTimelinePreferredSettingsState.getPreferredSetting(userId)],
      (preferredSetting: PreferredSetting) => {
        return preferredSetting?.threshold.max;
      },
    );
  }

  static getStaffLayoutStyle(userId: string) {
    return createSelector(
      [ShiftTimelinePreferredSettingsState.getPreferredSetting(userId)],
      (preferredSetting: PreferredSetting) => {
        return preferredSetting?.staffLayout;
      },
    );
  }

  static isCellView(userId: string) {
    return createSelector(
      [ShiftTimelinePreferredSettingsState.getPreferredSetting(userId)],
      (preferredSetting: PreferredSetting) => {
        return (
          preferredSetting?.assignmentLayout === AssignmentLayoutStyle.CellView
        );
      },
    );
  }

  static getPreferredSetting(userId: string) {
    return createSelector(
      [ShiftTimelinePreferredSettingsState],
      (state: PreferredSettingsModel) => {
        return state.preferredSettings[userId];
      },
    );
  }

  @Action(SetPreferredSetting)
  setPreferredSetting(
    ctx: StateContext<PreferredSettingsModel>,
    { userId, preferredSetting }: SetPreferredSetting,
  ) {
    const state = ctx.getState();
    const preferredSettingsToUpdate = cloneDeep(state.preferredSettings);
    preferredSettingsToUpdate[userId] = preferredSetting;
    ctx.patchState({
      preferredSettings: preferredSettingsToUpdate,
    });
  }
}
