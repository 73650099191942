import { Pipe, PipeTransform } from '@angular/core';
import { Absence } from '@wilson/interfaces';

@Pipe({
  name: 'isHalfDayStartOrEndAbsence',
  standalone: true,
  pure: true,
})
export class IsHalfDayStartOrEndAbsencePipe implements PipeTransform {
  transform(
    partialAbsence: Pick<Absence, 'halfDayStart' | 'halfDayEnd'>,
  ): boolean {
    return !!partialAbsence.halfDayStart || !!partialAbsence.halfDayEnd;
  }
}
