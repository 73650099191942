import {
  AccurateUnassignedItem,
  Activity,
  GroupedUnassignedUserDataV2,
} from '@wilson/interfaces';
import {
  constructRows,
  createDateTimeWithoutSeconds,
} from './determine-assignments-overlap';
import { sortIntervalsByAccurateStartTimeAndLength } from './timeline-items-interval-sorting-fn';
import {
  getMostLogicalEndDatetime,
  getMostLogicalStartDatetime,
} from './activity-utilities';

export function determineUnassignmentsOverlaps({
  serviceActivities,
  jobActivities,
  isDebugEnabled,
}: {
  serviceActivities: Map<string, Activity[]>;
  jobActivities: Map<string, Activity[]>;
  isDebugEnabled: boolean;
}): GroupedUnassignedUserDataV2[] {
  const items: {
    services: AccurateUnassignedItem[];
    jobs: AccurateUnassignedItem[];
  } = {
    services: [],
    jobs: [],
  };

  for (const [serviceId, activities] of serviceActivities.entries()) {
    const sortedActivities = sortActivitiesByLogicalTime(
      activities,
      isDebugEnabled,
    );
    const serviceItem = {
      id: serviceId,
      activities: sortedActivities,
      accurateStartDateTime: createDateTimeWithoutSeconds(
        sortedActivities[0].startDatetime,
      ),
      accurateEndDateTime: createDateTimeWithoutSeconds(
        sortedActivities[sortedActivities.length - 1].endDatetime,
      ),
    };
    items.services.push(serviceItem);
  }

  for (const [jobId, activities] of jobActivities.entries()) {
    const sortedActivities = sortActivitiesByLogicalTime(
      activities,
      isDebugEnabled,
    );
    const serviceItem = {
      id: jobId,
      activities: sortedActivities,
      accurateStartDateTime: createDateTimeWithoutSeconds(
        sortedActivities[0].startDatetime,
      ),
      accurateEndDateTime: createDateTimeWithoutSeconds(
        sortedActivities[sortedActivities.length - 1].endDatetime,
      ),
    };
    items.jobs.push(serviceItem);
  }

  return constructRows(items, isDebugEnabled) as {
    services: AccurateUnassignedItem[];
    jobs: AccurateUnassignedItem[];
  }[];
}

export function sortActivitiesByLogicalTime(
  activities: Activity[],
  isDebugEnabled: boolean,
): Activity[] {
  const itemsWithDateInterval: (Activity & {
    accurateStartDateTime: Date;
    accurateEndDateTime: Date;
    id: string;
  })[] = [];

  activities.forEach((item) => {
    const accurateStartDateTime = createDateTimeWithoutSeconds(
      getMostLogicalStartDatetime(item as Activity, isDebugEnabled).date,
    );
    const accurateEndDateTime = createDateTimeWithoutSeconds(
      getMostLogicalEndDatetime(item as Activity, isDebugEnabled).date,
    );

    itemsWithDateInterval.push({
      ...item,
      accurateStartDateTime,
      accurateEndDateTime,
    } as Activity & {
      accurateStartDateTime: Date;
      accurateEndDateTime: Date;
      id: string;
    });
  });

  const sortedActivitiesWithIntervals = itemsWithDateInterval.sort(
    sortIntervalsByAccurateStartTimeAndLength,
  );

  const sortedActivities = sortedActivitiesWithIntervals.map(
    // eslint-disable-next-line
    ({ accurateStartDateTime, accurateEndDateTime, ...restOfItem }) =>
      restOfItem,
  );

  return sortedActivities;
}
