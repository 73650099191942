import { Injectable } from '@angular/core';
import { SelectedVehicle } from './user-vehicle-preferences.interface';

@Injectable({
  providedIn: 'root',
})
export class UserVehiclePreferencesStateHelperService {
  filterUnselected(
    selectedVehicles: SelectedVehicle[],
    selectedVehiclePreferenceId: string,
  ) {
    const indexToDelete = selectedVehicles.findIndex(
      (vehicle) => vehicle.id === selectedVehiclePreferenceId,
    );

    const filteredResult = selectedVehicles.filter(
      (_, index) => index !== indexToDelete,
    );

    const removedItem = selectedVehicles[indexToDelete];

    return { filteredResult, removedItem };
  }
}
