import { Injectable } from '@angular/core';
import {
  Action,
  Selector,
  State,
  StateContext,
  createSelector,
} from '@ngxs/store';
import { OrganizationalUnit } from '@wilson/interfaces';
import { OrganizationalUnitService } from '../organizational-unit.service';
import { tap } from 'rxjs';
import { FetchOrgUnits } from './organizational-unit-state.action';

interface OrganizationalUnitsStateModel {
  organizationalUnits: OrganizationalUnit[];
}
export const ORGANIZATIONAL_UNITS_STATE_NAME = 'organizationalUnitsState';

const defaultOperationsState: OrganizationalUnitsStateModel = {
  organizationalUnits: [],
};

@State<OrganizationalUnitsStateModel>({
  name: ORGANIZATIONAL_UNITS_STATE_NAME,
  defaults: defaultOperationsState,
})
@Injectable()
export class OrganizationalUnitsState {
  constructor(private organizationalUnitService: OrganizationalUnitService) {}

  @Selector()
  static orgUnits(state: OrganizationalUnitsStateModel): OrganizationalUnit[] {
    return state.organizationalUnits;
  }

  static getOrganizationalUnitById(
    orgUnitId: string,
  ): (state: OrganizationalUnitsStateModel) => OrganizationalUnit | undefined {
    return createSelector(
      [OrganizationalUnitsState],
      (state: OrganizationalUnitsStateModel) => {
        return state.organizationalUnits.find(
          (orgUnit) => orgUnit.id === orgUnitId,
        );
      },
    );
  }

  @Action(FetchOrgUnits)
  fetchOrgUnits(ctx: StateContext<OrganizationalUnitsStateModel>) {
    return this.organizationalUnitService.getOrganizationalUnits().pipe(
      tap((organizationalUnits) => {
        ctx.patchState({ organizationalUnits });
      }),
    );
  }
}
