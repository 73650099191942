import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  FormsModule as AngularFormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { AddressAutocompleteComponent } from '../components/address-autocomplete/address-autocomplete.component';
import { ExceptionSelectorComponent } from '../components/exception-selector/exception-selector.component';
import { LocationAutocompleteComponent } from '../components/location-autocomplete/location-autocomplete.component';
import { WeekdaySelectorComponent } from '../components/weekday-selector/weekday-selector.component';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    AngularFormsModule,
    ReactiveFormsModule,
    NzFormModule,
    NzAutocompleteModule,
    NzToolTipModule,
    NzDatePickerModule,
    NzTagModule,
    NzInputModule,
    NzSelectModule,
  ],
  declarations: [
    WeekdaySelectorComponent,
    AddressAutocompleteComponent,
    LocationAutocompleteComponent,
    ExceptionSelectorComponent,
  ],
  exports: [
    WeekdaySelectorComponent,
    AddressAutocompleteComponent,
    LocationAutocompleteComponent,
    ExceptionSelectorComponent,
  ],
})
export class FormsModule {}
