import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivityCategorySelectionComponent } from './activity-category-selection/activity-category-selection.component';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { CompanySettingsService } from './company-settings.service';
import { ShiftTimePreferenceSettingLabelPipe } from './shift-time-preference-setting-label.pipe';
import { NzButtonModule } from 'ng-zorro-antd/button';

@NgModule({
  imports: [
    CommonModule,
    NzFormModule,
    FormsModule,
    NzSelectModule,
    TranslateModule.forChild(),
    NzMessageModule,
    NzButtonModule,
  ],
  declarations: [
    ActivityCategorySelectionComponent,
    ShiftTimePreferenceSettingLabelPipe,
  ],
  exports: [
    ActivityCategorySelectionComponent,
    ShiftTimePreferenceSettingLabelPipe,
  ],
  providers: [CompanySettingsService],
})
export class CompanySettingsCoreModule {}
