import { formatISO, parseISO } from 'date-fns';

/**
 * @param {number} hours - Time of the day in hours (0 - 24)
 *
 * @example
 * formatHoursISO(14); // "14:00:00Z"
 */
export function formatHoursISO(hours: number) {
  return formatISO(new Date().setHours(hours, 0, 0, 0), {
    representation: 'time',
  });
}

/**
 * @param {string} ISOTime - Time of the day in ISO time format
 *
 * @example
 * parseHoursISO("14:00:00Z"); // 14
 */
export function parseHoursISO(ISOTime: string) {
  const ISODate = formatISO(new Date(), { representation: 'date' });
  return parseISO(`${ISODate}T${ISOTime}`).getHours();
}

/**
 * @param {string} ISOTime - Time of the day in ISO time format
 *
 * @example
 * formatDateISO("14:00:00Z"); // 2022-11-18T14:00:00.000Z
 */

export function formatDateISO(ISOTime: string) {
  const date = formatISO(new Date(), { representation: 'date' });
  return parseISO(`${date}T${ISOTime}`).toISOString();
}
