import { VehiclePreferencesList } from '@wilson/preferences/core';

export class FetchSelectedVehicles {
  static readonly type = '[VehiclePreferences] Fetch Selected Vehicle';
}

export class SelectVehicle {
  static readonly type = '[VehiclePreferences] Select Vehicle';
  constructor(
    public payload: {
      vehicle: VehiclePreferencesList;
    },
  ) {}
}

export class UnselectAllVehicle {
  static readonly type = '[VehiclePreferences] Unseelct all Vehicle';
}

export class UnselectVehicle {
  static readonly type = '[VehiclePreferences] Unselect Location';
  constructor(
    public payload: {
      selectedVehicleId: string;
    },
  ) {}
}
