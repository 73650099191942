<div class="d-flex flex-row">
  <div class="flex-grow-1">
    <ng-container *ngIf="!isLoading; else loading">
      <!-- TODO Not available in latest design -->
      <!-- <ng-container
        *ngIf="shiftAssignment && shiftAssignment.length; else emptyState"
      > -->
      <ag-grid-angular
        class="ag-theme-wilson list-height"
        id="myGrid"
        [defaultColDef]="defaultColDef"
        [gridOptions]="gridOptions"
        [rowData]="shiftAssignment"
        [components]="frameworkComponents"
        [rowSelection]="'single'"
        [rowMultiSelectWithClick]="true"
        [suppressPropertyNamesCheck]="true"
        [columnDefs]="columnDefs$ | async"
        (gridReady)="onGridReady($event)"
        (rowClicked)="selectAssignment($event)"
      >
      </ag-grid-angular>
      <!-- TODO Not available in latest design -->
      <!-- </ng-container>
      <ng-template #emptyState>
        <p class="mb-0">
          {{ 'page.shifts.add_service.no_service' | translate }}
        </p>
        <p>{{ 'page.shifts.add_service.create_service' | translate }}</p>
      </ng-template> -->
    </ng-container>
    <ng-template #loading>
      <div class="w-100 mt-3">
        <nz-skeleton
          *ngFor="let data of [1, 2, 3, 4]"
          [nzActive]="true"
          [nzAvatar]="true"
          [nzTitle]="{
            width: 1175
          }"
          [nzParagraph]="{ rows: 0 }"
        ></nz-skeleton>
      </div>
    </ng-template>
  </div>
</div>
