import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViaPointsListComponent } from './via-points-list.component';
import { LocationAbbreviationNameModule } from '../location-abbreviation-name/location-abbreviation-name.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ViaPointsListComponent],
  imports: [
    CommonModule,
    LocationAbbreviationNameModule,
    TranslateModule.forChild(),
  ],
  exports: [ViaPointsListComponent],
})
export class ViaPointsListModule {}
