import { QualificationStatus, UserQualification } from '@wilson/interfaces';
import { add } from 'date-fns';

export class QualificationsUtilityService {
  public getQualificationStatus(
    qualification: UserQualification,
  ): QualificationStatus {
    const validUntil = new Date(qualification.validTill);
    const expireSoonDate = add(new Date(), { days: 90 });
    if (validUntil.getTime() < new Date().getTime()) {
      return QualificationStatus.expired;
    } else if (validUntil.getTime() <= expireSoonDate.getTime()) {
      return QualificationStatus.expiresSoon;
    } else {
      return QualificationStatus.valid;
    }
  }
}
