import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  FetchProfitabilityScoreSetting,
  UpdateCurrency,
  UpdateEnableSettings,
  UpdateHotelRatePerDay,
  UpdateNonBillableActivities,
  UpdateProfitabilityClassification,
  UpdateProfitabilityScoreSetting,
  UpdateRevenuePerHour,
  UpdateTransferActivities,
  UpdateTransferCostPerKilometer,
  UpdateWagePerHour,
} from './profitability-score.actions';

import {
  ProfitabilityScoreModelWithOptionalId,
  ProfitabilitySettingsGateway,
} from '@wilson/api/gateway';
import { catchError, filter, of, switchMap, take, tap } from 'rxjs';

export interface ProfitabilityScoreStateModel {
  settings: ProfitabilityScoreModelWithOptionalId;
  isFetching: boolean;
  isSaving: boolean;
}

@State<ProfitabilityScoreStateModel>({
  name: 'profitabilityScoreSettings',
  defaults: {
    settings: {
      enableSettings: false,
      currency: 'EUR',
      hotelRatePerDay: 30,
      nonBillableActivities: [],
      profitabilityClassification: {
        veryHigh: { min: 0, max: 0 },
        high: { min: 0, max: 0 },
        moderate: { min: 0, max: 0 },
        low: { min: 0, max: 0 },
        negative: { min: 0, max: 0 },
      },
      revenuePerHour: 100,
      organizationalUnitId: '',
      transferActivities: [],
      transferCostPerKilometer: 0.5,
      wagePerHour: 20,
    },
    isFetching: false,
    isSaving: false,
  },
})
@Injectable()
export class ProfitabilityScoreState {
  constructor(
    private profitabilitySettingsGateway: ProfitabilitySettingsGateway,
    private readonly store: Store,
  ) {}

  @Selector()
  static isFetching(
    state: ProfitabilityScoreStateModel,
  ): ProfitabilityScoreStateModel['isFetching'] {
    return state.isFetching;
  }

  @Selector()
  static isSaving(
    state: ProfitabilityScoreStateModel,
  ): ProfitabilityScoreStateModel['isSaving'] {
    return state.isSaving;
  }

  @Selector()
  static profitabilityScoreSettings(
    state: ProfitabilityScoreStateModel,
  ): ProfitabilityScoreStateModel['settings'] {
    return state.settings;
  }

  @Selector()
  static enableSettings(
    state: ProfitabilityScoreStateModel,
  ): ProfitabilityScoreStateModel['settings']['enableSettings'] {
    return state.settings.enableSettings;
  }

  @Selector()
  static currency(
    state: ProfitabilityScoreStateModel,
  ): ProfitabilityScoreStateModel['settings']['currency'] {
    return state.settings.currency;
  }

  @Selector()
  static hotelRatePerDay(
    state: ProfitabilityScoreStateModel,
  ): ProfitabilityScoreStateModel['settings']['hotelRatePerDay'] {
    return state.settings.hotelRatePerDay;
  }

  @Selector()
  static nonBillableActivities(
    state: ProfitabilityScoreStateModel,
  ): ProfitabilityScoreStateModel['settings']['nonBillableActivities'] {
    return state.settings.nonBillableActivities;
  }

  @Selector()
  static profitabilityClassification(
    state: ProfitabilityScoreStateModel,
  ): ProfitabilityScoreStateModel['settings']['profitabilityClassification'] {
    return state.settings.profitabilityClassification;
  }

  @Selector()
  static revenuePerHour(
    state: ProfitabilityScoreStateModel,
  ): ProfitabilityScoreStateModel['settings']['revenuePerHour'] {
    return state.settings.revenuePerHour;
  }

  @Selector()
  static organizationalUnitId(
    state: ProfitabilityScoreStateModel,
  ): ProfitabilityScoreStateModel['settings']['organizationalUnitId'] {
    return state.settings.organizationalUnitId;
  }

  @Selector()
  static transferActivities(
    state: ProfitabilityScoreStateModel,
  ): ProfitabilityScoreStateModel['settings']['transferActivities'] {
    return state.settings.transferActivities;
  }

  @Selector()
  static transferCostPerKilometer(
    state: ProfitabilityScoreStateModel,
  ): ProfitabilityScoreStateModel['settings']['transferCostPerKilometer'] {
    return state.settings.transferCostPerKilometer;
  }

  @Selector()
  static wagePerHour(
    state: ProfitabilityScoreStateModel,
  ): ProfitabilityScoreStateModel['settings']['wagePerHour'] {
    return state.settings.wagePerHour;
  }

  @Action(FetchProfitabilityScoreSetting)
  fetchProfitabilityScoreSetting({
    patchState,
    getState,
  }: StateContext<ProfitabilityScoreStateModel>) {
    return this.store.select(ProfitabilityScoreState.isFetching).pipe(
      filter((isFetching) => !isFetching),
      take(1),
      switchMap(() => {
        patchState({ isFetching: true });
        const currentSettings = getState().settings;
        return this.profitabilitySettingsGateway
          .getProfitabilitySettings()
          .pipe(
            tap((profitabilitySettings) => {
              patchState({
                settings: {
                  ...profitabilitySettings,
                  profitabilityClassification:
                    profitabilitySettings.profitabilityClassification,
                },
                isFetching: false,
              });
            }),
            catchError((err) => {
              console.error(err);
              patchState({
                settings: currentSettings,
                isFetching: false,
              });
              return of();
            }),
          );
      }),
    );
  }

  @Action(UpdateProfitabilityScoreSetting)
  updateProfitabilityScoreSetting(
    { patchState, getState }: StateContext<ProfitabilityScoreStateModel>,
    { profitabilityScore }: UpdateProfitabilityScoreSetting,
  ) {
    return this.store.select(ProfitabilityScoreState.isSaving).pipe(
      filter((isSaving) => !isSaving),
      take(1),
      switchMap(() => {
        patchState({ isSaving: true });
        const currentSettings = getState().settings;
        return this.profitabilitySettingsGateway
          .updateProfitabilitySettings(profitabilityScore)
          .pipe(
            tap(() => {
              patchState({
                settings: profitabilityScore,
                isSaving: false,
              });
            }),
            catchError((err) => {
              console.error(err);
              patchState({ settings: currentSettings, isSaving: false });
              return of();
            }),
          );
      }),
    );
  }

  @Action(UpdateEnableSettings)
  updateEnableSettings(
    ctx: StateContext<ProfitabilityScoreStateModel>,
    { enableSettings }: UpdateEnableSettings,
  ) {
    const newSettings = {
      ...ctx.getState().settings,
      enableSettings,
    };
    ctx.dispatch(new UpdateProfitabilityScoreSetting(newSettings));
  }

  @Action(UpdateCurrency)
  updateCurrency(
    { patchState, getState }: StateContext<ProfitabilityScoreStateModel>,
    { currency }: UpdateCurrency,
  ) {
    patchState({
      settings: {
        ...getState().settings,
        currency,
      },
    });
  }

  @Action(UpdateHotelRatePerDay)
  updateHotelRatePerDay(
    ctx: StateContext<ProfitabilityScoreStateModel>,
    { hotelRatePerDay }: UpdateHotelRatePerDay,
  ) {
    const newSettings = {
      ...ctx.getState().settings,
      hotelRatePerDay,
    };
    ctx.dispatch(new UpdateProfitabilityScoreSetting(newSettings));
  }

  @Action(UpdateNonBillableActivities)
  updateNonBillableActivities(
    ctx: StateContext<ProfitabilityScoreStateModel>,
    { nonBillableActivities }: UpdateNonBillableActivities,
  ) {
    const newSettings = {
      ...ctx.getState().settings,
      nonBillableActivities,
    };
    ctx.dispatch(new UpdateProfitabilityScoreSetting(newSettings));
  }

  @Action(UpdateProfitabilityClassification)
  updateProfitabilityClassification(
    ctx: StateContext<ProfitabilityScoreStateModel>,
    { profitabilityClassification }: UpdateProfitabilityClassification,
  ) {
    const newSettings = {
      ...ctx.getState().settings,
      profitabilityClassification,
    };
    ctx.dispatch(new UpdateProfitabilityScoreSetting(newSettings));
  }

  @Action(UpdateRevenuePerHour)
  updateRevenuePerHour(
    ctx: StateContext<ProfitabilityScoreStateModel>,
    { revenuePerHour }: UpdateRevenuePerHour,
  ) {
    const newSettings = {
      ...ctx.getState().settings,
      revenuePerHour,
    };
    ctx.dispatch(new UpdateProfitabilityScoreSetting(newSettings));
  }

  @Action(UpdateTransferActivities)
  updateTransferActivities(
    ctx: StateContext<ProfitabilityScoreStateModel>,
    { transferActivities }: UpdateTransferActivities,
  ) {
    const newSettings = {
      ...ctx.getState().settings,
      transferActivities,
    };
    ctx.dispatch(new UpdateProfitabilityScoreSetting(newSettings));
  }

  @Action(UpdateTransferCostPerKilometer)
  updateTransferCostPerKilometer(
    ctx: StateContext<ProfitabilityScoreStateModel>,
    { transferCostPerKilometer }: UpdateTransferCostPerKilometer,
  ) {
    const newSettings = {
      ...ctx.getState().settings,
      transferCostPerKilometer,
    };
    ctx.dispatch(new UpdateProfitabilityScoreSetting(newSettings));
  }

  @Action(UpdateWagePerHour)
  updateWagePerHour(
    ctx: StateContext<ProfitabilityScoreStateModel>,
    { wagePerHour }: UpdateWagePerHour,
  ) {
    const newSettings = {
      ...ctx.getState().settings,
      wagePerHour,
    };
    ctx.dispatch(new UpdateProfitabilityScoreSetting(newSettings));
  }
}
