import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { OperativeReport, OperativeReportsCategory } from '@wilson/interfaces';
import {
  CategorySettings,
  OperativeReportsCategorySettings,
} from '@wilson/operative-reports/interfaces';
import { createEmbedQueryParamString } from '@wilson/utils';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OperativeReportsGateway {
  protected readonly path = 'operative-reports';

  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
  ) {}

  public getOperativeReportsCategories(): Observable<
    OperativeReportsCategory[]
  > {
    return this.http.get<OperativeReportsCategory[]>(
      `${this.config.host}/operative-report-categories`,
    );
  }

  public getOperativeReportsCategoriesSettings(): Observable<
    OperativeReportsCategorySettings[]
  > {
    return this.http.get<OperativeReportsCategorySettings[]>(
      `${this.config.host}/operative-report-categories/settings`,
    );
  }

  public updateOperativeReports(
    operativeReportCategoryId: string,
    settingsOrEnabled: CategorySettings | { isEnabled: boolean },
  ): Observable<OperativeReportsCategorySettings> {
    let payload;
    if ('isEnabled' in settingsOrEnabled) {
      payload = settingsOrEnabled;
    } else {
      payload = { settings: settingsOrEnabled };
    }

    return this.http.patch<OperativeReportsCategorySettings>(
      `${this.config.host}/operative-report-categories/${operativeReportCategoryId}/settings`,
      payload,
    );
  }

  public createOperativeReport(operativeReport: OperativeReport) {
    return this.http.post(`${this.config.host}/${this.path}`, operativeReport);
  }

  public updateOperativeReportStatus(operativeReport: OperativeReport) {
    return this.http.patch<OperativeReport>(
      `${this.config.host}/${this.path}/${operativeReport.id}`,
      {
        status: operativeReport.status,
      },
    );
  }

  public getOperativeReports(): Observable<OperativeReport[]> {
    const queryParams = createEmbedQueryParamString([
      'activity',
      'senderUser',
      'shift',
      'updatedBy',
    ]);
    return this.http.get<OperativeReport[]>(
      `${this.config.host}/${this.path}?${queryParams}`,
    );
  }
}
