import { endOfDay, startOfDay } from 'date-fns';

export function getTimeZoneCorrectedDateRange(dateRange: Interval): {
  start: Date;
  end: Date;
} {
  const startDate = startOfDay(new Date(dateRange.start));
  const endDate = endOfDay(new Date(dateRange.end));

  //Timezone correction so that when we run toISOString(), it will return the true start and end of date
  startDate.setMinutes(startDate.getMinutes() - startDate.getTimezoneOffset());
  endDate.setMinutes(endDate.getMinutes() - endDate.getTimezoneOffset());

  return {
    start: startDate,
    end: endDate,
  };
}
