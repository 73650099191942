import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import {
  Action,
  NgxsAfterBootstrap,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import { catchError, finalize, of, take, tap } from 'rxjs';
import { InitShiftCategoriesState } from './shift-category.actions';
import { ShiftCategory } from '@wilson/interfaces';
import { ShiftCategoryService } from '../shift-category.service';

export interface ShiftCategoriesStateModel {
  shiftCategories: ShiftCategory[];
  isLoading: boolean;
}

const defaults: ShiftCategoriesStateModel = {
  shiftCategories: [],
  isLoading: false,
};

const SHIFT_CATEGORIES_STATE_NAME = 'shiftCategories';

@State<ShiftCategoriesStateModel>({
  name: SHIFT_CATEGORIES_STATE_NAME,
  defaults,
})
@Injectable()
export class ShiftCategoriesState implements NgxsAfterBootstrap {
  constructor(
    private readonly shiftCategoryService: ShiftCategoryService,
    private readonly storage: Storage,
  ) {}

  @Selector()
  static shiftCategories(state: ShiftCategoriesStateModel) {
    return state.shiftCategories;
  }

  @Selector()
  static isLoading(state: ShiftCategoriesStateModel) {
    return state.isLoading;
  }

  async ngxsAfterBootstrap(
    ctx: StateContext<ShiftCategoriesStateModel>,
  ): Promise<void> {
    const shiftCategoriesState:
      | { shiftCategories: ShiftCategory[] }
      | undefined = await this.storage.get(SHIFT_CATEGORIES_STATE_NAME);

    if (shiftCategoriesState) {
      ctx.patchState({ shiftCategories: shiftCategoriesState.shiftCategories });
    }
  }

  @Action(InitShiftCategoriesState)
  initializeShiftCategoryState(ctx: StateContext<ShiftCategoriesStateModel>) {
    ctx.patchState({ isLoading: true });
    return this.shiftCategoryService.getAll().pipe(
      take(1),
      tap((shiftCategories) => {
        ctx.patchState({
          shiftCategories,
        });
        this.storage.set(SHIFT_CATEGORIES_STATE_NAME, {
          shiftCategories,
        });
      }),
      catchError(() => {
        return of(null);
      }),
      finalize(() => {
        ctx.patchState({ isLoading: false });
      }),
    );
  }
}
