import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class UserPreferenceHelperService {
  constructor(
    private toastController: ToastController,
    private translateService: TranslateService,
  ) {}

  public async presentErrorToast() {
    const toast = await this.toastController.create({
      message: this.translateService.instant('userProfile.errorOnSaveMessage'),
      duration: 5000,
      position: 'bottom',
      buttons: [this.translateService.instant('general.close')],
      color: 'danger',
    });

    await toast.present();
  }
}
