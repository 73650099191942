import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { UserDataState } from '@wilson/user-data/state';
import { map, Observable } from 'rxjs';

@Pipe({
  name: 'organizationNamePure',
})
export class OrganizationNamePurePipe implements PipeTransform {
  constructor(private readonly store: Store) {}

  transform(organizationId: string): Observable<string | null> {
    return this.store
      .select(UserDataState.organization(organizationId))
      .pipe(map((o) => o?.name));
  }
}
