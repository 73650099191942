/// <reference types="@types/googlemaps" />

import { Pipe, PipeTransform } from '@angular/core';
import { Address, GeoLocation } from '@wilson/interfaces';
import { AddressPipe } from '../address/address.pipe';

@Pipe({
  name: 'locationName',
})
export class LocationNamePipe implements PipeTransform {
  private _emptyStringOnError = false;

  constructor(private readonly addressPipe: AddressPipe) {}

  transform(
    location:
      | Pick<GeoLocation, 'locationCode' | 'name'>
      | Pick<Address, 'street' | 'postCode' | 'city' | 'country'>
      | Pick<google.maps.places.QueryAutocompletePrediction, 'description'>
      | undefined
      | null,
    emptyStringOnError = false,
  ): string {
    this._emptyStringOnError = emptyStringOnError;

    if (!location) {
      return this.errorReturnString();
    }

    if ('locationCode' in location && 'name' in location) {
      return location.locationCode && location.name
        ? location.locationCode + ' - ' + location.name
        : location.name ?? this.errorReturnString();
    }

    if (
      'street' in location &&
      'postCode' in location &&
      'city' in location &&
      'country' in location
    ) {
      return this.addressPipe.transform(location);
    }

    if ('description' in location && location.description) {
      return location.description;
    }

    return this.errorReturnString();
  }

  private errorReturnString() {
    return this._emptyStringOnError ? '' : '---';
  }
}
