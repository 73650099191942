import { getMenlo79GlobalConfig } from '@wilson/utils';

export const environment = {
  production: true,
  backend: {
    host: getMenlo79GlobalConfig('backend_host'),
  },
  sentry: {
    enabled: !!+getMenlo79GlobalConfig('sentry_enabled'),
    dsn: getMenlo79GlobalConfig('sentry_dsn_mobile'),
    environment: getMenlo79GlobalConfig('sentry_environment'),
    tracingOrigins: getMenlo79GlobalConfig('sentry_tracing_origins').split(','),
  },
  application: 'mobile',
  releaseVersion: '1.604.2',
  talkJsConfig: {
    talkjsAppId: getMenlo79GlobalConfig('talkjs_app_id'),
    role: getMenlo79GlobalConfig('talkjs_role'),
  },
  launchDarklyClientId: getMenlo79GlobalConfig('launchdarkly_client_id'),
  productFruitsWorkspaceCode: getMenlo79GlobalConfig(
    'product_fruits_workspace_code',
  ),
  matomo: {
    disabled: !!+getMenlo79GlobalConfig('matomo_disabled'),
    trackerUrl: getMenlo79GlobalConfig('matomo_tracker_url'),
    siteId: getMenlo79GlobalConfig('matomo_site_id'),
  },
  privacyUrl: getMenlo79GlobalConfig('privacy_url'),
  imprintUrl: getMenlo79GlobalConfig('imprint_url'),
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
