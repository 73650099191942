import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { FeatureName } from '@wilson/interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'featurePure',
})
export class FeaturePurePipe implements PipeTransform {
  constructor(private readonly store: Store) {}

  transform(value: FeatureName): Observable<boolean> {
    return this.store
      .select((state) => state.permissions.featurePermissions)
      .pipe(map((features: string[]) => features.includes(value)));
  }
}
