import { Pipe, PipeTransform } from '@angular/core';
import { Activity } from '@wilson/interfaces';
import { isBefore } from 'date-fns';

@Pipe({
  name: 'isShiftInPastPipe',
})
export class IsShiftInPastPipe implements PipeTransform {
  transform(sortedActivities: Activity[]): boolean {
    if (!sortedActivities) return false;

    return isBefore(
      new Date(sortedActivities[sortedActivities?.length - 1].endDatetime),
      Date.now(),
    );
  }
}
