import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { MobileSetting } from '@wilson/interfaces';
import { stringify } from 'qs';
import { Observable } from 'rxjs';

@Injectable()
export class CompanySettingsService {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
  ) {}

  getMobileSettings(
    queryParams: Record<string, unknown>,
  ): Observable<MobileSetting[]> {
    const params = stringify(queryParams);
    return this.httpClient.get<MobileSetting[]>(
      `${this.config.host}/mobile-settings?${params}`,
    );
  }

  createMobileSettings(payload: MobileSetting[]): Observable<MobileSetting> {
    return this.httpClient.post<MobileSetting>(
      `${this.config.host}/mobile-settings`,
      {
        items: payload,
      },
    );
  }

  updateMobileSetting(mobileSettingId: string, mobileSetting: MobileSetting) {
    return this.httpClient.patch(
      `${this.config.host}/mobile-settings/${mobileSettingId}`,
      {
        ...mobileSetting,
      },
    );
  }
}
