import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { firstValueFrom, Observable } from 'rxjs';
import { PreferenceSettings } from './preferences.interface';

@Injectable({
  providedIn: 'root',
})
export class PreferencesGatewayService {
  protected readonly path = 'preference-settings';

  constructor(
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
    protected readonly http: HttpClient,
  ) {}

  getAll(): Observable<PreferenceSettings> {
    return this.http.get<PreferenceSettings>(
      `${this.config.host}/${this.path}`,
    );
  }

  updateSetting(
    settingId: string,
    settings: Partial<Exclude<PreferenceSettings, 'preferenceSettingRole'>>,
  ) {
    return firstValueFrom(
      this.http.patch(
        `${this.config.host}/${this.path}/${settingId}`,
        settings,
      ),
    );
  }

  updateRoleSetting(
    settingId: string,
    settings: {
      id: string;
      isEnabled: boolean;
    },
  ) {
    return firstValueFrom(
      this.http.patch(`${this.config.host}/${this.path}/${settingId}`, {
        preferenceSettingRole: settings,
      }),
    );
  }
}
