import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { Permissions } from '@wilson/interfaces';
import { Observable } from 'rxjs';

@Injectable()
export class PermissionsService {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
  ) {}

  getUserPermissions(userId: string): Observable<Permissions> {
    return this.httpClient.get<Permissions>(
      `${this.config.host}/users/${userId}/permissions`,
    );
  }
}
