import { Injectable } from '@angular/core';
import { activitiesCompareFn } from '@wilson/activities/util';
import {
  Activity,
  MobileSetting,
  OperationStatus,
  PublicationStatus,
  Shift,
  ShiftState,
  WilsonApp,
} from '@wilson/interfaces';
import { add, isAfter, isBefore } from 'date-fns';
import { cloneDeep } from 'lodash';

@Injectable()
export class ShiftUtilityService {
  canStartBeforePlannedStart(
    { activities }: Shift,
    { shiftStartBeforePlannedInMinutes }: MobileSetting,
  ): boolean {
    if (!activities) {
      return false;
    } else {
      activities = cloneDeep(activities);
      activities.sort(activitiesCompareFn);

      return isAfter(
        add(new Date(), {
          minutes: shiftStartBeforePlannedInMinutes || 5,
        }),
        new Date(activities[0].startDatetime),
      );
    }
  }

  hasShiftEnded(activities: Activity[]) {
    activities = cloneDeep(activities);
    activities.sort(activitiesCompareFn);

    for (const activity of activities) {
      if (activity.operationalStatus === OperationStatus.Ongoing) return false;
    }

    const endDateTime = new Date(activities[activities.length - 1].endDatetime);
    if (isBefore(new Date(), endDateTime)) return false;

    return true;
  }

  isAccepted({ state }: Shift): boolean {
    return (
      state === ShiftState.AcceptedTimes ||
      state === ShiftState.SubmittedToPayrollProvider
    );
  }

  isCompleted(shift: Shift): boolean {
    if (shift?.activities?.length) {
      return shift.activities.every(
        ({ operationalStatus }) =>
          operationalStatus === OperationStatus.Completed ||
          operationalStatus === OperationStatus.SkippedByUser ||
          operationalStatus === OperationStatus.Cancelled,
      );
    }

    return false;
  }

  isNotStarted(shift: Shift): boolean {
    if (shift?.activities?.length) {
      return shift.activities.every(
        ({ operationalStatus }) =>
          operationalStatus === OperationStatus.NotStarted,
      );
    }

    return false;
  }

  isOngoing(shift: Shift): boolean {
    if (!!shift && shift?.activities?.length) {
      return !!shift.activities.find(
        ({ operationalStatus }) =>
          operationalStatus === OperationStatus.Ongoing,
      );
    }

    return false;
  }

  isPublished({ publicationStatus }: Shift): boolean {
    return (
      publicationStatus !== PublicationStatus.NotPublished &&
      publicationStatus !== PublicationStatus.NotPublishedAgain
    );
  }

  isSelfDispatchedShift(shift: Shift): boolean {
    return shift?.createdFrom === WilsonApp.Mobile;
  }

  isSubmitted({ state }: Shift) {
    return (
      state === ShiftState.AcceptedTimes ||
      state === ShiftState.Submitted ||
      state === ShiftState.SubmittedToPayrollProvider
    );
  }
}
