import {
  AccurateTimeDetails,
  ActivityDeviationStatus,
  Shift,
  UnassignedShift,
  WithPreparedAttributes,
} from '@wilson/interfaces';
import { consoleInfo } from '@wilson/non-domain-specific/logger/logger';
import { differenceInMinutes, isBefore } from 'date-fns';

export function determineShiftRenderDatetime(
  shift:
    | (Shift &
        WithPreparedAttributes & {
          id: string;
        })
    | UnassignedShift,
): {
  startDatetime: AccurateTimeDetails;
  endDatetime: AccurateTimeDetails;
} {
  const startDatetime = getStartDatetime(shift);
  const endDatetime = getEndDatetime(shift);

  if (isBefore(new Date(startDatetime.date), new Date(endDatetime.date))) {
    return {
      startDatetime,
      endDatetime,
    };
  } else {
    consoleInfo(
      `Bad time interval, start datetime: ${startDatetime.date} is behind end datetime: ${endDatetime.date}. Using planned time instead.`,
    );
    return {
      startDatetime: getPlannedStartDatetime(shift),
      endDatetime: getPlannedEndDatetime(shift),
    };
  }
}

function getStartDatetime({
  startDatetime,
  deviatedStartDatetime,
  reportedStartDatetime,
  reportedStartLocation,
  startLocation,
}: Pick<
  WithPreparedAttributes,
  | 'startDatetime'
  | 'deviatedStartDatetime'
  | 'reportedStartDatetime'
  | 'reportedStartLocation'
  | 'startLocation'
>): AccurateTimeDetails {
  if (reportedStartDatetime) {
    const timeDifference = differenceInMinutes(
      new Date(reportedStartDatetime),
      new Date(startDatetime),
    );
    return {
      date: reportedStartDatetime,
      location: reportedStartLocation || startLocation,
      type: ActivityDeviationStatus.Reported,
      timeDifference: timeDifference,
    };
  } else if (deviatedStartDatetime) {
    const timeDifference = differenceInMinutes(
      new Date(deviatedStartDatetime),
      new Date(startDatetime),
    );
    return {
      date: deviatedStartDatetime,
      location: startLocation,
      type: ActivityDeviationStatus.Deviated,
      timeDifference: timeDifference,
    };
  } else {
    return getPlannedStartDatetime({
      startDatetime,
      startLocation,
    });
  }
}

function getEndDatetime({
  endDatetime,
  deviatedEndDatetime,
  reportedEndDatetime,
  reportedEndLocation,
  endLocation,
}: Pick<
  WithPreparedAttributes,
  | 'endDatetime'
  | 'deviatedEndDatetime'
  | 'reportedEndDatetime'
  | 'reportedEndLocation'
  | 'endLocation'
>): AccurateTimeDetails {
  if (reportedEndDatetime) {
    const timeDifference = differenceInMinutes(
      new Date(reportedEndDatetime),
      new Date(endDatetime),
    );
    return {
      date: reportedEndDatetime,
      location: reportedEndLocation || endLocation,
      type: ActivityDeviationStatus.Reported,
      timeDifference: timeDifference,
    };
  } else if (deviatedEndDatetime) {
    const timeDifference = differenceInMinutes(
      new Date(deviatedEndDatetime),
      new Date(endDatetime),
    );
    return {
      date: deviatedEndDatetime,
      location: endLocation,
      type: ActivityDeviationStatus.Deviated,
      timeDifference: timeDifference,
    };
  } else {
    return getPlannedEndDatetime({
      endDatetime,
      endLocation,
    });
  }
}

function getPlannedStartDatetime({
  startDatetime,
  startLocation,
}: Pick<WithPreparedAttributes, 'startDatetime' | 'startLocation'>) {
  return {
    date: startDatetime,
    location: startLocation,
    type: ActivityDeviationStatus.Planned,
    timeDifference: 0,
  };
}

function getPlannedEndDatetime({
  endDatetime,
  endLocation,
}: Pick<
  WithPreparedAttributes | UnassignedShift,
  'endDatetime' | 'endLocation'
>) {
  return {
    date: endDatetime,
    location: endLocation,
    type: ActivityDeviationStatus.Planned,
    timeDifference: 0,
  };
}
