import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthState } from '@wilson/auth/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { OrganizationalUnit } from '@wilson/interfaces';
import { createRelationsQueryParamString } from '@wilson/utils';
import { combineLatest, firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OrganizationalUnitService {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
    private readonly store: Store,
  ) {}

  public getOrganizationalUnits(): Observable<OrganizationalUnit[]> {
    return this.httpClient.get<OrganizationalUnit[]>(
      `${this.config.host}/organizational-units`,
    );
  }

  public getOrganizationalUnit(
    id: string,
    relations: string[] = [],
  ): Observable<OrganizationalUnit> {
    return this.httpClient.get<OrganizationalUnit>(
      `${this.config.host}/organizational-units/${id}${
        relations.length ? '?' + createRelationsQueryParamString(relations) : ''
      }`,
    );
  }

  public getOrganizationalUnitsChildren(
    parentId: string,
    withAllChildren = false, // withAllChildren resolves multiple levels down
  ): Observable<OrganizationalUnit[]> {
    const params = withAllChildren
      ? new HttpParams({ fromString: `withAllChildren=1` })
      : {};

    return this.httpClient.get<OrganizationalUnit[]>(
      `${this.config.host}/organizational-units/${parentId}/children`,
      { params },
    );
  }

  public async getResolvedOrganizationalUnit(
    id: string,
  ): Promise<OrganizationalUnit> {
    const orgUnit = await firstValueFrom(
      this.httpClient.post<OrganizationalUnit[]>(
        `${this.config.host}/organizational-units/resolved`,
        { id },
      ),
    );
    return orgUnit[0];
  }

  public async getOrganizationalUnitsByUserId(): Promise<OrganizationalUnit[]> {
    const rootOrgId = this.store.selectSnapshot(AuthState.rootOrgUnitId);
    return firstValueFrom(
      combineLatest([
        this.getOrganizationalUnit(rootOrgId as string),
        this.getOrganizationalUnitsChildren(rootOrgId as string, true),
      ]).pipe(
        map(([rootOrgUnit, children]) => [...children, rootOrgUnit]),
        map((organizationalUnits) =>
          organizationalUnits.sort((a, b) =>
            (a.name as string).localeCompare(b.name as string),
          ),
        ),
      ),
    );
  }

  public createOrgUnits(orgUnits: OrganizationalUnit[]) {
    return firstValueFrom(
      this.httpClient.post(`${this.config.host}/organizational-units`, {
        items: orgUnits,
      }),
    );
  }

  public updateOrgUnit(orgUnit: OrganizationalUnit, id: string) {
    return firstValueFrom(
      this.httpClient.patch(
        `${this.config.host}/organizational-units/${id}`,
        orgUnit,
      ),
    );
  }

  public deleteOrgUnit(id: string): Promise<OrganizationalUnit> {
    return firstValueFrom(
      this.httpClient.delete<OrganizationalUnit>(
        `${this.config.host}/organizational-units/${id}`,
      ),
    );
  }

  getUsersRootOrganizationalUnit(
    userId: string,
  ): Observable<OrganizationalUnit> {
    return this.httpClient.get<OrganizationalUnit>(
      `${this.config.host}/users/${userId}/root-organizational-unit`,
    );
  }
}
