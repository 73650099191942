import { Pipe, PipeTransform } from '@angular/core';
import { FullActivityReport, ResolvedActivityReport } from '@wilson/interfaces';
import { ReportUtilityService } from '../services/report-utility.service';

@Pipe({
  name: 'fullReport',
})
export class FullReportPipe implements PipeTransform {
  constructor(private reportUtility: ReportUtilityService) {}

  transform(reports: ResolvedActivityReport[]): FullActivityReport {
    return this.reportUtility.toFullReport(reports);
  }
}
