import { Injectable } from '@angular/core';
import { GanttShiftPixelLengthService } from './gantt-shift-pixel-length.service';
import {
  calculateStartingPixel,
  GanttActivityPixelCalculationInput,
} from './calculate-left-right-pixel.fn';

@Injectable({
  providedIn: 'root',
})
export class GanttScrollLeftPixelService {
  constructor(
    private ganttShiftPixelLengthService: GanttShiftPixelLengthService,
  ) {}

  calculate({
    startTime,
    endTime,
    frameLength,
    days,
    windowWidth,
    resourcesColumnWidthPx,
  }: GanttActivityPixelCalculationInput & {
    windowWidth: number;
    resourcesColumnWidthPx: number;
  }) {
    const shiftLength = this.ganttShiftPixelLengthService.calculate({
      startTime,
      endTime,
      frameLength,
      days,
    });

    const shiftLeftPx = calculateStartingPixel({
      startTime,
      frameLength,
      days,
    });

    const shiftMidpointPx = shiftLeftPx + shiftLength / 2;

    return shiftMidpointPx - windowWidth / 2 + resourcesColumnWidthPx;
  }
}
