// eslint-disable-next-line
export const combinedActivitiesCompareFn = (locationA: any, locationB: any) => {
  if (locationA['id'] && !locationB['id']) {
    return -1;
  }

  if (locationA['isHighlighted'] && !locationB['isHighlighted']) {
    return -1;
  }

  if (locationA['organizationalUnitId'] && !locationB['organizationalUnitId']) {
    return -1;
  }

  if (
    locationA['organizationalUnitId'] &&
    locationA['isHighlighted'] &&
    (!locationB['organizationalUnitId'] || !locationB['isHighlighted'])
  ) {
    return -1;
  }

  return 0;
};
