import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, of, take, tap } from 'rxjs';
import {
  CreateGeneralGeneralCompanySettings,
  FetchGeneralCompanySettings,
  UpdateGeneralGeneralCompanySettings,
} from './general-settings.actions';
import {
  CompanyAddressSettings,
  GeneralCompanySettings,
} from '@wilson/interfaces';
import { GeneralCompanySettingsGatewayService } from '../general-company-settings-gateway.service';
import { patch } from '@ngxs/store/operators';

export type GeneralSettingsStateModel = GeneralCompanySettings;

const defaults: GeneralSettingsStateModel = {
  address: {
    city: '',
    country: '',
    street: '',
    postCode: '',
    state: '',
  },
  companyLogo: '',
  email: '',
  generalManager: '',
  id: '',
  localCourt: '',
  organizationalUnitId: '',
  phone: '',
  taxIdentificationNumber: '',
  tradeRegisterNumber: '',
  vatId: '',
  websiteUrl: '',
};

export const GENERAL_COMPANY_SETTINGS_STATE_KEY = 'generalCompanySettings';

@State<GeneralSettingsStateModel>({
  name: GENERAL_COMPANY_SETTINGS_STATE_KEY,
  defaults,
})
@Injectable()
export class GeneralCompanySettingsState {
  constructor(
    private readonly generalCompanySettingsGatewayService: GeneralCompanySettingsGatewayService,
  ) {}

  @Selector()
  static generalCompanySettings(
    state: GeneralSettingsStateModel,
  ): GeneralCompanySettings {
    return state;
  }

  @Selector()
  static generalCompanyAddress(
    state: GeneralSettingsStateModel,
  ): CompanyAddressSettings {
    return state.address;
  }

  @Action(UpdateGeneralGeneralCompanySettings)
  async updateGeneralCompanyAddress(
    ctx: StateContext<GeneralSettingsStateModel>,
    action: UpdateGeneralGeneralCompanySettings,
  ) {
    const currentView = ctx.getState();

    ctx.setState(patch(action.generalCompanySettings));

    try {
      await this.generalCompanySettingsGatewayService.updateGeneralSettings(
        ctx.getState().id as string,
        action.generalCompanySettings,
      );

      if (action.generalCompanySettings.addressId === null) {
        action.generalCompanySettings.address = {
          city: '',
          country: '',
          street: '',
          postCode: '',
          state: '',
        };
      }

      ctx.patchState(action.generalCompanySettings);
    } catch (e) {
      ctx.patchState(currentView);
      return Promise.reject(e);
    }
  }

  @Action(CreateGeneralGeneralCompanySettings)
  async createGeneralGeneralCompanySettings(
    ctx: StateContext<GeneralSettingsStateModel>,
    action: UpdateGeneralGeneralCompanySettings,
  ) {
    const currentView = ctx.getState();

    ctx.setState(patch(action.generalCompanySettings));

    try {
      const generalSettings =
        await this.generalCompanySettingsGatewayService.createGeneralSettings(
          action.generalCompanySettings,
        );
      ctx.patchState(generalSettings);
    } catch (e) {
      ctx.patchState(currentView);
      return Promise.reject(e);
    }
  }

  @Action(FetchGeneralCompanySettings)
  fetchGeneralCompanySettings(ctx: StateContext<GeneralSettingsStateModel>) {
    return this.generalCompanySettingsGatewayService.getAll().pipe(
      take(1),
      tap((generalSettings) => {
        ctx.patchState({
          ...generalSettings,
        });
      }),
      catchError(() => {
        return of(null);
      }),
    );
  }
}
