import { ValidationErrors, ValidatorFn } from '@angular/forms';
import { DateTimeFormat } from '@wilson/utils';
import { format, isAfter } from 'date-fns';

export function EndDateTimeValidator(targetControlName: string): ValidatorFn {
  return (control): ValidationErrors | null => {
    const currentEndDatetime: string = control.value;
    const currentStartDatetime: string | null =
      control.parent?.controls[targetControlName]?.value;
    if (
      currentStartDatetime &&
      currentEndDatetime &&
      isAfter(
        new Date(
          format(
            new Date(currentEndDatetime),
            DateTimeFormat.DatabaseDateTimeFormat,
          ),
        ),
        new Date(
          format(
            new Date(currentStartDatetime),
            DateTimeFormat.DatabaseDateTimeFormat,
          ),
        ),
      )
    ) {
      return null;
    }

    return {
      isInvalid: true,
    };
  };
}
