import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  QualificationCategoryName,
  QualificationName,
  ShiftQuickCheckValidate,
  ShiftValidationDetails,
} from '@wilson/interfaces';

@Injectable({
  providedIn: 'root',
})
export class ShiftValidationErrorService {
  constructor(private readonly translate: TranslateService) {}

  static getShiftWorkingTimeValidation(
    errorMessages: ShiftValidationDetails[] | null,
    isValidationEnabled?: boolean,
  ) {
    const workingTimeValidation: ShiftValidationDetails[] = [];
    errorMessages?.forEach((errorMessage) => {
      if (
        isValidationEnabled &&
        errorMessage.checkName == ShiftQuickCheckValidate.UserApprovalCheck
      ) {
        workingTimeValidation.push(errorMessage);
      } else if (
        errorMessage.checkName == ShiftQuickCheckValidate.MaxNightShiftsInRow ||
        errorMessage.checkName ==
          ShiftQuickCheckValidate.MaxTimeBetweenRestDays ||
        errorMessage.checkName ==
          ShiftQuickCheckValidate.MaxWorkTimeInTimeRange ||
        errorMessage.checkName ==
          ShiftQuickCheckValidate.MinTimeBetweenShiftsV2 ||
        errorMessage.checkName ==
          ShiftQuickCheckValidate.MaximumActivityLengthCheck ||
        errorMessage.checkName ==
          ShiftQuickCheckValidate.MaximumProtectiveWorkingTimeCheck ||
        errorMessage.checkName ==
          ShiftQuickCheckValidate.MaximumShiftLengthCheck ||
        errorMessage.checkName ==
          ShiftQuickCheckValidate.BreakRequirementsCheck ||
        errorMessage.checkName ==
          ShiftQuickCheckValidate.MinimumTimeBetweenShiftCheck
      ) {
        workingTimeValidation.push(errorMessage);
      }
    });
    if (workingTimeValidation.length > 1) {
      workingTimeValidation.sort((a, b) =>
        String(a.valid).localeCompare(String(b.valid)),
      );
    }
    return workingTimeValidation;
  }

  getShiftProfessionValidation(
    errorMessages: ShiftValidationDetails[],
    isFeatureEnabled?: boolean,
  ) {
    const shiftProfessionValidations: ShiftValidationDetails[] = [];
    errorMessages?.forEach((errorMessage) => {
      if (
        isFeatureEnabled &&
        errorMessage.checkName == ShiftQuickCheckValidate.ShiftProfessionCheck
      ) {
        shiftProfessionValidations.push(errorMessage);
      }
    });
    if (shiftProfessionValidations.length > 1) {
      shiftProfessionValidations.sort((a, b) =>
        String(a.valid).localeCompare(String(b.valid)),
      );
    }
    return shiftProfessionValidations;
  }

  getShiftLogicValidation(errorMessages: ShiftValidationDetails[]) {
    const shiftLogicValidations: ShiftValidationDetails[] = [];
    errorMessages?.forEach((errorMessage) => {
      if (
        [
          ShiftQuickCheckValidate.ShiftTimeLogicCheck,
          ShiftQuickCheckValidate.ShiftLocationLogicCheck,
        ].includes(errorMessage.checkName as ShiftQuickCheckValidate)
      ) {
        shiftLogicValidations.push(errorMessage);
      }
    });
    if (shiftLogicValidations.length > 1) {
      shiftLogicValidations.sort((a, b) =>
        String(a.valid).localeCompare(String(b.valid)),
      );
    }
    return shiftLogicValidations;
  }

  getQualificationName(shiftValidations: ShiftValidationDetails[]) {
    const qualificationNames: QualificationName[] = [];
    shiftValidations.forEach((shiftValidation) => {
      const qualificationName: QualificationName = {
        title: '',
        caption: this.translate.instant('qualifications.name'),
        qualificationType: '',
        valid: false,
      };

      // make sure rest is joined to proper handle ' - ' in check names, e.g. routes
      const [qualificationCategory, ...rest] =
        shiftValidation.checkName.split(' - ');
      const qualificationCheckName = rest.join(' - ');

      qualificationName.qualificationType = this.translate.instant(
        `qualification_category.${qualificationCategory?.toLowerCase()}`,
      );

      qualificationName.valid = shiftValidation.valid;

      switch (qualificationCategory.toLowerCase()) {
        case QualificationCategoryName.Certificate:
          qualificationName.title = this.translate.instant(
            `certificate.${qualificationCheckName}`,
          );
          qualificationNames.push(qualificationName);
          break;
        case QualificationCategoryName.SafetyCertificate:
          qualificationName.title = qualificationCheckName;
          qualificationNames.push(qualificationName);
          break;
        case QualificationCategoryName.Country:
          qualificationName.title = qualificationCheckName;
          qualificationNames.push(qualificationName);
          break;
        case QualificationCategoryName.DriverLicense:
          qualificationName.title = this.translate.instant(
            `driverLicense.${qualificationCheckName}`,
          );
          qualificationNames.push(qualificationName);
          break;
        case QualificationCategoryName.FurtherEducation:
          qualificationName.title = this.translate.instant(
            `furtherEducation.${qualificationCheckName}`,
          );
          qualificationNames.push(qualificationName);
          break;
        case QualificationCategoryName.Language:
          qualificationName.title =
            qualificationCheckName.charAt(0).toUpperCase() +
            qualificationCheckName.slice(1);
          qualificationNames.push(qualificationName);
          break;
        case QualificationCategoryName.Location:
          qualificationName.title = qualificationCheckName;
          qualificationNames.push(qualificationName);
          break;
        case QualificationCategoryName.MedicalExamination:
          qualificationName.title = this.translate.instant(
            `medicalExamination.${qualificationCheckName.toLowerCase()}`,
          );
          qualificationNames.push(qualificationName);
          break;
        case QualificationCategoryName.More:
          qualificationName.title = this.translate.instant(
            `otherQualification.${qualificationCheckName}`,
          );
          qualificationNames.push(qualificationName);
          break;
        case QualificationCategoryName.Route:
          qualificationName.title = qualificationCheckName;
          qualificationName.caption = this.translate.instant(
            'qualifications.route.start',
          );
          qualificationNames.push(qualificationName);
          break;
        case QualificationCategoryName.Training:
          qualificationName.title = this.translate.instant(
            `training.${qualificationCheckName}`,
          );
          qualificationNames.push(qualificationName);
          break;
        case QualificationCategoryName.Vehicle:
          qualificationName.title = qualificationCheckName;
          qualificationName.caption = this.translate.instant(
            'qualifications.vehicle',
          );
          qualificationNames.push(qualificationName);
          break;
        default:
          break;
      }
    });

    qualificationNames.sort((a, b) =>
      String(a.valid).localeCompare(String(b.valid)),
    );
    return qualificationNames;
  }
}
