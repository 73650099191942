import { ActivityCategory } from '@wilson/interfaces';

export function compareActivityCategoryFn(
  selectedActivity: ActivityCategory | null,
  activity: ActivityCategory | null,
) {
  return selectedActivity && activity
    ? selectedActivity.id === activity.id
    : selectedActivity === activity;
}
