import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { InitBulkEditState } from './bulk-edit.action';
import { OrganizationalUnitService } from '@wilson/organizational-unit';
import { TypeOfGood } from '@wilson/activities/symbols';
import { map } from 'rxjs';

export interface BulkEditStateModel {
  transportGoods: TypeOfGood[];
}

const defaultBulkEditState: BulkEditStateModel = {
  transportGoods: [],
};

@State<BulkEditStateModel>({
  name: 'BulkEditStateModel',
  defaults: defaultBulkEditState,
})
@Injectable()
export class BulkEditState {
  constructor(
    private readonly organizationalUnitService: OrganizationalUnitService,
  ) {}

  @Selector()
  static selectTransportGoods(state: BulkEditStateModel): TypeOfGood[] {
    return state.transportGoods;
  }

  @Action(InitBulkEditState)
  initBulkEditState(
    { patchState }: StateContext<BulkEditStateModel>,
    { organizationalUnitId }: InitBulkEditState,
  ) {
    return this.organizationalUnitService
      .getOrganizationalUnit(organizationalUnitId, [
        'organizationalUnitTransportGoods',
      ])
      .pipe(
        map((organizationalUnit) => {
          patchState({
            transportGoods: organizationalUnit.organizationalUnitTransportGoods,
          });
        }),
      );
  }
}
