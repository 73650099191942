import { SideNavSetting, TileType } from './side-nav-settings.state';

export class SetSideNavSetting {
  static readonly type = '[SideNavSettings] Set SideNav Setting';
  constructor(public userId: string, public sideNavSettings: SideNavSetting) {}
}
export class SetSideNavExpandedTile {
  static readonly type = '[SideNavSettings] Set SideNav Expanded Tile';
  constructor(public userId: string, public sideNavExpandedTile?: TileType) {}
}
export class SetSideNavCollapsedStateSetting {
  static readonly type =
    '[SideNavSettings] Set SideNav Collapsed State Setting';
  constructor(public userId: string, public isCollapsed: boolean) {}
}
