import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  BackendService,
  ManyEntity,
  TypeOrmFindManyOptions,
  UpdateResult,
} from '@wilson/base';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { FeatureFlagPurePipe } from '@wilson/feature-flags';
import {
  AssignShiftInRange,
  BulkPublishShiftDto,
  FindConditions,
  PublicationStatus,
  PublishShiftDto,
  ResolvedShift,
  ResolvedShiftValidation,
  ResolvedShiftWithActivity,
  ResolvedShiftWithReports,
  Shift,
  ShiftAssignmentSuggestions,
  ShiftInDateRangeDto,
  ShiftPayrollInformation,
  ShiftReport,
  ShiftState,
  ShiftTemplate,
  ShiftValidationDetails,
  ShiftValidationsByShiftId,
  ShiftWithActivitiesWithLocations,
  User,
} from '@wilson/interfaces';
import { Note } from '@wilson/shift/interfaces';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { stringify } from 'qs';
import {
  combineLatest,
  firstValueFrom,
  from,
  Observable,
  of,
  throwError,
} from 'rxjs';
import { catchError, map, mergeMap, switchMap, toArray } from 'rxjs/operators';

export interface Validation {
  v1RequestError: boolean;
  v2RequestError: boolean;
  v2ValidationFlagEnabled: boolean;
  data: ResolvedShiftValidation[];
}

export const COMMON_SHIFT_RELATIONS = [
  'organizationalUnit',
  'user',
  'shiftCategory',
  'shiftSeries',
  'labels',
  'startLocation',
  'endLocation',
];

@Injectable({
  providedIn: 'root',
})
export class ShiftsService extends BackendService<Shift> {
  protected readonly path = 'shifts';

  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
    private readonly featureFlagPurePipe: FeatureFlagPurePipe,
  ) {
    super();
  }

  getShifts<T extends Shift>(
    options: TypeOrmFindManyOptions,
    extendedOptions = {},
  ): Observable<ManyEntity<T>> {
    const params = stringify({ ...options, ...extendedOptions });

    return this.http.get<ManyEntity<T>>(`${this.config.host}/shifts?${params}`);
  }

  getByUserInDateRange(
    options: {
      startDate: string;
      endDate: string;
      organizationalUnitId?: string[];
      userId?: string | string[];
      publicationStatus?: PublicationStatus;
      state?: ShiftState;
      declinedShifts?: boolean;
    },
    relations?: string[],
  ) {
    const params = stringify({ relations });
    return this.http.post<ResolvedShiftWithReports[]>(
      `${this.config.host}/shifts/date-range?${params}`,
      { ...options },
    );
  }

  public getInDateRange(
    lookup: FindConditions<ShiftInDateRangeDto> = {},
    relations?: string[],
  ) {
    const params = stringify({ relations });
    return this.http.post<ShiftWithActivitiesWithLocations[]>(
      `${this.config.host}/shifts/date-range?${params}`,
      { ...lookup },
    );
  }

  getShiftsWithFilteredStateInDateRange(
    dateRange: Interval,
    state: ShiftState,
    relations?: string[],
  ): Observable<ResolvedShiftWithReports[]> {
    const params = stringify({ relations });
    return this.http.post<ResolvedShiftWithReports[]>(
      `${this.config.host}/shifts/date-range?${params}`,
      {
        startDate: new Date(dateRange.start).toISOString(),
        endDate: new Date(dateRange.end).toISOString(),
        state,
      },
    );
  }

  public getInDateRangeWithValidations(
    startDate: Date,
    endDate: Date,
    lookup: FindConditions<ShiftInDateRangeDto> = {},
  ): Observable<Validation> {
    const v2shiftValidationRequest$: Observable<{
      v2ValidationFlagEnabled: boolean;
      isRequestError: boolean;
      data: ShiftValidationsByShiftId;
    }> = this.getInDateRangeWithValidationsV2(startDate, endDate, lookup);

    const shiftValidationRequest$: Observable<{
      isRequestError: boolean;
      data: ResolvedShiftValidation[];
    }> = this.http
      .post<ResolvedShiftValidation[]>(`${this.config.host}/shifts/validated`, {
        startDate,
        endDate,
        ...lookup,
      })
      .pipe(
        map((data) => ({
          isRequestError: false,
          data,
        })),
        catchError(() =>
          of({
            isRequestError: true,
            data: [],
          }),
        ),
      );

    return combineLatest([
      shiftValidationRequest$,
      v2shiftValidationRequest$,
    ]).pipe(
      map(([v1Response, v2Response]) => {
        const response: Validation = {
          v2ValidationFlagEnabled: v2Response.v2ValidationFlagEnabled,
          v1RequestError: v1Response.isRequestError,
          v2RequestError: v2Response.isRequestError,
          data: [],
        };
        if (!v1Response.isRequestError) {
          response.data = v1Response.data.map(({ shift, validations }) => {
            const v2Validations = v2Response.data[shift.id as string] ?? [];
            return {
              shift,
              validations: [...validations, ...v2Validations],
            };
          });
        }
        return response;
      }),
    );
  }

  // Experimental endpoint
  private getInDateRangeWithValidationsV2(
    startDatetime: Date,
    endDatetime: Date,
    lookup: FindConditions<ShiftInDateRangeDto> = {},
  ): Observable<{
    v2ValidationFlagEnabled: boolean;
    isRequestError: boolean;
    data: ShiftValidationsByShiftId;
  }> {
    const { userId, ...restLookup } = lookup;
    const newLookup = { userIds: userId, ...restLookup };

    return this.featureFlagPurePipe
      .transform('portal-shift-validation-v-2')
      .pipe(
        switchMap((v2ValidationFlagEnabled) => {
          const errorResponse = {
            v2ValidationFlagEnabled,
            isRequestError: true,
            data: {},
          };

          if (v2ValidationFlagEnabled) {
            return this.http
              .post<ShiftValidationsByShiftId>(
                `${this.config.host}/v2/shift-validations`,
                { startDatetime, endDatetime, ...newLookup },
              )
              .pipe(
                map((data) => ({
                  v2ValidationFlagEnabled,
                  isRequestError: false,
                  data,
                })),
                catchError(() => of(errorResponse)),
              );
          } else {
            return of(errorResponse);
          }
        }),
      );
  }

  getShiftInTimeRange(
    userIds: string[],
    startDatetime: string,
    endDatetime: string,
  ): Observable<ShiftWithActivitiesWithLocations[]> {
    return this.http.post<ShiftWithActivitiesWithLocations[]>(
      `${this.config.host}/shifts/time-range`,
      { userIds, startDatetime, endDatetime },
    );
  }

  getShiftInReportedTimeRange(
    userId: string,
    startDatetime: string,
    endDatetime: string,
  ): Observable<ShiftWithActivitiesWithLocations[]> {
    return this.http.post<ShiftWithActivitiesWithLocations[]>(
      `${this.config.host}/shifts/reported-time-range`,
      { userId, startDatetime, endDatetime },
    );
  }

  public getResolveShiftsByDate(
    startDate: string,
  ): Observable<ResolvedShiftWithActivity[]> {
    return this.http.post<ResolvedShiftWithActivity[]>(
      `${this.config.host}/shifts/resolved`,
      { startDate },
    );
  }

  public createShifts(shifts: Shift[]): Observable<Shift[]> {
    return this.http.post<Shift[]>(`${this.config.host}/shifts`, {
      items: shifts,
    });
  }

  public updateShift(shift: Shift): Observable<Shift> {
    return this.http.patch<Shift>(
      `${this.config.host}/shifts/${shift.id}`,
      shift,
    );
  }

  public getShift(shiftId: string): Observable<Shift> {
    return this.http.get<Shift>(`${this.config.host}/shifts/${shiftId}`);
  }

  public getResolvedShift(
    shiftId: string,
  ): Observable<ResolvedShiftWithActivity[]> {
    return this.http.post<ResolvedShiftWithActivity[]>(
      `${this.config.host}/shifts/resolved`,
      { id: shiftId },
    );
  }

  public getLiveShiftForUser(
    userId: string,
  ): Observable<ResolvedShiftWithReports | null> {
    return this.http
      .get<ResolvedShiftWithReports>(
        `${this.config.host}/shifts/users/${userId}/live-shift`,
      )
      .pipe(
        catchError((error) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status == 404) return of(null);
          }
          return throwError(() => error);
        }),
      );
  }

  public deleteShift(shiftId: string | undefined) {
    return firstValueFrom(
      this.http.delete(`${this.config.host}/shifts/${shiftId}`),
    );
  }

  public timelineReassignShift(
    shiftId: string,
    userId: string,
  ): Observable<ResolvedShift> {
    return this.http.patch<ResolvedShift>(
      `${this.config.host}/shifts/${shiftId}`,
      { userId, publicationStatus: PublicationStatus.NotPublished },
    );
  }

  public assignShift(
    shiftId: string,
    userId: string,
  ): Observable<ResolvedShift> {
    return this.http.patch<ResolvedShift>(
      `${this.config.host}/shifts/${shiftId}`,
      { userId },
    );
  }

  public autoAssignInDateRange(
    startDate: Date,
    endDate: Date,
    lookup: FindConditions<Shift> = {},
  ) {
    return this.http.post<ShiftWithActivitiesWithLocations[]>(
      `${this.config.host}/shifts/assignIn-time-range`,
      { startDate, endDate, ...lookup },
    );
  }

  public withdrawShift(shiftId: string): Observable<ResolvedShift> {
    return this.http.patch<ResolvedShift>(
      `${this.config.host}/shifts/${shiftId}`,
      { userId: null, publicationStatus: PublicationStatus.NotPublished },
    );
  }

  public withdrawShifts(shiftIds: string[]): Observable<UpdateResult[]> {
    if (!shiftIds.length) {
      return of([]);
    }
    const shiftToWithdraw = shiftIds.map((shiftId) => {
      return {
        id: shiftId,
        userId: null,
        publicationStatus: PublicationStatus.NotPublished,
      };
    });
    return this.updateShiftsBulk(shiftToWithdraw);
  }

  public publishShift(shiftId: string): Observable<ResolvedShift> {
    return this.http.patch<ResolvedShift>(
      `${this.config.host}/shifts/${shiftId}`,
      { publicationStatus: PublicationStatus.Published },
    );
  }

  public publishShifts(shiftIds: string[]): Observable<ResolvedShift[]> {
    const concurrent = 4;
    return from(shiftIds.map((shiftId) => this.publishShift(shiftId))).pipe(
      mergeMap((observable) => observable, concurrent),
      toArray(),
    );
  }

  public bulkPublishShifts(shifts: Shift[]): Observable<unknown> {
    const payload: BulkPublishShiftDto = {
      items: shifts.map((shift) => {
        const publishShiftDto: PublishShiftDto = {
          id: shift.id,
          name: shift.name,
          userId: shift.userId,
          startDate: shift.startDate,
        };
        return publishShiftDto;
      }),
    };
    return this.http.patch<unknown>(
      `${this.config.host}/shifts/publish`,
      payload,
    );
  }

  public getShiftValidation(
    shiftId: string,
    userId: string,
  ): Observable<ShiftValidationDetails[]> {
    return this.featureFlagPurePipe
      .transform('portal-shift-validation-v-2')
      .pipe(
        switchMap((isV2Enabled) => {
          const params: { include_v2_validations?: boolean } = {};
          if (isV2Enabled) {
            params.include_v2_validations = true;
          }

          return this.http.get<ShiftValidationDetails[]>(
            `${this.config.host}/shifts/${shiftId}/users/${userId}/validate`,
            {
              params: new HttpParams({
                fromObject: params,
              }),
            },
          );
        }),
      );
  }

  public updateShiftsBulk(shiftsToUpdate: Partial<Shift>[]) {
    return this.http.patch<UpdateResult[]>(`${this.config.host}/shifts/bulk`, {
      items: shiftsToUpdate,
    });
  }

  public startShift(shiftId: Shift['id']): Promise<void> {
    return firstValueFrom(
      this.http.post<void>(`${this.config.host}/shifts/${shiftId}/start`, {}),
    );
  }

  public endShift(shiftId: Shift['id']): Promise<void> {
    return firstValueFrom(
      this.http.post<void>(`${this.config.host}/shifts/${shiftId}/end`, {}),
    );
  }

  public assignAllShiftsInTimeRange(
    assignAllShiftsDetail: AssignShiftInRange,
  ): Promise<Shift[]> {
    return firstValueFrom(
      this.http.post<Shift[]>(
        `${this.config.host}/shifts/assign-in-time-range`,
        assignAllShiftsDetail,
      ),
    );
  }

  public shiftAssignmentSuggestions(
    shiftAssignmentSuggestions: ShiftAssignmentSuggestions,
  ): Promise<Partial<Record<string, string[]>>> {
    return firstValueFrom(
      this.http.post<Record<string, string[]>>(
        `${this.config.host}/v1/shift-assignment-suggestions`,
        shiftAssignmentSuggestions,
      ),
    );
  }

  public saveShiftPayrollInformation(
    shiftId: string | undefined,
    payrollInformation: Partial<ShiftPayrollInformation>,
  ): Observable<ShiftPayrollInformation> {
    return this.http.patch<ShiftPayrollInformation>(
      `${this.config.host}/shifts/${shiftId}/payroll-information`,
      payrollInformation,
    );
  }

  public saveShiftAsTemplate(shiftId: string, shiftTemplate: ShiftTemplate) {
    return firstValueFrom(
      this.http.post(
        `${this.config.host}/${this.path}/${shiftId}/shift-template`,
        shiftTemplate,
      ),
    );
  }

  public getShiftReport(shiftId: string) {
    return this.http.get<ShiftReport[]>(
      `${this.config.host}/${this.path}/${shiftId}/report`,
    );
  }

  public getShiftUser(shiftId: string): Observable<Partial<User> | undefined> {
    return this.http.get<Partial<User>>(
      `${this.config.host}/${this.path}/${shiftId}/user`,
    );
  }

  public patchBulkSubmitShifts(shiftIds: string[]) {
    return this.http.post(`${this.config.host}/${this.path}/submit`, {
      ids: shiftIds,
    });
  }

  public deleteShiftNote(shiftId: string, noteId: string) {
    return this.http.delete<Note>(
      `${this.config.host}/${this.path}/${shiftId}/notes/${noteId}`,
    );
  }

  public createShiftNote(shiftId: string, note: string) {
    return this.http.post<Note>(
      `${this.config.host}/${this.path}/${shiftId}/notes`,
      {
        note,
      },
    );
  }

  public fetchShiftNotes(shiftId: string) {
    return this.http.get<Note[]>(
      `${this.config.host}/${this.path}/${shiftId}/notes`,
    );
  }

  public deleteShiftNotes(shiftId: string, noteId: string) {
    return firstValueFrom(
      this.http.delete(
        `${this.config.host}/${this.path}/${shiftId}/notes/${noteId}`,
      ),
    );
  }

  getShiftExport(shiftId: string) {
    return this.http.get<Blob>(
      `${this.config.host}/${this.path}/${shiftId}/pdf-export`,
      {
        responseType: 'blob' as 'json',
      },
    );
  }

  addShiftAttachments(shiftId: string, files: NzUploadFile[]) {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('file', file as unknown as File);
    });

    return this.http.post(
      `${this.config.host}/${this.path}/${shiftId}/attachments`,
      formData,
    );
  }

  deleteShiftAttachments(shiftId: string, fileId: string) {
    return this.http.delete(
      `${this.config.host}/${this.path}/${shiftId}/attachments/${fileId}`,
    );
  }

  getShiftAttachments(shiftId: string) {
    return this.http.get<NzUploadFile[]>(
      `${this.config.host}/${this.path}/${shiftId}/attachments`,
    );
  }

  downloadZippedShiftAttachments(shiftId: string) {
    return this.http.get<Blob>(
      `${this.config.host}/${this.path}/${shiftId}/attachments/zip-export`,
      {
        responseType: 'blob' as 'json',
      },
    );
  }
}
