import { Client } from '@wilson/clients/interfaces';
import { NzCascaderOption } from 'ng-zorro-antd/cascader';

export function generateOptions(clients: Client[]): NzCascaderOption[] {
  const result: NzCascaderOption[] = [];
  clients
    .sort((a, b) => a.name.localeCompare(b.name))
    .forEach((client: Client) => {
      if (client.agreements?.length) {
        result.push({
          value: client.id || '',
          label: client.name,
          children: client.agreements
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((agreement) => {
              return {
                value: agreement.id,
                label: agreement.name,
                isLeaf: true,
              };
            }),
        });
      }
    });

  return result;
}
