import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  EventType,
  NavigationStart,
  Router,
} from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { Network } from '@capacitor/network';
import { SplashScreen } from '@capacitor/splash-screen';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import {
  InitializeAzureSSOFlow,
  SetCurrentlyReplacingAzureTokens,
} from '@wilson/auth/core';
import { FeatureFlagsService } from '@wilson/feature-flags';
import { NotificationTimerService } from '@wilson/notifications';
import { firstValueFrom } from 'rxjs';
import { filter, first, take } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { VersionUpdateService } from './modules/version-update/version-update.service';
import { PushNotificationService } from './services/push-notification.service';
import { StorageService } from './services/storage.service';
import { DarkModeService } from '@wilson/ionic/services';

@Component({
  selector: 'wilson-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private readonly storage: StorageService,
    private readonly featureFlags: FeatureFlagsService,
    private readonly pushNotificationService: PushNotificationService,
    private readonly notificationTimerService: NotificationTimerService,
    private readonly versionUpdateService: VersionUpdateService,
    private readonly zone: NgZone,
    private router: Router,
    private readonly toastController: ToastController,
    private readonly translateService: TranslateService,
    private readonly store: Store,
    private readonly activatedRoute: ActivatedRoute,
    private readonly darkModeService: DarkModeService,
  ) {
    this.preloadToast();
    this.startup()
      .catch((err) => {
        console.error('something bad happened during app startup', err);
      })
      .then(() => {
        this.versionUpdateService.initAppVersionCheck();
        // NOTE: the version check services needs to be updated to cleanly remove navigation initialization.
        // This will be changed in an upcoming refactoring task to a repository near you!
        // this.router.initialNavigation();
      });
    if (Capacitor.isNativePlatform()) {
      this.addAppUrlOpenListener();
    } else {
      this.addAzureAuthListener();
    }

    console.info(`Current Version: `, environment.releaseVersion);
  }

  ngOnInit(): void {
    this.darkModeService.initTheming();
  }

  ngOnDestroy(): void {
    this.darkModeService.destroyTheming();
  }

  private async startup(): Promise<void> {
    // 1. finish initializing prerequisites
    await this.initFeatureFlags();

    // when we're here, the app is actually starting

    // 2. restore previous app state

    // 3. initialize services that might be reliant on session state
    this.initNotifications();

    await SplashScreen.hide();
  }

  private async initFeatureFlags(): Promise<void> {
    try {
      const networkState = await Network.getStatus();

      // must be online or have at least one stored feature flag
      //      if ( networkState.connected && ( !storedFlags || Object.values(storedFlags)?.length < 1)) {
      if (networkState.connected) {
        await this.featureFlags.isLaunchDarklyReady();
      }
    } catch (err) {
      console.error('Problem initializing feature flags', err);
    }

    this.featureFlags.flag$.pipe(first()).subscribe((flags) => {
      this.storage.persistent('featureFlags', flags);
    });
  }

  initNotifications(): void {
    this.pushNotificationService.initPush();
    this.notificationTimerService.initNotificationCountTimer();
  }

  /**
   * Handles Azure SSO for web mobile.
   * - Requires app routing init to be set to 'enabledNonBlocking'
   * - Listens for NavigationStart events containing 'accessToken' and 'refreshToken'.
   * - Updates state and authenticates user with the extracted tokens.
   * - Falls back to SSO error handling on failure.
   * Note: Uses URLSearchParams due to router not giving us the full URL
   */
  private addAzureAuthListener(): void {
    this.router.events
      .pipe(
        filter(
          (event: NavigationStart) =>
            event.type === EventType.NavigationStart &&
            event.url &&
            ((event.url.includes('accessToken') &&
              event.url.includes('refreshToken')) ||
              (event.url.includes('azureErrorInternal') &&
                event.url.includes('azureErrorExternal'))),
        ),
        take(1),
      )
      .subscribe(async (event) => {
        const urlSearchParams = new URLSearchParams(event.url.split('?')[1]);
        try {
          this.store.dispatch(new SetCurrentlyReplacingAzureTokens(true));
          const accessToken = urlSearchParams.get('accessToken');
          const refreshToken = urlSearchParams.get('refreshToken');
          // throws exception if accessToken or refreshToken do not have a value (e.g. azure error / backend error)
          await this.authenticate(accessToken, refreshToken);
        } catch {
          this.handleSSOError(urlSearchParams);
        }
      });
  }

  /**
   * Manages Azure SSO for native platforms via app URL scheme.
   * - Listens for 'appUrlOpen' events and validates the protocol against the app's bundle ID.
   * - Closes in-app browser and triggers authentication with extracted tokens.
   * - Handles SSO errors and updates state accordingly.
   */
  private addAppUrlOpenListener(): void {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.store.dispatch(new SetCurrentlyReplacingAzureTokens(true));
      this.zone.run(async () => {
        const url = new URL(event.url);
        const bundleId = (await App.getInfo()).id;
        const urlSearchParams = new URLSearchParams(url.search);
        try {
          if (url.protocol === `${bundleId}:`) {
            Browser.close();
            const accessToken = url.searchParams.get('accessToken');
            const refreshToken = url.searchParams.get('refreshToken');
            // throws exception if accessToken or refreshToken do not have a value (e.g. azure error / backend error)
            await this.authenticate(accessToken, refreshToken);
          } else {
            throw new Error('Invalid app url protocol');
          }
        } catch {
          this.handleSSOError(urlSearchParams);
        }
      });
    });
  }

  private async authenticate(
    accessToken: string,
    refreshToken: string,
  ): Promise<void> {
    if (!accessToken || !refreshToken) {
      throw new Error('Invalid access or refreshtoken!');
    }
    await firstValueFrom(
      this.store.dispatch(
        new InitializeAzureSSOFlow(accessToken, refreshToken),
      ),
    );
    this.router.navigate(['/tabs/home']);
  }

  private async handleSSOError(
    urlSearchParams: URLSearchParams,
  ): Promise<void> {
    this.store.dispatch(new SetCurrentlyReplacingAzureTokens(false));
    const azureErrorInternal = urlSearchParams.get('azureErrorInternal');
    const azureErrorExternal = urlSearchParams.get('azureErrorExternal');
    this.presentSSOError(azureErrorInternal, azureErrorExternal);
  }

  private async presentSSOError(
    azureErrorInternal: string,
    azureErrorExternal: string,
  ): Promise<void> {
    console.error('azureError:', azureErrorInternal, azureErrorExternal);
    const toast = await this.toastController.create({
      color: 'danger',
      header: this.translateService.instant(
        'auth.login.azure_sso_failed.header',
      ),
      message: `${this.translateService.instant(
        'auth.login.azure_sso_failed.message',
      )} ${azureErrorExternal}`,
      duration: 5000,
    });
    await toast.present();
  }

  // This is required to load necessary chunk before hand. If device directly go offline then this chunk is not loaded thus toast is not displayed.
  async preloadToast(): Promise<void> {
    const toast = await this.toastController.create({
      message: '',
      duration: 0,
    });
    await toast.present();
    await toast.dismiss();
  }
}
