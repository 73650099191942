import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { UserFavoriteGeoLocation } from '@wilson/interfaces';

@Injectable({
  providedIn: 'root',
})
export class UserFavoriteLocationsService {
  protected readonly path = 'user-favorite-locations';

  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
  ) {}

  getFavoriteLocations() {
    return this.http.get<UserFavoriteGeoLocation[]>(
      `${this.config.host}/${this.path}?relations=location`,
    );
  }

  setFavoriteLocationForUser(locationId: string) {
    return this.http.post(`${this.config.host}/${this.path}`, {
      locationId,
    });
  }

  removeFavoriteLocationFromUser(locationId: string) {
    return this.http.delete(`${this.config.host}/${this.path}/${locationId}`);
  }
}
