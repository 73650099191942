import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { LoginResponse } from '@wilson/interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthService {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
  ) {}

  login(email: string, password: string) {
    return this.httpClient.post<LoginResponse>(
      `${this.config.host}/auth/login`,
      {
        email,
        password,
      },
      {
        headers: {
          'X-Application': this.config.application,
          'X-Ignore-Auth-Interceptor': '1',
        },
      },
    );
  }

  forgotPassword(email: string) {
    return this.httpClient.post(
      `${this.config.host}/auth/forgot-password`,
      {
        email,
      },
      {
        headers: {
          'X-Ignore-Auth-Interceptor': '1',
        },
      },
    );
  }

  resetPassword(token: string, password: string) {
    return this.httpClient.post(
      `${this.config.host}/auth/reset-password`,
      {
        token,
        password,
      },
      {
        headers: {
          'X-Ignore-Auth-Interceptor': '1',
        },
      },
    );
  }

  setInitialPassword(token: string, password: string) {
    return this.httpClient.post(
      `${this.config.host}/auth/set-initial-password`,
      {
        token,
        password,
      },
      {
        headers: {
          'X-Ignore-Auth-Interceptor': '1',
        },
      },
    );
  }

  logout(refreshToken: string) {
    return this.httpClient.post(
      `${this.config.host}/auth/logout`,
      {
        refreshToken,
      },
      {
        headers: {
          'X-Ignore-Auth-Interceptor': '1',
        },
      },
    );
  }

  refreshToken(refreshToken: string): Observable<string> {
    return this.httpClient
      .post<Record<string, string>>(`${this.config.host}/auth/refresh-token`, {
        refreshToken,
      })
      .pipe(map((payload) => payload['accessToken']));
  }

  resendInvitation(email: string) {
    return this.httpClient.post<void>(
      `${this.config.host}/auth/resend-invitation`,
      { email },
      {
        headers: {
          'X-Ignore-Auth-Interceptor': '1',
        },
      },
    );
  }
}
