import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngxs/store';
import { filter, map, Observable, switchMap } from 'rxjs';
import { AuthState } from '@wilson/auth/core';
import { RolesService } from '../roles.service';
import { UserRole } from '@wilson/interfaces';

@Pipe({
  name: 'roles',
})
export class RolesPipe implements PipeTransform {
  constructor(
    private readonly rolesService: RolesService,
    private readonly store: Store,
  ) {}

  transform(roles: string[]): Observable<boolean> {
    return this.store.select(AuthState.userId).pipe(
      filter((userId) => !!userId),
      switchMap((userId) => this.rolesService.getUserRoles(userId)),
      map((userRoles: UserRole[]) => {
        return !!userRoles.find((userRole) =>
          roles.includes(userRole.role.name),
        );
      }),
    );
  }
}
