import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { firstValueFrom } from 'rxjs';
import {
  UpdateUserGeneralPreferencePayload,
  UserGeneralPreference,
} from './preferences.interface';

@Injectable({
  providedIn: 'root',
})
export class GeneralUserPreferencesGatewayService {
  protected readonly path = (id: string) => `users/${id}/general/preferences`;

  constructor(
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
    protected readonly http: HttpClient,
  ) {}

  get(userId: string) {
    return this.http.get<UserGeneralPreference>(
      `${this.config.host}/${this.path(userId)}`,
    );
  }

  create(userId: string, data: UserGeneralPreference) {
    return firstValueFrom(
      this.http.post(`${this.config.host}/${this.path(userId)}`, {
        endShiftAtHomeRating: data.endShiftAtHomeRating,
        id: data.id,
      }),
    );
  }

  update(
    userId: string,
    userGeneralPreferenceId: string,
    payload: UpdateUserGeneralPreferencePayload,
  ) {
    return firstValueFrom(
      this.http.patch(
        `${this.config.host}/${this.path(userId)}/${userGeneralPreferenceId}`,
        payload,
      ),
    );
  }

  delete(userId: string, userGeneralPreferenceId: string) {
    return firstValueFrom(
      this.http.delete(
        `${this.config.host}/${this.path(userId)}/${userGeneralPreferenceId}`,
      ),
    );
  }
}
