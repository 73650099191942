import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { UserNotificationSearchParams } from '@wilson/interfaces';
import { BehaviorSubject, of, ReplaySubject, Subject, timer } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { NotificationsService } from './notifications.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationTimerService implements OnDestroy {
  private destroyed$ = new ReplaySubject<void>();
  private stopNotificationTimer$ = new Subject<void>();
  unreadNotificationCounter = new BehaviorSubject<number>(0);
  $unreadNotificationCounter = this.unreadNotificationCounter.asObservable();

  constructor(
    private readonly store: Store,
    private readonly notificationService: NotificationsService,
  ) {}

  initNotificationCountTimer() {
    this.store
      .select((state) => state.auth.userId)
      .pipe(
        filter((userId) => !!userId),
        takeUntil(this.destroyed$),
      )
      .subscribe((userId) => {
        this.stopNotificationTimer$.next();
        const payload: UserNotificationSearchParams = {
          userId,
          isRead: false,
        };

        timer(0, 30000)
          .pipe(
            switchMap(() =>
              this.notificationService.getUserNotifications(payload).pipe(
                takeUntil(this.stopNotificationTimer$),
                takeUntil(this.destroyed$),
                catchError(() => of([])),
              ),
            ),
            takeUntil(this.stopNotificationTimer$),
            takeUntil(this.destroyed$),
            map((notifications) => notifications.length),
          )
          .subscribe((count) => {
            this.unreadNotificationCounter.next(count);
          });
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
    this.stopNotificationTimer$.complete();
  }
}
