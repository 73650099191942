import { Injectable } from '@angular/core';
import { StateOperator, Store } from '@ngxs/store';
import { append, insertItem, patch, updateItem } from '@ngxs/store/operators';
import { activityReportFactory } from '@wilson/activities/util';
import {
  ActivityReportCategory,
  ActivityReportType,
  DefaultActivityCategory,
  OperationStatus,
  ResolvedActivityReport,
  ResolvedActivityWithReports,
} from '@wilson/interfaces';
import { MasterDataState } from '@wilson/master-data/states';
import { WilsonApp } from '@wilson/interfaces';
import { addMinutes } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import { ResolvedShiftActions } from './state/resolved-shifts.action';
import {
  ResolvedShiftsStateModel,
  ResolvedShiftWithSyncStatus,
} from './state/resolved-shifts.state';

@Injectable()
export class StartPauseActivityStateService {
  constructor(private readonly store: Store) {}

  patchStartPauseActivity(
    action: ResolvedShiftActions.StartPauseActivityLiveShift,
    shift: ResolvedShiftWithSyncStatus,
  ): StateOperator<ResolvedShiftsStateModel>[] {
    const pauseActivityId = uuidv4();
    const pauseCategory = this.store
      .selectSnapshot(MasterDataState.activityCategories)
      ?.find(({ name }) => name === DefaultActivityCategory.Break);
    const currentActivity = action.currentActivity;
    const currentActivityIndex = shift.activities.findIndex(
      (a) => a.id === currentActivity.id,
    );

    let stateOperators: StateOperator<ResolvedShiftsStateModel>[] = [];

    stateOperators = [
      ...stateOperators,
      patch<ResolvedShiftsStateModel>({
        shifts: updateItem<ResolvedShiftWithSyncStatus>(
          (s) => s?.id === shift.id,
          patch<ResolvedShiftWithSyncStatus>({
            syncedAt: null,
          }),
        ),
      }),
    ];

    stateOperators = [
      ...stateOperators,
      patch<ResolvedShiftsStateModel>({
        shifts: updateItem<ResolvedShiftWithSyncStatus>(
          (s) => s?.id === shift.id,
          patch<ResolvedShiftWithSyncStatus>({
            activities: insertItem<ResolvedActivityWithReports>(
              {
                id: pauseActivityId,
                name: '',
                activityCategoryId: pauseCategory?.id as string,
                activityCategory: pauseCategory,
                shiftId: shift.id,
                serviceId: null,
                viaLocationIds: [],
                startLocationId: currentActivity.endLocationId,
                startLocation: currentActivity.endLocation,
                endLocationId: currentActivity.endLocationId,
                endLocation: currentActivity.endLocation,
                startDatetime: action.startDateTime.toISOString(),
                endDatetime: addMinutes(
                  new Date(action.startDateTime),
                  action.minPauseTime,
                ).toISOString(),
                operationalStatus: OperationStatus.Ongoing,
                createdFrom: WilsonApp.Mobile,
                activityComments: [],
                activityReports: [
                  activityReportFactory(
                    pauseActivityId,
                    new Date().toISOString(),
                    currentActivity.endLocation,
                    currentActivity.endLocationId,
                    ActivityReportCategory.Start,
                    ActivityReportType.IsTimes,
                  ),
                ],
                jobId: null,
                professionId: null,
              } as ResolvedActivityWithReports,
              currentActivityIndex + 1,
            ),
          }),
        ),
      }),
    ];

    stateOperators = [
      ...stateOperators,
      patch<ResolvedShiftsStateModel>({
        shifts: updateItem<ResolvedShiftWithSyncStatus>(
          (s) => s?.id === shift.id,
          patch<ResolvedShiftWithSyncStatus>({
            activities: updateItem<ResolvedActivityWithReports>(
              (a) => a?.id === currentActivity.id,
              patch<ResolvedActivityWithReports>({
                operationalStatus: OperationStatus.Completed,
                activityReports: append<ResolvedActivityReport>([
                  activityReportFactory(
                    currentActivity.id as string,
                    new Date().toISOString(),
                    currentActivity.endLocation,
                    currentActivity.endLocationId,
                    ActivityReportCategory.End,
                    ActivityReportType.IsTimes,
                  ),
                ]),
              }),
            ),
          }),
        ),
      }),
    ];

    return stateOperators;
  }
}
