import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigOptions, ConfigService } from '@wilson/config';
import { UserFavoriteActivityCategory } from '@wilson/interfaces';

@Injectable({
  providedIn: 'root',
})
export class UserFavoriteActivityCategoriesService {
  protected readonly path = 'user-favorite-activity-categories';

  constructor(
    protected readonly http: HttpClient,
    @Inject(ConfigService)
    protected readonly config: ConfigOptions,
  ) {}

  getFavoriteActivityCategories() {
    return this.http.get<UserFavoriteActivityCategory[]>(
      `${this.config.host}/${this.path}?relations=activityCategory`,
    );
  }

  setFavoriteActivityCategoryForUser(activityCategoryId: string) {
    return this.http.post(`${this.config.host}/${this.path}`, {
      activityCategoryId,
    });
  }

  removeFavoriteActivityCategoryFromUser(activityCategoryId: string) {
    return this.http.delete(
      `${this.config.host}/${this.path}/${activityCategoryId}`,
    );
  }
}
