import { VehicleModel } from '@wilson/interfaces';

export class FetchVehiclePreferenceSetting {
  static readonly type =
    '[VehiclePreferences] Fetch Vehicle Preference Setting';
}

export class AddVehiclePreferenceSetting {
  static readonly type = '[VehiclePreferences] Add Vehicle Preference Setting';
  constructor(
    public payload: {
      vehicle: VehicleModel;
    },
  ) {}
}

export class RemoveVehiclePreferenceSetting {
  static readonly type =
    '[VehiclePreferences] Remove Vehicle Preference Setting';
  constructor(
    public payload: {
      vehicleId: string;
    },
  ) {}
}
