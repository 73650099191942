import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { VehicleModel } from '@wilson/interfaces';
import {
  OrganizationalUnitVehiclePreference,
  VehiclePreferencesGatewayService,
} from '@wilson/preferences/core';
import { catchError, map, of, tap } from 'rxjs';
import {
  AddVehiclePreferenceSetting,
  FetchVehiclePreferenceSetting,
  RemoveVehiclePreferenceSetting,
} from './vehicle-preferences.actions';
import { v4 as uuidv4 } from 'uuid';
import { NzMessageService } from 'ng-zorro-antd/message';
import { TranslateService } from '@ngx-translate/core';
import { SelectableVehicle } from './vehicle-preferences.interface';
import { HttpErrorResponse } from '@angular/common/http';

export const VehiclePreferencesStateName = 'vehiclePreferences';

export interface VehiclePreferenceSettingStateModel {
  loadingPreference: boolean;
  loadingPreferenceSuccess: boolean;
  loadingPreferenceError: boolean;
  selectableVehicle: SelectableVehicle[];
}

const defaults = {
  loadingPreference: false,
  loadingPreferenceSuccess: false,
  loadingPreferenceError: false,
  selectableVehicle: [],
};

@State<VehiclePreferenceSettingStateModel>({
  name: VehiclePreferencesStateName,
  defaults,
})
@Injectable()
export class VehiclePreferencesState {
  constructor(
    private vehiclePreferencesGatewayService: VehiclePreferencesGatewayService,
    private messageService: NzMessageService,
    private translateService: TranslateService,
  ) {}

  @Selector()
  static selectableVehicles(state: VehiclePreferenceSettingStateModel) {
    return state.selectableVehicle;
  }

  @Selector()
  static loadingPreference(state: VehiclePreferenceSettingStateModel) {
    return state.loadingPreference;
  }

  @Selector()
  static loadingPreferenceSuccess(state: VehiclePreferenceSettingStateModel) {
    return state.loadingPreferenceSuccess;
  }

  @Selector()
  static loadingPreferenceError(state: VehiclePreferenceSettingStateModel) {
    return state.loadingPreferenceError;
  }

  @Action(AddVehiclePreferenceSetting)
  async addPreselectedVehicles(
    { patchState, getState }: StateContext<VehiclePreferenceSettingStateModel>,
    { payload }: AddVehiclePreferenceSetting,
  ) {
    const currentList = getState().selectableVehicle;
    const newPreference = {
      id: uuidv4(),
      vehicleModelId: payload.vehicle.id,
    };

    try {
      patchState({
        selectableVehicle: [
          ...currentList,
          {
            id: newPreference.id,
            name: payload.vehicle.name,
            vehicleId: payload.vehicle.id,
          },
        ],
      });
      await this.vehiclePreferencesGatewayService.add(newPreference);
    } catch {
      patchState({
        selectableVehicle: [...currentList],
      });
      this.messageService.error(this.translateService.instant('general.error'));
    }
  }

  @Action(RemoveVehiclePreferenceSetting)
  async removeVehiclePreferenceSetting(
    { patchState, getState }: StateContext<VehiclePreferenceSettingStateModel>,
    { payload }: RemoveVehiclePreferenceSetting,
  ) {
    const currentList = getState().selectableVehicle;
    const settingIdToRemove = currentList.find(
      (setting) => setting.vehicleId === payload.vehicleId,
    )?.id;

    if (settingIdToRemove) {
      patchState({
        selectableVehicle: currentList.filter(
          (setting) => setting.vehicleId !== payload.vehicleId,
        ),
      });
      try {
        await this.vehiclePreferencesGatewayService.remove(settingIdToRemove);
      } catch {
        patchState({
          selectableVehicle: [...currentList],
        });
        this.messageService.error(
          this.translateService.instant('general.error'),
        );
      }
    } else {
      console.error(
        'Unexpected error. No preference object found with vehicle id',
        payload.vehicleId,
      );
    }
  }

  @Action(FetchVehiclePreferenceSetting)
  fetchVehiclePreferenceSetting({
    patchState,
  }: StateContext<VehiclePreferenceSettingStateModel>) {
    patchState({
      loadingPreference: true,
      loadingPreferenceSuccess: false,
      loadingPreferenceError: false,
    });
    return this.vehiclePreferencesGatewayService
      .getAll({
        embed: ['vehicleModel'],
      })
      .pipe(
        map((data) =>
          data.map(
            (
              vehicle: OrganizationalUnitVehiclePreference & {
                vehicleModel: VehicleModel;
              },
            ) => ({
              id: vehicle.id,
              name: vehicle.vehicleModel.name,
              vehicleId: vehicle.vehicleModel.id,
            }),
          ),
        ),
        tap((result) => {
          patchState({
            loadingPreference: false,
            loadingPreferenceSuccess: true,
            loadingPreferenceError: false,
            selectableVehicle: result,
          });
        }),
        catchError((e: HttpErrorResponse) => {
          patchState({
            loadingPreference: false,
            loadingPreferenceSuccess: false,
            loadingPreferenceError: true,
          });

          return of(e);
        }),
      );
  }
}
