import { ServiceActivityStatus } from './serviceActivityStatus';
import { StatusTranslationKey } from './statusTranslationKey';

export const ServiceActivityStatusTranslationMap: {
  [key in ServiceActivityStatus]: StatusTranslationKey;
} = {
  [ServiceActivityStatus.StaffAssigned]: StatusTranslationKey.Occupied,
  [ServiceActivityStatus.AssignedToShift]: StatusTranslationKey.PartlyOccupied,
  [ServiceActivityStatus.NotStaffed]: StatusTranslationKey.NotOccupied,
};
