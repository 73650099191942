import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { TranslateService } from '@ngx-translate/core';
import {
  Action,
  NgxsAfterBootstrap,
  Selector,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { patch, removeItem } from '@ngxs/store/operators';
import { GeoLocation } from '@wilson/interfaces';
import {
  AddLocationSelectionHistory,
  ClearLocationSelectionHistory,
  DeleteLocationSelectionHistory,
} from './location-selection-history.action';
import { FeatureFlagNumberPipe } from '@wilson/feature-flags';
import { firstValueFrom } from 'rxjs';
import { FavoriteLocationsState } from '../favorite-locations-state/favorite-locations.state';

interface LocationSelectionHistoryStateModal {
  locationSelectionHistory: GeoLocation[];
}
const STATE_NAME = 'locationSelectionHistory';
const defaultOperationsState: LocationSelectionHistoryStateModal = {
  locationSelectionHistory: [],
};

@State<LocationSelectionHistoryStateModal>({
  name: STATE_NAME,
  defaults: defaultOperationsState,
})
@Injectable()
export class LocationSelectionHistoryState implements NgxsAfterBootstrap {
  constructor(
    private readonly storage: Storage,
    private readonly translate: TranslateService,
    private readonly toastController: ToastController,
    private readonly featureFlagNumberPipe: FeatureFlagNumberPipe,
    private readonly store: Store,
  ) {}

  @Selector()
  static locationSelectionHistory(state: LocationSelectionHistoryStateModal) {
    return state.locationSelectionHistory;
  }

  async ngxsAfterBootstrap(
    ctx: StateContext<LocationSelectionHistoryStateModal>,
  ): Promise<void> {
    const locationSelectionHistoryState = await this.storage.get(STATE_NAME);
    if (locationSelectionHistoryState)
      ctx.patchState(locationSelectionHistoryState);
  }

  @Action(AddLocationSelectionHistory)
  async addLocationSelectionHistory(
    ctx: StateContext<LocationSelectionHistoryStateModal>,
    action: AddLocationSelectionHistory,
  ) {
    try {
      const isFavoriteLocation = this.store.selectSnapshot(
        FavoriteLocationsState.isFavoriteLocation(action.location.id as string),
      );
      if (!isFavoriteLocation) {
        const maxHistoryCount = await firstValueFrom(
          this.featureFlagNumberPipe.transform('mobile-location-history-count'),
        );
        const state = ctx.getState();
        let locationHistory = state.locationSelectionHistory || [];

        locationHistory = locationHistory.filter(
          (location) => location.id !== action.location.id,
        );
        locationHistory.unshift(action.location);
        if (locationHistory.length > maxHistoryCount) {
          locationHistory = locationHistory.slice(0, maxHistoryCount);
        }

        ctx.setState(
          patch<LocationSelectionHistoryStateModal>({
            locationSelectionHistory: locationHistory,
          }),
        );
        this.updateStorage(ctx);
      }
    } catch (error) {
      console.error(error);
      const toast = await this.toastController.create({
        message: this.translate.instant('general.errorMessage'),
        duration: 3000,
        position: 'bottom',
      });
      await toast.present();
    }
  }

  @Action(ClearLocationSelectionHistory)
  async clearLocationSelectionHistory(
    ctx: StateContext<LocationSelectionHistoryStateModal>,
  ) {
    try {
      ctx.setState(
        patch<LocationSelectionHistoryStateModal>({
          locationSelectionHistory: [],
        }),
      );
      this.updateStorage(ctx);
    } catch (error) {
      console.error(error);
      const toast = await this.toastController.create({
        message: this.translate.instant('general.errorMessage'),
        duration: 3000,
        position: 'bottom',
      });
      await toast.present();
    }
  }

  @Action(DeleteLocationSelectionHistory)
  async deleteLocationSelectionHistory(
    ctx: StateContext<LocationSelectionHistoryStateModal>,
    action: DeleteLocationSelectionHistory,
  ) {
    try {
      ctx.setState(
        patch<LocationSelectionHistoryStateModal>({
          locationSelectionHistory: removeItem<GeoLocation>(
            (location) => location.id === action.locationId,
          ),
        }),
      );
      this.updateStorage(ctx);
    } catch (error) {
      console.error(error);
      const toast = await this.toastController.create({
        message: this.translate.instant('general.errorMessage'),
        duration: 3000,
        position: 'bottom',
      });
      await toast.present();
    }
  }

  private updateStorage(ctx: StateContext<LocationSelectionHistoryStateModal>) {
    this.storage.set(STATE_NAME, ctx.getState());
  }
}
