import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { ActivityCategory } from '@wilson/interfaces';
import { ActivityEditService } from '../../services/activity-edit.service';

@Component({
  selector: 'wilson-activity-edit-category',
  templateUrl: './activity-edit-category.component.html',
  styleUrls: ['./activity-edit-category.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityEditCategoryComponent implements OnInit {
  @Input() activityCategory: ActivityCategory;
  @Output() activityCategoryChange = new EventEmitter<ActivityCategory>();

  @Input() disabled = false;

  activityCategories: ActivityCategory[];

  constructor(
    private readonly activityEditService: ActivityEditService,
    private readonly store: Store,
    private readonly translateService: TranslateService,
  ) {}

  ngOnInit() {
    const activityCategoriesFromService =
      this.activityEditService.activityCategories;

    const isSelectedActivityCategoryAllowed =
      !!activityCategoriesFromService.find(
        ({ id }) => id === this.activityCategory.id,
      );
    if (!isSelectedActivityCategoryAllowed) {
      activityCategoriesFromService.push(this.activityCategory);
    }

    activityCategoriesFromService.sort((a, b) =>
      this.translateService
        .instant(a.name)
        .localeCompare(this.translateService.instant(b.name)),
    );

    this.activityCategories = activityCategoriesFromService;
  }

  onActivityCategoryChange(activityCategory: ActivityCategory) {
    this.activityCategoryChange.emit(activityCategory);
  }

  compareActivityCategoryFn = (
    selectedActivityCategory: ActivityCategory,
    activityCategory: ActivityCategory,
  ) => {
    return selectedActivityCategory?.id === activityCategory?.id;
  };
}
