import { Pipe, PipeTransform } from '@angular/core';
import { Address, GeoLocation, LocationType } from '@wilson/interfaces';
import { AddressPipe } from '../address/address.pipe';

@Pipe({
  name: 'locationNameSub',
})
export class LocationNameSubPipe implements PipeTransform {
  constructor(private readonly addressPipe: AddressPipe) {}

  transform(
    location:
      | Pick<
          GeoLocation,
          'locationCategory' | 'address' | 'latitude' | 'longitude'
        >
      | Pick<Address, 'street' | 'postCode' | 'city' | 'country'>,
  ): string {
    if (!location) {
      return '';
    }

    if ('locationCategory' in location && location.locationCategory?.name) {
      if (location.locationCategory.name === LocationType.Address) {
        return this.addressPipe.transform(location.address as Address);
      } else if (location.locationCategory.name === LocationType.Custom) {
        return `${location.latitude}, ${location.longitude}`;
      }
    }

    if (
      'street' in location &&
      'postCode' in location &&
      'city' in location &&
      'country' in location
    ) {
      return this.addressPipe.transform(location);
    }

    return '';
  }
}
