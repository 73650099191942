import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { AuthState } from '@wilson/auth/core';
import { LaunchDarklyClientId } from './feature-flags.service';
import { FeatureFlagNumberPipe } from './pipes/feature-flag-number.pipe';
import { FeatureFlagPurePipe } from './pipes/feature-flag-pure.pipe';
import { FeatureFlagJsonPipe } from './pipes/feature-flag-json.pipe';

@NgModule({
  imports: [CommonModule, NgxsModule.forFeature([AuthState])],
  providers: [FeatureFlagPurePipe, FeatureFlagNumberPipe, FeatureFlagJsonPipe],
  declarations: [
    FeatureFlagPurePipe,
    FeatureFlagNumberPipe,
    FeatureFlagJsonPipe,
  ],
  exports: [FeatureFlagPurePipe, FeatureFlagNumberPipe, FeatureFlagJsonPipe],
})
export class FeatureFlagsModule {
  static forRoot(config: {
    clientId: string;
  }): ModuleWithProviders<FeatureFlagsModule> {
    return {
      ngModule: FeatureFlagsModule,
      providers: [
        {
          provide: LaunchDarklyClientId,
          useValue: config.clientId,
        },
      ],
    };
  }
}
