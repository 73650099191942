import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'wilson-version-update',
  templateUrl: './version-update.component.html',
  styleUrls: ['./version-update.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionUpdateComponent {
  @Input() updateRequired = false;
  @Input() storeUrl: string;

  constructor(private modalCtrl: ModalController, private router: Router) {}

  dismiss() {
    this.modalCtrl.dismiss();
    this.router.navigate(['/tabs/home']);
  }
}
