import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationAbbreviationNamePipe } from './location-abbreviation-name.pipe';

@NgModule({
  declarations: [LocationAbbreviationNamePipe],
  imports: [CommonModule],
  exports: [LocationAbbreviationNamePipe],
})
export class LocationAbbreviationNameModule {}
