import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsOneActivityStartedPipe } from './is-one-activity-started.pipe';
import { AreAllActivitiesFinishedPipe } from './are-all-activities-finished.pipe';
import { IsShiftSubmittedPipe } from './is-shift-submitted.pipe';
import { IsShiftInPastPipe } from './is-shift-in-past.pipe';
import { IsItemInBucketPipe } from './is-item-in-bucket.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    IsShiftInPastPipe,
    IsShiftSubmittedPipe,
    IsOneActivityStartedPipe,
    AreAllActivitiesFinishedPipe,
    IsItemInBucketPipe,
  ],
  providers: [
    IsShiftInPastPipe,
    IsShiftSubmittedPipe,
    IsOneActivityStartedPipe,
    AreAllActivitiesFinishedPipe,
  ],
  exports: [
    IsShiftInPastPipe,
    IsShiftSubmittedPipe,
    IsOneActivityStartedPipe,
    AreAllActivitiesFinishedPipe,
    IsItemInBucketPipe,
  ],
})
export class ActivitiesPipesModule {}
