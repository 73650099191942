import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TypeOrmFindManyOptions } from '@wilson/base';
import { ConfigOptions, ConfigService } from '@wilson/config';
import {
  Activity,
  AtLeast,
  ResolvedService,
  Service,
} from '@wilson/interfaces';
import { DateTimeFormat } from '@wilson/utils';
import { format } from 'date-fns';
import { stringify } from 'qs';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ServicesGateway {
  constructor(
    private readonly httpClient: HttpClient,
    @Inject(ConfigService)
    private readonly config: ConfigOptions,
  ) {}

  getServicesByDateAndSortActivities(
    startDate: Date,
    endDate: Date,
    withActivityStatus = false,
  ) {
    return this.getServices(
      {
        where: {
          startDate: [
            format(startDate, DateTimeFormat.DatabaseDateFormat),
            format(endDate, DateTimeFormat.DatabaseDateFormat),
          ],
        },
        relations: [
          'organizationalUnit',
          'activities',
          'activities.activityCategory',
          'activities.activityReports',
          'activities.activityReports.location',
          'activities.startLocation',
          'activities.endLocation',
          'activities.serviceDeviations',
          'serviceSeries',
          'serviceSeries.serviceSeries',
          'labels',
        ],
        order: {},
        limit: 0,
        offset: 0,
      },
      {
        withActivityStatus,
      },
    ).pipe(
      map((services: ResolvedService[]) => {
        services.forEach((service) => {
          // Sort in place
          this.sortActivitiesAscendingByStartDate(service.activities);
        });
        return services;
      }),
    );
  }

  // Earlier time first
  private sortActivitiesAscendingByStartDate(activities: Activity[]): void {
    activities.sort((a, b) => a.startDatetime.localeCompare(b.startDatetime));
  }

  getServicesByDate(startDate: string) {
    return this.httpClient.post<Service[]>(
      `${this.config.host}/services/search`,
      { startDate },
    );
  }

  getService(serviceId: string) {
    return this.httpClient.get<Service>(
      `${this.config.host}/services/${serviceId}`,
    );
  }

  getServiceWithActivityInRange(
    options: TypeOrmFindManyOptions,
  ): Observable<ResolvedService[]> {
    const params = stringify({ ...options });

    return this.httpClient.get<ResolvedService[]>(
      `${this.config.host}/services/activities/date-range?${params}`,
    );
  }

  getServices(
    options: TypeOrmFindManyOptions,
    extendedOptions = {},
  ): Observable<ResolvedService[]> {
    const params = stringify({ ...options, ...extendedOptions });

    return this.httpClient.get<ResolvedService[]>(
      `${this.config.host}/services?${params}`,
    );
  }

  getResolvedService(serviceId: string) {
    return this.httpClient.post<ResolvedService[]>(
      `${this.config.host}/services/resolved`,
      { id: serviceId },
    );
  }

  getResolvedServicesByDate(startDate: string) {
    return this.httpClient.post<ResolvedService[]>(
      `${this.config.host}/services/resolved`,
      { startDate },
    );
  }

  createService(
    services: Omit<
      Service,
      'id' | 'createdAt' | 'updatedAt' | 'deletedAt' | 'version'
    >[],
  ) {
    return firstValueFrom(
      this.httpClient.post<Service[]>(`${this.config.host}/services`, {
        items: services,
      }),
    );
  }

  updateService(service: AtLeast<Service, 'id'>) {
    return firstValueFrom(
      this.httpClient.patch<Service>(
        `${this.config.host}/services/${service.id}`,
        service,
      ),
    );
  }

  deleteService(serviceId: string) {
    return firstValueFrom(
      this.httpClient.delete<Service>(
        `${this.config.host}/services/${serviceId}`,
      ),
    );
  }

  getResolvedServicesActivitiesByDateRange(startDate: string, endDate: string) {
    return this.getServiceWithActivityInRange({
      where: {
        startDate,
        endDate,
      },
      relations: [
        'organizationalUnit',
        'activities',
        'activities.agreement',
        'activities.agreement.client',
        'activities.activityCategory',
        'activities.startLocation',
        'activities.endLocation',
        'activities.profession',
        'serviceSeries',
        'serviceSeries.serviceSeries',
      ],
    }).pipe(
      map((currentServices) => {
        const serviceActivities: Activity[] = [];
        for (const service of currentServices) {
          for (const activity of service.activities) {
            activity.service = service;
            serviceActivities.push(activity);
          }
        }
        return serviceActivities;
      }),
    );
  }
}
