export enum PublicationStatus {
  Published = 'published',
  NotPublished = 'notPublished',
  NotPublishedAgain = 'notPublishedAgain',
  Read = 'read',
}

export enum ShiftActivityStatus {
  Occupied = 'Occupied',
  NotOccupied = 'NotOccupied',
  NotReleased = 'NotReleased',
}

export enum AdditionalShiftActivityStatusFilter {
  Released = 'Released',
}
