import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { parseISO } from 'date-fns';

const SECONDS_IN_A_MINUTE = 60;
const SECONDS_IN_AN_HOUR = 3600;
const SECONDS_IN_A_DAY = 86400;
const SECONDS_IN_A_MONTH = 2592000;
const SECONDS_IN_A_YEAR = 31536000;

@Pipe({
  name: 'lastUpdate',
  standalone: true,
  pure: false,
})
export class LastUpdatePipe implements PipeTransform {
  constructor(private readonly translate: TranslateService) {}

  transform(
    date: string,
    settings: { prefixText: string; showSeconds: boolean },
  ): string {
    const now = new Date();
    const updatedDate = parseISO(date);
    const secondsAgo = Math.floor(
      (now.getTime() - updatedDate.getTime()) / 1000,
    );

    let timeString = '';

    if (secondsAgo < SECONDS_IN_A_MINUTE) {
      if (settings.showSeconds && secondsAgo >= 10) {
        const unit = this.translate.instant(
          'unit.second.n' + (secondsAgo === 1 ? '1' : 'n'),
        );
        timeString = this.translate.instant('unit.day.last_update_str', {
          num: secondsAgo,
          str: unit,
        });
      } else {
        timeString = this.translate.instant('general.seconds_ago');
      }
    } else if (secondsAgo < SECONDS_IN_AN_HOUR) {
      const minutes = Math.floor(secondsAgo / SECONDS_IN_A_MINUTE);
      const unit = this.translate.instant(
        'unit.minute.n' + (minutes === 1 ? '1' : 'n'),
      );
      timeString = this.translate.instant('unit.day.last_update_str', {
        num: minutes,
        str: unit,
      });
    } else if (secondsAgo < SECONDS_IN_A_DAY) {
      const hours = Math.floor(secondsAgo / SECONDS_IN_AN_HOUR);
      const minutes = Math.floor(
        (secondsAgo % SECONDS_IN_AN_HOUR) / SECONDS_IN_A_MINUTE,
      );
      const hourUnit = this.translate.instant(
        'unit.hour.n' + (hours === 1 ? '1' : 'n'),
      );
      const minuteUnit =
        minutes > 0
          ? ` ${minutes} ${this.translate.instant(
              'unit.minute.n' + (minutes === 1 ? '1' : 'n'),
            )}`
          : '';
      timeString = this.translate.instant('unit.day.last_update_str', {
        num: hours,
        str: `${hourUnit}${minuteUnit}`,
      });
    } else if (secondsAgo < SECONDS_IN_A_MONTH) {
      const days = Math.floor(secondsAgo / SECONDS_IN_A_DAY);
      const unit = this.translate.instant(
        'unit.day.n' + (days === 1 ? '1' : 'n'),
      );
      timeString = this.translate.instant('unit.day.last_update_str', {
        num: days,
        str: unit,
      });
    } else if (secondsAgo < SECONDS_IN_A_YEAR) {
      const months = Math.floor(secondsAgo / SECONDS_IN_A_MONTH);
      const unit = this.translate.instant(
        'unit.month.n' + (months === 1 ? '1' : 'n'),
      );
      timeString = this.translate.instant('unit.day.last_update_str', {
        num: months,
        str: unit,
      });
    } else {
      const years = Math.floor(secondsAgo / SECONDS_IN_A_YEAR);
      const unit = this.translate.instant(
        'unit.year.n' + (years === 1 ? '1' : 'n'),
      );
      timeString = this.translate.instant('unit.day.last_update_str', {
        num: years,
        str: unit,
      });
    }

    return `${settings.prefixText} ${timeString}`;
  }
}
